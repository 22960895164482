/*  IF the page loads > 959, show the builder.
 IF the page loads < 959, show the message.
 IF the page loads < 959 and grows, show the message.
 IF the page loads > 959 and shrinks, show the message. */
'use strict'
/**
 * builderScreenMessage handles the showing/hiding of the builder on small devices.
 * @param $document
 * @param $window
 * @returns {{templateUrl: string, link: link}}
 */
function mcqsBuilderScreenMessage ($document, $window) {
  'ngInject'
  return {
    templateUrl: 'partials/templates/builder/screen_message.html',
    link: link
  }

  /**
   * link function for mcqs-builder-screen-message directive
   * @param scope angular scope
   * @param elem current element
   */
  function link (scope, elem) {
    let el
    let win
    let hideInitially

    activate()

    /**
     * initialisation function that sets up event listeners for resize and sets initial display
     */
    function activate () {
      // Set jqLite elements
      el = angular.element(elem)
      win = angular.element($window)
      // Add event listeners
      win.on('resize', update)
      scope.$on('$destroy', removeWindowListener)
      hideInitially = isMinWidth()
      if (hideInitially) { el.css({'display': 'none'}) }
    }

    /**
     * function that fires on resizing of window
     */
    function update () {
      // If started big is now big
      // Else if started small and is now big OR started small and is still small.
      if (hideInitially && isMinWidth()) {
        el.css({'display': 'none'})
      } else if ((!hideInitially && isMinWidth()) || (hideInitially && !isMinWidth())) {
        el.css({'display': 'block'})
      }
    }

    /**
     * calculates width to decide whether a user sees the builder
     * @returns {boolean}
     */
    function isMinWidth () {
      let body = $document[0].body.offsetWidth
      let window = $window.innerWidth
      return (body > 959 && window > 959)
    }

    /**
     * removes windows listener when scope si destroyed
     */
    function removeWindowListener () { win.off('resize') }
  }
}

export { mcqsBuilderScreenMessage }
