import { cookieBotRender } from './terms_cookiebot_render'
import { termsConfig } from './terms_routes'
import { mcqsTermsToggle } from './terms_toggle'
import { termsController } from './terms_controller';

(() => {
  'use strict'
  angular
    .module('terms', [])
    .config(termsConfig)
    .component('mcqsTermsToggle', mcqsTermsToggle)
    .controller('termsController', termsController)
    .directive('cookieBotRender', cookieBotRender)
})()

export default 'terms'
