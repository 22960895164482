'use strict'
/**
 * mcqs-comment-delete directive
 * @type {{templateUrl: string, bindings: {commentId: string, commentIdx: string, replyIdx: string, isHidden: string, tourComment: string}, controller: mcqsCommentDeleteController, controllerAs: string}}
 */
const mcqsCommentDelete = {
  templateUrl: 'partials/templates/comment/comment_delete.html',
  bindings: {
    commentId: '@',
    commentIdx: '@',
    replyIdx: '<',
    isHidden: '@',
    tourComment: '<'
  },
  controller: mcqsCommentDeleteController,
  controllerAs: 'vm'
}

/**
 * handles the deletion of a comment
 * @param $mdDialog
 * @param CommentService
 */
function mcqsCommentDeleteController ($mdDialog, CommentService) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.openConfirm = openConfirm

  /**
   * opens a dialog requesting the user to confirm deletion, if success calls CommentService.deleteQuestion()
   */
  function openConfirm () {
    $mdDialog.show({
      templateUrl: 'partials/templates/comment/comment_delete_dialog.html',
      controller: 'mcqsCommentDeleteDialogController',
      controllerAs: 'vm',
      disableParentScroll: false,
      fullscreen: true
    }).then(() => {
      CommentService.deleteComment(vm.commentId).then(
        data => CommentService.updateCommentDelete(vm.commentIdx, vm.replyIdx),
        error => console.error('Failed to delete comment:', error)
      )
    })
  }
}

/**
 * controller to handle functionality of the confirm deletion dialog
 * @param $mdDialog
 */
function mcqsCommentDeleteDialogController ($mdDialog) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.cancel = $mdDialog.cancel
  vm.confirm = $mdDialog.hide
}

export { mcqsCommentDelete, mcqsCommentDeleteDialogController }
