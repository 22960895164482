'use strict'

/**
 * mcqs-flashcard-next-button directive
 * @returns {{template: string, scope: {}, bindToController: {isFlipped: string}, controller: string, controllerAs: string}}
 */
function mcqsFlashcardNextButton () {
  'ngInject'
  return {
    template: `
            <md-button
                class="question__toolbar-action md-primary md-raised md-hue-1"
                ng-click="vm.nextFlashcard()"
                ng-disabled="vm.disableNext"
            > Next Flashcard </md-button>
        `,
    scope: {},
    controller: 'flashcardNextController',
    controllerAs: 'vm'
  }
}

/**
 * controller that handles the functionality of the flashcard next button
 * @param $state
 * @param $timeout
 * @param QuestionService
 */
function flashcardNextController ($state, $timeout, QuestionService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.nextFlashcard = nextFlashcard

  /**
   * request a new question
   */
  function nextFlashcard () {
    QuestionService.getQuestion().then(success, failure)

    /**
     * success handler for QuestionService.getQuestion()
     * updates current question
     * @param data object returned from QuestionService.getQuestion()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      // timeout to trigger digest cycle
      $timeout(() => {
        $state.go('root.auth.flashcard')
        QuestionService.updateQuestion(data)
      })
    }

    /**
     * error handler for QuestionService.getQuestion()
     * sets default text for question error
     * @param error object returned from QuestionService.getQuestion()
     */
    function failure (error) {
      console.error('Loading question failed:', error)
      QuestionService.setFailedQuestion()
      $state.go('root.auth.flashcard')
    }
  }
}

export { mcqsFlashcardNextButton, flashcardNextController }
