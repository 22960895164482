// The question view has nested states with their own controllers/data.
'use strict'
import { questionViewController } from './question_view_controller'

/**
 * sets up routes for the question module
 * @param $stateProvider
 */
function routeConfig ($stateProvider) {
  'ngInject'
  $stateProvider
    .state('root.auth.question', {
      url: '/question',
      views: {
        'main@': {
          templateUrl: 'partials/views/question.html',
          controller: questionViewController,
          controllerAs: 'vm'
        }
      }
    })
    .state('root.auth.question.answered', {
      views: {
        'answered@root.auth.question': {
          templateUrl: 'partials/views/question_answered.html',
          controller: 'questionAnsweredController',
          controllerAs: 'vm'
        }
      }
    })
    .state('root.auth.question.answered.explanation', {
      views: {
        'bottom@root.auth.question.answered': {
          templateUrl: 'partials/views/question_explanation.html'
        }
      }
    })
    .state('root.auth.question.answered.comments', {
      views: {
        'bottom@root.auth.question.answered': {
          templateUrl: 'partials/views/comments.html',
          controller: 'commentViewController',
          controllerAs: 'vm',
          resolve: {
            // on resolve passes attemptId to commentViewController
            reqObj: QuestionService => { return { attemptId: QuestionService.question.attemptId || null } }
          }
        }
      }
    })
}

export { routeConfig }
