'use strict'

/**
 * mcqs-lottie-player directive
 * @param LottieService
 * @param LottieHandler
 * @param $compile
 * @param $timeout
 * @returns {{scope: {lottie: string}, link: link, restrict: string}}
 */
function mcqsLottieMediaPlayer (LottieService, LottieHandler, $compile, $timeout) {
  return {
    restrict: 'E',
    template: `<div flex display="flex" class="lottie-media-bar-container" layout="row">
               <md-button
                  class="md-icon-button lottie-player__buttons"
                  ng-click="play()"
              >
                  <md-tooltip>Play/Pause</md-tooltip>
                  <md-icon ng-class="playPauseClass()"></md-icon>
              </md-button>
              <md-button
                  ng-if="audio"
                  ng-click="muteAudio()"
                  class="md-icon-button lottie-player__buttons">
                  <md-tooltip>Mute/Unmute</md-tooltip>
                  <md-icon ng-class="muteUnmuteClass()"></md-icon>
              </md-button>    
              <md-button
                  ng-click="slowDown()"
                  class="md-icon-button lottie-player__buttons">
                  <md-tooltip>Slow Down</md-tooltip>
                  <md-icon class="fa fa-1x fa-backward lottie-player__icons"></md-icon>
              </md-button>
              <md-button
                  ng-click="speedUp()"
                  class="md-icon-button lottie-player__buttons">
                  <md-tooltip>Speed up</md-tooltip>
                   <md-icon class="fa fa-1x fa-forward lottie-player__icons"></md-icon>
              </md-button>
              <md-slider-container class="lottie-slider-container" flex-grow>
                  <md-slider
                      aria-label="lottieAnimation-slider"
                      ng-change="onChange()"
                      class="media-slider"
                      ng-model="lottieAnimation.currentFrame"
                      min="0"
                      max="{{lottieAnimation.totalFrames}}"
                  ></md-slider>
              </md-slider-container>
              <md-button
                  ng-click="invertDirection()"
                  class="md-icon-button lottie-player__buttons">
                      <md-tooltip>Reverse play order</md-tooltip>
                      <md-icon ng-class="flipPlayDirectionClass()"></md-icon>
              </md-button>
            </div>`,
    scope: {
      lottieAnimation: '=',
      questionId: '@',
      audio: '<'
    },
    link: (scope, elem) => {
      'ngInject'
      const animationStateId = LottieHandler.addAnimation()
      const playerStates = LottieHandler.playerStates[animationStateId]
      let slider, polyAudio

      scope.playPauseClass = playPauseClass
      scope.flipPlayDirectionClass = flipPlayDirectionClass
      scope.invertDirection = invertDirection
      scope.speedUp = speedUp
      scope.slowDown = slowDown
      scope.onChange = onChange
      scope.muteUnmuteClass = muteUnmuteClass
      scope.muteAudio = muteAudio
      scope.play = play

      if (scope.audio) {
        polyAudio = new window.Howl({
          src: [scope.audio],
          format: 'mp3',
          autoplay: false,
          loop: false,
          volume: 0.2
        })
      }

      createEvents()

      /**
       * creates events for pressing of buttons and slider
       */
      function createEvents () {
        slider = angular.element(elem[0].querySelector('.media-slider'))

        scope.lottieAnimation.addEventListener('loopComplete', () => {
          // if audio is not finished wait for it
          if (polyAudio) {
            if (polyAudio.playing() && polyAudio.volume() !== 0) {
              playerStates.waitingForAudio = true
              scope.lottieAnimation.goToAndStop(0.01, false)
            } else {
              polyAudio.pause()
              polyAudio.seek(0)
              if (!playerStates.directionAltered &&
                !playerStates.speedAltered) polyAudio.play()
            }
          }
        })

        if (polyAudio) {
          polyAudio.on('play', () => {
            scope.$apply(() => {
              playerStates.polyPlaying = true
            })
          })
          polyAudio.on('pause', () => {
            scope.$apply(() => {
              playerStates.polyPlaying = false
            })
          })
          polyAudio.on('end', () => {
            if (!scope.lottieAnimation.isPaused) return
            $timeout(() => {
              scope.$apply(() => {
                playerStates.waitingForAudio = false
                polyAudio.seek(0)
                scope.lottieAnimation.goToAndPlay(0, false)
                if (!playerStates.directionAltered &&
                  !playerStates.speedAltered) polyAudio.play()
              })
            })
          })
        }

        slider.on('$md.pressdown', (e) => {
          playerStates.isSeeking = true
          scope.lottieAnimation.pause()
          if (polyAudio) polyAudio.pause()
        })

        slider.on('$md.pressup', () => {
          playerStates.isSeeking = false
          playerStates.waitingForAudio = false
          if (polyAudio) polyAudio.pause()
          if (playerStates.tempIsPaused) {
            scope.lottieAnimation.goToAndStop(scope.lottieAnimation.currentFrame, true)
            if (polyAudio) polyAudio.seek(scope.lottieAnimation.currentFrame / scope.lottieAnimation.frameRate)
          } else {
            scope.lottieAnimation.goToAndPlay(scope.lottieAnimation.currentFrame, true)
            if (polyAudio && !playerStates.directionAltered) {
              polyAudio.seek(scope.lottieAnimation.currentFrame / scope.lottieAnimation.frameRate)
              polyAudio.play()
            }
            scope.$digest()
          }
        })

        elem.on('$destroy', function () {
          if (polyAudio) {
            polyAudio.off()
            polyAudio.unload()
          }
          LottieHandler.resetPlayerStates()
          scope.lottieAnimation.destroy()
        })
      }

      /**
       * Plays the animation/audio
       */
      function play () {
        switch (true) {
          case scope.lottieAnimation.isPaused &&
            !playerStates.waitingForAudio :
            playerStates.tempIsPaused = false
            scope.lottieAnimation.goToAndPlay(scope.lottieAnimation.currentFrame, true)
            if (polyAudio &&
              !playerStates.directionAltered &&
              !playerStates.speedAltered) polyAudio.play()
            break
          case scope.lottieAnimation.isPaused &&
          !playerStates.polyPlaying &&
          playerStates.directionAltered :
            playerStates.waitingForAudio = false
            scope.lottieAnimation.goToAndPlay(0, true)
            break
          case scope.lottieAnimation.isPaused &&
          !playerStates.polyPlaying &&
          polyAudio &&
          !playerStates.directionAltered &&
          !playerStates.speedAltered :
            polyAudio.play()
            break
          case playerStates.waitingForAudio && polyAudio :
            polyAudio.pause()
            break
          default:
            playerStates.tempIsPaused = true
            if (polyAudio && !playerStates.directionAltered) polyAudio.pause()
            scope.lottieAnimation.goToAndStop(scope.lottieAnimation.currentFrame, true)
            break
        }
      }

      /**
       * ng-class function which return correct icon for play/pause button
       * @returns {string}
       */
      function playPauseClass () {
        switch (true) {
          case scope.lottieAnimation.isPaused &&
          !playerStates.polyPlaying &&
          !playerStates.isSeeking :
            return 'fa fa-1x fa-play lottie-player__icons'
          case !playerStates.tempIsPaused && scope.lottieAnimation.isPaused :
            return 'fa fa-1x fa-pause lottie-player__icons'
          case scope.lottieAnimation.isPaused :
            return 'fa fa-1x fa-play lottie-player__icons'
          case !playerStates.polyPlaying :
            return 'fa fa-1x fa-pause lottie-player__icons'
          default:
            return 'fa fa-1x fa-pause lottie-player__icons'
        }
      }

      /**
       * ng-class function which changes icon for reversing flow of direction
       * @returns {string}
       */
      function flipPlayDirectionClass () {
        return !playerStates.directionAltered
          ? 'fa fa-1x fa-hourglass-start lottie-player__icons'
          : 'fa fa-1x fa-hourglass-end lottie-player__icons'
      }

      /**
       * invert direction of the lottieAnimation flow
       */
      function invertDirection () {
        if (!playerStates.directionAltered) {
          scope.lottieAnimation.setDirection(-1)
          playerStates.directionAltered = true
          if (polyAudio) polyAudio.pause()
          if (playerStates.waitingForAudio) scope.lottieAnimation.play()
        } else {
          scope.lottieAnimation.setDirection(1)
          playerStates.directionAltered = false
          if (polyAudio) {
            polyAudio.seek(scope.lottieAnimation.currentFrame / scope.lottieAnimation.frameRate)
            if (!scope.lottieAnimation.isPaused) polyAudio.play()
          }
        }
      }

      /**
       * function to fire on drag of slider. This allows the lottieAnimation
       * to move in line with the slider position
       */
      function onChange () {
        scope.lottieAnimation.goToAndStop(scope.lottieAnimation.currentFrame, true)
      }

      /**
       * speeds lottieAnimation forward by 0.5
       */
      function speedUp () {
        scope.lottieAnimation.setSpeed(scope.lottieAnimation.playSpeed + 0.5)
        scope.lottieAnimation.playSpeed !== 1
          ? playerStates.speedAltered = true
          : playerStates.speedAltered = false
        if (polyAudio) handleAudioOnAlterSpeed()
      }

      /**
       * slows lottieAnimation down by 0.5
       */
      function slowDown () {
        if (scope.lottieAnimation.playSpeed !== 0.5) {
          scope.lottieAnimation.setSpeed(scope.lottieAnimation.playSpeed - 0.5)
        }
        scope.lottieAnimation.playSpeed !== 1
          ? playerStates.speedAltered = true
          : playerStates.speedAltered = false
        if (polyAudio) handleAudioOnAlterSpeed()
      }

      /**
       * checks the state of the media player to decide whether to play or pause audio
       */
      function handleAudioOnAlterSpeed () {
        if (!scope.lottieAnimation.isPaused && playerStates.speedAltered) {
          polyAudio.pause()
        } else {
          switch (true) {
            case !scope.lottieAnimation.isPaused && playerStates.waitingForAudio :
              playerStates.waitingForAudio = false
              scope.lottieAnimation.goToAndPlay(0, true)
              polyAudio.play()
              break
            case scope.lottieAnimation.isPaused && playerStates.waitingForAudio :
              playerStates.waitingForAudio = false
              scope.lottieAnimation.goToAndStop(0, true)
              polyAudio._onend[0].fn()
              polyAudio.pause()
              break
            case playerStates.directionAltered :
              if (polyAudio) polyAudio.pause()
              break
            case !scope.lottieAnimation.isPaused :
              polyAudio.seek(scope.lottieAnimation.currentFrame / scope.lottieAnimation.frameRate)
              polyAudio.play()
              break
          }
        }
      }
      // TODO test below
      /**
       * returns css for mute button
       * @returns {string}
       */
      function muteUnmuteClass () {
        if (polyAudio) {
          return polyAudio.volume() === 0.2
            ? 'fa fa-1x fa-volume-up lottie-player__icons'
            : 'fa fa-1x fa-volume-off lottie-player__icons'
        } else {
          return 'fa fa-1x fa-volume-off lottie-player__icons'
        }
      }

      /**
       * mutes/unmutes audio
       */
      function muteAudio () {
        if (polyAudio) {
          polyAudio.volume() === 0.2 ? polyAudio.volume(0) : polyAudio.volume(0.2)
        }
      }
    }
  }
}

export { mcqsLottieMediaPlayer }
