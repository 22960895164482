/* global AWS */
'use strict'
import { default as moment } from 'moment'

function config ($httpProvider, $injector, $mdDateLocaleProvider) {
  'ngInject'
  $httpProvider.interceptors.push('httpRequestInterceptor')
  $mdDateLocaleProvider.formatDate = date => moment(date).format('YYYY-MM-DD')

  AWS.config.region = process.env.AWS_REGION // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.AWS_IDENTITY_POOL_ID
  })
}
// http://stackoverflow.com/questions/29944997/angularjs-http-custom-header-for-all-requests

// interceptors are used to add the token cookie as a header for a request header.
// If a response (valid or invalid) returns with a 401 status
// that means users are not authenticated and a dialog popover prompts the users
// to sign back into the app, if they cancel the dialog, they're signed out and redirected

function httpRequestInterceptor ($cookies, $injector, $q, $timeout, $window, APP_VERSION, DOMAIN, GlobalProgressService) {
  'ngInject'
  // If a request takes longer than 500ms to complete, then toggle the progress bar
  let requestTimer
  return {
    request: setHeaders,
    response: handleResponse,
    responseError: handleResponseError
  }
  // Set token header whenever a request is made
  function setHeaders (config) {
    // The Vimeo API request fails if the 'token' header is present
    if (config.url.indexOf('https://vimeo.com/') >= 0) return config
    // stops options being sent when requesting info from youtube api
    if (config.url.indexOf('https://www.googleapis.com/youtube/v3/') >= 0) return config
    config.headers['token'] = $cookies.get('token') || undefined
    config.headers['mcqs_version'] = APP_VERSION || '0.0.0'
    // If the request was made to the API (as opposed to the CDN or cache, etc.)
    if (config.url.indexOf(DOMAIN) >= 0) {
      requestTimer = $timeout(() => {
        // Start global progress bar
        GlobalProgressService.setProgress(true)
      })
    }
    return config
  }

  function handleResponse (response) {
    // kill the timeout
    $timeout.cancel(requestTimer)
    GlobalProgressService.setProgress(false)
    handle400(response.status)
    handle401(response.status)
    handle205(response.status)
    return response || $q.when(response)
  }

  function handleResponseError (response) {
    // kill the timeout
    $timeout.cancel(requestTimer)
    GlobalProgressService.setProgress(false)
    $injector.get('TourBuilderHandler').builderTour.cancel()
    $injector.get('TourNavbarHandler').navbarTour.cancel()
    $injector.get('TourQuestionHandler').questionTour.cancel()
    handle400(response.status)
    handle401(response.status)
    handle205(response.status)
    return $q.reject(response)
  }

  function handle401 (status) {
    // Only show sign_in_dialog if 401 AND on restricted view
    let onRestrictedView = $injector.get('$state').current.name.indexOf('root.auth') >= 0
    if ((status === 401) && onRestrictedView) {
      $injector.get('$mdDialog').cancel()
      $injector.get('$mdDialog').show({
        templateUrl: 'partials/templates/authentication/sign_in_dialog.html',
        controller: 'signInController',
        controllerAs: 'vm',
        fullscreen: true
      })
    }
  }

  function handle400 (status) {
    if (status === 400) {
      $injector.get('$mdDialog').cancel()
      $injector.get('$mdDialog').show(
        $injector.get('$mdDialog').alert()
          .clickOutsideToClose(false)
          .title('400 bad request')
          .textContent('You made a bad request that could not be completed. To prevent this from happening again, please refresh the page.')
          .ariaLabel('400 bad request')
          .ok('Refresh')
      ).then(refreshView)
    }
  }

  function handle205 (status) { if (status === 205) { refreshView() } }

  function refreshView () { $window.location.reload() }
}

export { config, httpRequestInterceptor }
