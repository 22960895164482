'use strict'
import { default as Shepherd } from 'tether-shepherd'

/**
 * This service is where the steps and actions for the nav bar tour are defined
 * @param $document
 * @returns {{mediator, navbarTour: Tour, shepherd, navbarStartTour: navbarStartTour}}
 * @constructor
 */
function TourNavbarHandler (TourProfileHandler, $document) {
  'ngInject'
  const defaults = {
    classes: 'shepherd-theme-arrows-plain-buttons mcqs-shepherd mcqs-shepherd-head'
  }

  const service = {
    mediator: new Shepherd.Evented(),
    navbarTour: new Shepherd.Tour({ defaults: defaults }),
    shepherd: Shepherd,
    navbarStartTour: navbarStartTour
  }

  const cancelButton = {
    text: 'Cancel',
    classes: ' md-button ',
    action: () => service.navbarTour.cancel()
  }

  const buttons = [
    cancelButton,
    {
      text: 'Next',
      classes: ' md-button md-primary md-raised',
      action: () => service.navbarTour.next()
    }
  ]
  service.navbarTour.addStep('1', {
    text: `<p>Clicking this image will display<br>our terms of service and privacy<br>policy.</p>`,
    attachTo: 'mcqs-terms-toggle left',
    scrollTo: true,
    advanceOn: 'click',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          !$document[0].querySelector('.fa-graduation-cap') ? service.navbarTour.next() : service.shepherd.activeTour.show('3')
        }
      }
    ]
  }).addStep('2', {
    title: `Feel like writing a question?`,
    text: `<p>This button will bring you to<br>the Question Builder, where<br>you can create your own questions<br>for the community.</p>`,
    attachTo: '.fa-magic bottom',
    advanceOn: 'click',
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => { service.shepherd.activeTour.show('4') }
      }
    ]
  }).addStep('3', {
    title: `Feel like answering some questions?`,
    text: `<p>This button will bring you to the Question page, <br>where you can answer questions in your current exam.</p>`,
    attachTo: 'a,.md-fab bottom',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    advanceOn: 'click',
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => { service.shepherd.activeTour.show('5') }
      }
    ]
  }).addStep('4', {
    title: `Want to chat with other users?`,
    text: `<p>This button will open the chat side menu, <br>where you can chat with your peers in<br> your current exam.</p>`,
    attachTo: '.fa-comment-o bottom',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    advanceOn: 'click',
    buttons: buttons
  }).addStep('5', {
    title: 'Want to edit your profile?',
    text: `<p>This button will open your<br>profile allowing you to<br>change exams and update<br>your personal information.</p>`,
    attachTo: '.fa-user bottom',
    advanceOn: 'click',
    buttons: buttons
  }).addStep('6', {
    title: `If you ever need help`,
    text: `<p>This button will show you the<br>currently available tours.</p>`,
    attachTo: '.fa-info left',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    advanceOn: 'click',
    buttons: buttons
  }).addStep('7', {
    text: `<p>This button will sign you out<br>and end your current session.</p>`,
    attachTo: '.fa-power-off left',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    advanceOn: 'click',
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          service.navbarTour.complete()
          TourProfileHandler.profileStartTour()
        }
      }
    ]
  })

  // listener to fire off navbar tour
  service.mediator.on('navbar-start-tour', () => { service.navbarTour.start() })

  return service

  function navbarStartTour () { service.mediator.trigger('navbar-start-tour') }
}

export { TourNavbarHandler }
