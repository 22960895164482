'use strict'
import { signInController } from './authentication_signin_controller'

/**
 * mcqs-sign-in-form directive
 * @type {{templateUrl: string, controller: signInController, controllerAs: string}}
 */
const mcqsSignInForm = {
  templateUrl: 'partials/templates/authentication/sign_in_form.html',
  controller: signInController,
  controllerAs: 'vm'
}
export { mcqsSignInForm }
