'use strict'
/**
 * mcqs-submit-on-return directive
 * @param $parse
 * @param $timeout
 * @returns {{link: link}}
 */
function mcqsSubmitOnReturn ($parse, $timeout) {
  'ngInject'
  return {
    link: link
  }

  /**
   * allows users to send messages in the user chat by hitting the return key
   * @param scope
   * @param elem
   * @param attrs
   */
  function link (scope, elem, attrs) {
    const keys = []

    activate()

    /**
     * initialisation function that creates event triggers
     */
    function activate () {
      elem.on('keydown', trackKeys)
      elem.on('keyup', submit)
      elem.bind('$destroy', () => {
        elem.off('keydown', trackKeys)
        elem.off('keyup', submit)
      })
    }

    /**
     * checks the keys pressed and decides if message should be sumbitted
     * @param e event
     */
    function submit (e) {
      // timeout to manually fire digest cycle
      // removes last pressed key from array
      keys.splice(keys.indexOf(e.which), 1)

      let combo = (e.which === 13 && keys.indexOf(16) === -1)

      // if shift + enter are pressed do not submit message
      // $parse is called to fire the function attached to the mcqsSubmitOnReturn attribute in the DOM
      if (combo && attrs.mcqsSubmitOnReturn && isAllowed() && /\S/.test(scope.vm.message)) {
        keys.length = 0
        $parse(attrs.mcqsSubmitOnReturn)(scope)
      }
    }

    /**
     * checks key pressed and pushes it to array
     * @param e
     */
    function trackKeys (e) {
      if (attrs.mcqsSubmitOnReturn && isAllowed()) keys.push(e.which)
    }

    /**
     * checks for allowSubmit attribute on element which is set in ChatService
     * @returns {boolean}
     */
    function isAllowed () {
      return (attrs.allowSubmit !== 'false' && attrs.allowSubmit !== false && attrs.allowSubmit !== 0)
    }
  }
}

export { mcqsSubmitOnReturn }
