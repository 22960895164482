// for vanilla JS:
window.Ps = require('perfect-scrollbar')
// for lottie
window.lottie = require('lottie-web')

window.Howl = require('howler').Howl
// for lottieInteractivity
window.LottieInteractivity = require('@lottiefiles/lottie-interactivity')
// for jQuery:
require('perfect-scrollbar/jquery.js')
