'use strict'
/**
 * mcqs-comment-list-item directive
 * @type {{templateUrl: string, controller: string, bindings: {comment: string}, controllerAs: string, transclude: boolean}}
 */
const mcqsCommentListItem = {
  templateUrl: 'partials/templates/comment/comment_list_item.html',
  controller: 'commentListItemController',
  bindings: { comment: '=' },
  controllerAs: 'vm',
  transclude: true
}

export { mcqsCommentListItem }
