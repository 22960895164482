import io from 'socket.io-client'

/**
 * ChatService is used to handle the creating and closing chat sockets, send and
 * report chat messages
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @param DOMAIN_CHAT teh value of process.env.APP_CHAT.trim()
 * @param $cookies
 * @param $http
 * @param $mdSidenav
 * @param $timeout
 * @returns {{messages: Array, users: {}, settings: {submitOnReturn: boolean, notificationsMuted: boolean}, status: {open: boolean, message: string, unread: boolean}, io: lookup, open: open, report: (function(*=): *), sendMessage: sendMessage, close: close, clearUnread: clearUnread, isOpen: (function(): *), currentUserName: string}}
 * @constructor
 */
function ChatService (DOMAIN, DOMAIN_CHAT, $cookies, $http, $mdSidenav, $timeout) {
  'ngInject'
  let socket
  let messageObj = {type: 'chat'}
  let updateObj = {type: 'update'}
  let socketOptions = {
    'reconnection': true,
    'reconnectionDelay': 200,
    'reconnectionDelayMax': 250,
    'reconnectionAttempts': 10,
    'transports': ['websocket'],
    'force new connection': true
  }
  let userId = null
  let currentUserName = ''

  const service = {
    messages: [],
    // users is an array-like object, e.g. "51": { username: "", profilePic: "" }
    users: {},
    settings: {
      submitOnReturn: $cookies.get('mcqsPreferencesChatSubmit') === 'true',
      notificationsMuted: $cookies.get('mcqsPreferencesChatNotifications') === 'true'
    },
    status: {open: false, message: '', unread: false},
    io,
    open,
    report,
    sendMessage,
    close,
    clearUnread,
    isOpen,
    // service.currentUsername is not currently being used. It was planned for tagging others in the chat.
    currentUserName
  }

  return service

  /**
   * sends the report to the BE
   * @param reportObj
   * @returns {*}
   */
  function report (reportObj) {
    return $http.post(`${DOMAIN}/api/chat/report`, reportObj).then(
      response => (response.status !== 200) ? response : response.data || {},
      error => error
    )
  }

  /**
   * opens a socket for the chat
   * @returns {null}
   */
  function open () {
    if (socket && socket != null) return null
    socket = service.io.connect(`${DOMAIN_CHAT}`, socketOptions)
    socket.on('ChatInit', () => {
      setStatus(true, 'Connected to chat server', Number($cookies.get('userId')))
      setSocketEventHandlers()
      socket.emit('join', {userId})
    })
    socket.on('connect_error', error => setStatus(false, 'Failed to connect to chat server.', null, error))
  }

  /**
   * sets the number of current users to be displayed in the DOM
   */
  function setUsers () {
    let numUsers = Object.keys(service.users).length
    let string = numUsers > 1 ? `${numUsers} active users.` : `One active user.`
    $timeout(() => setStatus(true, `${string}`, userId), 1500)
  }

  /**
   * initialises various socket triggers
   */
  function setSocketEventHandlers () {
    socket.on('users', data => {
      service.users = data.users
      setUsers()
      // service.currentUsername is not currently being used. It was planned for tagging others in the chat.
      service.currentUserName = '@' + service.users[$cookies.get('userId')].username
    })
    socket.on('messages', data => {
      // maps through messages, sets profilePic and pushes into messages array
      data.messages.map(m => {
        const messageOwner = service.users[m.userId]
        if (!m.owner && messageOwner) m.profilePic = messageOwner.profilePic || null
        service.messages.push(Object.assign(m, messageObj))
      })
    })
    // Default socket events
    socket.on('reconnect', data => setStatus(true, 'Reconnected to chat server.', userId))
    socket.on('reconnect_error', data => setStatus(false, 'Reconnection failed. Trying again...'))
    socket.on('reconnect_failed', data => setStatus(false, 'Could not reconnect to chat.'))
    socket.on('disconnect', data => setStatus(false, 'You have been disconnected from the chat server.'))
    // Exception is sent from the back-end in the event of an error (SQL, 404, 500 etc.)
    socket.on('exception', data => {
      console.error('Socket disconnected because of exception:', data)
      service.close()
      $timeout(service.open, 2000)
    })
    // Custom socket events
    socket.on('message', data => {
      // small conflict here bewtween cookie name and local variable.
      // mcqsPreferencesChatNotifications being set to true gives the impression that notifications
      // turned on  but it ois actually the opposite.
      const notificationsMuted = $cookies.get('mcqsPreferencesChatNotifications') === 'true'
      service.settings.notificationsMuted = notificationsMuted
      data.owner = data.owner ? data.owner : data.userId === userId
      const messageOwner = service.users[data.userId]
      if (!data.owner && messageOwner) data.profilePic = messageOwner.profilePic || null
      service.status.unread = (!$mdSidenav('chat').isOpen() && !notificationsMuted)
      $timeout(() => service.messages.push(Object.assign(data, messageObj)))
    })
    socket.on('update', data => $timeout(() => service.messages.push(Object.assign(data, updateObj))))
  }

  /**
   * sets he status of the chat
   * @param open boolean if chat is currently open or not
   * @param message string current message
   * @param user int userId
   * @param error object error if present
   */
  function setStatus (open = false, message = '', user = null, error) {
    $timeout(() => {
      service.status.open = open
      service.status.message = error || message
    })
    userId = user
  }

  /**
   * clears unread status of messages
   */
  function clearUnread () { service.status.unread = false }

  /**
   * emits the message to the socket
   * @param message string message to be sent
   * @returns {null} returned to handle execution
   */
  function sendMessage ({message}) {
    if (!socket || socket == null) return null
    socket.emit('send:message', {userId, message})
  }

  /**
   * destroys and closes the chat socket
   * @returns {null}
   */
  function close () {
    if (!socket || socket == null) return null
    socket.disconnect()
    socket = null
    userId = null
    service.messages.splice(0, service.messages.length)
    Object.keys(service.users).forEach(key => delete service.users[key])
    setStatus(false, 'Disconnected from chat server.')
  }

  /**
   * checks if chat sidenav is open
   * @returns {*}
   */
  function isOpen () { return $mdSidenav('chat').isOpen() }
}

export { ChatService }
