'use strict'
/**
 * mcqs-question-report directive
 * @type {{templateUrl: string, controller: questionReportController, controllerAs: string}}
 */
const mcqsQuestionReport = {
  templateUrl: 'partials/templates/question/question_report.html',
  controller: questionReportController,
  controllerAs: 'vm'
}

/**
 * controller which opens the report dialog
 * @param $mdDialog
 */
function questionReportController ($mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.showDialog = showDialog

  /**
   * opens the question report dialog
   */
  function showDialog () {
    $mdDialog.show({
      templateUrl: 'partials/templates/question/question_report_dialog.html',
      controller: questionReportDialogController,
      controllerAs: 'vm',
      disableParentScroll: false,
      fullscreen: true
    })
  }
}

/**
 * controller attached to the dialog which sends the report to the QuestionService
 * @param $timeout
 * @param $mdDialog
 * @param QuestionService
 */
function questionReportDialogController ($timeout, $mdDialog, QuestionService) {
  /* jshint validthis:true */
  const vm = this

  vm.reportMessage = ''
  vm.selectedOption = 1
  vm.statusMessage = ''
  vm.submitted = false

  vm.options = [
    {label: '...contains incorrect information', value: 1},
    {label: '...has a fault in the options', value: 2},
    {label: '...is poorly worded/written', value: 3},
    {label: '...does not belong in this subject/exam', value: 4},
    {label: '...may contain plagiarised content', value: 5},
    {label: '[ other ]', value: 6}
  ]

  vm.closeDialog = $mdDialog.cancel

  vm.sendReport = sendReport

  /**
   * passes the report to the Question Service
   */
  function sendReport () {
    vm.submitted = true
    vm.statusMessage = ''
    // option 6 allows a user to specify the error
    let message = vm.selectedOption === '6' ? vm.reportMessage : undefined
    QuestionService.reportQuestion(vm.selectedOption, message).then(success, failure)

    /**
     * success handler for QuestionService.reportQuestion()
     * @param data object returned from QuestionService.reportQuestion()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      vm.statusMessage = 'Report sent. Thanks for your feedback!'
      // wait 3 seconds before closing the dialog to allow user to see their report has been received
      $timeout(vm.closeDialog, 3000)
    }

    /**
     * error handler for QuestionService.reportQuestion()
     * @param error object returned from QuestionService.reportQuestion()
     */
    function failure (error) {
      vm.submitted = false
      console.error('Report failed:', error)
      vm.statusMessage = 'Whoops! Something went wrong. Please try again.'
    }
  }
}

export { mcqsQuestionReport, questionReportDialogController }
