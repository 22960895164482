'use strict'

/**
 * controller for the global progress bar to attach the service to the DOM
 * @param GlobalProgressService
 */
function globalProgressController (GlobalProgressService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  // exposed here to attach to the DOM
  vm.progressService = GlobalProgressService
}

export { globalProgressController }
