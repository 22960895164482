'use strict'
function themeConfig ($mdIconProvider, $mdThemingProvider) {
  'ngInject'
  $mdIconProvider.fontSet('fa', 'fontawesome')
  $mdThemingProvider.definePalette('mcqs-navy', {
    '50': '#deeaf7',
    '100': '#a0c2e9',
    '200': '#72a5df',
    '300': '#3880d1',
    '400': '#2c71bf',
    '500': '#2662a6',
    '600': '#20538d',
    '700': '#1b4574',
    '800': '#15365b',
    '900': '#0f2742',
    'A100': '#deeaf7',
    'A200': '#a0c2e9',
    'A400': '#2c71bf',
    'A700': '#1b4574',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 A100 A200'
  }).definePalette('mcqs-green', {
    '50': '#dbf3ea',
    '100': '#a2dfc9',
    '200': '#78d1b0',
    '300': '#43bf91',
    '400': '#3aaa80',
    '500': '#32936f',
    '600': '#2a7c5e',
    '700': '#22654c',
    '800': '#1b4e3b',
    '900': '#13382a',
    'A100': '#dbf3ea',
    'A200': '#a2dfc9',
    'A400': '#3aaa80',
    'A700': '#22654c',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 300 400 A100 A200 A400'
  }).definePalette('mcqs-red', {
    '50': '#ffdada',
    '100': '#ff8e8e',
    '200': '#ff5656',
    '300': '#ff0e0e',
    '400': '#ef0000',
    '500': '#d00000',
    '600': '#b10000',
    '700': '#930000',
    '800': '#740000',
    '900': '#560000',
    'A100': '#ffdada',
    'A200': '#ff8e8e',
    'A400': '#ef0000',
    'A700': '#930000',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': '50 100 200 A100 A200'
  })

  $mdThemingProvider.theme('default')
    .primaryPalette('mcqs-navy')
    .accentPalette('mcqs-green', {
      'default': '400',
      'hue-1': '50'
    })
    .warnPalette('mcqs-red')
}

export { themeConfig }
