'use strict'
/**
 * mcqs-blur directive
 * @param $parse
 * @param $timeout
 * @returns {{restrict: string, link(*=, *=, *=): void}}
 */
function mcqsBlur ($parse, $timeout) {
  'ngInject'
  return {
    restrict: 'A',
    /**
     * This is used to to fire an update in the question builder when focus is lost on a text area
     * @param scope
     * @param elem
     * @param attrs
     */
    link (scope, elem, attrs) {
      activate()

      /**
       * initialisation function that attaches a trigger to fire on blur
       */
      function activate () {
        elem.on('blur', execute)
        scope.$on('$destroy', () => elem.off('blur', execute))
      }

      /**
       * triggers timeout after specified delay
       * @param e event
       */
      function execute (e) {
        const delay = attrs.blurDelay || 0

        // timeout to trigger digest cycle
        $timeout(() => $parse(attrs.mcqsBlur)(scope), delay)
      }
    }
  }
}

export { mcqsBlur }
