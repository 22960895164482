'use strict'

/**
 * controller for mcqsBuilderTags directive
 * @param BuilderService
 * @param BuilderHandler
 * @param ExamService
 * @param AuthenticationService
 * @param $cookies
 * @param $scope
 * @param $filter
 * @param $compile
 * @param $timeout
 * @param $window
 */
function builderTagsController (BuilderService,
                                BuilderHandler,
                                ExamService,
                                AuthenticationService,
                                $cookies,
                                $scope,
                                $filter,
                                $compile,
                                $timeout,
                                $window) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  const userId = $cookies.get('userId')

  vm.removable = true
  vm.placeholder = 'Search for a tag'
  vm.maxChips = 100
  vm.tagNotFoundText = 'Tag not found'

  vm.tagSearch = tagSearch
  vm.tagAdded = tagAdded
  vm.tagRemoved = tagRemoved
  vm.addTag = addTag

  $scope.$watch('vm.questionId', (newVal, oldVal) => {
    if (newVal !== oldVal) {
      loadAllTags()
      if (vm.selectedTags.length === vm.maxChips) {
        vm.tagForm.tagChips.$error['md-max-chips'] = false
      }
    }
  })

  $scope.$watch('vm.examId', (newVal, oldVal) => {
    if (newVal !== oldVal) { loadTagsForExam() }
  })

  init()

  /**
   * initialization function
   */
  function init () {
    AuthenticationService.isAdmin().then((adminId) => {
      vm.adminId = adminId
      loadAllTags()
      $scope.$emit('adminCheckDone')
    }, (err) => {
      console.error('Error checking if user is an admin: ' + err)
    })
  }

  /**
   * Loads question tags, sets selectedTags and loads exam tags
   */
  function loadAllTags () {
    BuilderService.loadQuestionTags(vm.questionId).then((tags) => {
      if (tags.length >= vm.maxChips) {
        // required to display the error on initial load if max
        vm.tagForm.tagChips.$error['md-max-chips'] = true
      }
      setSelectedTags(tags)
      loadTagsForExam()
    }, handlerError)
  }

  /**
   * Allows a user to add a new tag
   * @param tagName - String
   */
  function addTag (tagName) {
    if (vm.liveTags) return
    if (vm.selectedTags.length === 0) {
      BuilderService.addNewTag(tagName, vm.questionId).then(() => {
        BuilderService.loadQuestionTags(vm.questionId).then((tags) => {
          setSelectedTags(tags)
          vm.searchText = ''
        }, handlerError)
      }, handlerError)
    } else {
      for (let selectedTag of vm.selectedTags) {
        // tag already exists in selected tags
        if (selectedTag.tag.toLowerCase() === tagName) {
          vm.tagForm.tagChips.$error['custom-error'] = true
          vm.tagForm.tagChips.$setValidity('custom-error', false)
          break
        } else if (vm.selectedTags.indexOf(selectedTag) === Object.keys(vm.selectedTags).length - 1) {
          BuilderService.addNewTag(tagName, vm.questionId).then(() => {
            BuilderService.loadQuestionTags(vm.questionId).then((tags) => {
              setSelectedTags(tags)
              vm.searchText = ''
            }, handlerError)
          }, handlerError)
        }
      }
    }
  }

  /**
   * loads all tags for exam
   * removes selected tags from tags returned from BE
   * and then sets the vm.tags binding to the updated tags list
   */
  function loadTagsForExam () {
    BuilderService.loadTags(vm.examId || $cookies.get('currentExam'), vm.liveTags).then((tags) => {
      if (vm.selectedTags && vm.selectedTags.length > 0) {
        const selectedTags = vm.selectedTags.map(t => t.tag)
        vm.tags = $filter('orderBy')(tags.filter((element) => !selectedTags.includes(element.tag)), `tag`)
      } else {
        vm.tags = $filter('orderBy')(tags, 'tag')
      }
    }, (err) => {
      console.error('Error loading tags for exam: ' + err)
    })
  }

  /**
   * sets the tags in the handler and if tags are max updates style
   * @param tags
   */
  function setSelectedTags (tags) {
    BuilderHandler.setSelectedTags(tags)
  }

  /**
   * returns either a filtered array by searchText or an empty array
   * @param searchText
   * @returns {Array}
   */

  function tagSearch (searchText) {
    const search = angular.lowercase(searchText)
    checkCustomTag(search)
    // leaving in commented for now in case we want exact match
    // let exactMatch = vm.tags.filter(t => t.tag.toLowerCase().substring(0, search.length).includes(search))
    searchText = vm.tags.filter(t => t.tag.toLowerCase().includes(search)) || []
    return searchText
  }

  /**
   * Checks if a custom tag exists in already selected tags and disables adding
   * if it does
   * @param search - String
   */
  function checkCustomTag (search) {
    if (!vm.liveTags) {
      for (let tag of vm.selectedTags) {
        if (tag.tag.toLowerCase() === search) {
          vm.disableCustomTag = true
          return
        } else {
          vm.disableCustomTag = false
        }
      }
      for (let tag of vm.tags) {
        if (tag.tag.toLowerCase() === search) {
          vm.disableCustomTag = true
          return
        } else {
          vm.disableCustomTag = false
        }
      }
    }
  }

  /**
   * handles adding of a tag to a question
   * if maxLength tags updates style
   * if success then splice added tag out of list
   * @param tag
   * @param index
   */
  function tagAdded (tag, index) {
    if (vm.liveTags) return vm.tags.splice(vm.tags.indexOf(tag), 1)
    BuilderService.addQuestionTags(userId, tag, vm.questionId).then(() => {
      vm.tags.splice(vm.tags.indexOf(tag), 1)
    }, (err) => {
      if (err) console.error('Error adding tag: ' + err)
    })
  }

  /**
   * handles removing of a tag from a question
   * if maxLength tags -1, updates style
   * if success then add tag back into list
   * @param tag
   * @param index
   */
  function tagRemoved (tag, index) {
    if (vm.liveTags) return vm.tags.push(tag)
    if (vm.selectedTags.length === (vm.maxChips - 1)) {
      // bit of a hack but lessens the visual impact of the "pop"
      vm.placeholder = 'Search for a tag'
    }
    BuilderService.removeQuestionTags(userId, tag, vm.questionId).then(() => {
      vm.tags.push(tag)
    }, (err) => {
      if (err) console.error('Error removing tag: ' + err)
    })
  }

  /**
   * logs error if error when loading question tags
   * @param err
   */
  function handlerError (err) {
    console.error('Error loading tags for question: ' + err)
  }
}

export { builderTagsController }
