'use strict'

const mcqsBuilderLinkPuzzles = {
  templateUrl: 'partials/templates/builder/builder_link_puzzles.html',
  bindings: {
    questionId: '@'
  },
  scope: {
    questionId: '@'
  },
  controller: builderLinkedPuzzlesController,
  controllerAs: 'vm'
}

/**
 * builderQuestionListController is used to attach the bindings to the vm, and then question_list.html
 */
function builderLinkedPuzzlesController ($cookies, BuilderService, BuilderHandler, TourBuilderHandler, $scope, $state, $mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this // eslint-disable-line no-unused-vars

  vm.openLinkedPuzzle = openLinkedPuzzle
  vm.deletePuzzleLink = deletePuzzleLink
  vm.toggleDelete = toggleDelete
  vm.cancelDelete = cancelDelete
  vm.addNextPuzzle = addNextPuzzle

  vm.showDeleteOptions = false
  vm.unlinkedPuzzlesLoaded = false

  // TODO this is pulling on load of page
  // TODO Need to pull on load of question
  $scope.$watch('vm.questionId', (newVal, oldVal) => {
    if (newVal !== oldVal) {
      BuilderService.getLinkedChessPuzzles()
        .then((linkedPuzzles) => {
          vm.previousPuzzle = linkedPuzzles.previousPuzzle
          vm.nextPuzzle = linkedPuzzles.nextPuzzle
        })
    }
  })

  function openLinkedPuzzle (questionId) {
    BuilderService.getQuestion(questionId).then((data) => {
      BuilderHandler.clearAll()
      BuilderHandler.setAll(data.question, data.data, data.images || [], data.links || [], data.chess || undefined, data.status)
      BuilderHandler.setLastSave()
      Object.keys(data.data).length > 1 ? $state.go('root.auth.builder.dynamic') : $state.go('root.auth.builder.edit')
      if (TourBuilderHandler.showBuilderTour()) TourBuilderHandler.builderStartTour()
    }, (error) => {
      console.error('Failed to open question ' + questionId, error)
    })
  }

  function toggleDelete () {
    vm.showDeleteOptions = true
  }

  function cancelDelete () {
    vm.showDeleteOptions = false
  }

  function deletePuzzleLink (puzzleId, linkedPuzzleId) {
    BuilderService.deleteLinkedPuzzle(puzzleId, linkedPuzzleId).then(() => {
      BuilderService.getLinkedChessPuzzles()
        .then((linkedPuzzles) => {
          vm.previousPuzzle = linkedPuzzles.previousPuzzle
          vm.nextPuzzle = linkedPuzzles.nextPuzzle
        })
    })
  }

  function addNextPuzzle () {
    // TODO get list of all puzzles that are not already linked
    BuilderService.getUnlinkedPuzzles($cookies.get('currentExam'))
      .then((unlinkedPuzzles) => {
        vm.unlinkedPuzzles = unlinkedPuzzles
        vm.unlinkedPuzzlesLoaded = true

        $mdDialog.show({
          templateUrl: './../../partials/templates/builder/builder_linked_puzzle_list.html',
          controller: builderLinkedPuzzleListController,
          bindToController: true,
          locals: {
            unlinkedPuzzles,
            questionId: vm.questionId
          },
          isolateScope: false,
          controllerAs: 'vm'
        }).then(() => {
          BuilderService.getLinkedChessPuzzles()
            .then((linkedPuzzles) => {
              vm.previousPuzzle = linkedPuzzles.previousPuzzle
              vm.nextPuzzle = linkedPuzzles.nextPuzzle
            })
        })
      })
  }
}

function builderLinkedPuzzleListController (unlinkedPuzzles, questionId, $mdDialog, BuilderService, BuilderHandler, $state) {
  const vm = this
  vm.limit = 20
  vm.unlinkedPuzzles = unlinkedPuzzles

  vm.linkPuzzle = linkPuzzle
  vm.createNewLinkedPuzzle = createNewLinkedPuzzle
  vm.close = close

  function linkPuzzle (linkedQuestionId) {
    BuilderService.setPuzzleLink(questionId, linkedQuestionId)
      .then(() => {
        $mdDialog.hide()
      })
  }

  function createNewLinkedPuzzle () {
    const currentQuestionId = questionId
    BuilderService.cloneQuestion(currentQuestionId)
      .then((data) => {
        let {question, images, links, status, chess} = data
        BuilderHandler.setAll(question, data.data, images || [], links || [], chess || undefined, status)
        // Check if dynamic and change states in case user is on preview
        let isDynamic = Object.keys(data.data).length > 1
        isDynamic ? $state.go('root.auth.builder.dynamic') : $state.go('root.auth.builder.edit')
        // prefix the title with Clone:
        let newTitle = 'Clone: ' + BuilderHandler.question.title
        BuilderHandler.question.title = newTitle.slice(0, 200)
        BuilderService.saveQuestion().then(() => {
          BuilderService.setPuzzleLink(currentQuestionId, question.questionId)
            .then(() => {
              $mdDialog.hide()
            })
        }, (err) => {
          console.error('Error saving question: ', err)
        })
      })
  }

  function close () {
    $mdDialog.hide()
  }
}

export { mcqsBuilderLinkPuzzles }
