import { mcqsAccountProfileForm } from './account_profile_form'
import { routeConfig } from './account_routes'
import { AccountService } from './account_service'
import { accountViewController } from './account_view_controller';

(() => {
  'use strict'
  angular
    .module('account', [])
    .component('mcqsAccountProfileForm', mcqsAccountProfileForm)
    .config(routeConfig)
    .factory('AccountService', AccountService)
    .controller('accountViewController', accountViewController)
})()

export default 'account'
