'use strict'

/**
 * route provider for terms module
 * @param $stateProvider
 */
function termsConfig ($stateProvider) {
  'ngInject'
  $stateProvider.state('root.terms', {
    url: '/terms',
    views: {
      'main@': {
        templateUrl: 'partials/views/terms.html',
        controller: 'termsController',
        controllerAs: 'vm'
      },
      'navbar@': {
        templateUrl: 'partials/views/landing_header.html'
      }
    }
  })
}

export { termsConfig }
