'use strict'
/**
 * mcqs-builder-background directive
 * @type {{templateUrl: string, bindings: {background: string, question: string}, controller: builderBackgroundController, controllerAs: string}}
 */
const mcqsBuilderBackground = {
  templateUrl: 'partials/templates/builder/background.html',
  bindings: {
    background: '=',
    question: '='
  },
  controller: builderBackgroundController,
  controllerAs: 'vm'
}

/**
 * builderBackgroundController is a controller that handles the saving of text in
 * the background and question fields
 * @param BuilderService
 * @param BuilderHandler
 */
function builderBackgroundController (BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.relatedData = []

  vm.getRelatedData = getRelatedData
  vm.update = update

  /**
   * sets the relatedData in the DOM to the realted data in the BuilderHandler
   */
  function getRelatedData () { vm.relatedData = BuilderHandler.getRelatedData() }

  /**
   * sets the background in the handler then verifies and saves the question
   * when losing focus on background inputs
   */
  function update () {
    BuilderHandler.setBackground(vm.background, vm.question).then(() => {
      BuilderHandler.verifyEntireQuestion((err) => {
        if (err) return failure(err)
        BuilderService.saveQuestion().then(success, failure)
      })
    })

    /**
     * success handler for BuilderService.saveQuestion()
     * @param data object returned from BuilderService.saveQuestion
     */
    function success (data) { if (!data || data.status) failure(data) }

    /**
     * error handler for BuilderHandler.verifyEntireQuestion()
     * and BuilderService.saveQuestion()
     * @param error object returned from BuilderHandler.verifyEntireQuestion()
     * and BuilderService.saveQuestion()
     */
    function failure (error) { console.error('Question did not save:', error) }
  }
}

export { mcqsBuilderBackground }
