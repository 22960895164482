'use strict'
import { default as privacy } from './privacy.json'
import { default as terms } from './terms.json'

/**
 * controller for the terms view
 * @param $mdDialog
 * @param $cookies
 * @param APP_VERSION process.env.APP_VERSION.trim()
 */
function termsController ($mdDialog, $cookies, APP_VERSION) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.hidden = true
  vm.cancel = $mdDialog.cancel
  vm.isLoggedIn = false

  activate()

  /**
   * initialisation function that checks if a user is logged in  and sets the
   * starting text of the terms and conditions
   */
  function activate () {
    if ($cookies.get('loginId') != null) {
      vm.isLoggedIn = true
    } else {
      vm.isLoggedIn = false
    }

    vm.disclaimer = `
            We are always working to improve MCQS.com and we release updates to the platform regularly.
            Changes to the site and user experience may occur frequently.
            We ask that you are patient and understanding while new features are integrated into the application.
            You are currently using Version ${APP_VERSION}.
        `
    vm.terms = terms
    vm.privacy = privacy
  }
}

export { termsController }
