'use strict'
/**
 * mcqs-sign-out form directive
 * @type {{templateUrl: string, controller: signOutController, controllerAs: string}}
 */
const mcqsSignOut = {
  templateUrl: 'partials/templates/authentication/sign_out.html',
  controller: signOutController,
  controllerAs: 'vm'
}

/**
 * The signOutController handles signing the user out of all aspects of the app
 * @param $state
 * @param AuthenticationService
 * @param ChatService
 * @param FBService
 * @param GoogleService
 * @param QuestionService
 * @param TourQuestionHandler
 * @param TourBuilderHandler
 * @param TourNavbarHandler
 */
function signOutController ($state, AuthenticationService, ChatService, FBService, GoogleService, QuestionService, TourQuestionHandler, TourBuilderHandler, TourNavbarHandler) {
  'ngInject'
  /* jshint validthis:true */
  let vm = this

  vm.signOut = signOut

  /**
   * Signs a user out using the Authentication Service
   */
  function signOut () {
    AuthenticationService.signOut().then(data => {
      if (!data || data.status) return failure(data)
      destroyAndRedirect()
    }, failure)
  }

  /**
   * Error handler
   * @param error object
   */
  function failure (error) {
    console.error('Sign out failed:', error)
    destroyAndRedirect()
  }

  /**
   * completely ends the current session and redirects the user to the landing page
   */
  function destroyAndRedirect () {
    AuthenticationService.destroySession()
    ChatService.close()
    FBService.loginStatus().then(() => FBService.logout(), error => console.error(error))
    GoogleService.signOut()
    // if tour is active kill all instances of it
    if (TourQuestionHandler.shepherd.activeTour) {
      TourQuestionHandler.questionTour.cancel()
      TourNavbarHandler.navbarTour.cancel()
      TourBuilderHandler.builderTour.cancel()
    }
    QuestionService.question = {}
    $state.go('root.landing', {}, { reload: true })
  }
}
export { mcqsSignOut }
