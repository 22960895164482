import { TourQuestionHandler } from './tour_question_handler'
import { TourBuilderHandler } from './tour_builder_handler'
import { TourNavbarHandler } from './tour_navbar_handler'
import { TourProfileHandler } from './tour_profile_handler'
import { mcqsTourToggle } from './tour_toggle'
import { tourConfig } from './tour_config';

(() => {
  'use strict'
  angular
    .module('tour', [])
    .run(tourConfig)
    .factory('TourBuilderHandler', TourBuilderHandler)
    .factory('TourQuestionHandler', TourQuestionHandler)
    .factory('TourNavbarHandler', TourNavbarHandler)
    .factory('TourProfileHandler', TourProfileHandler)
    .component('mcqsTourToggle', mcqsTourToggle)
})()

export default 'tour'
