'use strict'
/**
 * mcqs-builder-question-speed-dial directive
 * @type {{templateUrl: string, bindings: {examId: string, questionId: string}, controller: builderQuestionSpeedDialController, controllerAs: string}}
 */
const mcqsBuilderQuestionSpeedDial = {
  templateUrl: 'partials/templates/builder/question_speed_dial.html',
  bindings: {
    examId: '@',
    questionId: '@'
  },
  controller: builderQuestionSpeedDialController,
  controllerAs: 'vm'
}

/**
 * builderQuestionSpeedDialController is used to handle the new question functionality
 * on the builder actions md speed dial
 * @param $state
 * @param BuilderService
 * @param BuilderHandler
 * @param TourBuilderHandler
 */
function builderQuestionSpeedDialController ($state, BuilderService, BuilderHandler, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.isOpen = false

  vm.newQuestion = newQuestion
  vm.openDialog = openDialog
  vm.toggle = toggle

  /**
   * toggles display for md-fab-speed-dial
   * @param boolean
   */
  function toggle (boolean) { vm.isOpen = boolean }

  /**
   * opens the question list dialog
   */
  function openDialog () {
    TourBuilderHandler.builderTour.cancel()
    BuilderService.openQuestionDialog({
      closeable: true,
      currentExam: Number(vm.examId),
      currentQuestionId: vm.questionId
    })
  }

  /**
   * creates a new question. First checks if is valid and warns user if it is not
   */
  function newQuestion () {
    if (BuilderHandler.isQuestionValid()) {
      BuilderService.newQuestion(vm.examId).then(success, failure)
    } else {
      // shows dialog that informs user that question will not be saved because of errors
      BuilderHandler.showWarning().then(warningSuccess)
    }
  }

  /**
   * success handler for BuilderHandler.showWarning
   * when BuilderHandler.showWarning resolves successfully create a new question
   */
  function warningSuccess () {
    BuilderService.newQuestion(vm.examId).then(success, failure)
  }

  /**
   * success handler for BuilderService.newQuestion()
   * sets whether is dynamic and sets all questions fields to appropriate values returned
   * and then saves the question
   * @param data {{data: {items: {0: string, 1: string, 2: string, 3: string, 4: string}, title: string}, images: [], question: {background: string, core: string, exam: {examId: int, examName: string}, explanation: string, options: {correct: [], incorrect: []}, optionsArray: [], question: string, questionId: int, relatedQuestions: int, title: string}, status: {dynamic: boolean, isDynamic: boolean, isPublished: boolean, isValid: boolean, messages: []}}}
   */
  function success (data) {
    if (!data || !data.hasOwnProperty('question')) return failure(data)
    // Change path, then clear the old question, then set the new question, then add data if dynamic
    let isDynamic = Object.keys(BuilderHandler.data).length > 1
    isDynamic ? $state.go('root.auth.builder.dynamic') : $state.go('root.auth.builder.edit')
    BuilderHandler.clearAll()
    BuilderHandler.setAll(data.question, data.data, data.images || [], data.links || [], data.chess || undefined, data.status)
    if (isDynamic) { BuilderHandler.addRelatedData() }
    // Have to add a title so there is are differences to save
    BuilderHandler.question.title = 'Question title'
    BuilderService.saveQuestion().then(saveSuccess, saveFailure)
  }

  /**
   * error handler for BuilderService.newQuestion()
   * @param error object returned from BuilderService.newQuestion()
   */
  function failure (error) { console.error('Failed to get a new question:', error) }

  /**
   * success handler for BuilderService.saveQuestion() sets status
   * @param data {{isDynamic: boolean, isPublished: boolean, isValid: boolean, messages: []}}
   */
  function saveSuccess (data) { (data && !data.status) ? BuilderHandler.setStatus(data) : failure(data) }

  /**
   * error handler for BuilderService.saveQuestion()
   * @param error object returned from BuilderService.saveQuestion()
   */
  function saveFailure (error) { console.error('Failed to save new question on load:', error) }
}

export { mcqsBuilderQuestionSpeedDial }
