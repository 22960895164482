'use strict'

/**
 * Controller for the contact us page
 * @param $window
 */
function contactController ($window) {
  'ngInject'
  /* jshint validthis:true */
}

export { contactController }
