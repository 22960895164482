/**
 * mcqs-comment-actions directive
 * @type {{templateUrl: string, bindings: {comment: string, commentIdx: string, reply: string, replyIdx: string}, controller: mcqsCommentActionsController, controllerAs: string}}
 */
const mcqsCommentActions = {
  templateUrl: 'partials/templates/comment/comment_actions.html',
  bindings: {
    comment: '=',
    commentIdx: '@',
    reply: '=',
    replyIdx: '@'
  },
  controller: mcqsCommentActionsController,
  controllerAs: 'vm'
}

/**
 * hadles the toggling of a comments replies
 * @param TourQuestionHandler
 */
function mcqsCommentActionsController (TourQuestionHandler) {
  'ngInject'
  /* validthis:true */
  const vm = this
  const mediator = TourQuestionHandler.mediator

  vm.showReply = false

  vm.toggleReply = toggleReply

  activate()

  /**
   * initialisation function that creates bindings and a trigger for the tour
   */
  function activate () {
    // Don't duplicate the bindings
    mediator.bindings['open-reply'] = []
    // listener to open reply dropdown for tour
    mediator.on('open-reply', () => {
      if (vm.showReply !== true) {
        toggleReply()
        TourQuestionHandler.checkForElement('#comment-post-0')
      }
    })
  }

  /**
   * toggles the boolean that handles showing/hiding of a comments replies
   */
  function toggleReply () { vm.showReply = !vm.showReply }
}
export { mcqsCommentActions }
