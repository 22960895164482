'use strict'
/**
 * mcqs-question-likes directive
 * @type {{templateUrl: string, bindings: {likes: string, dislikes: string, hasRated: string}, controller: questionLikesController, controllerAs: string}}
 */
const mcqsQuestionLikes = {
  templateUrl: 'partials/templates/question/question_likes.html',
  bindings: {
    likes: '=',
    dislikes: '=',
    hasRated: '='
  },
  controller: questionLikesController,
  controllerAs: 'vm'
}

/**
 * controller to handle binding of likes information to the DOM
 * @param QuestionService
 */
function questionLikesController (QuestionService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.rateQuestion = rateQuestion

  activate()

  /**
   * initialisation function that disables rating if question has already been rated
   */
  function activate () {
    vm.disabled = vm.hasRated ? setBoolean(vm.hasRated) : false
    if (vm.disabled === true) { setButtonClasses(vm.hasRated) }
    setAsString()
  }

  /**
   * converts likes/dislikes to a string
   */
  function setAsString () {
    vm.likesAsString = vm.likes ? setNumbers(vm.likes) : 0
    vm.dislikesAsString = vm.dislikes ? setNumbers(vm.dislikes) : 0
  }

  /**
   * passes the rating to the BE
   * @param thumbId id of button clicked
   */
  function rateQuestion (thumbId) {
    vm.disabled = true
    setButtonClasses(thumbId);
    (thumbId === 1) ? vm.likes++ : vm.dislikes++
    setAsString()
    QuestionService.rateQuestion(thumbId, vm.likes, vm.dislikes).then(success, failure)
    function success (data) { if (!data || data.status) { failure(data) } }
    function failure (error) { if (error) { console.error('Rating attempt failed:', error) } }
  }

  /**
   * converts a number to a boolean
   * @param number
   * @returns {boolean}
   */
  function setBoolean (number) { return (number > 0) }

  /**
   * truncates large numbers to a more presentable format eg 10k , 1M
   * @param number
   * @returns {*}
   */
  function setNumbers (number) {
    if (number > 999) {
      let numberLength = ('' + number).length
      let power = Math.pow
      let decimal = power(10, 1)
      let remaining = numberLength -= (numberLength % 3)
      return '' + Math.round(((number * decimal) / power(10, remaining)) / decimal) + ' kMGTPE'[remaining / 3]
    }
    return number || 0
  }

  /**
   * sets css classes to be appended to thumbs up/down buttons
   * @param thumbId id of button clicked
   */
  function setButtonClasses (thumbId) {
    (Number(thumbId) === 1) ? applyClasses('question__rating-icon--liked', '') : applyClasses('', 'question__rating-icon--disliked')
  }

  /**
   * applies to classes to ng-class
   * @param likedClass string class if liked
   * @param dislikedClass string class if disliked
   */
  function applyClasses (likedClass, dislikedClass) {
    vm.buttonLikedClass = likedClass
    vm.buttonDislikedClass = dislikedClass
  }
}

export { mcqsQuestionLikes }
