/*
 The dashboard module is used to display details to the user such as user score, answered
 questions and score over time.
 The dashboard is currently not used in the app.
 */
import { mcqsDashboardGraph } from './dashboard_graph';

(() => {
  'use strict'
  angular
    .module('dashboard', [])
    .component('mcqsDashboardGraph', mcqsDashboardGraph)
})()

export default 'dashboard'
