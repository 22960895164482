module.exports=[
    {
        "text": "By visiting, registering with and using MCQS.com you agree to the following terms of service and acknowledge our privacy policies regarding registration, personal information, cookies, contact and third-party vendors."
    },
    {
        "text": "MCQS.com refers to products and services at the registered domains https://mcqs.com and https://faircity.com including the application code and content."
    },
    {
        "text": "Changes made to personal information or to a user’s records and progress reports are done at the discretion of the user. MCQS.com does not take responsibility for errors which may cause damage or disruption to a user’s account, personal information, session data, progress data or ranking."
    },
    {
        "text": "Site members and users declare by their activity that they will not use the site to copy, reproduce, sell or steal any content, both artistic and academic, from the site, nor will they allow their account to be tampered with by a third-party for such purposes. All content is kept, produced, reproduced and maintained by MCQS.com as an e-learning resource and is to be used in such capacity only. Any breaches to this agreement will be seen as a failure by the user/member and any action (legal and otherwise) may be taken against such."
    },
    {
        "text": "MCQS.com reserves the right to suspend or permanently remove a user account if the user is found to be in breach of any terms of service."
    },
    {
        "text": "Thank you for using MCQS.com."
    }
]
