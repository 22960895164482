'use strict'
/**
 * mcqs-password-reset-leave directive
 * @type {{template: string, controller: passwordResetLeaveController, controllerAs: string}}
 */
const mcqsPasswordResetLeave = {
  template: `
        <md-button ui-sref="root.auth.account" ng-if="vm.hasSession">Back to account</md-button>
        <md-button ui-sref="root.auth.question" class="profile__back-to-questions-button" ng-if="vm.hasSession">Back to questions</md-button>
        <md-button ui-sref="root.signin" ng-if="!vm.hasSession">Sign in</md-button>
    `,
  controller: passwordResetLeaveController,
  controllerAs: 'vm'
}

/**
 * Users should not be allowed access the landing, sign in or registration views
 * if they are signed in and authenticated. This is the controller for a button
 * that sits on the password reset view which changes depending on whether a
 * user is logged in or not
 * @param AuthenticationService
 */
function passwordResetLeaveController (AuthenticationService) {
  'ngInject'
  /* jshint validthis:true */
  let vm = this

  vm.hasSession = false

  activate()

  /**
   * Initial function that runs when passwordResetLeaveController is loaded
   * checks for an active session
   */
  function activate () { vm.hasSession = AuthenticationService.hasSession() }
}
export { mcqsPasswordResetLeave }
