import { aboutUsController } from './about_us_controller'
import { contactController } from './contact_controller'
import { examplesController } from './examples_controller'
import { mcqsFooter } from './footer'
import { landingFooterController } from './footer-controller'
import { landingHeaderController } from './header_controller'
import { landingController } from './landing_controller'
import { productController } from './product_controller'

(() => {
  'use strict'
  angular
    .module('landing', [])
    .controller('aboutUsController', aboutUsController)
    .controller('contactController', contactController)
    .controller('examplesController', examplesController)
    .controller('landingFooterController', landingFooterController)
    .controller('landingHeaderController', landingHeaderController)
    .controller('landingController', landingController)
    .controller('productController', productController)
    .component('mcqsFooter', mcqsFooter)
})()

export default 'landing'
