'use strict'
/**
 * mcqs-builder-publish-button directive
 * @type {{template: string, controller: builderPublishButtonController, controllerAs: string}}
 */
const mcqsBuilderPublishButton = {
  template: `
    <div>
        <md-button
            id="builder_publish_button"
            class="md-accent md-hue-2 md-raised"
            ng-if="!vm.service.status.isPublished"
            ng-disabled="!vm.service.status.isValid"
            ng-click="vm.setPublishStatus(true)"
        >Publish</md-button>
        <md-button
            id="builder_publish_button"
            class="md-warn md-hue-3 md-raised"
            ng-if="vm.service.status.isPublished"
            ng-click="vm.setPublishStatus(false)"
        >Unpublish</md-button>
    </div>
    `,
  controller: builderPublishButtonController,
  controllerAs: 'vm'
}

/**
 * builderPublishButtonController handles the publishing of questions.
 * @param BuilderService
 * @param BuilderHandler
 */
function builderPublishButtonController (BuilderService, BuilderHandler) {
  'ngInect'
  /* jshint validthis:true */
  const vm = this

  vm.service = BuilderHandler

  vm.setPublishStatus = setPublishStatus

  /**
   * toggles whether a question is published or unpublished
   * @param boolean true for published false for unpublished
   */
  function setPublishStatus (boolean) {
    BuilderService.publishQuestion(boolean).then(success, failure)

    /**
     * success handler for BuilderService.publishQuestion()
     * sets the status in the service to published or calls failure
     * @param data data object returned from BuilderService.publishQuestion()
     */
    function success (data) { (data && !data.status) ? vm.service.setStatus({ isPublished: boolean }) : failure(data) }

    /**
     * error handler for BuilderService.publishQuestion()
     * @param error object returned from BuilderService.publishQuestion()
     */
    function failure (error) { console.error(`Failed to set publish status ${boolean}: `, error) }
  }
}

export { mcqsBuilderPublishButton }
