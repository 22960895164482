import { chatConfig } from './chat_config'
import { mcqsChatMessage } from './chat_message'
import { mcqsChatReport, chatReportDialogController } from './chat_report'
import { ChatService } from './chat_service'
import { mcqsChatSettings, mcqsChatSettingsDialogController } from './chat_settings'
import { chatSidenav } from './chat_sidenav'
import { mcqsChatStatus } from './chat_status'
import { mcqsChatUpdate } from './chat_update';

(() => {
  'use strict'
  angular
    .module('chat', [])
    .run(chatConfig)
    .component('mcqsChatMessage', mcqsChatMessage)
    .component('mcqsChatReport', mcqsChatReport)
    .controller('chatReportDialogController', chatReportDialogController)
    .factory('ChatService', ChatService)
    .component('mcqsChatSettings', mcqsChatSettings)
    .controller('mcqsChatSettingsDialogController', mcqsChatSettingsDialogController)
    .component('chatSidenav', chatSidenav)
    .component('mcqsChatStatus', mcqsChatStatus)
    .component('mcqsChatUpdate', mcqsChatUpdate)
})()

export default 'chat'
