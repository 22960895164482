'use strict'

/**
 * configuration used to manage transitions while the tour is active
 * @param $rootScope
 * @param TourNavbarHandler
 * @param TourBuilderHandler
 * @param TourQuestionHandler
 * @param TourProfileHandler
 */
function tourConfig ($rootScope, TourNavbarHandler, TourBuilderHandler, TourQuestionHandler, TourProfileHandler) {
  'ngInject'
  $rootScope.$on('$stateChangeStart', (e, toState, toParams, fromState) => {
    const toQuestion = toState.name.indexOf('root.auth.question') >= 0
    const fromQuestion = fromState.name.indexOf('root.auth.question') >= 0
    const toBuilder = toState.name.indexOf('root.auth.builder') >= 0
    const fromBuilder = fromState.name.indexOf('root.auth.builder') >= 0
    const toPreview = toState.name.indexOf('preview') >= 0
    const toFlashcard = toState.name.indexOf('root.auth.flashcard') >= 0
    const fromFlashcard = fromState.name.indexOf('root.auth.flashcard') >= 0

    // stops execution if going to to question from question
    if (toQuestion && fromQuestion) return
    // complets tour if going to preview
    if (toPreview) TourBuilderHandler.builderTour.complete()
    // stops execution if going to to builder from builder
    if (toBuilder && fromBuilder) return
    // stops execution if changing between question and flashcard
    if ((toFlashcard && fromQuestion) || (fromFlashcard && toQuestion)) return

    // on any other state changes end tours
    TourBuilderHandler.builderTour.complete()
    TourNavbarHandler.navbarTour.complete()
    TourQuestionHandler.questionTour.complete()
    TourProfileHandler.profileTour.complete()
  })
}

export { tourConfig }
