'use strict'
/* global Cookiebot */

const mcqsCookieError = {
  templateUrl: 'partials/templates/authentication/cookie_error.html',
  restrict: 'E',
  controller: cookieErrorController,
  controllerAs: 'vm'
}

function cookieErrorController (CookieConsentService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.cookieService = CookieConsentService

  vm.returnClass = returnClass
  vm.openCookieDialog = openCookieDialog

  function openCookieDialog () { Cookiebot.show() }

  function returnClass () {
    if (vm.cookieService.cookieConsent) return 'cookie-accepted-text'
    return 'cookie-accepted-not-text'
  }
}

export { mcqsCookieError }
