'use strict'
/**
 * mcqs-builder-clone-question directive
 * @type {{template: string, bindings: {questionId: string}, controller: builderCloneQuestionController, controllerAs: string}}
 */
const mcqsBuilderCloneQuestion = {
  template: `
        <md-button
            aria-label="Clone question"
            class="md-fab md-raised md-mini md-primary md-hue-3"
            ng-click="vm.clone()"
            ng-disabled="vm.isInvalid()"
            ng-class="vm.disabledButton()"
        >
            <md-tooltip md-direction="right">Clone question</md-tooltip>
            <md-icon md-font-set="fa" class="fa fa-clone fa-2x"></md-icon>
        </md-button>
    `,
  bindings: {questionId: '@'},
  controller: builderCloneQuestionController,
  controllerAs: 'vm'
}

/**
 * The builderCloneQuestionController saves the question and the creates a clone of it
 * and switches focus to the new question
 * @param $state
 * @param BuilderService
 * @param BuilderHandler
 */
function builderCloneQuestionController ($state, BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  let handler = BuilderHandler

  vm.clone = clone
  vm.isInvalid = isInvalid
  vm.disabledButton = disabledButton

  /**
   * saves the current question and makes a clone of it if the save is successful
   */
  function clone () {
    BuilderService.saveQuestion().then(data => {
      if (!data || data.status) return saveFailureBefore(data)
      BuilderService.cloneQuestion(vm.questionId).then(success, failure)
    }, saveFailureBefore)
  }

  /**
   * returns whether a question is invalid or not
   * @returns {boolean} true for invalid false for valid
   */
  function isInvalid () {
    if (handler.status.urgentErrorMessages) return (handler.status.urgentErrorMessages.length > 0)
    return false
  }

  /**
   * returns css class to html setting button enabled/disabled state
   * @returns {string} css class to disable button or just empty string
   */
  function disabledButton () {
    return (isInvalid() === true) ? 'builder__button-disabled' : ''
  }

  /**
   * success handler for BuilderService.cloneQuestion()
   * sets all of the clones fields to the data returned from clone question
   * and then saves the clone
   * @param data
   */
  function success (data) {
    let {question, images, links, status, chess} = data
    BuilderHandler.setAll(question, data.data, images || [], links || [], chess || undefined, status)
    // Check if dynamic and change states in case user is on preview
    let isDynamic = Object.keys(data.data).length > 1
    isDynamic ? $state.go('root.auth.builder.dynamic') : $state.go('root.auth.builder.edit')
    // prefix the title with Clone:
    let newTitle = 'Clone: ' + BuilderHandler.question.title
    BuilderHandler.question.title = newTitle.slice(0, 200)
    BuilderService.saveQuestion().then(null, saveFailureAfter)
  }

  /**
   * error handler for BuilderService.cloneQuestion
   * @param error object returned from BuilderService.cloneQuestion()
   */
  function failure (error) { console.error('Failed to clone a new question:', error) }
  // logs an error saving clone but still clones question. Clone will be saved
  // on next question edit.
  function saveFailureBefore (error) {
    console.error('Failed to save clone before clone:', error)
    BuilderService.cloneQuestion(vm.questionId).then(success, failure)
  }

  /**
   * error handler for BuilderService.saveQuestion()
   * @param error object returned from BuilderService.saveQuestion()
   */
  function saveFailureAfter (error) { console.error('Failed to save clone after clone:', error) }
}

export { mcqsBuilderCloneQuestion }
