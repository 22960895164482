import { globalProgressController } from './progress_controller'
import { GlobalProgressService } from './progress_service';

(() => {
  'use strict'
  angular
    .module('progress', [])
    .controller('globalProgressController', globalProgressController)
    .factory('GlobalProgressService', GlobalProgressService)
})()

export default 'progress'
