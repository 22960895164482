'use strict'
/**
 * mcqs-chat-status directive
 * @type {{templateUrl: string, bindings: {status: string}, controller: chatStatusController, controllerAs: string}}
 */
const mcqsChatStatus = {
  templateUrl: 'partials/templates/chat/status.html',
  bindings: {status: '='},
  controller: chatStatusController,
  controllerAs: 'vm'
}

/**
 * chatStatusController binds the status values to chat/status.html
 */
function chatStatusController () {
  'ngInject'
  /* jshint validthis:true */
  const vm = this // eslint-disable-line no-unused-vars
}
export { mcqsChatStatus }
