'use strict'

/**
 * controller for flashcard view
 * @param $timeout
 * @param $scope
 * @param ExamService
 * @param QuestionService
 * @param AuthenticationService
 */
function flashcardViewController ($timeout, $scope, ExamService, QuestionService,
                                  AuthenticationService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.isFlipped = false
  vm.disableNext = false
  /* Bind the controller to the service so that when the question updates
   in the service, the bound object updates also, and it's then passed
   down the chain to the view and to the components in that view. */
  vm.qService = QuestionService

  vm.flip = flip

  if (AuthenticationService.hasSession() !== undefined) { activate() }

  let resizeInProgress = false
  let faceCard = document.getElementById('faceCard')
  let expCard = document.getElementById('expFlashCard')

  $scope.$on('angular-resizable.resizeStart', (event, args) => {
    resizeInProgress = true
  })

  $scope.$on('angular-resizable.resizeEnd', (event, args) => {
    $timeout(() => {
      resizeInProgress = false
    })
  })

  /**
   * initialisation function that gets a flashcard when the view loads
   */
  function activate () {
    // Check for current exam
    let exam = ExamService.getCurrentExam()
    if (!exam || (typeof exam) === 'undefined' || exam === 0) {
      ExamService.showExamSelectDialog(true, null, $scope)
    } else {
      // Reset the question whenever the view loads to stop old flashcards showing
      vm.qService.setBlankQuestion()
      vm.qService.getQuestion().then(data => {
        if (!data || data.status) { return failure(data) }
        QuestionService.updateQuestion(data)
        $timeout(() => {
          updateFaceCard()
        }, 10)
      }, failure)
    }

    /**
     * error handler for QuestionService.getQuestion()
     * @param error object returned from QuestionService.getQuestion()
     */
    function failure (error) {
      console.error('Loading question failed:', error)
      vm.qService.setFailedQuestion()
    }
  }

  /**
   * flip the card and trigger updating
   */
  function flip (override, e) {
    if (e.srcElement.nodeName === 'MD-ICON' ||
      e.srcElement.className === 'md-slider-wrapper' ||
    e.srcElement.nodeName === 'path' ||
    e.srcElement.className.includes('lottie-media-bar-container') ||
    e.srcElement.className.includes('lottie-player__question') ||
    e.srcElement.localName === 'mcqs-lottie-player') return
    if (override) {
      vm.isFlipped = false
      return updateFaceCard()
    }
    if (!resizeInProgress) {
      vm.isFlipped = !vm.isFlipped
      // if (vm.isFlipped) updateFaceCard()
      updateFaceCard()
    }
  }

  /**
   * trigger the updating of the exp cards height
   */
  function updateFaceCard () {
    if (faceCard.style.height === '') {
      faceCard.style.height = '300px'
      expCard.style.height = '0px'
      return
    }
    if (vm.isFlipped === true) {
      faceCard.style.height = '0px'
      setHeight(expCard)
    } else {
      faceCard.style.height = '300px'
      expCard.style.height = '0px'
    }
  }

  /**
   * sets the height of the explanation card
   */
  function setHeight (expCard) {
    let totalHeight = 5
    Array.from(expCard.querySelectorAll('[marked]')).map(e => { totalHeight += e.scrollHeight })
    // styles to be applied after flip
    expCard.style.bottom = '0px'
    expCard.style.position = '0px'
    expCard.style.height = calcHeights(totalHeight) + 'px'
  }

  /**
   * calculates the required card height
   * @param totalHeight int height allowing for padding
   * @returns int updated height
   */
  function calcHeights (totalHeight) {
    return totalHeight < 300 ? 300 : totalHeight + 50
  }
}

export { flashcardViewController }
