/**
 * mcqs-builder-status directive
 * @type {{templateUrl: string, controller: mcqsBuilderStatusController, controllerAs: string}}
 */
const mcqsBuilderStatus = {
  templateUrl: 'partials/templates/builder/builder_status.html',
  controller: mcqsBuilderStatusController,
  controllerAs: 'vm'
}

/**
 * mcqsBuilderStatus handles the setting of the overall question status. It does not
 * display the exact errors but it does inform you off what type/how many errors
 * @param BuilderHandler
 */
function mcqsBuilderStatusController (BuilderHandler) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.showMessages = false
  vm.iconClass = 'fa-angle-double-down'

  vm.handler = BuilderHandler

  vm.getStatusText = getStatusText
  vm.toggleMessages = toggleMessages

  /**
   * decides which error/validation error to display to the user
   * @returns {string}
   */
  function getStatusText () {
    const {isValid, isPublished, messages, urgentErrorMessages} = vm.handler.status
    if (isValid && isPublished) return 'Your question is valid and has been published successfully.'
    if (isValid && !isPublished) return 'Your question is valid and is ready to be published.'
    if (!isValid && urgentErrorMessages) {
      if (urgentErrorMessages.length > 1) return `There are ${urgentErrorMessages.length} problems which prevent your questions from being saved`
      if (urgentErrorMessages.length === 1) return `There is a problem which prevents your questions from being saved`
    }
    if (!isValid && messages) {
      if (messages.length > 1) return `There are ${messages.length} problems which prevent your questions from being published`
      if (messages.length === 1) return `There is a problem which prevents your questions from being published`
    }
  }

  /**
   * shows/hides the error messages and flips the icon appropriately
   */
  function toggleMessages () {
    vm.showMessages = !vm.showMessages
    vm.iconClass = vm.showMessages ? 'fa-angle-double-up' : 'fa-angle-double-down'
  }
}

export { mcqsBuilderStatus }
