'use strict'
/**
 * mcqs-header-actions directive
 * @type {{templateUrl: string, bindings: {questionId: string, exam: string}, transclude: boolean, controller: builderHeaderActionsController, controllerAs: string}}
 */
const mcqsHeaderActions = {
  templateUrl: 'partials/templates/builder/header_actions.html',
  bindings: {
    questionId: '@',
    exam: '='
  },
  transclude: true,
  controller: builderHeaderActionsController,
  controllerAs: 'vm'
}

/**
 * builderHeaderActionsController is used to handle switching between preview and edit
 * @param $state
 * @param BuilderHandler
 * @param TourBuilderHandler
 */
function builderHeaderActionsController ($state, BuilderHandler, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.tour = TourBuilderHandler.shepherd
  vm.$state = $state

  vm.edit = edit

  /**
   * handles transition from preview mode back to edit mode
   */
  function edit () {
    let isDynamic = Object.keys(BuilderHandler.data).length > 1
    vm.$state.go(isDynamic ? 'root.auth.builder.dynamic' : 'root.auth.builder.edit')
  }
}

export { mcqsHeaderActions }
