'use strict'

/**
 * Controller for the chess board in the preview
 * @param $scope
 * @param $rootScope
 * @param $timeout
 * @param BuilderHandler
 * @param ChessHandler
 */
function builderChessPreviewController ($scope, $rootScope, $timeout, BuilderHandler, ChessHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const originalFen = BuilderHandler.chess.fen

  vm.fen = BuilderHandler.chess.fen
  vm.orientation = BuilderHandler.chess.orientation

  let game = ChessHandler.newChessGame(vm.fen)

  $timeout(() => {
    ChessHandler.createStyles()

    let board = document.getElementsByTagName('chess-board')[0]

    board.addEventListener('drag-start', (e) => {
      ChessHandler.addDragStartEvent(e, game)
    })

    board.addEventListener('drop', (e) => {
      ChessHandler.addDropEvent(e, game, board, false, BuilderHandler.preview.optionsArray)
    })

    board.addEventListener('mouseover-square', (e) => {
      ChessHandler.mouseOver(e, BuilderHandler.preview.optionsArray)
    })

    board.addEventListener('mouseout-square', () => {
      ChessHandler.mouseOut()
    })

    $scope.$on('$destroy', () => {
      ChessHandler.removeStyles()
    })

    $rootScope.$on('chessRefreshed', () => {
      game = ChessHandler.resetBoard(board, game, originalFen)
    })
  })
}

export { builderChessPreviewController }
