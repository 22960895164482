import { mcqsBuilderChess } from './builder-chess'
import { mcqsBuilderLinkPuzzles } from './builder-link-chess-puzzle'
import { builderChessController } from './builder-chess-controller'
import { builderChessPreviewController } from './builder-chess-preview-controller'
import { questionChessController } from './question-chess-controller'
import { ChessHandler } from './chess_handler'

(() => {
  'use strict'
  angular
    .module('chess', [])
    .component('mcqsBuilderChess', mcqsBuilderChess)
    .component('mcqsBuilderLinkPuzzles', mcqsBuilderLinkPuzzles)
    .controller('builderChessController', builderChessController)
    .controller('builderChessPreviewController', builderChessPreviewController)
    .controller('questionChessController', questionChessController)
    .factory('ChessHandler', ChessHandler)
})()

export default 'chess'
