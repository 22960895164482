'use strict'
/**
 * mcqs-full-question-visible directive
 * @param $timeout
 * @returns {{restrict: string, link: link}}
 */
function mcqsFullQuestionVisible ($timeout) {
  'ngInject'
  return {
    restrict: 'A',
    /**
     * This sets the height to of the question card as it loads so all of the background text and question text are visible
     * @param scope angular scope
     * @param elem HTML element
     * @param attrs attributes attached to the HTML element
     */
    link: (scope, elem, attrs) => {
      // observes question background and question question and fires updateCards
      // when they change
      if (attrs.refreshOnChange) {
        attrs.$observe('refreshOnChange', updateCards)
        attrs.$observe('refreshOnChange', updateCardsForChess)
      }

      scope.$on('DOMLoaded', () => $timeout(setHeight))

      /**
       * updates the question cards appropriately
       */
      function updateCards () {
        // timeout to trigger digest cycle
        $timeout(() => {
          const images = elem[0].querySelectorAll('img')

          // if no images, finish execution and set height
          if (images.length <= 0) return $timeout(setHeight)
          // if images wait for them to load then set height
          Array.from(images).forEach((img, idx) => {
            img.onload = () => {
              $timeout(setHeight)
            }
            img.src = images[idx].src
          })
        })
      }

      function updateCardsForChess () {
        // timeout to trigger digest cycle
        $timeout(() => {
          const chessBoard = elem[0].querySelectorAll('chess-board')
          if (chessBoard.length <= 0) return $timeout(setHeight)
          elem[0].style.height = chessBoard[0].offsetHeight + 100 + 'px'
        }, 1000)
      }

      /**
       * sets the height of the cards
       */
      function setHeight () {
        // minus 64 to account for navbar.
        const bodyHeight = document.body.clientHeight - 64
        // usable body height is 60% bodyHeight
        const bodyPercent = (bodyHeight / 100) * 60
        // 91 to account for margins on question p tags.
        let totalHeight = 91
        Array.from(elem[0].querySelectorAll('[marked]')).map(e => { totalHeight += e.scrollHeight })
        if (elem[0].id !== 'expFlashCard') {
          elem[0].style.height = calcHeights(totalHeight, bodyPercent) + 'px'
        }
      }

      /**
       * calculates the required card height
       * @param totalHeight int height allowing for padding
       * @param bodyPercent float available height
       * @returns {*}
       */
      function calcHeights (totalHeight, bodyPercent) {
        const isMax = (attrs.maxResize && attrs.maxResize === 'true')
        // if height is greater than usable height set height to usable height
        if (isMax && totalHeight > bodyPercent) return bodyPercent
        // if less than 300 set to 300, else set it to height
        return totalHeight < 300 ? 300 : totalHeight
      }
    }
  }
}
export { mcqsFullQuestionVisible }
