module.exports=[
  {
    "title": "Personal information",
    "text": "Any personal information you register with us or provide at a later date is stored in our database and isn’t shared with any other companies or partner groups. We do not sell, rent or disclose our customers' names, addresses, e-mail addresses, or any other personal information. This applies to all service interactions, including registration, profile updates, exam progress reports and data. This information is managed by our site administrators and routinely checked for bugs, breaches or hacks."
  },
  {
    "title": "Social networks",
    "text": "Users may register and sign in to our services by using a third-party social network like Facebook or Google. Users accept the requesting and use of personal and public information from these services, including names, e-mail addresses and pictures (i.e. 'profile' or 'cover' photographs)."
  },
  {
    "title": "Data we collect and process",
    "text": "To provide you with the best user experience possible we collect and process some data provided by you. This includes an email to handle logging a user in, device information such as browser version and operating system to allow us to implement fallbacks, cookies which are fully explained in the table below and finally we allow a user to optionally provide a first name, surname, date of birth and country to allow us to build a profile for the user."
  },
  {
    "title": "Contact",
    "text": "MCQS.com will contact you from time to time via email regarding updates to the site, improvements and changes to user services, and any new features we launch on the site. Additional communications may relate to security updates or recommendations, and these will also be broadcast across the site. MCQS.com will never contact you for you to share your e-mail address, password or payment information."
  }
]
