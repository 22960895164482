'use strict'
/**
 * mcqs-drag-string directive
 * @returns {{restrict: string, link: link}}
 */
function mcqsDragString () {
  'ngInject'
  return {
    restrict: 'A',
    link: link
  }

  /**
   * This allows a user to drag a media item to a question by dragging into a text area
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attributes attached to HTML element
   */
  function link (scope, elem, attrs) {
    let stringToDrag = ''
    const mediaString = new RegExp(/(!\[YOUTUBE]|!\[VIMEO]|!\[SOUNDCLOUD]|!\[LOTTIE])(\(.*\))/)

    activate()

    /**
     * initialisation function that attaches a dragstart event trigger
     */
    function activate () {
      elem.on('dragstart', setString)
      elem.bind('$destroy', destroy)
    }

    /**
     * sets the value of dragString and appends it to the destination
     * @param e
     */
    function setString (e) {
      if (mediaString.test(attrs.mcqsDragString) === true) {
        stringToDrag = '\n\n ' + attrs.mcqsDragString + '\n'
      } else {
        stringToDrag = '\n ![Image description](' + attrs.mcqsDragString + ') \n'
      }
      // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer
      // console.log('e.originalEvent.dataTransfer ==== ', e.originalEvent.dataTransfer)
      if (e.originalEvent === undefined) {
        e.dataTransfer.setData('text', stringToDrag)
      } else {
        e.originalEvent.dataTransfer.setData('text', stringToDrag)
      }
    }

    /**
     * destroys the dragStart event trigger
     */
    function destroy () { elem.off('dragstart', setString) }
  }
}

export { mcqsDragString }
