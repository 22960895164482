'use strict'
/**
 * mcqs-builder-markdown-help directive
 * @type {{template: string, controller: builderMarkdownHelpController, controllerAs: string}}
 */
const mcqsBuilderMarkdownHelp = {
  template: `
        <md-button class="md-icon-button" ng-click="vm.open()">
        <md-tooltip md-direction="left">Markdown formatting</md-tooltip>
        <md-icon md-font-set="fa" class="fa fa-lightbulb-o fa-2x"></md-icon>
        </md-button>
    `,
  controller: builderMarkdownHelpController,
  controllerAs: 'vm'
}

/**
 * builderMarkdownHelpController handles the displaying of the markdownHelperDialog
 * @param $mdDialog
 */
function builderMarkdownHelpController ($mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.open = open

  /**
   * opens a dialog displaying markdown help to the user
   */
  function open () {
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/markdown_help_dialog.html',
      clickOutsideToClose: true,
      disableParentScroll: false
    })
  }
}

export { mcqsBuilderMarkdownHelp }
