'use strict'
/**
 * Directive that contains flashcard/question switch buttons
 * @type {{templateUrl: string, controller: profileSidenavStateController, controllerAs: string}}
 */
const mcqsProfileSidenavState = {
  templateUrl: 'partials/templates/profile/profile_sidenav_state.html',
  bindings: {
    flashcardHidden: '='
  },
  controller: profileSidenavStateController,
  controllerAs: 'vm'
}

/**
 * Controller to handle the switching of states
 * @param $state
 * @param $cookies
 */
function profileSidenavStateController ($state, $cookies) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  let currentState = $cookies.get('mcqsQuestionFlashcard') || 'question'

  vm.switchState = switchState
  vm.buttonClass = buttonClass
  vm.disableButton = disableButton

  activate()

  function activate () {
    if ($state.current.name.includes('question') && currentState === 'flashcard') {
      $cookies.put('mcqsQuestionFlashcard', 'question')
      currentState = 'question'
    } else if ($cookies.get('mcqsQuestionFlashcard') === undefined) {
      $cookies.put('mcqsQuestionFlashcard', 'question')
    }
  }

  function switchState (state) {
    currentState === 'question'
      ? $state.go('root.auth.flashcard')
      : $state.go('root.auth.question')
    currentState = state
    $cookies.put('mcqsQuestionFlashcard', currentState)
  }

  function buttonClass (state) {
    if (vm.flashcardHidden === 1) return 'sidenav__button-disabled'
    return state === currentState
      ? 'sidenav__button-disabled'
      : 'sidenav__button-enabled'
  }

  function disableButton (state) {
    if (vm.flashcardHidden === 1) return true
    return state === currentState
  }
}

export { mcqsProfileSidenavState }
