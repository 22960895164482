'use strict'
import * as hljs from 'highlight.js'

/**
 * initialises markdown
 * @param markedProvider
 */
function markdownConfig (markedProvider) {
  'ngInject'
  // Apply syntax highlighting to rendered markdown
  // Highlight JS needs the code and a valid language, if an invalid language
  // is given it will not be passed to HighlightJS: https://highlightjs.org/
  markedProvider.setOptions({
    gfm: true,
    tables: true,
    highlight: (code, lang) => {
      return (lang && hljs.getLanguage(lang)) ? hljs.highlight(lang, code, true).value : hljs.highlightAuto(code).value
    },
    sanitize: true
  })
}

export { markdownConfig }
