// https://toddmotto.com/everything-about-custom-filters-in-angular-js/
'use strict'
// this looks to be replaced by utils_overflow_scroll
/**
 * mcqs-ellipsis-filter directive
 * @returns {function(*, *=): string}
 */
function mcqsEllipsisFilter () {
  'ngInject'
  return (value, length) => {
    let cutOff = length || 35
    return value.length <= cutOff ? value : value.slice(0, cutOff) + '...'
  }
}

export { mcqsEllipsisFilter }
