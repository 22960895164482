'use strict'

/**
 * Controller for the landing page
 * @param $window
 * @param $scope
 */
function landingController ($window, $scope) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const body = angular.element(document.getElementsByTagName('body'))
  const realtimeAnalytics = document.getElementsByClassName('landing__realtime-analytics')
  const landingTabs = document.getElementsByClassName('landing-tabs')
  const landingFooter = document.getElementsByClassName('landing-footer')

  angular.element(body[0]).on('scroll', () => {
    if (realtimeAnalytics[0].getBoundingClientRect().top <= $window.innerHeight &&
      !realtimeAnalytics[0].classList.contains('landing__instant-slide-in')) {
      attachSlideIn(realtimeAnalytics[0])
    }
    if (landingTabs[0].getBoundingClientRect().top <= $window.innerHeight &&
      !landingTabs[0].classList.contains('landing__instant-slide-in')) {
      attachSlideIn(landingTabs[0])
    }
    if (landingFooter[0].getBoundingClientRect().top <= $window.innerHeight &&
      !landingFooter[0].classList.contains('landing__instant-slide-in')) {
      attachSlideIn(landingFooter[0])
    }
  })

  $scope.$on('$destroy', () => {
    angular.element(body[0]).off('scroll')
  })

  /**
   * attahces slide in class to the element
   * @param element
   */
  function attachSlideIn (element) {
    angular.element(element).addClass('landing__instant-slide-in')
  }
}

export { landingController }
