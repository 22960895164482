'use strict'
/**
 * mcqs-builder-related-data directive
 * @type {{templateUrl: string, bindings: {relatedData: string}, controller: builderRelatedDataController, controllerAs: string}}
 */
const mcqsBuilderRelatedData = {
  templateUrl: 'partials/templates/builder/dynamic/related_data.html',
  bindings: { relatedData: '=' },
  controller: builderRelatedDataController,
  controllerAs: 'vm'
}

/**
 * The builderRelatedDataController Fires off a verify/save whenever a user updates
 * the relatedData
 * @param BuilderService
 * @param BuilderHandler
 */
function builderRelatedDataController (BuilderService, BuilderHandler) {
  /* jshint validthis:true */
  const vm = this

  vm.isRelated = BuilderHandler.isRelatedData
  // Object.keys returns an array of an objects own property names.
  // Here we bind it to vm.keys so we use it in related_data.html to get the
  // length of objects.
  vm.keys = Object.keys

  vm.toggle = toggle
  vm.update = update

  /**
   * fires validation and save when losing focus on related data input area
   */
  function update () {
    BuilderHandler.data[1] = vm.relatedData
    BuilderHandler.verifyEntireQuestion((err) => {
      if (err) return failure(err)
      BuilderService.saveQuestion().then(success, failure)
    })

    /**
     * success handler for BuilderService.saveQuestion()
     * @param data object returned from BuilderService.saveQuestion()
     */
    function success (data) { if (!data || data.status) failure(data) }

    /**
     * error handler for BuilderService.saveQuestion(), and
     * BuilderHandler.verifyEntireQuestion()
     * @param error object returned from either BuilderService.saveQuestion() or
     * BuilderHandler.verifyEntireQuestion()
     */
    function failure (error) { console.error('Question did not save:', error) }
  }

  /**
   * toggle related data on/off for specific related data node
   * @param parent int 0 for options, 1 for related data
   * @param child int index of toggled child node
   */
  function toggle (parent, child) {
    // checks that the relatedData node has a title
    if (vm.relatedData.title) {
      BuilderHandler.toggleRelatedData(parent, child)
      vm.update()
    }
  }
}

export { mcqsBuilderRelatedData }
