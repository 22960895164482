'use strict'
/**
 * mcqs-builder-related-questions directive
 * @type {{template: string, bindings: {questionId: string, total: string}, controller: builderRelatedQuestionsController, controllerAs: string}}
 */
const mcqsBuilderRelatedQuestions = {
  template: `
        <md-button
            class="md-icon-button builder__header-action"
            ng-click="vm.openDialog()"
            ng-disabled="!vm.total || vm.total < 1"
        >
            <md-tooltip>Related questions (clones)</md-tooltip>
            <div class="fa-stack">
                <md-icon md-font-set="fa" class="fa fa-folder-o fa-stack-2x"></md-icon>
                <strong class="fa-stack-1x builder__header-icon-text" ng-bind="vm.total | mcqsAddPlus"></strong>
            </div>
        </md-button>
    `,
  bindings: {
    questionId: '@',
    total: '='
  },
  controller: builderRelatedQuestionsController,
  controllerAs: 'vm'
}

/**
 * builderRelatedQuestionsController is used to display a dialog to the user showing related questions.
 * @param $mdDialog
 * @param TourBuilderHandler
 */
function builderRelatedQuestionsController ($mdDialog, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.openDialog = openDialog

  /**
   * opens a dialog showing the user all the questions related to the current question
   */
  function openDialog () {
    TourBuilderHandler.builderTour.cancel()
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/dynamic/related_questions_dialog.html',
      controller: builderRelatedQuestionsDialogController,
      controllerAs: 'vm',
      clickOutsideToClose: true,
      disableParentScroll: false,
      locals: { questionId: vm.questionId }
    })
  }
}

/**
 * builderRelatedQuestionsDialogController is used to get and open the related questions.
 * @param questionId
 * @param $mdDialog
 * @param BuilderService
 * @param BuilderHandler
 */
function builderRelatedQuestionsDialogController (questionId, $mdDialog, BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.questions = []
  // bind mdDialog to DDOM so we can close it directly from html
  vm.cancel = $mdDialog.cancel

  vm.loadRelated = loadRelated

  vm.loadRelated()

  /**
   * gets the related questions for the current question and sets them in
   * the BuilderHandler
   */
  function loadRelated () {
    BuilderService.getRelatedQuestions(questionId).then(
      data => {
        if (!data || data.status) return failure(data)
        vm.questions = data
        /* I couldn't get two-way binding to work with 'locals' or 'bindToController'
                    so I update the top level value of relatedQuestions and let it pass back down. */
        BuilderHandler.question.relatedQuestions = vm.questions.length
        // if no related questions found cancel the mdDialog
        if (vm.questions.length < 1) { vm.cancel() }
      }, failure
    )

    /**
     * error handler for BuilderService.getRelatedQuestions()
     * @param error object returned from BuilderService.getRelatedQuestions()
     */
    function failure (error) { console.error('Could not load related questions:', error) }
  }
}

export { mcqsBuilderRelatedQuestions, builderRelatedQuestionsDialogController }
