'use strict'
/**
 * mcqs-chat-settings directive
 * @type {{template: string, controller: mcqsChatSettingsController, controllerAs: string}}
 */
const mcqsChatSettings = {
  template: `
    <div>
        <md-button class="md-icon-button" aria-label="Open chat settings" ng-click="vm.open()">
            <md-icon md-font-set="fa" class="fa fa-cog fa-2x"></md-icon>
        </md-button>
    </div>
    `,
  controller: mcqsChatSettingsController,
  controllerAs: 'vm'
}

/**
 * mcqsChatSettingsController handles opening the dialog for chat settings
 * @param $mdDialog
 */
function mcqsChatSettingsController ($mdDialog) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.open = open

  /**
   * opens the settings windows dialog
   */
  function open () {
    $mdDialog.show({
      templateUrl: 'partials/templates/chat/settings_dialog.html',
      controller: mcqsChatSettingsDialogController,
      controllerAs: 'vm',
      disableParentScroll: false,
      escapeToClose: false
    })
  }
}

/**
 * mcqsChatSettingsDialogController handles the chat settings functionality
 * @param $cookies
 * @param $mdDialog
 * @param ChatService
 */
function mcqsChatSettingsDialogController ($cookies, $mdDialog, ChatService) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.settings = ChatService.settings

  vm.cancel = cancel
  vm.save = $mdDialog.cancel
  vm.toggleSetting = toggleSetting

  activate()

  /**
   * initialisation function, sets settings for chat
   */
  function activate () { vm.originalSettings = angular.copy(vm.settings) }

  /**
   * sets settings back to there value from when dialog was opened
   */
  function cancel () {
    angular.merge(ChatService.settings, vm.originalSettings)
    $mdDialog.cancel()
  }

  /**
   * toggles specific chat setting
   * @param setting string the setting that was changed
   * @param value boolean value of setting to be changed to
   * @param dated boolean used for checking whether the muted cookie should have an expiry
   */
  function toggleSetting (setting, value, dated) {
    let cookieOptions = {}
    // dated is only passed when muting notifications. It is used to check if
    // the cookie should have an expiry
    if (dated && dated === true) {
      let expires = new Date()
      expires.setHours(expires.getHours() + 1)
      cookieOptions = {expires}
    }
    $cookies.put(setting, value, cookieOptions)
  }
}

export { mcqsChatSettings, mcqsChatSettingsDialogController }
