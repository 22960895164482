'use strict'
import { Chess } from 'chess.js'

/**
 * Controller for the chess board in the builder
 * @param $timeout
 * @param BuilderHandler
 * @param BuilderService
 * @param ChessHandler
 */
function builderChessController ($timeout, BuilderHandler, BuilderService, ChessHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const startPosition = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR'
  const game = new Chess()

  let castlingNotation = ''
  let playerNotation = ''
  let enPassant = '-'

  let board = document.getElementsByTagName('chess-board')[0]

  vm.handler = BuilderHandler

  vm.newGame = newGame
  vm.flipBoard = flipBoard
  vm.copyFen = copyFen
  vm.clearBoard = clearBoard
  vm.customFenBlur = customFenBlur
  vm.setEnPassant = setEnPassant
  vm.enPassantChange = enPassantChange

  vm.handler.chess.orientation === 'white'
    ? vm.validEnPassantMoves = ChessHandler.validEnPassantWhite
    : vm.validEnPassantMoves = ChessHandler.validEnPassantBlack

  $timeout(() => {
    if (!game.validate_fen(vm.handler.chess.fen).valid) {
      buildExtraNotation()
      handleErrorFen()
    }
    if (vm.handler.chess.fen.split(' ')[3]) {
      enPassant = vm.handler.chess.fen.split(' ')[3]
      if (enPassant !== '-') {
        setEnPassant(enPassant, false)
        vm.enPassantEnabled = true
        vm.currentEnPassantSquare = enPassant
      }
    }
    // TODO atm every board is saved on question creation this is because there is
    // TODO a PHP error that causes question generation to crash if loading a chess
    // TODO question with no board saved. This is a quick fix for now.
    // TODO This will be resolved when a chess category is created because
    // TODO every question in the chess category should already have a board anyway.
    // TODO Once the chess category is in use could change the fix to the php and just
    // TODO return default board if no board in db.
    BuilderService.saveChess(vm.handler.chess.fen, vm.handler.chess.orientation)
  })

  /**
   * function to run on enPassant change
   * @param enabled
   */
  function enPassantChange (enabled) {
    if (!enabled) {
      setEnPassant('-', true)
    }
  }

  function handleErrorFen () {
    let correctedFen = vm.handler.chess.fen.split(' ')
    buildExtraNotation(correctedFen[0])
  }

  /**
   * attached to html fen input, updates the fen on element blur
   */
  function customFenBlur () {
    if (!game.validate_fen(vm.handler.chess.fen).valid) {
      handleErrorFen()
    }
    if (vm.handler.chess.fen.split(' ')[1] === 'w') {
      board.orientation = 'white'
    } else {
      board.orientation = 'black'
    }
    vm.handler.chess.orientation = board.orientation
    vm.enPassantEnabled = false
    BuilderService.saveChess(vm.handler.chess.fen, vm.handler.chess.orientation)
  }

  /**
   * sets the enPassant square from existing fen
   * @param enPassantSquare
   * @param shouldSave
   */
  function setEnPassant (enPassantSquare, shouldSave) {
    enPassant = enPassantSquare
    vm.currentEnPassantSquare = enPassantSquare
    if (vm.handler.chess.fen.split(' ').length <= 1) {
      buildExtraNotation()
    }
    let fen = vm.handler.chess.fen.split(' ')
    fen[3] = enPassant
    fen = fen.join()
    vm.handler.chess.fen = fen.replace(/,/g, ' ')
    if (shouldSave) {
      BuilderService.saveChess(vm.handler.chess.fen, vm.handler.chess.orientation)
    }
  }

  /**
   * chess-board doesn not return a valid fen. This function
   * builds a fully valid fen for chess.js
   */
  function buildExtraNotation (fenToCorrect) {
    if (fenToCorrect) vm.handler.chess.fen = fenToCorrect
    castlingNotation = ''
    if (board.position.e1 === 'wK') {
      if (board.position.h1 === 'wR') {
        castlingNotation += 'K'
      }
      if (board.position.a1 === 'wR') {
        castlingNotation += 'Q'
      }
    }
    if (board.position.e8 === 'bK') {
      if (board.position.h8 === 'bR') {
        castlingNotation += 'k'
      }
      if (board.position.a8 === 'bR') {
        castlingNotation += 'q'
      }
    }

    if (castlingNotation.length === 0) castlingNotation = '-'

    if (vm.handler.chess.orientation === 'black') {
      playerNotation = 'b'
    } else {
      playerNotation = 'w'
    }
    vm.handler.chess.fen = vm.handler.chess.fen + ' ' + playerNotation + ' ' + castlingNotation + ' ' + enPassant + ' 0 1'
  }

  /**
   * copies ![CHESS] to clipboard
   */
  function copyFen () {
    navigator.clipboard.writeText('![CHESS]')
  }

  /**
   * flips the orientation of the board
   */
  function flipBoard () {
    vm.handler.chess.orientation = vm.handler.chess.orientation === 'white' ? 'black' : 'white'
    updateFen()
    BuilderService.saveChess(vm.handler.chess.fen, vm.handler.chess.orientation)
  }

  /**
   * Resets board position
   */
  function newGame () {
    board.setPosition(startPosition)
    updateFen()
  }

  /**
   * empties the board
   */
  function clearBoard () {
    board.clear()
    updateFen()
  }

  /**
   * updates the fen to match the board
   */
  function updateFen () {
    vm.handler.chess.fen = board.fen()
    buildExtraNotation()
  }

  board.addEventListener('drop', () => {
    $timeout(() => {
      updateFen()
      BuilderService.saveChess(vm.handler.chess.fen, vm.handler.chess.orientation)
    })
  })
}

export { builderChessController }
