import { WebinarService } from './webinar_service'
import { webinarDialogController } from './webinar_dialog_controller'

(() => {
  'use strict'
  angular
    .module('webinar', [

    ])
    .factory('WebinarService', WebinarService)
    .controller('webinarDialogController', webinarDialogController)
})()

export default 'webinar'
