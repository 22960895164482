'use strict'

/**
 * controller to handle functionlaity of password prompt dialog
 * @param $mdDialog
 * @param $location
 * @param $http
 * @param userId - Integer
 * @param DOMAIN
 */
function authenticationPasswordDialogs ($mdDialog, $location, $http, userId, DOMAIN) {
  'ngInject'
  let vm = this

  vm.ignorePrompt = false

  /**
   * closes dialog, if user has set ignore to true shows a secondary dialog
   */
  vm.cancel = () => {
    if (vm.ignorePrompt) {
      $http.post(DOMAIN + '/api/user/password/ignore', {userId: userId}).then(() => {
        $mdDialog.show({
          templateUrl: 'partials/templates/authentication/password_info_dialog.html',
          controller: customDialogController,
          controllerAs: 'vm',
          escapeToClose: false,
          disableParentScroll: true
        })
      }, (err) => {
        console.error('Error setting password preference: ', err)
      })
    }
    $mdDialog.hide()
  }

  /**
   * closes dialog and redirects to password page
   */
  vm.ok = () => {
    $location.path('/password')
    $mdDialog.hide()
  }

  /**
   * controller to handle closing of secondary information dialog
   */
  function customDialogController () {
    const vm = this

    vm.close = $mdDialog.hide
  }
}

export { authenticationPasswordDialogs }
