'use strict'

/**
 * controller for initialising magnifier
 * @param imageSrc string path to image
 * @param imageArray array of images
 * @param startIdx int index of which image should be opened first
 * @param $mdDialog
 */
function magnifiedImageController (imageSrc, imageArray, startIdx, $mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.cancel = $mdDialog.cancel
  vm.step = step
  vm.isZoomed = false

  activate()

  /**
   * initialisation function that checks for an image array and then sets which
   * image should be loaded displayed first
   */
  function activate () {
    vm.hasArray = (imageArray && imageArray.length > 0)
    // if imageArray exists startIdx is index of clicked image
    vm.src = vm.hasArray ? imageArray[startIdx] : imageSrc
  }

  /**
   * function for cycling through the array of images in the modal
   * @param dir
   */
  function step (dir) {
    startIdx = parseInt(startIdx) + dir
    if (startIdx < 0) { startIdx = imageArray.length - 1 }
    if (startIdx >= imageArray.length) { startIdx = 0 }
    vm.src = imageArray[startIdx]
  }
}

export { magnifiedImageController }
