import { CookieConsentService } from './utils_cookie_consent_service'
import { mcqsAddPlus } from './utils_add_plus'
import { mcqsAlphanumericValidation } from './utils_alphanumeric_validation'
import { mcqsBlur } from './utils_blur'
import { mcqsCharacterReplace } from './utils_character_replace'
import { mcqsCmdSave } from './utils_cmd_ctrl_s'
import { mcqsDragString } from './utils_drag_string'
import { mcqsEllipsisFilter } from './utils_ellipsis'
import { mcqsErrorSource } from './utils_image_error'
import { mcqsFullQuestionVisible } from './utils_full_question_visible'
import { mcqsLazyLoad } from './utils_lazy_load'
import { mcqsMathjax } from './utils_mathjax'
import { mcqsMediaValidator } from './utils_media_validator'
import { mcqsClipboard } from './utils_clipboard'
import { mcqsNoReturn } from './utils_no_return'
import { mcqsOnDrop } from './utils_on_drop'
import { mcqsOverflowScroll } from './utils_overflow_scroll'
import { mcqsPerfectScrollbar } from './utils_perfect_scrollbar'
import { mcqsSubmitOnReturn } from './utils_submit_on_return'
import { mcqsListFilter } from './utils_list_filter'
import { mcqsSanitize } from './utils_sanitize'
import { hammerWrapper } from './utils_hammer';

(() => {
  'use strict'
  angular
    .module('utils', [])
    .factory('CookieConsentService', CookieConsentService)
    .filter('mcqsAddPlus', mcqsAddPlus)
    .directive('mcqsAlphanumericValidation', mcqsAlphanumericValidation)
    .directive('mcqsBlur', mcqsBlur)
    .directive('mcqsCharacterReplace', mcqsCharacterReplace)
    .directive('mcqsClipboard', mcqsClipboard)
    .directive('mcqsCmdSave', mcqsCmdSave)
    .directive('mcqsDragString', mcqsDragString)
    .filter('mcqsEllipsis', mcqsEllipsisFilter)
    .directive('mcqsErrorSource', mcqsErrorSource)
    .directive('mcqsLazyLoad', mcqsLazyLoad)
    .directive('mcqsFullQuestionVisible', mcqsFullQuestionVisible)
    .directive('mcqsMathjax', mcqsMathjax)
    .directive('mcqsMediaValidator', mcqsMediaValidator)
    .directive('mcqsNoReturn', mcqsNoReturn)
    .directive('mcqsOnDrop', mcqsOnDrop)
    .directive('mcqsOverflowScroll', mcqsOverflowScroll)
    .directive('mcqsPerfectScrollbar', mcqsPerfectScrollbar)
    .directive('mcqsSanitize', mcqsSanitize)
    .directive('mcqsSubmitOnReturn', mcqsSubmitOnReturn)
    .directive('hammerWrapper', hammerWrapper)
    .component('mcqsListFilter', mcqsListFilter)
})()

export default 'utils'
