'use strict'
/**
 * mcqs-comment-list-item-reply directive
 * @type {{templateUrl: string, bindings: {reply: string}, controller: string, controllerAs: string, transclude: boolean}}
 */
const mcqsCommentListItemReply = {
  templateUrl: 'partials/templates/comment/comment_list_item_reply.html',
  bindings: { reply: '<' },
  controller: 'commentListItemController',
  controllerAs: 'vm',
  transclude: true
}

export { mcqsCommentListItemReply }
