'use strict'
/**
 * The signInController is used by several components to handle different ways of signing in.
 * Primarily users can sign in on base view (/) by the signin_form component.
 * If sign in succeeds, they're redirected to the question view.
 * Otherwise an error message is displayed.
 * In the event of a 401 response stats, the signInController is used by the signin_dialog component.
 * Users can sign back in to the application through a popover dialog.
 * If they choose not to sign back in, they're redirected to the base view.
 * @param $mdDialog
 * @param $state
 * @param AuthenticationService
 * @param ExamService
 * @param ProfileService
 * @param QuestionService
 * @param $window
 * @param CookieConsentService
 * @param $timeout
 * @param $rootScope
 */
function signInController ($mdDialog, $state, AuthenticationService, ExamService, ProfileService, QuestionService, $window, $timeout, $rootScope) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const auth = AuthenticationService

  vm.username = ''
  vm.password = ''
  vm.errorMessage = ''

  vm.signIn = signIn
  vm.closeDialog = closeDialog

  /**
   *
   */
  function signIn () {
    // eslint-disable-next-line
    // If cookies have not been consented too, shows cookie error on signin
    // Removing for now because of how Chrome is updating to handle cookies
    // if (!Cookiebot.hasResponse) {
    //   vm.signInForm.password.$setValidity('consentMessage', false)
    //   vm.signInForm.password.$error.consentMessage = true
    //   return
    // }
    vm.errorMessage = ''
    auth.signIn(vm.username, vm.password).then(success, failure)
    function success (data) {
      if (!data || data.status || !data.token || (data.token && data.token.length <= 0)) return failure(data)
      auth.createSession(data.loginId, data.userId, data.token, vm.username)
      ProfileService.setPreferences(data.preferences)
      ExamService.updateCurrentExam(data.currentExam)
      // if signin happens after a user has timed out on an authorised route
      // empty the question and reload the page
      if ($state.includes('root.auth')) {
        QuestionService.question = {}
        // reloads to page to trigger automatic redirection
        $window.location.reload()
      } else {
        $state.go('root.auth.question')
      }
      // If a dialog exists, remove it.
      $mdDialog.cancel()
    }

    function failure (error) {
      console.error('Sign in failed:', error)
      if (error && error.data && error.data.message.includes('Hashed')) {
        // disables user/password error warnings
        vm.signInForm.password.$error.customError = false
        vm.signInForm.password.$setValidity('customError', true)
        // enables no hashed passsword error warning
        vm.signInForm.password.$setValidity('noPasswordError', false)
        vm.signInForm.password.$error.noPasswordError = true
      } else {
        // disables no hashed password error warnings
        vm.signInForm.password.$error.noPasswordError = false
        vm.signInForm.password.$setValidity('noPasswordError', true)
        // enables user/password error warnings
        vm.signInForm.password.$setValidity('customError', false)
        vm.signInForm.password.$error.customError = true
      }
    }
  }
  // If the user cancels the dialog, destroy their session and return to base view
  function closeDialog () {
    auth.destroySession()
    $state.go('root.signin')
    $mdDialog.cancel()
  }
}

export { signInController }
