'use strict'
import { default as Shepherd } from 'tether-shepherd'

/**
 * This service is where the steps and actions for the question tour are defined
 * @param ProfileService
 * @param $document
 * @param $state
 * @param $timeout
 * @returns {{mediator, questionTour: Tour, shepherd, nextQuestion: nextQuestion, nextStep: nextStep, questionStartTour: questionStartTour, showQuestionTour: showQuestionTour, tourSelectAnswer: tourSelectAnswer, openReply: openReply, filterQuestions: filterQuestions, removeDefaultComments: removeDefaultComments, changeNavItem: changeNavItem, checkForElement: checkForElement}}
 * @constructor
 */
function TourQuestionHandler (ProfileService, TourNavbarHandler, $document, $state, $timeout) {
  'ngInject'

  // !$document[0].querySelector('.some-element') is used repeatedly in this
  // file to check for existing elements and then deciding the next step.
  // This is because a user has some freedom during the tour and they can
  // affect the flow of the tour.

  const defaults = {
    classes: 'shepherd-theme-arrows-plain-buttons mcqs-shepherd-head mcqs-shepherd'
  }

  let counter = 0

  const service = {
    mediator: new Shepherd.Evented(),
    questionTour: new Shepherd.Tour({defaults: defaults}),
    shepherd: Shepherd,
    nextQuestion: nextQuestion,
    nextStep: nextStep,
    questionStartTour: questionStartTour,
    showQuestionTour: showQuestionTour,
    tourSelectAnswer: tourSelectAnswer,
    openReply: openReply,
    filterQuestions: filterQuestions,
    removeDefaultComments: removeDefaultComments,
    changeNavItem: changeNavItem,
    checkForElement: checkForElement
  }

  const defaultTether = {
    // attachment properties for tour
    // quite a few steps manually set this again. Should be refactored to
    // instead use defaultTether.constraints
    constraints: [
      {
        // attaches the element to the same element as the scroll bar
        to: 'scrollParent',
        // sets the tour element to attach to its relevant element. Flipping
        // above or below based on spacing unless explicitly stated
        attachment: 'together',
        // stops the tour element from leaving the screen
        pin: true
      }
    ]
  }

  const cancelButton = {
    text: 'Cancel',
    classes: ' md-button ',
    action: () => {
      service.mediator.trigger('finish-question-tour')
      ProfileService.setQuestionTourPreference(1)
        .then(() => {
          service.removeDefaultComments()
        })
    }
  }

  const buttons = [
    cancelButton,
    {
      text: 'Next',
      classes: 'md-button md-primary md-raised',
      action: () => service.questionTour.next()
    }
  ]

  service.questionTour.addStep({
    id: '1',
    title: `Know what's being asked`,
    text: `<p>This section sets the scene for the<br>question. Make sure you read this<br>card carefully.</p>`,
    attachTo: '#question_card left',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '2',
    title: `Need more space?`,
    text: `<p>This handle can be used to expand and contract the question container.<br>There is also one of these handles on the explanation container.</p>`,
    attachTo: '#question_card bottom',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: {
      offset: '-12 0',
      constraints: defaultTether.constraints
    },
    advanceOn: 'click',
    buttons: buttons
  }).addStep({
    id: '3',
    title: `Pick one`,
    text: `<p>A question will always have five options<br>and will only ever have one correct answer.<br><br>You can also use <b>1-5</b> on your keyboard to<br>select an answer</p>`,
    attachTo: 'mcqs-question-options .question__option-wrapper left',
    advanceOn: 'user-create',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => service.tourSelectAnswer()
      }
    ]
  }).addStep({
    id: '4',
    text: `<p>The correct answer is always green.</p>`,
    attachTo: '.question__option--correct left',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          !$document[0].querySelector('.question__option--incorrect') ? service.shepherd.activeTour.show('7') : service.questionTour.next()
        }
      }
    ]
  }).addStep({
    id: '5',
    text: `<p>The incorrect answer is always red</p>`,
    attachTo: '.question__option--incorrect left',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '6',
    title: `Pay attention`,
    text: `<p>This section will show you the most<br>important teaching point of the question<br>as well as a detailed explanation</p>`,
    attachTo: '#md-nav-explanation top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '7',
    title: `Who made this?`,
    text: `<p>This is the question's Author. Keep<br>track of your favourite content producers.</p>`,
    attachTo: '.md-user-avatar left',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '8',
    title: `How did you feel about this question?`,
    text: `<p>These buttons can be used to either like or dislike a question.<br>You can only like/dislike a question once. Give it a try.</p>`,
    attachTo: '.fa-thumbs-up right',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '9',
    title: `Something wrong?`,
    text: `<p>If there is something wrong with this<br>question, click here to report it.</p>`,
    attachTo: '.fa-warning bottom',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '10',
    title: `Comments`,
    text: `<p>Want to see what people think of this question?<br>Click here!</p>`,
    attachTo: '#md-nav-comments top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          service.changeNavItem()
          $state.go('root.auth.question.answered.comments')
        }
      }
    ]
  }).addStep({
    id: '11',
    text: `<p>You can filter the comments by date/popularity.</p>`,
    attachTo: 'md-select right',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          service.filterQuestions()
          service.questionTour.next()
        }
      }
    ]
  }).addStep({
    id: '12',
    text: `<p>You can let people know what you think of this question.<br><br><i>Comments are disabled during the tour.</i></p>`,
    attachTo: '#comment-post-initial top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          $document[0].querySelector('.md-avatar') ? service.questionTour.next() : service.shepherd.activeTour.show('19')
        }
      }
    ]
  }).addStep({
    id: '13',
    text: `Hovering over a user's picture will give you more detailed information.`,
    attachTo: '.md-avatar right',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '14',
    title: `Do you agree with this comment?`,
    text: `You can like or dislike each comment.<br><br>Be careful your first choice is final!`,
    attachTo: '.comment__rating top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          $document[0].querySelector('mcqs-comment-report .fa-warning') ? service.questionTour.next() : service.shepherd.activeTour.show('16')
        }
      }
    ]
  }).addStep({
    id: '15',
    text: `<p>Is this comment offensive?<br><br><i>Reporting is disabled during the tour.</i></p>`,
    attachTo: 'mcqs-comment-report .fa-warning top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '16',
    text: `This button will allow you to reply to a specific comment.<br>Give it a go.`,
    attachTo: '.fa-reply top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          service.openReply()
        }
      }
    ]
  }).addStep({
    id: '17',
    text: `<p>Simply type here and click the <b>post reply</b> button below.<br><br><i>Comments are disabled during the tour.</i></p>`,
    attachTo: '#comment-post-0 top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          $document[0].querySelectorAll('mcqs-comment-delete .fa-trash') ? service.questionTour.next() : service.shepherd.activeTour.show('19')
        }
      }
    ]
  }).addStep({
    id: '18',
    text: `<p>Unhappy with your comment? Delete it!<br><br><i>Deleting is disabled during the tour.</i><br><br><i>You can only delete your own comments.</i></p>`,
    attachTo: '.fa-trash left',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          $document[0].querySelectorAll('mcqs-comment-delete .fa-trash') ? service.questionTour.next() : service.shepherd.activeTour.show('19')
        }
      }
    ]
  }).addStep({
    id: '19',
    text: `<p>Make a mistake? You can edit your comment.<br><br><i>Editing is disabled during the tour.</i><br><br><i>You can only edit your own comments.</i></p>`,
    attachTo: '.fa-pencil left',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '20',
    text: `<p>When you're ready to move on click here<br>to load the next question.<br><br>You can also use the <b>enter</b> key to<br>advance to the next question.</p>`,
    attachTo: '#tour-next-question top',
    advanceOn: 'click',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: 'md-button md-primary md-raised',
        action: () => {
          service.mediator.trigger('finish-question-tour')
          ProfileService.setQuestionTourPreference(1).then(() => {
            service.removeDefaultComments()
            TourNavbarHandler.navbarStartTour()
          })
        }
      }
    ]
  })

  // this trigger will skip onto the next step if the user selects one of the options
  service.mediator.on('next-question-step', () => {
    if (service.shepherd.activeTour && service.shepherd.activeTour.getCurrentStep().id === '3') {
      service.questionTour.next()
    }
  })
  service.mediator.on('start-question-tour', () => {
    service.activeTour = true
    service.questionTour.start()
  })

  return service

  /**
   * this checks the page for an element exists for a step to attach itself to
   * if the element is not found it will skip onto the next step
   * @param element
   */
  function checkForElement (element) {
    let correctOption = $document[0].querySelector(element)
    if (!correctOption) {
      counter++
      // look for element repeatedly, if never found end tour
      if (counter >= 1000) service.mediator.trigger('finish-question-tour')
      $timeout(() => { checkForElement(element) })
    } else {
      counter = 0
      service.questionTour.next()
    }
  }

  function customScroller (a) { a.scrollIntoView({behavior: 'smooth'}) }

  function nextQuestion () { service.mediator.trigger('next-question') }

  function questionStartTour () { service.mediator.trigger('start-question-tour') }

  function nextStep () { service.mediator.trigger('next-question-step') }

  function showQuestionTour () { if (ProfileService.getPreferences().questionTour === '0') { return true } }

  function tourSelectAnswer () { service.mediator.trigger('tour-select-answer') }

  function openReply () { service.mediator.trigger('open-reply') }

  function filterQuestions () { service.mediator.trigger('filter-questions') }

  function removeDefaultComments () { service.mediator.trigger('remove-default-comments') }

  function changeNavItem () { service.mediator.trigger('change-nav-item') }
}

export { TourQuestionHandler }
