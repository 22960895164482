'use strict'
/**
 * mcqs-builder-save-button directive
 * @type {{template: string, controller: builderSaveController, controllerAs: string}}
 */
const mcqsBuilderSaveButton = {
  template: `
        <md-button
            id="builder_save_button"
            class="md-raised md-primary md-hue-3"
            ng-click="vm.save()"
            ng-disabled="vm.disabled"
        >Save</md-button>
    `,
  controller: builderSaveController,
  controllerAs: 'vm'
}

/**
 * builderSaveController handles the manual saving of a question.
 * @param $timeout
 * @param BuilderService
 * @param BuilderHandler
 * @param TourBuilderHandler
 */
function builderSaveController ($timeout, BuilderService, BuilderHandler, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  const mediator = TourBuilderHandler.mediator

  vm.disabled = false

  vm.save = save
  // if tour is active then on save-question tour step manually fire save
  if (!mediator.bindings['save-question']) {
    TourBuilderHandler.mediator.on('save-question', vm.save)
  }
  /**
   * verifies the question and saves it if valid.
   */
  function save () {
    // disables save while save in progress
    vm.disabled = true
    BuilderHandler.verifyEntireQuestion((err) => {
      if (err) return failure(err)
      BuilderService.saveQuestion().then(success, failure)
    })

    /**
     * success handler for BuilderHandler.verifyEntireQuestion()
     * @param data {{isDynamic: boolean, isPublished: boolean, isValid: boolean, messages: []}}
     */
    function success (data) {
      checkSaveStep()
      vm.disabled = false;
      (data && !data.status) ? BuilderHandler.setStatus(data) : failure(data)
    }

    /**
     * error handler for BuilderService.saveQuestion()
     * @param error object returned from BuilderService.saveQuestion()
     */
    function failure (error) {
      checkSaveStep()
      vm.disabled = false
      console.error('Question did not save:', error)
    }

    /**
     * triggers a listener in the tour builder to decide next step
     */
    function checkSaveStep () {
      if (TourBuilderHandler.shepherd.activeTour && TourBuilderHandler.shepherd.activeTour.currentStep.id === '11') {
        TourBuilderHandler.mediator.trigger('check-save-step')
      }
    }
  }
}

export { mcqsBuilderSaveButton }
