'use strict'
import { diff } from 'deep-diff'

/**
 * adminImageViewController handles the view for admin_image_management.html
 * @param $mdDialog
 * @param AdminManagementService
 * @param Upload
 * @param DOMAIN
 * @param $filter
 * @param $scope
 * @param $timeout
 */
function adminImageViewController ($mdDialog, AdminManagementService, Upload, DOMAIN, $filter, $timeout, $scope) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  let originalImagesArr, updatedObject, limit, tempStart

  vm.upload = upload
  vm.blankRow = blankRow
  vm.updateRow = updateRow
  vm.updateAllRows = updateAllRows
  vm.isLoaded = isLoaded
  vm.deleteRow = deleteRow
  vm.updateList = updateList
  vm.disableLastButton = disableLastButton
  vm.disableNextButton = disableNextButton
  vm.startPos = 0

  limit = 30

  $scope.$watch('vm.searchText', () => {
    $timeout(() => {
      if (vm.searchText !== '' && vm.searchText !== undefined) {
        vm.startPos = 0
      } else if (tempStart !== undefined) {
        vm.startPos = tempStart
      }
    })
  })

  // document.querySelector('#linkedchat-button').style.display = 'none'

  getAllImages()

  /**
   * Requests the service to insert a blank row to db
   * displays toast and updates images on success
   */
  function blankRow () {
    AdminManagementService.insertBlankRow().then(() => {
      AdminManagementService.showToast('New row added successfully')
      getAllImages()
    }, failure)
  }

  /**
   * requests the service to delete selected row from db
   * displays toast and updates images on success
   * @param current object this reference to an image in the html
   */
  function deleteRow (current) {
    $mdDialog.show({
      templateUrl: 'partials/templates/admin/admin_image_management_delete_dialog.html',
      controller: mcqsDeleteDialogController,
      controllerAs: 'vm',
      disableParentScroll: false,
      escapeToClose: false
    }).then(() => {
      AdminManagementService.deleteRow(current.image.question_related_image_id)
        .then(() => {
          AdminManagementService.showToast('Row deleted successfully')
          getAllImages()
          if (vm.startPos + limit >= vm.images.length) vm.updateList(null, true)
        }, failure)
    })
  }

  /**
   * requests all images from the db
   */
  function getAllImages () {
    AdminManagementService.getAllAdminImages().then((images) => {
      originalImagesArr = angular.copy(images)
      vm.images = angular.copy(images)
    })
  }

  /**
   * returns a boolean to check whether images have been loaded
   * @returns {*}
   */
  function isLoaded () { return vm.images }

  /**
   * updates the items currently displayed in the html
   * @param newItems boolean to load next images
   * @param oldItems boolean to load old images
   */
  function updateList (newItems, oldItems) {
    // checks length of displayed items after search
    if ($filter('filter')(originalImagesArr, vm.searchText).length <= limit) return
    // ensures that a blank page will not be loaded
    if (newItems && (vm.startPos + limit < vm.images.length)) {
      vm.startPos += limit
    } else if (oldItems && (vm.startPos !== 0)) {
      vm.startPos -= limit
    }
    if (vm.searchText !== undefined && vm.searchText !== '') {
      tempStart = vm.startPos
    }
  }

  /**
   * requests all rows that have been changed to be updated in db
   */
  function updateAllRows (override) {
    return new Promise((resolve, reject) => {
      let allUpdatedRows = []
      // angular.copy used to remove hashes before comparison
      let images = angular.copy(vm.images)
      let arrayDiff = diff(images, originalImagesArr)
      if (arrayDiff === undefined) return resolve()
      vm.images.map((item, idx, arr) => {
        if (diff(arr[idx], originalImagesArr[idx])) {
          allUpdatedRows.push(arr[idx])
        }
      })
      AdminManagementService.updateAllImageRows(allUpdatedRows).then((data) => {
        // if (!data || data.status) return reject(new Error('Error updating rows'))
        if (!data || data.status) return reject(failure())
        if (!override) AdminManagementService.showToast('Rows updated successfully')
        getAllImages()
        resolve()
      }, failure)
    })
  }

  /**
   * requests the selected row to be updated in db
   * and on success loads all images again
   * @param current object this reference to an image
   */
  function updateRow (current) {
    // angular.copy used to remove hashes before comparison
    let updatedRow = angular.copy(current.image)
    // sets the row before changes by filtering by id
    let originalRow = $filter('filter')(originalImagesArr,
      {'question_related_image_id': current.image.question_related_image_id},
      true)
    let imageDiff = diff(updatedRow, originalRow[0])
    if (imageDiff === undefined) return

    updatedObject = {
      question_related_image_id: updatedRow.question_related_image_id,
      default_image: updatedRow.default_image,
      structure: updatedRow.structure,
      coords: updatedRow.coords,
      raw_image: updatedRow.raw_image
    }
    AdminManagementService.updateRow(updatedObject)
      .then((data) => {
        if (!data || data.status) return failure()
        AdminManagementService.showToast('Row updated successfully')
        getAllImages()
      }, failure)
  }

  /**
   * uploads a given image to the db and on success loads all images again
   * @param files files to be uploaded
   * @param invalidFiles invalid files given
   */
  function upload (files, invalidFiles) {
    if (invalidFiles && invalidFiles.length > 0) return showError('Invalid files supplied')
    if (files && files.length) {
      vm.updateAllRows(true).then(() => {
        for (let i = 0; i < files.length; i++) {
          Upload.upload({
            url: DOMAIN + '/api/admin-images/image-upload/',
            data: {file: files[i]}
          }).then(uploadSuccess, uploadFailure)
        }
      }, failure)
    }

    /**
     * success function for upload
     * shows toast and gets all images
     * @param response object returned from Upload.upload
     */
    function uploadSuccess (response) {
      if (!response ||
        !response.data ||
        !response.data.hasOwnProperty('shortURL')) return uploadFailure(response)
      AdminManagementService.showToast('Image uploaded successfully')
      getAllImages()
    }

    /**
     * failure function for upload
     * shows toast and logs error
     * @param error object returned from Upload.upload
     */
    function uploadFailure (error) {
      console.error('Image upload failed:', error)
      showError('Image upload failed')
    }

    /**
     * displays a toast with the given upload error
     * @param text string error to be displayed
     */
    function showError (text) {
      AdminManagementService.showToast(text, true)
    }
  }

  function disableLastButton () {
    if (vm.startPos === 0) return true
    return false
  }

  function disableNextButton () {
    if (vm.startPos + limit >= vm.images.length) return true
    return false
  }

  /**
   * displays an error toast on response failure from BE response
   */
  function failure () {
    AdminManagementService.showToast('Error updating row data', true)
  }
}

function mcqsDeleteDialogController ($mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.cancel = () => {
    $mdDialog.cancel()
  }

  vm.ok = () => {
    $mdDialog.hide()
  }
}

export { adminImageViewController }
