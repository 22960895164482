'use strict'
/**
 * mcqs-chat-message directive
 * @type {{templateUrl: string, bindings: {message: string, currentUserName: string}, controller: chatMessageController, controllerAs: string}}
 */
const mcqsChatMessage = {
  templateUrl: 'partials/templates/chat/message.html',
  bindings: {
    message: '=',
    currentUserName: '<'
  },
  controller: chatMessageController,
  controllerAs: 'vm'
}

/**
 * chatMessageController is used to set up bindings to message.html
 */
function chatMessageController () {
  'ngInject'
  /* jshint validthis:true */
  const vm = this // eslint-disable-line no-unused-vars

  activate()

  function activate () { }
}

export { mcqsChatMessage }
