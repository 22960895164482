'use strict'
/**
 * mcqs-on-drop directive
 * @param $timeout
 * @param $parse
 * @returns {{restrict: string, link: link}}
 */
function mcqsOnDrop ($timeout, $parse) {
  'ngInject'
  return {
    restrict: 'A',
    link: link
  }

  /**
   * This is used to fire an update in the question builder when text is dragged and dropped
   * into a question text area and saving the question
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attribute attached to HTML element
   */
  function link (scope, elem, attrs) {
    activate()

    /**
     * initialisation function that creates bindings
     */
    function activate () {
      elem.bind('drop', performAction)
      elem.bind('$destroy', destroy)
    }

    /**
     * on drop fire function bound to attrs.mcqsOnDrop
     * @param e
     */
    function performAction (e) {
      // Have to pass scope in: http://stackoverflow.com/a/24224971/3025130
      if (attrs.mcqsOnDrop) $timeout(() => $parse(attrs.mcqsOnDrop)(scope), 50)
    }

    /**
     * destroys bindings
     */
    function destroy () { elem.off('drop', performAction) }
  }
}

export { mcqsOnDrop }
