'use strict'
/**
 * mcqs-profile-images directive
 * @type {{templateUrl: string, bindings: {cover: string, profile: string, username: string, name: string}, transclude: boolean, controller: profileImagesController, controllerAs: string}}
 */
const mcqsProfileImages = {
  templateUrl: 'partials/templates/profile/profile_images.html',
  bindings: {
    cover: '=',
    profile: '=',
    username: '=',
    name: '@'
  },
  transclude: true,
  controller: profileImagesController,
  controllerAs: 'vm'
}

/**
 * controller to handle uploading of profile image
 * @param $mdDialog
 * @param Upload 3rd party upload service: https://github.com/danialfarid/ng-file-upload
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 */
function profileImagesController ($mdDialog, Upload, DOMAIN) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.uploadPhoto = uploadPhoto

  /**
   * function that fires off the image upload
   * @param path string either cover or profile
   * @param file object containing all of the image details
   * @param invalidFiles array of invalid files provided
   */
  function uploadPhoto (path, file, invalidFiles) {
    if (file) {
      // Upload handled by ng-file-upload: https://github.com/danialfarid/ng-file-upload
      Upload.upload({
        url: DOMAIN + '/api/profile/image/' + path,
        data: { file: file }
      }).then(response => {
        let { data } = response
        if (!data || !data.hasOwnProperty('url')) return failure('No data in image upload response')
        vm[path] = data.url
      }, failure)
    } else if (invalidFiles && invalidFiles.length > 0) {
      failure('No valid images to upload.')
    }
  }

  /**
   * error handler for Upload.upload()
   * displays a dialog on failure that informs a user to check file size and file type
   * @param error
   */
  function failure (error) {
    console.error('Image upload failed:', error)
    $mdDialog.show(
      $mdDialog.alert()
        .clickOutsideToClose(true)
        .title('Image upload failed')
        .disableParentScroll(false)
        .textContent('An error occurred while saving your image. Please try again. Images must be less than 2MB, and of the type PNG, JPEG or GIF.')
        .ariaLabel('Image upload failure dialog')
        .ok('Okay')
    )
  }
}

export { mcqsProfileImages }
