'use strict'
const Clipboard = require('clipboard')

/**
 * mcqs-clipboard directive
 * @param $mdDialog
 * @param $mdToast
 * @returns {{restrict: string, link: link}}
 */
function mcqsClipboard ($mdDialog, $mdToast) {
  'ngInject'
  return {
    restrict: 'A',
    link
  }

  /**
   * This allows the user to copy a media item to their clipboard by clicking on an icon
   * @param scope
   * @param elem
   * @param attrs
   */
  function link (scope, elem, attrs) {
    const board = new Clipboard(elem[0], {
      text: () => attrs.mcqsClipboard || ''
    })

    board.on('success', () => {
      $mdToast.show(
        $mdToast.simple()
          .textContent('Copied to clipboard!')
          .position('bottom right')
          .hideDelay(1200)
          .parent(document.getElementById('toast-container'))
      )
    })

    board.on('error', () => {
      const clip = attrs.mcqsClipboard
      if (!clip || clip.length < 1) return
      $mdDialog.show(
        $mdDialog.alert()
          .parent(elem)
          .clickOutsideToClose(true)
          .title('Cannot copy to clipboard')
          .textContent('Your browser might not support this action. Please highlight the following text and press CTRL/CMD + C to copy: ' + clip)
          .ariaLabel('Clipboard copy failed')
          .ok('Got it!')
      )
    })
  }
}

export { mcqsClipboard }
