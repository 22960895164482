'use strict'
/**
 * mcqs-builder-images directive
 * @type {{templateUrl: string, bindings: {images: string, questionId: string}, controller: builderImagesController, controllerAs: string}}
 */
const mcqsBuilderImages = {
  templateUrl: 'partials/templates/builder/images.html',
  bindings: {
    images: '=',
    questionId: '@'
  },
  controller: builderImagesController,
  controllerAs: 'vm'
}

/**
 * builderImageController handles the uploading/deleting of images
 * @param $mdDialog
 * @param Upload 3rd party upload service: https://github.com/danialfarid/ng-file-upload
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @param BuilderService
 * @param BuilderHandler
 */
function builderImagesController ($mdDialog, Upload, DOMAIN, BuilderService, BuilderHandler) {
  'ngInect'
  /* jshint validthis:true */
  const vm = this

  let numberOfRequests
  let deleting = false
  vm.selectedImageIdx = null
  // vm.uploading shows the progess bar until the final upload is completed
  vm.uploading = false

  vm.deleteImage = deleteImage
  vm.select = select
  vm.upload = upload

  /**
   * sets the selectedImage
   * @param idx int index of selected image
   */
  function select (idx) { if (!deleting) { vm.selectedImageIdx = (vm.selectedImageIdx === idx) ? null : idx } }

  /**
   * This function handles the uploading of the image via ng-file-upload
   * @param files array of files to be uploaded
   * @param invalidFiles array of invalid files provided
   */
  function upload (files, invalidFiles) {
    numberOfRequests = 0
    if (files && files.length) {
      // doesn't allow more than 10 images
      numberOfRequests = (vm.images.length + files.length > 10) ? 10 - vm.images.length : files.length
      vm.uploading = vm.images.length < 10
      for (let i = 0; i < files.length; i++) {
        if (vm.images.length + (i + 1) > 10) { break }
        Upload.upload({
          url: DOMAIN + '/api/builder/question/image/' + vm.questionId,
          data: {file: files[i]}
        }).then(success, failure)
      }
    }
    if (invalidFiles && invalidFiles.length > 0) showError()

    /**
     * success handler for Upload.upload()
     * @param response {{data: {imageId: int, shortUrl: string, thumbnail: string}}}
     */
    function success (response) {
      if (!response || !response.data || !response.data.hasOwnProperty('shortURL')) return failure(response)
      // every time an image is successfully uploaded reduce the number of requests by 1
      numberOfRequests--
      if (vm.images.length < 10) { BuilderHandler.addImage(response.data) }
      if (numberOfRequests <= 0 || vm.images.length >= 10) { vm.uploading = false }
    }

    /**
     * error handler for Upload.upload()
     * @param error object returned from Upload.upload()
     */
    function failure (error) {
      console.error('Image upload failed:', error)
      numberOfRequests--
      if (numberOfRequests <= 0) { vm.uploading = false }
      showError()
    }
  }

  /**
   * opens a dialog to display an error when an error occurs during upload
   */
  function showError () {
    $mdDialog.show(
      $mdDialog.alert()
        .clickOutsideToClose(true)
        .title('Image upload failed')
        .disableParentScroll(false)
        .textContent('An error occurred while saving your image. Please try again.  Images must be of type  PNG, JPEG or GIF.')
        .ariaLabel('Image upload failure dialog')
        .ok('Okay')
    )
  }

  /**
   * opens a dialog asking the user to confirm deletion of an image
   */
  function deleteImage () {
    $mdDialog.show(
      $mdDialog.confirm()
        .title('Are you sure you want to delete this image?')
        .disableParentScroll(false)
        .textContent('Once you remove an image it will no longer be available. This action cannot be undone.')
        .ariaLabel('Delete image')
        .ok("I'm sure")
        .cancel('Cancel')
    ).then(remove)
  }

  /**
   * This function removes the image and all references to the image from the
   * question
   */
  function remove () {
    deleting = true
    let imageId = vm.images[vm.selectedImageIdx].imageId
    BuilderService.deleteImage(imageId).then(success, failure)

    /**
     * success handler for BuilderService.deleteImage()
     * removes image and resets all selectedImage fields
     * @param data string if success, object if failure
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      BuilderHandler.removeImage(vm.selectedImageIdx)
      vm.selectedImageIdx = null
      vm.selectedImage = {}
      deleting = false
    }

    /**
     * error handler for BuilderService.deleteImage()
     * @param error object returned from BuilderService.deleteImage()
     */
    function failure (error) {
      console.error('Could not delete image:', error)
      // if 404 image doesnt exist so remove from array
      if (error && error.status === 404) { vm.images.splice(vm.selectedImageIdx, 1) }
      deleting = false
    }
  }
}

export { mcqsBuilderImages }
