'use strict'

/**
 * mcqs-builder-tags directive
 * @param $compile
 * @param $window
 * @param $timeout
 * @returns {{bindToController: {questionId: string, examId: string, selectedTags: string}, controller: string, scope: {ngBlur: string, ngFocus: string, questionId: string, selectedTags: string}, link: link, restrict: string, controllerAs: string, templateUrl: string}}
 */
function mcqsBuilderTags ($compile, $window, $timeout) {
  return {
    restrict: 'E',
    templateUrl: `partials/templates/builder/builder_tags.html`,
    bindToController: {
      questionId: '@',
      examId: '@',
      selectedTags: '=',
      liveTags: '='
    },
    scope: {
      questionId: '@',
      selectedTags: '=',
      ngBlur: '<',
      ngFocus: '<',
      liveTags: '='
    },
    controller: 'builderTagsController',
    controllerAs: 'vm',
    link: link
  }

  function link (scope) {
    let tabs, cssClass, mdChipsWrap

    let classCreated = false
    let addTagElementAttached = false

    scope.maxChips = 100
    scope.onBlur = onBlur
    scope.onFocus = onFocus

    scope.$watch('vm.questionId', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        scope.vm.searchText = ''
        tabs = angular.element(document.getElementsByTagName('md-tabs'))
        removeCssClass()
      }
    })

    scope.$watch('vm.searchText', (newText) => {
      if (newText === '') onBlur()
    })

    scope.$watch('vm.selectedTags', () => {
      if (scope.selectedTags && (scope.selectedTags.length === scope.maxChips)) {
        scope.vm.placeholder = ''
        updateStyle()
      }
    })

    scope.$on('adminCheckDone', () => {
      if (scope.vm.adminId && addTagElementAttached === false) {
        addTagElementAttached = true
        attachAddTagElement()
      }
    })

    init()

    function init () {
      tabs = angular.element(document.getElementsByTagName('md-tabs'))

      mdChipsWrap = angular.element(document.getElementsByTagName('md-chips-wrap'))
      mdChipsWrap.addClass('md-chips-scroll')
      mdChipsWrap.attr('mcqs-perfect-scrollbar', '')

      // element needs to be re compiled after attaching perfect scrollbar
      $compile(mdChipsWrap)(scope)

      createCssClass()
    }

    function attachAddTagElement () {
      if (scope.liveTags) return
      $timeout(() => {
        let input = angular.element(document.getElementsByTagName('md-autocomplete-wrap'))[0]
          .children[0]
        input.style.width = '95%'
        let html = `<md-button
                      style="min-width: 0px"
                      ng-click="vm.addTag(vm.searchText)"
                      ng-disabled="vm.searchText.length === 0 || vm.disableCustomTag">
                        <md-icon class="fa fa-plus fa-2x">
                            <md-tooltip md-direction="bottom">Add custom tag</md-tooltip>
                        </md-icon>
                    </md-button>`
        html = angular.element(html)
        html = $compile(html)(scope)
        html[0].style.display = 'initial'
        input.insertAdjacentElement('afterend', html[0])
      })
    }

    /**
     * Creates a style element and attaches it to the dom
     */
    function createCssClass () {
      if (angular.element(document.body)[0].scrollHeight > $window.innerHeight) {
        if (!classCreated) {
          cssClass = document.createElement('style')
          cssClass.innerHTML = `md-virtual-repeat-container {bottom: ${($window.innerHeight - angular.element(document.body)[0].scrollHeight) + tabs[0].clientHeight}px !important}`
          document.getElementsByTagName('head')[0].appendChild(cssClass)
          classCreated = true
        } else {
          document.getElementsByTagName('head')[0].removeChild(cssClass)
          cssClass.innerHTML = `md-virtual-repeat-container {bottom: ${($window.innerHeight - angular.element(document.body)[0].scrollHeight) + tabs[0].clientHeight}px !important}`
          document.getElementsByTagName('head')[0].appendChild(cssClass)
        }
      }
    }

    /**
     * removes the style element from the dom
     */
    function removeCssClass () {
      if (cssClass) {
        document.getElementsByTagName('head')[0].removeChild(cssClass)
        classCreated = false
      }
    }

    /**
     * function to fire on blurring of tabs input
     */
    function onBlur () {
      angular.element(document.body)[0].scrollTop = document.body.scrollHeight
    }

    /**
     * function to fire on focus of tabs input
     */
    function onFocus () { createCssClass() }

    /**
     * updates the style of md-chips-wrap to remove test box when full
     * @param max
     */
    function updateStyle (max) {
      max ? mdChipsWrap.addClass('md-chips-wrap-override')
        : mdChipsWrap.removeClass('md-chips-wrap-override')
    }
  }
}

export { mcqsBuilderTags }
