'use strict'
/**
 * mcqs-image-preview directive
 * @type {{template: string, bindings: {url: string, imageArray: string, key: string, idx: string}, controller: imagePreviewController, controllerAs: string}}
 */
const mcqsImagePreview = {
  template: `
    <i class="fa fa-search-plus builder__image-preview" ng-click="vm.preview($event)"></i>
    `,
  bindings: {
    url: '@',
    imageArray: '=',
    key: '@',
    idx: '@'
  },
  controller: imagePreviewController,
  controllerAs: 'vm'
}

/**
 * controller to handle the previewing of an image
 * @param ImageHandler
 */
function imagePreviewController (ImageHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const CDN = 'https://diatldtcixe86.cloudfront.net/question/original/'

  vm.preview = preview

  /**
   * selects the correct image and then opens a dialog to dislpay it
   * @param $event click event
   */
  function preview ($event) {
    /* Preview handles a single image (vm.url) or an array of images (vm.imageArray).
            The key is used to identify the correct part of the array object.
            The default key was 'shortURL'. idx is the starting point of the array.
            If not idx is supplied, 0 is default. */
    if (!vm.imageArray || vm.imageArray.length < 1) {
      let parsedUrl = parseUrl(vm.url)
      ImageHandler.openDialog($event, parsedUrl)
    } else {
      let key = vm.key || 'shortURL'
      let newArr = vm.imageArray.map(image => parseUrl(image[key]))
      ImageHandler.openDialog($event, null, newArr, vm.idx || 0)
    }
  }

  /**
   * returns either full url or image or cdn path to image
   * @param url
   * @returns {string}
   */
  function parseUrl (url) { return url.indexOf('http') !== -1 ? url : CDN + url }
}

export { mcqsImagePreview }
