'use strict'
/**
 * mcqs-comment-post directive
 * @type {{templateUrl: string, bindings: {attemptId: string, commentId: string, commentIdx: string, tourComment: string}, controller: mcqsCommentPostController, controllerAs: string}}
 */
const mcqsCommentPost = {
  templateUrl: 'partials/templates/comment/comment_post.html',
  bindings: {
    attemptId: '<',
    commentId: '<',
    commentIdx: '@',
    tourComment: '<'
  },
  controller: mcqsCommentPostController,
  controllerAs: 'vm'
}

/**
 * controller that handles the validation and triggers the sending of a comment
 * @param $timeout
 * @param CommentService
 * @param TourQuestionHandler
 */
function mcqsCommentPostController ($timeout, CommentService, TourQuestionHandler) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.tour = TourQuestionHandler.shepherd

  vm.comment = ''
  vm.errorMessage = ''
  vm.disablePost = false

  vm.sendComment = sendComment
  vm.checkComment = checkComment
  // This regex is used to prevent images from being added to comments
  const regExMarkdownImg = new RegExp(/!\[.*]\(.*\)/)

  /**
   * checks the validity of a comment
   */
  function checkComment () {
    if (regExMarkdownImg.test(vm.comment)) {
      vm.errorMessage = 'You can’t post a comment that contains links or images, sorry!'
      vm.disablePost = true
    } else {
      vm.errorMessage = ''
      vm.disablePost = false
    }
  }

  /**
   * creates the comment object and passes it to the the CommentService
   */
  function sendComment () {
    let commentObj = {
      text: vm.comment,
      attemptId: vm.attemptId,
      commentId: vm.commentId
    }
    CommentService.sendComment(commentObj).then(
      data => {
        vm.comment = ''
        data.comment = commentObj.text
        data.owner = true
        // if commentIdx is undefined then it updates CommentService.comments
        // otherwise it updates the CommentService.comments[commentIdx].replies array
        CommentService.addComment(data, vm.commentIdx)
      },
      error => {
        vm.errorMessage = 'An error has occurred please try again.'
        $timeout(() => (vm.errorMessage = ''), 1000)
        console.error('An error has occurred while posting your comment:', error)
      }
    )
  }
}

export { mcqsCommentPost }
