'use strict'
/**
 * mcqs-no-return directive
 * @param $parse
 * @returns {{restrict: string, require: string, link: link}}
 */
function mcqsNoReturn ($parse) {
  'ngInject'
  return {
    restrict: 'A',
    require: 'ngModel',
    link: link
  }

  /**
   * This link function is used to block the return key being used to add a new line to a text area
   * when a set number of lines is reached.
   * It is currently being used for the related data and options text areas
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attribute attached to HTML element
   * @param ngModel ng-model
   */
  function link (scope, elem, attrs, ngModel) {
    activate()

    /**
     * initialisation function that creates trigger for keydown, keyup and input
     */
    function activate () {
      elem.on('keydown keypress', blockReturn)
      elem.on('input', clearReturns)
      elem.bind('$destroy', destroy)
    }

    /**
     * blocks the return key
     * @param e event key
     * @returns {boolean}
     */
    function blockReturn (e) {
      if (e.which === 13 && (!attrs.limit || attrs.limit < 1)) {
        e.returnValue = false
        return false
      }
    }

    /**
     * clears return on input
     * @param e event input
     */
    function clearReturns (e) {
      // /r matches ASCII 13, /n matches ASCII 10
      let reg = new RegExp(/\r\n|\r|\n/, 'g')
      // updates the input without the output of the recursive function
      e.target.value = recursive(e.target.value, attrs.limit || 0, 0)

      /**
       * checks every character for a return space and if that point is/would be
       * greater than the limit allowed removes it
       * @param string string to be checked
       * @param limit int limit for input length
       * @param point int point in string
       * @returns {*}
       */
      function recursive (string, limit, point) {
        if (reg.exec(string) == null) return string
        if (point === limit) string = string.substr(0, reg.lastIndex - 1) + '' + string.substr(reg.lastIndex)
        if (point > limit) string = (string.substr(0, reg.lastIndex - 1) + ' ' + string.substr(reg.lastIndex)).replace(/ {2,}/g, ' ')
        point++
        return recursive(string, limit, point)
      }
      // http://stackoverflow.com/questions/22841225/ngmodel-formatters-and-parsers
      ngModel.$setViewValue(e.target.value)
    }

    /**
     * destroys all bindings
     */
    function destroy () {
      elem.off('keydown', blockReturn)
      elem.off('keypress', blockReturn)
      elem.off('input', clearReturns)
    }
  }
}

export { mcqsNoReturn }
