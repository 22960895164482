'use strict'
/**
 * mcqs-password-reset-form directive
 * @type {{templateUrl: string, controller: passwordResetController, controllerAs: string}}
 */
const mcqsPasswordResetForm = {
  templateUrl: 'partials/templates/authentication/password_reset_form.html',
  controller: passwordResetController,
  controllerAs: 'vm'
}

/**
 * The passwordResetController is used to handle the first part of the reset
 * password flow. It takes an email and sends a confirmation code to that email
 * @param $mdDialog
 * @param AuthenticationService
 */
function passwordResetController ($mdDialog, AuthenticationService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  // vm.statusClass and vm.errorMessage are bindings to password_reset_form.html
  // which update on success and failure.

  vm.email = ''
  vm.disableButton = false

  vm.getCode = getCode

  /**
   * sends a users email to the BE to trigger the sending of a password reset code
   */
  function getCode () {
    vm.disableButton = true
    AuthenticationService.getPasswordResetCode(vm.email).then(
      data => {
        if (!data || data.status) return failure(data)
        vm.errorMessage = null
        $mdDialog.show({
          templateUrl: 'partials/templates/authentication/password_reset_dialog.html',
          controller: passwordResetDialogController,
          controllerAs: 'vm',
          bindToController: true,
          locals: {email: vm.email},
          disableParentScroll: false,
          fullscreen: true
        }).then(resetButtonState, resetButtonState)
      }, failure
    )

    /**
     * Error handler to display an error when sending reset code
     * @param error object
     */
    function failure (error) {
      vm.disableButton = false
      vm.statusClass = 'text--warn'
      console.error('Password reset code request failed:', error)
      vm.errorMessage = (error && error.data) ? error.data.message : 'An error occurred. Please try again.'
    }
  }

  function resetButtonState () { vm.disableButton = false }
}

/**
 * The passwordResetDialogController handles the second part of the reset password flow.
 * It takes an email, resetCode and newPassword and uses these to set your new password.
 * @param $timeout
 * @param $location
 * @param $mdDialog
 * @param email string users email address
 * @param AuthenticationService
 */
// If we get a chance this should be split into it's own file.
function passwordResetDialogController ($timeout, $location, $mdDialog, email, AuthenticationService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  // vm.statusClass and vm.statusMessage are bindings to password_reset_dialog.html
  // which update on success and failure.
  vm.password = ''
  vm.resetCode = ''
  // bind this to DOM so we can close mdDialog from the html
  vm.closeDialog = $mdDialog.cancel

  vm.resetPassword = resetPassword

  /**
   * sends the users email, reset code and password to the BE tp update their password
   */
  function resetPassword () {
    vm.statusMessage = ''
    vm.disableSubmit = true
    AuthenticationService.resetPassword(vm.email, vm.resetCode, vm.password).then(
      data => {
        if (!data || data.status) return failure(data)
        vm.statusClass = 'text--accent'
        vm.statusMessage = 'Your password has been reset'
        // timeout is called to make the application wait 3 seconds to ensure
        // that the password reset has been applied
        $timeout(finish, 3000)
      }, failure
    )

    /**
     * Error handler for resetPassword
     * @param error object returned from AuthenticationService.resetPassword
     */
    function failure (error) {
      vm.statusClass = 'text--warn'
      console.error('Password reset failed:', error)
      vm.statusMessage = 'Password reset failed. Check code and try again.'
      vm.disableSubmit = false
    }
  }

  /**
   * called to update the path after resetPassword has successfully completed
   */
  function finish () {
    $location.path('/')
    vm.closeDialog()
  }
}
export { mcqsPasswordResetForm, passwordResetDialogController }
