import * as Mentio from './vendor_mentio';

(() => {
  'use strict'
  angular
    .module('vendor', [
      'mentio'
    ])
})()

export default 'vendor'
