'use strict'

/**
 * builderQuestionDialogController handles the exam section of the builder question dialog
 * @param closeable boolean is dialog closeable
 * @param currentExam int currentExam id
 * @param currentQuestionId int
 * @param $mdDialog
 * @param $cookies
 * @param $state
 * @param $timeout
 * @param BuilderService
 * @param BuilderHandler
 * @param ExamService
 * @param TourBuilderHandler
 */
function builderQuestionDialogController (closeable, currentExam, currentQuestionId, $mdDialog, $cookies, $state, $timeout, BuilderService, BuilderHandler, ExamService, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.deletePrompted = false
  vm.dialogText = ''
  vm.disableNew = false
  vm.questionsLoaded = false
  vm.questionsLoadError = false

  vm.selectedExam = {}
  vm.categories = []
  vm.questions = []
  // passed from builder_question_speed_dial.js if dialog should be closeable
  vm.closeable = closeable

  vm.close = close
  vm.newQuestion = newQuestion
  vm.selectExam = selectExam

  activate()

  /**
   * Initialisation function that sets currentExam, currentQuestionId and a listener for the tour
   */
  function activate () {
    let examId = currentExam || ExamService.getCurrentExam()
    vm.selectedExam = {examId: Number(examId)}
    vm.currentQuestionId = currentQuestionId || null
    TourBuilderHandler.mediator.on('builder-new-question', vm.newQuestion)
  }

  /**
   * clears all values and the sets a new exam
   * @param exam {{$$hashKey: string, $$mdSelected: int, examDesc: string, examId: int, examName: string}}
   */
  function selectExam (exam) {
    vm.questionsLoadError = false
    vm.questionsLoaded = false
    vm.questions = []
    vm.selectedQuestionId = null
    let examId = exam.examId
    // gets the questions for the exam and calls success to populate array with them
    BuilderService.getQuestionsArray(examId).then(success, failure)

    /**
     * success handler sets all questions returned from BuilderService.getQuestionsArray()
     * @param data {{questions: [{attempts: int, corrects: int, dateCreated: string, dateModified: string, dislikes: int, incorrects: int, isPublished: int, likes: int, questionId: int, score: float}]}}
     */
    // timeout to allow question changes to propagate and then trigger digest cycle
    function success (data) { (data && !data.status) ? $timeout(() => setQuestions(data.questions), 1000) : failure(data) }

    /**
     * error handler for BuilderService.getQuestionsArray()
     * @param error object returned from BuilderService.getQuestionsArray()
     */
    function failure (error) {
      vm.questionsLoadError = true
      vm.questionsLoaded = true
      console.error('Failed to load array of questions:', error)
    }
  }

  /**
   * sets he questions array in the DOM
   * @param questions [{attempts: int, corrects: int, dateCreated: string, dateModified: string, dislikes: int, incorrects: int, isPublished: int, likes: int, questionId: int, score: float}]
   */
  function setQuestions (questions) {
    vm.questions = questions
    vm.questionsLoaded = true
  }

  /**
   * adds a new question and then saves it
   */
  function newQuestion () {
    if (vm.selectedExam.examId) {
      // disables new question button in question_dialog.html
      vm.disableNew = true
      BuilderService.newQuestion(vm.selectedExam.examId).then(success, failure)
    }

    /**
     * success handler for BuilderService.newQuestion()
     * clears current question, sets new question with new empty data and
     * saves the new empty question
     * @param data {{data: {items: {0: string, 1: string, 2: string, 3: string, 4: string}}, images: [], question:{background: string, core: string, exam: {examId: int, examName: string}, explanation: string, options: {correct: [], incorrect: []}, optionsArray: [], question: string, questionId: int, relatedQuestions: int, title: int}, status: {dynamic: boolean, isDynamic: boolean, isPublished: boolean, isValid, boolean, messages: []}}}
     */
    function success (data) {
      if (!data || !data.hasOwnProperty('question')) return failure(data)
      BuilderHandler.clearAll()
      BuilderHandler.setAll(data.question, data.data, data.images || [], data.links || [], data.chess || undefined, data.status)
      // Have to add a title so there is are differences to save
      BuilderHandler.question.title = 'Question title'
      BuilderService.saveQuestion().then(saveSuccess, saveFailure)
      $state.go('root.auth.builder.edit')
      $mdDialog.cancel()
      if (TourBuilderHandler.showBuilderTour()) TourBuilderHandler.builderStartTour()
    }

    /**
     * error handler for BuilderService.newQuestion()
     * @param error object returned from BuilderService.newQuestion()
     */
    function failure (error) {
      // enables new question button in question_dialog.html on failure
      vm.disableNew = false
      console.error('Failed to get a new question:', error)
    }

    /**
     * success handler for BuilderService.saveQuestion()
     * if success sets status if false positive calls failure()
     * @param data
     */
    function saveSuccess (data) { (data && !data.status) ? BuilderHandler.setStatus(data) : failure(data) }

    /**
     * error handler for BuilderService.saveQuestion()
     * @param error object returned from BuilderService.saveQuestion()
     */
    function saveFailure (error) { console.error('Failed to save new question on load:', error) }
  }

  /**
   * closes mdDialog if possible, if not or questionId is undefined change state to question page
   */
  function close () {
    let currentQuestionId = BuilderHandler.question.questionId
    if (!vm.closeable || currentQuestionId === undefined) {
      $state.go('root.auth.question')
    }
    $mdDialog.cancel()
  }
}

export { builderQuestionDialogController }
