/*
    Included by: Robert
    Included on: 2016/05/04
    Latest version: 0.9.23
    Reason: unmaintained dependency containing a bug which needs manual fixing.
    https://github.com/jeff-collins/ment.io/issues/27
    https://github.com/jeff-collins/ment.io/pull/127
*/

'use strict'

angular.module('mentio', [])
    .directive('mentio', ['mentioUtil', '$document', '$compile', '$log', '$timeout',
      function (mentioUtil, $document, $compile, $log, $timeout) {
        return {
          restrict: 'A',
          scope: {
            macros: '=mentioMacros',
            search: '&mentioSearch',
            select: '&mentioSelect',
            items: '=mentioItems',
            typedTerm: '=mentioTypedTerm',
            altId: '=mentioId',
            iframeElement: '=mentioIframeElement',
            requireLeadingSpace: '=mentioRequireLeadingSpace',
            selectNotFound: '=mentioSelectNotFound',
            trimTerm: '=mentioTrimTerm',
            ngModel: '='
          },
          controller: ['$scope', '$timeout', '$attrs', function ($scope, $timeout, $attrs) {
            $scope.query = function (triggerChar, triggerText) {
              let remoteScope = $scope.triggerCharMap[triggerChar]

              if ($scope.trimTerm === undefined || $scope.trimTerm) {
                triggerText = triggerText.trim()
              }

              remoteScope.showMenu()

              remoteScope.search({
                term: triggerText
              })

              remoteScope.typedTerm = triggerText
            }

            $scope.defaultSearch = function (locals) {
              let results = []
              angular.forEach($scope.items, function (item) {
                if (item.label.toUpperCase().indexOf(locals.term.toUpperCase()) >= 0) {
                  results.push(item)
                }
              })
              $scope.localItems = results
            }

            $scope.bridgeSearch = function (termString) {
              let searchFn = $attrs.mentioSearch ? $scope.search : $scope.defaultSearch
              searchFn({
                term: termString
              })
            }

            $scope.defaultSelect = function (locals) {
              return $scope.defaultTriggerChar + locals.item.label
            }

            $scope.bridgeSelect = function (itemVar) {
              let selectFn = $attrs.mentioSelect ? $scope.select : $scope.defaultSelect
              return selectFn({
                item: itemVar
              })
            }

            $scope.setTriggerText = function (text) {
              if ($scope.syncTriggerText) {
                $scope.typedTerm = ($scope.trimTerm === undefined || $scope.trimTerm) ? text.trim() : text
              }
            }

            $scope.context = function () {
              if ($scope.iframeElement) {
                return {iframe: $scope.iframeElement}
              }
            }

            $scope.replaceText = function (text, hasTrailingSpace) {
              $scope.hideAll()

              mentioUtil.replaceTriggerText($scope.context(), $scope.targetElement, $scope.targetElementPath,
                        $scope.targetElementSelectedOffset, $scope.triggerCharSet, text, $scope.requireLeadingSpace,
                        hasTrailingSpace)

              if (!hasTrailingSpace) {
                $scope.setTriggerText('')
                angular.element($scope.targetElement).triggerHandler('change')
                if ($scope.isContentEditable()) {
                  $scope.contentEditableMenuPasted = true
                  let timer = $timeout(function () {
                    $scope.contentEditableMenuPasted = false
                  }, 200)
                  $scope.$on('$destroy', function () {
                    $timeout.cancel(timer)
                  })
                }
              }
            }

            $scope.hideAll = function () {
              for (let key in $scope.triggerCharMap) {
                if ($scope.triggerCharMap.hasOwnProperty(key)) {
                  $scope.triggerCharMap[key].hideMenu()
                }
              }
            }

            $scope.getActiveMenuScope = function () {
              for (let key in $scope.triggerCharMap) {
                if ($scope.triggerCharMap.hasOwnProperty(key)) {
                  if ($scope.triggerCharMap[key].visible) {
                    return $scope.triggerCharMap[key]
                  }
                }
              }
              return null
            }

            $scope.selectActive = function () {
              for (let key in $scope.triggerCharMap) {
                if ($scope.triggerCharMap.hasOwnProperty(key)) {
                  if ($scope.triggerCharMap[key].visible) {
                    $scope.triggerCharMap[key].selectActive()
                  }
                }
              }
            }

            $scope.isActive = function () {
              for (let key in $scope.triggerCharMap) {
                if ($scope.triggerCharMap.hasOwnProperty(key)) {
                  if ($scope.triggerCharMap[key].visible) {
                    return true
                  }
                }
              }
              return false
            }

            $scope.isContentEditable = function () {
              return ($scope.targetElement.nodeName !== 'INPUT' && $scope.targetElement.nodeName !== 'TEXTAREA')
            }

            $scope.replaceMacro = function (macro, hasTrailingSpace) {
              if (!hasTrailingSpace) {
                $scope.replacingMacro = true
                $scope.timer = $timeout(function () {
                  mentioUtil.replaceMacroText($scope.context(), $scope.targetElement,
                                $scope.targetElementPath, $scope.targetElementSelectedOffset,
                                $scope.macros, $scope.macros[macro])
                  angular.element($scope.targetElement).triggerHandler('change')
                  $scope.replacingMacro = false
                }, 300)
                $scope.$on('$destroy', function () {
                  $timeout.cancel($scope.timer)
                })
              } else {
                mentioUtil.replaceMacroText($scope.context(), $scope.targetElement, $scope.targetElementPath,
                            $scope.targetElementSelectedOffset, $scope.macros, $scope.macros[macro])
              }
            }

            $scope.addMenu = function (menuScope) {
              if (menuScope.parentScope && $scope.triggerCharMap.hasOwnProperty(menuScope.triggerChar)) {
                return
              }
              $scope.triggerCharMap[menuScope.triggerChar] = menuScope
              if ($scope.triggerCharSet === undefined) {
                $scope.triggerCharSet = []
              }
              $scope.triggerCharSet.push(menuScope.triggerChar)
              menuScope.setParent($scope)
            }

            $scope.$on(
                    'menuCreated', function (event, data) {
                      if (
                            $attrs.id !== undefined ||
                            $attrs.mentioId !== undefined
                        ) {
                        if (
                                $attrs.id === data.targetElement ||
                                (
                                    $attrs.mentioId !== undefined &&
                                    $scope.altId === data.targetElement
                                )
                            ) {
                          $scope.addMenu(data.scope)
                        }
                      }
                    }
                )

            $document.on(
                    'click', function () {
                      if ($scope.isActive()) {
                        $scope.$apply(function () {
                          $scope.hideAll()
                        })
                      }
                    }
                )

            $document.on(
                    'keydown keypress paste', function (event) {
                      let activeMenuScope = $scope.getActiveMenuScope()
                      if (activeMenuScope) {
                        if (event.which === 9 || event.which === 13) {
                          event.preventDefault()
                          activeMenuScope.selectActive()
                        }

                        if (event.which === 27) {
                          event.preventDefault()
                          activeMenuScope.$apply(function () {
                            activeMenuScope.hideMenu()
                          })
                        }

                        if (event.which === 40) {
                          event.preventDefault()
                          activeMenuScope.$apply(function () {
                            activeMenuScope.activateNextItem()
                          })
                          activeMenuScope.adjustScroll(1)
                        }

                        if (event.which === 38) {
                          event.preventDefault()
                          activeMenuScope.$apply(function () {
                            activeMenuScope.activatePreviousItem()
                          })
                          activeMenuScope.adjustScroll(-1)
                        }

                        if (event.which === 37 || event.which === 39) {
                          event.preventDefault()
                        }
                      }
                    }
                )
          }],
          link: function (scope, element, attrs) {
            scope.triggerCharMap = {}

            scope.targetElement = element
            attrs.$set('autocomplete', 'off')

            if (attrs.mentioItems) {
              scope.localItems = []
              scope.parentScope = scope
              let itemsRef = attrs.mentioSearch ? ' mentio-items="items"' : ' mentio-items="localItems"'

              scope.defaultTriggerChar = attrs.mentioTriggerChar ? scope.$eval(attrs.mentioTriggerChar) : '@'

              let html = '<mentio-menu' +
                        ' mentio-search="bridgeSearch(term)"' +
                        ' mentio-select="bridgeSelect(item)"' +
                        itemsRef

              if (attrs.mentioTemplateUrl) {
                html = html + ' mentio-template-url="' + attrs.mentioTemplateUrl + '"'
              }
              html = html + ' mentio-trigger-char="\'' + scope.defaultTriggerChar + '\'"' +
                        ' mentio-parent-scope="parentScope"' +
                        '/>'
              let linkFn = $compile(html)
              let el = linkFn(scope)

              element.parent().append(el)

              scope.$on('$destroy', function () {
                el.remove()
              })
            }

            if (attrs.mentioTypedTerm) {
              scope.syncTriggerText = true
            }

            function keyHandler (event) {
              function stopEvent (event) {
                event.preventDefault()
                event.stopPropagation()
                event.stopImmediatePropagation()
              }
              let activeMenuScope = scope.getActiveMenuScope()
              if (activeMenuScope) {
                if (event.which === 9 || event.which === 13) {
                  stopEvent(event)
                  activeMenuScope.selectActive()
                  return false
                }

                if (event.which === 27) {
                  stopEvent(event)
                  activeMenuScope.$apply(function () {
                    activeMenuScope.hideMenu()
                  })
                  return false
                }

                if (event.which === 40) {
                  stopEvent(event)
                  activeMenuScope.$apply(function () {
                    activeMenuScope.activateNextItem()
                  })
                  activeMenuScope.adjustScroll(1)
                  return false
                }

                if (event.which === 38) {
                  stopEvent(event)
                  activeMenuScope.$apply(function () {
                    activeMenuScope.activatePreviousItem()
                  })
                  activeMenuScope.adjustScroll(-1)
                  return false
                }

                if (event.which === 37 || event.which === 39) {
                  stopEvent(event)
                  return false
                }
              }
            }

            scope.$watch(
                    'iframeElement', function (newValue) {
                      if (newValue) {
                        let iframeDocument = newValue.contentWindow.document
                        iframeDocument.addEventListener('click',
                                function () {
                                  if (scope.isActive()) {
                                    scope.$apply(function () {
                                      scope.hideAll()
                                    })
                                  }
                                }
                            )

                        iframeDocument.addEventListener('keydown', keyHandler, true /* capture */)

                        scope.$on('$destroy', function () {
                          iframeDocument.removeEventListener('keydown', keyHandler)
                        })
                      }
                    }
                )

            scope.$watch(
                    'ngModel',
                    function (newValue, oldValue) {
                        /* jshint maxcomplexity:14 */
                        /* jshint maxstatements:39 */
                        // yes this function needs refactoring
                      if ((!newValue || newValue === '' || newValue === oldValue) && !scope.isActive()) {
                            // ignore while setting up
                        return
                      }
                      if (scope.triggerCharSet === undefined) {
                        $log.error('Error, no mentio-items attribute was provided, ' +
                                'and no separate mentio-menus were specified.  Nothing to do.')
                        return
                      }

                      if (scope.contentEditableMenuPasted) {
                            // don't respond to changes from insertion of the menu content
                        scope.contentEditableMenuPasted = false
                        return
                      }

                      if (scope.replacingMacro) {
                        $timeout.cancel(scope.timer)
                        scope.replacingMacro = false
                      }

                      let isActive = scope.isActive()
                      let isContentEditable = scope.isContentEditable()

                      let mentionInfo = mentioUtil.getTriggerInfo(scope.context(), scope.triggerCharSet,
                            scope.requireLeadingSpace, isActive)

                      if (mentionInfo !== undefined &&
                                (
                                    !isActive ||
                                    (isActive &&
                                        (
                                            /* content editable selection changes to local nodes which
                                            modifies the start position of the selection over time,
                                            just consider triggerchar changes which
                                            will have the odd effect that deleting a trigger char pops
                                            the menu for a previous
                                            trigger char sequence if one exists in a content editable */
                                            (isContentEditable && mentionInfo.mentionTriggerChar ===
                                                scope.currentMentionTriggerChar) ||
                                            (!isContentEditable && mentionInfo.mentionPosition ===
                                                scope.currentMentionPosition)
                                        )
                                    )
                                )
                            ) {
                            /** save selection info about the target control for later re-selection */
                        if (mentionInfo.mentionSelectedElement) {
                          scope.targetElement = mentionInfo.mentionSelectedElement
                          scope.targetElementPath = mentionInfo.mentionSelectedPath
                          scope.targetElementSelectedOffset = mentionInfo.mentionSelectedOffset
                        }

                            /* publish to external ngModel */
                        scope.setTriggerText(mentionInfo.mentionText)
                            /* remember current position */
                        scope.currentMentionPosition = mentionInfo.mentionPosition
                        scope.currentMentionTriggerChar = mentionInfo.mentionTriggerChar
                            /* perform query */
                        scope.query(mentionInfo.mentionTriggerChar, mentionInfo.mentionText)
                      } else {
                        let currentTypedTerm = scope.typedTerm
                        scope.setTriggerText('')
                        scope.hideAll()

                        let macroMatchInfo = mentioUtil.getMacroMatch(scope.context(), scope.macros)

                        if (macroMatchInfo !== undefined) {
                          scope.targetElement = macroMatchInfo.macroSelectedElement
                          scope.targetElementPath = macroMatchInfo.macroSelectedPath
                          scope.targetElementSelectedOffset = macroMatchInfo.macroSelectedOffset
                          scope.replaceMacro(macroMatchInfo.macroText, macroMatchInfo.macroHasTrailingSpace)
                        } else if (scope.selectNotFound && currentTypedTerm && currentTypedTerm !== '') {
                          let lastScope = scope.triggerCharMap[scope.currentMentionTriggerChar]
                          if (lastScope) {
                                    // just came out of typeahead state
                            let text = lastScope.select({
                              item: {label: currentTypedTerm}
                            })
                            if (typeof text.then === 'function') {
                                        /* text is a promise, at least our best guess */
                              text.then(scope.replaceText)
                            } else {
                              scope.replaceText(text, true)
                            }
                          }
                        }
                      }
                    }
                )
          }
        }
      }])

    .directive('mentioMenu', ['mentioUtil', '$rootScope', '$log', '$window', '$document',
      function (mentioUtil, $rootScope, $log, $window, $document) {
        return {
          restrict: 'E',
          scope: {
            search: '&mentioSearch',
            select: '&mentioSelect',
            items: '=mentioItems',
            triggerChar: '=mentioTriggerChar',
            forElem: '=mentioFor',
            parentScope: '=mentioParentScope'
          },
          templateUrl: function (tElement, tAttrs) {
            return tAttrs.mentioTemplateUrl !== undefined ? tAttrs.mentioTemplateUrl : 'mentio-menu.tpl.html'
          },
          controller: ['$scope', function ($scope) {
            $scope.visible = false

                // callable both with controller (menuItem) and without controller (local)
            this.activate = $scope.activate = function (item) {
              $scope.activeItem = item
            }

                // callable both with controller (menuItem) and without controller (local)
            this.isActive = $scope.isActive = function (item) {
              return $scope.activeItem === item
            }

                // callable both with controller (menuItem) and without controller (local)
            this.selectItem = $scope.selectItem = function (item) {
              let text = $scope.select({
                item: item
              })
              if (typeof text.then === 'function') {
                        /* text is a promise, at least our best guess */
                text.then($scope.parentMentio.replaceText)
              } else {
                $scope.parentMentio.replaceText(text)
              }
            }

            $scope.activateNextItem = function () {
              let index = $scope.items.indexOf($scope.activeItem)
              this.activate($scope.items[(index + 1) % $scope.items.length])
            }

            $scope.activatePreviousItem = function () {
              let index = $scope.items.indexOf($scope.activeItem)
              this.activate($scope.items[index === 0 ? $scope.items.length - 1 : index - 1])
            }

            $scope.isFirstItemActive = function () {
              let index = $scope.items.indexOf($scope.activeItem)

              return index === 0
            }

            $scope.isLastItemActive = function () {
              let index = $scope.items.indexOf($scope.activeItem)

              return index === ($scope.items.length - 1)
            }

            $scope.selectActive = function () {
              $scope.selectItem($scope.activeItem)
            }

            $scope.isVisible = function () {
              return $scope.visible
            }

            $scope.showMenu = function () {
              if (!$scope.visible) {
                $scope.requestVisiblePendingSearch = true
              }
            }

            $scope.setParent = function (scope) {
              $scope.parentMentio = scope
              $scope.targetElement = scope.targetElement
            }
          }],

          link: function (scope, element) {
            element[0].parentNode.removeChild(element[0])
            $document[0].body.appendChild(element[0])
            scope.menuElement = element // for testing

            if (scope.parentScope) {
              scope.parentScope.addMenu(scope)
            } else {
              if (!scope.forElem) {
                $log.error('mentio-menu requires a target element in tbe mentio-for attribute')
                return
              }
              if (!scope.triggerChar) {
                $log.error('mentio-menu requires a trigger char')
                return
              }
                    // send own scope to mentio directive so that the menu
                    // becomes attached
              $rootScope.$broadcast('menuCreated',
                {
                  targetElement: scope.forElem,
                  scope: scope
                })
            }

            angular.element($window).bind(
                    'resize', function () {
                      if (scope.isVisible()) {
                        let triggerCharSet = []
                        triggerCharSet.push(scope.triggerChar)
                        mentioUtil.popUnderMention(scope.parentMentio.context(),
                                triggerCharSet, element, scope.requireLeadingSpace)
                      }
                    }
                )

            scope.$watch('items', function (items) {
              if (items && items.length > 0) {
                scope.activate(items[0])
                if (!scope.visible && scope.requestVisiblePendingSearch) {
                  scope.visible = true
                  scope.requestVisiblePendingSearch = false
                }
              } else {
                scope.hideMenu()
              }
            })

            scope.$watch('isVisible()', function (visible) {
                    // wait for the watch notification to show the menu
              if (visible) {
                let triggerCharSet = []
                triggerCharSet.push(scope.triggerChar)
                mentioUtil.popUnderMention(scope.parentMentio.context(),
                            triggerCharSet, element, scope.requireLeadingSpace)
              }
            })

            scope.parentMentio.$on('$destroy', function () {
              element.remove()
            })

            scope.hideMenu = function () {
              scope.visible = false
              element.css('display', 'none')
            }

            scope.adjustScroll = function (direction) {
              let menuEl = element[0]
              let menuItemsList = menuEl.querySelector('ul')
              let menuItem = menuEl.querySelector('[mentio-menu-item].active')

              if (scope.isFirstItemActive()) {
                return menuItemsList.scrollTop = 0
              } else if (scope.isLastItemActive()) {
                return menuItemsList.scrollTop = menuItemsList.scrollHeight
              }

              if (direction === 1) {
                menuItemsList.scrollTop += menuItem.offsetHeight
              } else {
                menuItemsList.scrollTop -= menuItem.offsetHeight
              }
            }
          }
        }
      }])

    .directive('mentioMenuItem', function () {
      return {
        restrict: 'A',
        scope: {
          item: '=mentioMenuItem'
        },
        require: '^mentioMenu',
        link: function (scope, element, attrs, controller) {
          scope.$watch(function () {
            return controller.isActive(scope.item)
          }, function (active) {
            if (active) {
              element.addClass('active')
            } else {
              element.removeClass('active')
            }
          })

          element.bind('mouseenter', function () {
            scope.$apply(function () {
              controller.activate(scope.item)
            })
          })

          element.bind('click', function () {
            controller.selectItem(scope.item)
            return false
          })
        }
      }
    })
    .filter('unsafe', ['$sce', function ($sce) {
      return function (val) {
        return $sce.trustAsHtml(val)
      }
    }])
    .filter('mentioHighlight', function () {
      function escapeRegexp (queryToEscape) {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
      }

      return function (matchItem, query, hightlightClass) {
        if (query) {
          let replaceText = hightlightClass
            ? '<span class="' + hightlightClass + '">$&</span>'
            : '<strong>$&</strong>'
          return ('' + matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), replaceText)
        } else {
          return matchItem
        }
      }
    })

'use strict'

angular.module('mentio')
    .factory('mentioUtil', ['$window', '$location', '$anchorScroll', '$timeout', function ($window, $location, $anchorScroll, $timeout) {
        // public
      function popUnderMention (ctx, triggerCharSet, selectionEl, requireLeadingSpace) {
        let coordinates
        let mentionInfo = getTriggerInfo(ctx, triggerCharSet, requireLeadingSpace, false)

        if (mentionInfo !== undefined) {
          if (selectedElementIsTextAreaOrInput(ctx)) {
            coordinates = getTextAreaOrInputUnderlinePosition(ctx, getDocument(ctx).activeElement,
                        mentionInfo.mentionPosition)
          } else {
            coordinates = getContentEditableCaretPosition(ctx, mentionInfo.mentionPosition)
          }

                // Move the button into place.
          selectionEl.css({
            top: coordinates.top + 'px',
            left: coordinates.left + 'px',
            position: 'absolute',
            zIndex: 100,
            display: 'block'
          })

          $timeout(function () {
            scrollIntoView(ctx, selectionEl)
          }, 0)
        } else {
          selectionEl.css({
            display: 'none'
          })
        }
      }

      function scrollIntoView (ctx, elem) {
            // cheap hack in px - need to check styles relative to the element
        let reasonableBuffer = 20
        let maxScrollDisplacement = 100
        let clientRect
        let e = elem[0]
        while (clientRect === undefined || clientRect.height === 0) {
          clientRect = e.getBoundingClientRect()
          if (clientRect.height === 0) {
            e = e.childNodes[0]
            if (e === undefined || !e.getBoundingClientRect) {
              return
            }
          }
        }
        let elemTop = clientRect.top
        let elemBottom = elemTop + clientRect.height
        if (elemTop < 0) {
          $window.scrollTo(0, $window.pageYOffset + clientRect.top - reasonableBuffer)
        } else if (elemBottom > $window.innerHeight) {
          let maxY = $window.pageYOffset + clientRect.top - reasonableBuffer
          if (maxY - $window.pageYOffset > maxScrollDisplacement) {
            maxY = $window.pageYOffset + maxScrollDisplacement
          }
          let targetY = $window.pageYOffset - ($window.innerHeight - elemBottom)
          if (targetY > maxY) {
            targetY = maxY
          }
          $window.scrollTo(0, targetY)
        }
      }

      function selectedElementIsTextAreaOrInput (ctx) {
        let element = getDocument(ctx).activeElement
        if (element !== null) {
          let nodeName = element.nodeName
          let type = element.getAttribute('type')
          return (nodeName === 'INPUT' && type === 'text') || nodeName === 'TEXTAREA'
        }
        return false
      }

      function selectElement (ctx, targetElement, path, offset) {
        let range
        let elem = targetElement
        if (path) {
          for (let i = 0; i < path.length; i++) {
            elem = elem.childNodes[path[i]]
            if (elem === undefined) {
              return
            }
            while (elem.length < offset) {
              offset -= elem.length
              elem = elem.nextSibling
            }
            if (elem.childNodes.length === 0 && !elem.length) {
              elem = elem.previousSibling
            }
          }
        }
        let sel = getWindowSelection(ctx)

        range = getDocument(ctx).createRange()
        range.setStart(elem, offset)
        range.setEnd(elem, offset)
        range.collapse(true)
        try { sel.removeAllRanges() } catch (error) {}
        sel.addRange(range)
        targetElement.focus()
      }

      function pasteHtml (ctx, html, startPos, endPos) {
        let range, sel
        sel = getWindowSelection(ctx)
        range = getDocument(ctx).createRange()
        range.setStart(sel.anchorNode, startPos)
        range.setEnd(sel.anchorNode, endPos)
        range.deleteContents()

        let el = getDocument(ctx).createElement('div')
        el.innerHTML = html
        let frag = getDocument(ctx).createDocumentFragment()
        let node
        let lastNode
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node)
        }
        range.insertNode(frag)

            // Preserve the selection
        if (lastNode) {
          range = range.cloneRange()
          range.setStartAfter(lastNode)
          range.collapse(true)
          sel.removeAllRanges()
          sel.addRange(range)
        }
      }

      function resetSelection (ctx, targetElement, path, offset) {
        let nodeName = targetElement.nodeName
        if (nodeName === 'INPUT' || nodeName === 'TEXTAREA') {
          if (targetElement !== getDocument(ctx).activeElement) {
            targetElement.focus()
          }
        } else {
          selectElement(ctx, targetElement, path, offset)
        }
      }

        // public
      function replaceMacroText (ctx, targetElement, path, offset, macros, text) {
        resetSelection(ctx, targetElement, path, offset)

        let macroMatchInfo = getMacroMatch(ctx, macros)

        if (macroMatchInfo.macroHasTrailingSpace) {
          macroMatchInfo.macroText = macroMatchInfo.macroText + '\xA0'
          text = text + '\xA0'
        }

        if (macroMatchInfo !== undefined) {
          let element = getDocument(ctx).activeElement
          if (selectedElementIsTextAreaOrInput(ctx)) {
            let startPos = macroMatchInfo.macroPosition
            let endPos = macroMatchInfo.macroPosition + macroMatchInfo.macroText.length
            element.value = element.value.substring(0, startPos) + text +
                        element.value.substring(endPos, element.value.length)
            element.selectionStart = startPos + text.length
            element.selectionEnd = startPos + text.length
          } else {
            pasteHtml(ctx, text, macroMatchInfo.macroPosition,
                            macroMatchInfo.macroPosition + macroMatchInfo.macroText.length)
          }
        }
      }

        // public
      function replaceTriggerText (ctx, targetElement, path, offset, triggerCharSet,
                text, requireLeadingSpace, hasTrailingSpace) {
        resetSelection(ctx, targetElement, path, offset)

        let mentionInfo = getTriggerInfo(ctx, triggerCharSet, requireLeadingSpace, true, hasTrailingSpace)

        if (mentionInfo !== undefined) {
          if (selectedElementIsTextAreaOrInput()) {
            let myField = getDocument(ctx).activeElement
            text = text + ' '
            let startPos = mentionInfo.mentionPosition
            let endPos = mentionInfo.mentionPosition + mentionInfo.mentionText.length + 1
            myField.value = myField.value.substring(0, startPos) + text +
                        myField.value.substring(endPos, myField.value.length)
            myField.selectionStart = startPos + text.length
            myField.selectionEnd = startPos + text.length
          } else {
                    // add a space to the end of the pasted text
            text = text + '\xA0'
            pasteHtml(ctx, text, mentionInfo.mentionPosition,
                            mentionInfo.mentionPosition + mentionInfo.mentionText.length + 1)
          }
        }
      }

      function getNodePositionInParent (ctx, elem) {
        if (elem.parentNode === null) {
          return 0
        }
        for (let i = 0; i < elem.parentNode.childNodes.length; i++) {
          let node = elem.parentNode.childNodes[i]
          if (node === elem) {
            return i
          }
        }
      }

        // public
      function getMacroMatch (ctx, macros) {
        let selected
        let path = []
        let offset

        if (selectedElementIsTextAreaOrInput(ctx)) {
          selected = getDocument(ctx).activeElement
        } else {
                // content editable
          let selectionInfo = getContentEditableSelectedPath(ctx)
          if (selectionInfo) {
            selected = selectionInfo.selected
            path = selectionInfo.path
            offset = selectionInfo.offset
          }
        }
        let effectiveRange = getTextPrecedingCurrentSelection(ctx)
        if (effectiveRange !== undefined && effectiveRange !== null) {
          let matchInfo

          let hasTrailingSpace = false

          if (effectiveRange.length > 0 &&
                    (effectiveRange.charAt(effectiveRange.length - 1) === '\xA0' ||
                        effectiveRange.charAt(effectiveRange.length - 1) === ' ')) {
            hasTrailingSpace = true
                    // strip space
            effectiveRange = effectiveRange.substring(0, effectiveRange.length - 1)
          }

          angular.forEach(macros, function (macro, c) {
            let idx = effectiveRange.toUpperCase().lastIndexOf(c.toUpperCase())

            if (idx >= 0 && c.length + idx === effectiveRange.length) {
              let prevCharPos = idx - 1
              if (idx === 0 || effectiveRange.charAt(prevCharPos) === '\xA0' ||
                            effectiveRange.charAt(prevCharPos) === ' ') {
                matchInfo = {
                  macroPosition: idx,
                  macroText: c,
                  macroSelectedElement: selected,
                  macroSelectedPath: path,
                  macroSelectedOffset: offset,
                  macroHasTrailingSpace: hasTrailingSpace
                }
              }
            }
          })
          if (matchInfo) {
            return matchInfo
          }
        }
      }

      function getContentEditableSelectedPath (ctx) {
            // content editable
        let sel = getWindowSelection(ctx)
        let selected = sel.anchorNode
        let path = []
        let offset
        if (selected != null) {
          let i
          let ce = selected.contentEditable
          while (selected !== null && ce !== 'true') {
            i = getNodePositionInParent(ctx, selected)
            path.push(i)
            selected = selected.parentNode
            if (selected !== null) {
              ce = selected.contentEditable
            }
          }
          path.reverse()
                // getRangeAt may not exist, need alternative
          offset = sel.getRangeAt(0).startOffset
          return {
            selected: selected,
            path: path,
            offset: offset
          }
        }
      }

        // public
      function getTriggerInfo (ctx, triggerCharSet, requireLeadingSpace, menuAlreadyActive, hasTrailingSpace) {
            /* jshint maxcomplexity:11 */
            // yes this function needs refactoring
        let selected, path, offset
        if (selectedElementIsTextAreaOrInput(ctx)) {
          selected = getDocument(ctx).activeElement
        } else {
                // content editable
          let selectionInfo = getContentEditableSelectedPath(ctx)
          if (selectionInfo) {
            selected = selectionInfo.selected
            path = selectionInfo.path
            offset = selectionInfo.offset
          }
        }
        let effectiveRange = getTextPrecedingCurrentSelection(ctx)

        if (effectiveRange !== undefined && effectiveRange !== null) {
          let mostRecentTriggerCharPos = -1
          let triggerChar
          triggerCharSet.forEach(function (c) {
            let idx = effectiveRange.lastIndexOf(c)
            if (idx > mostRecentTriggerCharPos) {
              mostRecentTriggerCharPos = idx
              triggerChar = c
            }
          })
          if (mostRecentTriggerCharPos >= 0 &&
                        (
                            mostRecentTriggerCharPos === 0 ||
                            !requireLeadingSpace ||
                            /[\xA0\s]/g.test(
                                effectiveRange.substring(
                                    mostRecentTriggerCharPos - 1,
                                    mostRecentTriggerCharPos)
                            )
                        )
                    ) {
            let currentTriggerSnippet = effectiveRange.substring(mostRecentTriggerCharPos + 1,
                        effectiveRange.length)

            triggerChar = effectiveRange.substring(mostRecentTriggerCharPos, mostRecentTriggerCharPos + 1)
            let firstSnippetChar = currentTriggerSnippet.substring(0, 1)
            let leadingSpace = currentTriggerSnippet.length > 0 &&
                        (
                            firstSnippetChar === ' ' ||
                            firstSnippetChar === '\xA0'
                        )
            if (hasTrailingSpace) {
              currentTriggerSnippet = currentTriggerSnippet.trim()
            }
            if (!leadingSpace && (menuAlreadyActive || !(/[\xA0\s]/g.test(currentTriggerSnippet)))) {
              return {
                mentionPosition: mostRecentTriggerCharPos,
                mentionText: currentTriggerSnippet,
                mentionSelectedElement: selected,
                mentionSelectedPath: path,
                mentionSelectedOffset: offset,
                mentionTriggerChar: triggerChar
              }
            }
          }
        }
      }

      function getWindowSelection (ctx) {
        if (!ctx) {
          return window.getSelection()
        } else {
          return ctx.iframe.contentWindow.getSelection()
        }
      }

      function getDocument (ctx) {
        if (!ctx) {
          return document
        } else {
          return ctx.iframe.contentWindow.document
        }
      }

      function getTextPrecedingCurrentSelection (ctx) {
        let text
        if (selectedElementIsTextAreaOrInput(ctx)) {
          let textComponent = getDocument(ctx).activeElement
          let startPos = textComponent.selectionStart
          text = textComponent.value.substring(0, startPos)
        } else {
          let selectedElem = getWindowSelection(ctx).anchorNode
          if (selectedElem != null) {
            let workingNodeContent = selectedElem.textContent
            let selectStartOffset = getWindowSelection(ctx).getRangeAt(0).startOffset
            if (selectStartOffset >= 0) {
              text = workingNodeContent.substring(0, selectStartOffset)
            }
          }
        }
        return text
      }

      function getContentEditableCaretPosition (ctx, selectedNodePosition) {
        let markerTextChar = '\ufeff'
        let markerEl
        let markerId = 'sel_' + new Date().getTime() + '_' + Math.random().toString().substr(2)

        let range
        let sel = getWindowSelection(ctx)
        let prevRange = sel.getRangeAt(0)
        range = getDocument(ctx).createRange()

        range.setStart(sel.anchorNode, selectedNodePosition)
        range.setEnd(sel.anchorNode, selectedNodePosition)

        range.collapse(false)

            // Create the marker element containing a single invisible character using DOM methods and insert it
        markerEl = getDocument(ctx).createElement('span')
        markerEl.id = markerId
        markerEl.appendChild(getDocument(ctx).createTextNode(markerTextChar))
        range.insertNode(markerEl)
        sel.removeAllRanges()
        sel.addRange(prevRange)

        let coordinates = {
          left: 0,
          top: markerEl.offsetHeight
        }

        localToGlobalCoordinates(ctx, markerEl, coordinates)

        markerEl.parentNode.removeChild(markerEl)
        return coordinates
      }

      function localToGlobalCoordinates (ctx, element, coordinates) {
        let obj = element
        let iframe = ctx ? ctx.iframe : null
        while (obj) {
          coordinates.left += obj.offsetLeft
          coordinates.top += obj.offsetTop
          if (obj !== getDocument().body) {
            coordinates.top -= obj.scrollTop
            coordinates.left -= obj.scrollLeft
          }
          obj = obj.offsetParent
          if (!obj && iframe) {
            obj = iframe
            iframe = null
          }
        }
      }

      function getTextAreaOrInputUnderlinePosition (ctx, element, position) {
        let properties = [
          'direction',
          'boxSizing',
          'width',
          'height',
          'overflowX',
          'overflowY',
          'borderTopWidth',
          'borderRightWidth',
          'borderBottomWidth',
          'borderLeftWidth',
          'paddingTop',
          'paddingRight',
          'paddingBottom',
          'paddingLeft',
          'fontStyle',
          'fontVariant',
          'fontWeight',
          'fontStretch',
          'fontSize',
          'fontSizeAdjust',
          'lineHeight',
          'fontFamily',
          'textAlign',
          'textTransform',
          'textIndent',
          'textDecoration',
          'letterSpacing',
          'wordSpacing'
        ]

        let isFirefox = (window.mozInnerScreenX !== null)

        let div = getDocument(ctx).createElement('div')
        div.id = 'input-textarea-caret-position-mirror-div'
        getDocument(ctx).body.appendChild(div)

        let style = div.style
        let computed = window.getComputedStyle ? getComputedStyle(element) : element.currentStyle

        style.whiteSpace = 'pre-wrap'
        if (element.nodeName !== 'INPUT') {
          style.wordWrap = 'break-word'
        }

            // position off-screen
        style.position = 'absolute'
        style.visibility = 'hidden'

            // transfer the element's properties to the div
        properties.forEach(function (prop) {
          style[prop] = computed[prop]
        })

        if (isFirefox) {
          style.width = (parseInt(computed.width) - 2) + 'px'
          if (element.scrollHeight > parseInt(computed.height)) {
            style.overflowY = 'scroll'
          }
        } else {
          style.overflow = 'hidden'
        }

        div.textContent = element.value.substring(0, position)

        if (element.nodeName === 'INPUT') {
          div.textContent = div.textContent.replace(/\s/g, '\u00a0')
        }

        let span = getDocument(ctx).createElement('span')
        span.textContent = element.value.substring(position) || '.'
        div.appendChild(span)

        let coordinates = {
          top: span.offsetTop + parseInt(computed.borderTopWidth) + parseInt(computed.fontSize),
          left: span.offsetLeft + parseInt(computed.borderLeftWidth)
        }

        localToGlobalCoordinates(ctx, element, coordinates)

        getDocument(ctx).body.removeChild(div)

        return coordinates
      }

      return {
            // public
        popUnderMention: popUnderMention,
        replaceMacroText: replaceMacroText,
        replaceTriggerText: replaceTriggerText,
        getMacroMatch: getMacroMatch,
        getTriggerInfo: getTriggerInfo,
        selectElement: selectElement,

            // private: for unit testing only
        getTextAreaOrInputUnderlinePosition: getTextAreaOrInputUnderlinePosition,
        getTextPrecedingCurrentSelection: getTextPrecedingCurrentSelection,
        getContentEditableSelectedPath: getContentEditableSelectedPath,
        getNodePositionInParent: getNodePositionInParent,
        getContentEditableCaretPosition: getContentEditableCaretPosition,
        pasteHtml: pasteHtml,
        resetSelection: resetSelection,
        scrollIntoView: scrollIntoView
      }
    }])

angular.module('mentio').run(['$templateCache', function ($templateCache) { $templateCache.put('mentio-menu.tpl.html', "<style>\n.scrollable-menu {\n    height: auto;\n    max-height: 300px;\n    overflow: auto;\n}\n\n.menu-highlighted {\n    font-weight: bold;\n}\n</style>\n<ul class=\"dropdown-menu scrollable-menu\" style=\"display:block\">\n    <li mentio-menu-item=\"item\" ng-repeat=\"item in items track by $index\">\n        <a class=\"text-primary\" ng-bind-html=\"item.label | mentioHighlight:typedTerm:\'menu-highlighted\' | unsafe\"></a>\n    </li>\n</ul>") }])
