'use strict'

/**
 * mcqs-question-next-button directive
 * @param $document
 * @param NavbarService
 * @returns {{template: string, scope: {}, bindToController: {disableNext: string}, controller: string, controllerAs: string, link: link}}
 */
function mcqsQuestionNextButton ($document, NavbarService) {
  'ngInject'
  return {
    template: `
            <md-button
                class="question__toolbar-action md-primary md-raised md-hue-1"
                ng-click="vm.nextQuestion()"
                ng-disabled="vm.disableNext"
            > Next question </md-button>
        `,
    scope: {},
    bindToController: { disableNext: '=' },
    controller: 'questionNextController',
    controllerAs: 'vm',
    link: link
  }

  /**
   * link function that handles the bindings on the next button
   * @param scope
   * @param elem
   * @param attrs
   */
  function link (scope, elem, attrs) {
    activate()

    /**
     * initialisation function that attaches bindings to the button
     */
    function activate () {
      /* There are two 'next' buttons on the question view.
                There are no keyboard listeners on the mobile-only one.
                Checking for the noListeners attribute stops the event firing twice. */
      if (!attrs.noListeners) {
        $document.off('keyup', getKey)
        $document.on('keyup', getKey)
      }
      elem.on('$destroy', unbindEvent)
    }

    /**
     * gets he key that was pressed
     * @param e key event
     */
    function getKey (e) {
      let target = e.target.tagName
      let notInput = (target !== 'INPUT' && target !== 'TEXTAREA' && target !== 'BUTTON')
      if (!NavbarService.isOpen() && e.which === 13 && notInput && !scope.vm.disableNext) scope.vm.nextQuestion()
    }

    /**
     * unbind the event trigger and destroy the scope
     */
    function unbindEvent () {
      // http://stackoverflow.com/questions/26983696/angularjs-does-destroy-remove-event-listeners
      $document.off('keyup', getKey)
      scope.$destroy()
    }
  }
}

/**
 * controller that handles the functionality of the next button
 * @param $state
 * @param $timeout
 * @param QuestionService
 * @param TourQuestionHandler
 */
function questionNextController ($state, $timeout, QuestionService, TourQuestionHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const mediator = TourQuestionHandler.mediator

  vm.nextQuestion = nextQuestion

  activate()

  /**
   * initialisation function that sets up a trigger for the tour
   */
  function activate () {
    // Fires nextQuestion when the tour has been completed
    if (!mediator.bindings['next-question']) mediator.on('next-question', nextQuestion)
  }

  /**
   * request a new question
   */
  function nextQuestion () {
    vm.disableNext = true
    QuestionService.getQuestion().then(success, failure)

    /**
     * success handler for QuestionService.getQuestion()
     * cancels active tours and updates current question
     * @param data object returned from QuestionService.getQuestion()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      vm.disableNext = false
      let { activeTour } = TourQuestionHandler.shepherd
      if (activeTour) TourQuestionHandler.questionTour.cancel()
      // timeout to trigger digest cycle
      $timeout(() => {
        $state.go('root.auth.question')
        QuestionService.updateQuestion(data)
      })
    }

    /**
     * error handler for QuestionService.getQuestion()
     * sets dfault text for question error
     * @param error object returned from QuestionService.getQuestion()
     */
    function failure (error) {
      console.error('Loading question failed:', error)
      QuestionService.setFailedQuestion()
      $state.go('root.auth.question')
    }
  }
}

export { mcqsQuestionNextButton, questionNextController }
