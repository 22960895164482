/**
 * mcqs-sanitize directive
 * @param $compile
 * @param $timeout
 * @returns {{restrict: string, link: link}}
 */
function mcqsSanitize ($compile, $timeout) {
  'ngInject'
  return {
    restrict: 'A',
    link
  }

  /**
   * This un-sanitizes the iFrames that are sent from the back end to they're displayed in the question
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attribute attached to HTML element
   */
  function link (scope, elem, attrs) {
    // The regexes need to be very explicit so we do not get malicious injection
    const YOUTUBE_REGEX = new RegExp(/<iframe.*src="(https:\/\/www.youtube\.com\/embed\/)((\w|-){11})".*mcqs-video.*><\/iframe>/, 'g')
    const VIMEO_REGEX = new RegExp(/<iframe.*src="(https:\/\/player\.vimeo\.com\/video\/)((\d|-){1,9})".*mcqs-video.*><\/iframe>/, 'g')
    const SOUNDCLOUD_REGEX = new RegExp(/<iframe.*src="(https:\/\/w\.soundcloud\.com\/player\/\?url=.*)><\/iframe>/g)
    const LOTTIE_REGEX = new RegExp(/<mcqs-lottie-player.*lottie="((\w|-){10,20}|\w{20})"><\/mcqs-lottie-player>/g)
    const PREVIEW_CHESS_REGEX = new RegExp(/<chess-board.*id="board".*draggable-pieces.*ng-controller="builderChessPreviewController as vm".*orientation=".*" position=".*"><\/chess-board>/g)
    const QUESTION_CHESS_REGEX = new RegExp(/<chess-board.*id="board".*draggable-pieces.*ng-controller="questionChessController as vm".*orientation=".*" position=".*"><\/chess-board>/g)

    let isSanitizing = false

    activate()

    /**
     * initialisation function that checks for question__code, sanitizes code
     * once and then watches for updates to sanitize
     */
    function activate () {
      if (!elem.hasClass('question__code')) return console.error("mcqsSanitize expected element with class '.question__code'.")
      sanitize()
      attrs.$observe('mcqsSanitize', sanitize)
    }

    /**
     * check if santization is on process, if not sanitize
     */
    function sanitize () {
      if (isSanitizing) return
      isSanitizing = true
      $timeout(loopNodes, 333)
    }

    /**
     * loop through each HTML child and check for STRONG tags
     */
    function loopNodes () {
      for (let i = 0; i < elem[0].children.length; i++) {
        const childNodes = elem[0].children[i].childNodes
        // strong must be checked for as some elements of the question are
        // returned inside <strong> tags
        if (elem[0].children[i].tagName === 'STRONG' || elem[0].children[i].tagName === 'STRONG') {
          loopStrongNodes(i, childNodes)
        } else {
          loopChildNodes(i, childNodes)
        }
      }
      isSanitizing = false
    }

    /**
     * Loop through all STRONG nodes and sanitize them
     * @param childIdx int idx of child
     * @param childNodes array of child nodes
     */
    function loopStrongNodes (childIdx, childNodes) {
      for (let i = 0; i < childNodes.length; i++) {
        const node = childNodes[i].children
        for (let x = 0; x < node.length; x++) {
          const {innerText} = node[x]
          // checks if the first element of innerText is an iframe and that it passes one of the regex tests
          if (verfiyTags(innerText)) {
            // compiles the innerText, sets appropriate dimensions and replaces the initial element in the DOM with it
            let newNode = $compile(innerText)(scope)[0]
            newNode = setDimensions(newNode)
            elem[0].children[childIdx].children[i].replaceChild(newNode, node[x])
            scope.$apply()
          }
        }
      }
    }
    /**
     * Loop through all child nodes and sanitize them
     * @param childIdx int idx of child
     * @param childNodes array of child nodes
     */
    function loopChildNodes (childIdx, childNodes) {
      for (let i = 0; i < childNodes.length; i++) {
        const node = childNodes[i]
        const {innerText} = node
        // checks if the first element of innerText is an iframe and that it passes one of the regex tests
        if (verfiyTags(innerText)) {
          // compiles the innerText, sets appropriate dimensions and replaces the initial element in the DOM with it
          let newNode = $compile(innerText)(scope)[0]
          newNode = setDimensions(newNode)
          elem[0].children[childIdx].replaceChild(newNode, node)
          scope.$apply()
        }
      }
    }

    function verfiyTags (innerText) {
      return ((innerText && innerText.trim().indexOf('<iframe')) === 0 ||
        (innerText && innerText.trim().indexOf('<mcqs-lottie-player') === 0) ||
        (innerText && innerText.trim().indexOf('<chess-board') === 0)) &&
        (matches(innerText, YOUTUBE_REGEX) ||
          matches(innerText, VIMEO_REGEX) ||
          matches(innerText, SOUNDCLOUD_REGEX) ||
          matches(innerText, LOTTIE_REGEX) ||
          matches(innerText, QUESTION_CHESS_REGEX) ||
          matches(innerText, PREVIEW_CHESS_REGEX))
    }

    /**
     * sets the video player size based on available screen size
     * @param node <iframe> html element
     * @returns {*}
     */
    function setDimensions (node) {
      const {innerWidth} = window
      if (!innerWidth || innerWidth >= 960) {
        node.width = node.width - 50
      } else if (innerWidth >= 600) {
        node.width = (innerWidth * 0.9)
      } else {
        node.width = (innerWidth * 0.8)
      }
      node.height = (node.width / 16) * 9
      return node
    }

    /**
     * returns true if a match is found for the appropriate regex
     * @param string
     * @param regex
     * @returns {boolean}
     */
    function matches (string, regex) {
      const search = regex.test(string)
      const match = string.match(regex)
      return (
        search === true &&
        Array.isArray(match) && match.length === 1
      )
    }
  }
}

export { mcqsSanitize }
