import { adminImageViewController } from './admin_image_management_view_controller'
import { adminAnimationViewController } from './admin_animation_management_view_controller'
import { AdminManagementService } from './admin_management_service'
import { routeConfig } from './admin_management_routes'

(() => {
  'use strict'
  angular
    .module('admin_management', [])
    .config(routeConfig)
    .controller('adminImageViewController', adminImageViewController)
    .controller('adminAnimationViewController', adminAnimationViewController)
    .factory('AdminManagementService', AdminManagementService)
})()

export default 'admin_management'
