'use strict'

/**
 * mcqs-profile-user-details-directive
 * @param $compile
 * @param $filter
 * @param ProfileService
 * @returns {{restrict: string, link: link}}
 */
function mcqsProfileUserDetails ($compile, $filter, ProfileService) {
  'ngInject'
  return {
    restrict: 'A',
    link
  }

  /**
   * link function that dislpays users details on hover of a users picture
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attributes attached to HTML
   */
  function link (scope, elem, attrs) {
    activate()

    /**
     * initialisation function that attaches a trigger to the element
     */
    function activate () {
      // attaches event listener to users profile question in question explanation
      elem.on('mouseover', getProfile)
      elem.bind('$destroy', () => elem.off('hover', getProfile))
    }

    /**
     * requests the profile details of the user who's image has been hovered on
     */
    function getProfile () {
      // on second mouseover details will already be present
      if (elem.hasClass('profile__details')) return
      elem.addClass('profile__details')
      let userId = attrs.mcqsProfileUserDetails
      if (userId) ProfileService.getProfile(userId).then(success, failure)
    }

    /**
     * success handler for ProfileService.getProfile()
     * Displays the users details in a card similar to a tooltip
     * @param data
     */
    function success (data) {
      let username = attrs.username ? `<p class="md-body-2 no-margin">${attrs.username}</p>` : ''
      let { dateJoined, examScore, questionsWritten, status } = data
      if (!data || status) return failure('No user details in response data.')
      dateJoined = $filter('date')(dateJoined, 'longDate')
      examScore = $filter('number')(examScore *= 100, 2) + '%'
      let totalQs = questionsWritten.all > 0 ? `<p class="md-caption"><b>Total Qs written:</b> ${questionsWritten.all}</p>` : ''
      let currentQs = questionsWritten.current > 0 ? `<p class="md-caption"><b>Qs for exam:</b> ${questionsWritten.current}</p>` : ''
      let metadata = $compile(`
                <div class="profile__details-container">
                    <md-card class="md-whiteframe-10dp">
                        <md-card-content>
                            ${username}
                            <p class="md-caption"><b>Date joined:</b> ${dateJoined}</p>
                            <p class="md-caption"><b>Exam score:</b> ${examScore}</p>
                            ${totalQs}
                            ${currentQs}
                        </md-card-content>
                    </md-card>
                </div>
            `)(scope)
      elem.append(metadata)
    }

    /**
     * error handler for ProfileService.getProfile()
     * @param error object returned from ProfileService.getProfile()
     */
    function failure (error) { console.error('Could not get user details:', error) }
  }
}

export { mcqsProfileUserDetails }
