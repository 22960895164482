import { default as ngFileUpload } from 'ng-file-upload'

import { mcqsProfileCurrentExam } from './profile_current_exam'
import { mcqsProfileImages } from './profile_images'
import { ProfileService } from './profile_service'
import { profileSidenav } from './profile_sidenav'
import { mcqsProfileSidenavState } from './profile_sidenav_state'
import { mcqsProfileUserDetails } from './profile_user_details'
import { mcqsProfileUserPicture } from './profile_user_picture';

(() => {
  'use strict'
  angular
    .module('profile', [
      ngFileUpload
    ])
    .component('mcqsProfileCurrentExam', mcqsProfileCurrentExam)
    .component('mcqsProfileImages', mcqsProfileImages)
    .factory('ProfileService', ProfileService)
    .component('profileSidenav', profileSidenav)
    .component('mcqsProfileSidenavState', mcqsProfileSidenavState)
    .directive('mcqsProfileUserDetails', mcqsProfileUserDetails)
    .directive('mcqsProfileUserPicture', mcqsProfileUserPicture)
})()

export default 'profile'
