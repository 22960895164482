'use strict'
/**
 * mcqs-builder-change-exam directive
 * @type {{template: string, bindings: {questionId: string, exam: string}, controller: builderChangeExamController, controllerAs: string}}
 */
const mcqsBuilderChangeExam = {
  template: `
        <div layout="row" layout-align="start center">
        <md-button class="md-icon-button" ng-click="vm.changeExam()">
            <md-tooltip md-direction="bottom">Change exam</md-tooltip>
            <md-icon md-font-set="fa" class="fa fa-exchange fa-2x"></md-icon>
        </md-button>
        <h2 class="md-body-2 builder__exam-name" ng-bind="vm.exam"></h2>
        </div>
    `,
  bindings: {
    questionId: '@',
    exam: '@'
  },
  controller: builderChangeExamController,
  controllerAs: 'vm'
}

/**
 * builderChangeExamController is used to dislpay a change exam dialog to the user
 * @param $mdDialog
 * @param TourBuilderHandler
 */
function builderChangeExamController ($mdDialog, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.changeExam = changeExam

  /**
   * opens a dialog allowing the user to change exam
   */
  function changeExam () {
    TourBuilderHandler.builderTour.cancel()
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/change_exam_modal.html',
      controller: builderExamChangeDialogController,
      controllerAs: 'vm',
      locals: {
        questionId: vm.questionId,
        questionExam: vm.exam
      }
    })
  }
}

/**
 * builderExamChangeDialogController is used to call BuilderService.changeExam
 * @param questionId int current question id
 * @param questionExam int current exam
 * @param $mdDialog
 * @param BuilderService
 * @param BuilderHandler
 */
function builderExamChangeDialogController (questionId, questionExam, $mdDialog, BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.selectedExam = {}

  vm.cancel = $mdDialog.cancel
  vm.changeExam = changeExam
  vm.questionId = questionId
  vm.questionExam = questionExam

  /**
   * Changes the users current exam
   */
  function changeExam () {
    BuilderService.changeExam(questionId, vm.selectedExam.examId).then(success, failure)

    /**
     * success handler for BuilderService.changeExam()
     * sets the new exam in BuilderHandler and closes the mdDialog
     * @param data object returned from BuilderService.changeExam()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      BuilderHandler.setExam(vm.selectedExam)
      $mdDialog.hide()
    }

    /**
     * error handler for BuilderService.changeExam()
     * @param error object returned from BuilderService.changeExam()
     */
    function failure (error) { console.error('Switching exam failed: ', error) }
  }
}

export { mcqsBuilderChangeExam, builderExamChangeDialogController }
