'use strict'
/**
 * mcqs-comment-report directive
 * @type {{template: string, bindings: {comment: string}, controller: mcqsCommentReportController, controllerAs: string}}
 */
const mcqsCommentReport = {
  template: `
    <md-button
        class="md-icon-button comment__action"
        ng-click="vm.openDialog()"
        ng-disabled="vm.comment.isHidden || vm.comment.tourComment"
    >
        <md-tooltip md-direction"bottom">Report comment</md-tooltip>
        <md-icon md-font-set="fa" class="fa fa-warning"></md-icon>
    </md-button>
    `,
  bindings: { comment: '<' },
  controller: mcqsCommentReportController,
  controllerAs: 'vm'
}

/**
 * handle the opening of the report dialog
 * @param $mdDialog
 */
function mcqsCommentReportController ($mdDialog) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.openDialog = openDialog

  function openDialog () {
    $mdDialog.show({
      templateUrl: 'partials/templates/comment/comment_report_dialog.html',
      controller: commentReportDialogController,
      controllerAs: 'vm',
      disableParentScroll: false,
      fullscreen: true,
      locals: { comment: vm.comment }
    })
  }
}

/**
 * takes the comment to be reported and passes it to the CommentService
 * @param comment object to be reported
 * @param $mdDialog
 * @param $timeout
 * @param CommentService
 */
function commentReportDialogController (comment, $mdDialog, $timeout, CommentService) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.disabled = false
  vm.message = ''
  vm.reason = ''

  vm.comment = comment

  vm.cancel = $mdDialog.cancel
  vm.report = report

  /**
   * passes the comment to CommentService.reportComment
   */
  function report () {
    vm.disabled = true
    vm.message = ''
    const { commentId } = comment
    CommentService.reportComment({ commentId, reason: vm.reason }).then(() => {
      vm.message = 'Thank you for filing this report. We take breaches of our Terms of Service seriously and will investigate this matter.'
      $timeout(vm.cancel, 3000)
    }, error => {
      vm.disabled = false
      vm.message = 'An error occurred while filing your report. Please try again.'
      console.error('Failed to post comment:', error)
    })
  }
}

export { mcqsCommentReport, commentReportDialogController }
