'use strict'
/* global lottie */

/**
 * mcqs-lottie-player directive
 * @param $state
 * @param LottieService
 * @param BuilderHandler
 * @param QuestionService
 * @param $compile
 * @returns {{scope: {lottie: string}, link: link, restrict: string}}
 */
function mcqsLottiePlayer ($state, $timeout, LottieService, BuilderHandler, QuestionService, $compile) {
  return {
    restrict: 'E',
    template: '<div></div>',
    scope: {
      lottie: '@',
      ngIf: '@'
    },
    link: (scope, elem, attrs) => {
      'ngInject'
      /* jshint validthis:true */
      let regex = new RegExp(/!\[LOTTIE]\((.*)\)/m)
      let lottieFile = regex.test(scope.lottie) ? scope.lottie.match(regex)[1] : scope.lottie
      let mediaBar, rendererClass, autoplay, animation

      if (attrs.ngIf) {
        rendererClass = 'lottie-player__builder'
        autoplay = true
      } else {
        rendererClass = 'lottie-animation-class'
        autoplay = false
      }

      scope.$watch('lottie', (newVal, oldVal) => {
        if (newVal !== oldVal && attrs.ngIf) {
          animation.destroy()
          lottieFile = regex.test(scope.lottie) ? scope.lottie.match(regex)[1] : scope.lottie
          init()
        }
      })

      scope.questionId = $state.current.name.includes('builder')
        ? BuilderHandler.question.questionId
        : QuestionService.question.questionId

      init()

      function init () {
        LottieService.getLottieFile(lottieFile, scope.questionId).then((data) => {
          animation = lottie.loadAnimation({
            container: elem[0].childNodes[0],
            renderer: 'svg',
            loop: true,
            autoplay: autoplay,
            animationData: data.data.animation,
            rendererSettings: {
              hideOnTransparent: false,
              className: rendererClass
            }
          })

          scope.frameData = animation
          scope.audio = data.data.audio

          // emits an event so that the card size can be updated to
          // accommodate the animations
          animation.addEventListener('DOMLoaded', () => {
            scope.$emit('DOMLoaded')
          })

          if (!attrs.ngIf) {
            createMediaBar()
          }
          styleAnimation()

          /**
           * creates the media player, compile and attaches it to parent element
           */
          function createMediaBar () {
            mediaBar = `<mcqs-lottie-media-player 
            audio="audio"
            question-id="{{questionId}}" 
            lottie-animation="frameData"></mcqs-lottie-media-player>`

            mediaBar = $compile(mediaBar)(scope)

            elem.append(mediaBar)
          }

          /**
           * applies specifc styles depending on whether animation is in
           * builder media links or question/preview
           */
          function styleAnimation () {
            elem[0].childNodes[0].setAttribute('height', '500')

            if (attrs.ngIf) {
              elem[0].childNodes[0].classList.add('lottie-player__builder')
              elem[0].childNodes[0].setAttribute('mcqs-drag-string', '{{link.url}}')
            } else {
              elem[0].childNodes[0].classList.add('lottie-player__question')
              elem[0].childNodes[0].classList.add('lottie-animation-class')
            }
          }
        }, (err) => {
          console.error(`Error loading Lottie File: ${scope.lottie}: ${err}`)
        })
      }
    }
  }
}

export { mcqsLottiePlayer }
