'use strict'
/**
 * mcqs-sign-up-form directive
 * @type {{templateUrl: string, controller: signUpController, controllerAs: string}}
 */
const mcqsSignUpForm = {
  templateUrl: 'partials/templates/authentication/sign_up_form.html',
  controller: signUpController,
  controllerAs: 'vm'
}

/**
 * The signUpController handles local sign up
 * @param $state
 * @param AuthenticationService
 * @param ProfileService
 * @param vcRecaptchaService angular recaptcha: https://github.com/VividCortex/angular-recaptcha
 */
function signUpController ($state, AuthenticationService, ProfileService, vcRecaptchaService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const auth = AuthenticationService

  let widget

  vm.errorMessage = ''
  vm.isRecaptchaValid = false
  let recaptchaResponse = ''

  vm.details = {
    firstName: '',
    surname: '',
    email: '',
    username: '',
    password: ''
  }

  vm.signUp = signUp
  vm.reCaptchaSuccess = reCaptchaSuccess
  vm.reCaptchaExpire = reCaptchaExpire
  vm.setWidgetId = setWidgetId
  vm.siteKey = process.env.RECAPTCHA_SITE_KEY

  activate()

  /**
   * initialisation function sets firstName, lastName and message if passed with $state
   */
  function activate () {
    if ($state.params) {
      // Just for FB
      const {firstName, lastName, message} = $state.params
      vm.details.firstName = firstName
      vm.details.surname = lastName
      vm.errorMessage = message
    }
  }

  /**
   * sets the widget id for the recaptcha
   * @param widgetId int id of recpatcha widget in DOM
   */
  function setWidgetId (widgetId) {
    widget = widgetId
  }

  /**
   * success handler for recaptcha widget
   * @param response string response from execution of recpatch widget
   */
  function reCaptchaSuccess (response) {
    recaptchaResponse = response
    vm.isRecaptchaValid = true
  }

  /**
   * function that fires when the recaptcha widget has expired in the DOM
   */
  function reCaptchaExpire () {
    vm.isRecaptchaValid = false
  }

  /**
   * calls AuthenticationService.signUp to sign a user up using supplied values
   */
  function signUp () {
    // If cookies have not been consented too, shows cookie error on signup
    // eslint-disable-next-line
    // Removing for now due to how Chrome is handling cookies
    // if (!Cookiebot.hasResponse) {
    //   vm.errorMessage = 'Please complete the cookie consent form below to continue'
    //   return
    // }
    vm.errorMessage = ''
    auth.signUp(vm.details, recaptchaResponse).then(success, failure)

    /**
     * Success handler for auth.signUp(), signs a user in if signUp is successful
     * @param data object response from successful signin
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      // The user is automatically signed in after they register
      auth.signIn(vm.details.username, vm.details.password).then(signInSuccess, failure)
    }

    /**
     * success handler for auth.signIn()
     * @param data object response from auth.signIn()
     * @returns {promise|*|void}
     */
    function signInSuccess (data) {
      // if no data/token returned, return user to landing page
      if (!data || data.status || !data.token || (data.token && data.token.length <= 0)) return $state.go('root.landing')
      ProfileService.setPreferences(data.preferences)
      auth.createSession(data.loginId, data.userId, data.token, vm.details.username)
      $state.go('root.auth.question')
    }

    /**
     * Error handler
     * @param error object response from either auth.signUp(), or auth.signIn()
     */
    function failure (error) {
      console.error('Sign up failed:', error)
      let hasMessage = (error) && (error.hasOwnProperty('data') && error.data.hasOwnProperty('message'))
      // if message returned use it, if not use generic error message
      vm.errorMessage = hasMessage ? error.data.message : 'Oops! Sign up failed. Please check connection and try again.'
      vcRecaptchaService.reload(widget)
    }
  }
}
export { mcqsSignUpForm }
