'use strict'

/**
 * Service that handles server requests for exam information
 * @param $cookies
 * @param $http
 * @param $mdDialog
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @param TourProfileHandler
 * @returns {{changeExam: (function(*=): angular.IPromise<any>), getExamsByCategory: (function(): *), getCurrentExam: (function(): number), showExamSelectDialog: showExamSelectDialog, updateCurrentExam: updateCurrentExam}}
 * @constructor
 */
function ExamService ($cookies, $http, $mdDialog, DOMAIN, TourProfileHandler) {
  'ngInject'
  return {
    changeExam: changeExam,
    getExamsByCategory: getExamsByCategory,
    getCurrentExam: getCurrentExam,
    getFlashCardHidden: getFlashcardHidden,
    showExamSelectDialog: showExamSelectDialog,
    updateCurrentExam: updateCurrentExam,
    updateFlashcardHidden: updateFlashcardHidden,
    flashcardHidden: 1
  }

  /**
   * updates ucrrent exam in the BE
   * @param examId
   * @returns {angular.IPromise<any>}
   */
  function changeExam (examId) {
    return $http.put(DOMAIN + '/api/profile/exam', {
      'examId': examId
    }).then(success, failure)
  }

  /**
   * get all exams in a category
   * @returns {*}
   */
  function getExamsByCategory () {
    return $http.get(DOMAIN + '/api/categories').then(success, failure)
  }

  /**
   * gets the current exam from the cookies
   * @returns {number}
   */
  function getCurrentExam () { return Number($cookies.get('currentExam')) }

  function getFlashcardHidden (examId) {
    return $http.post(DOMAIN + '/api/flashcard-hidden', {examId: examId})
      .then(success, failure)
  }

  /**
   * success handler for changeExam and getExamsByCategory
   * @param response returned from either changeExam or getExamsByCategory
   * @returns {*|{}}
   */
  function success (response) { return (response.status !== 200) ? response : response.data || {} }

  /**
   * erro handler for changeExam and getExamsByCategory
   * @param error object returned from changeExam or getExamsByCategory
   * @returns {*}
   */
  function failure (error) { return error }

  /**
   * controller for opening exam select dialog in question/profile
   * @param noCancel boolean whether or not the dialog can be cancelled
   * @param currentExam object currentExam object
   * @param scope reference to the current scope so that the £emit can be identified
   */
  function showExamSelectDialog (noCancel, currentExam, scope) {
    let { activeTour } = TourProfileHandler.shepherd
    if (activeTour) TourProfileHandler.profileTour.cancel()
    $mdDialog.show({
      templateUrl: 'partials/templates/exam/exam_select_dialog.html',
      controller: 'examSelectDialogController',
      controllerAs: 'vm',
      locals: {
        noCancel: noCancel,
        currentExam: currentExam
      },
      disableParentScroll: false,
      escapeToClose: false,
      fullscreen: true
    }).then(() => {}, () => { scope.$emit('examSelected') })
  }

  /**
   * updates  the currentExam in the cookies
   * @param examId int current examID
   */
  function updateCurrentExam (examId) {
    $cookies.remove('currentExam')
    $cookies.put('currentExam', examId)
  }

  function updateFlashcardHidden (flashcardHidden) { this.flashcardHidden = flashcardHidden }
}

export { ExamService }
