'use strict'
/**
 * mcqs-builder-add-node-item directive
 * @type {{template: string, bindings: {nodeIdx: string, disabled: string}, controller: addNodeItemController, controllerAs: string}}
 */
const mcqsBuilderAddNodeItem = {
  template: `
    <md-button
        class="md-icon-button"
        ng-click="vm.addNodeItem()"
        ng-disabled="vm.disabled"
    >
    <md-icon md-font-set="fa" class="fa fa-plus fa-2x"></md-icon>
    <md-tooltip md-direction="bottom">Add item</md-tooltip>
    </md-button>
    `,
  bindings: {
    nodeIdx: '@',
    disabled: '='
  },
  controller: addNodeItemController,
  controllerAs: 'vm'
}

/**
 * The addNodeItemController exposes the addNodeItem function to the html
 * @param BuilderHandler
 */
function addNodeItemController (BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.addNodeItem = addNodeItem

  /**
   * adds an extra related data node to the question
   */
  function addNodeItem () {
    BuilderHandler.addNodeItem(vm.nodeIdx)
  }
}

export { mcqsBuilderAddNodeItem }
