'use strict'
/**
 * mcqs-dashboard-graph directive
 * @type {{templateUrl: string, controller: mcqsDashboardController, bindings: {currentScore: string}, controllerAs: string}}
 */
const mcqsDashboardGraph = {
  templateUrl: 'partials/templates/dashboard/dashboard_graph.html',
  controller: mcqsDashboardController,
  bindings: { currentScore: '=' },
  controllerAs: 'vm'
}

/**
 * controller that sets the css style for the dashboard graph
 */
function mcqsDashboardController () {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.progressClass = setProgressColor()

  function setProgressColor () {
    vm.currentScore *= 100
    if (vm.currentScore < 25) {
      return 'md-warn'
    } else if (vm.currentScore >= 25 && vm.currentScore < 50) {
      return 'dashboard__progress-bar dashboard__progress-bar--orange'
    } else if (vm.currentScore >= 50 && vm.currentScore < 75) {
      return 'dashboard__progress-bar dashboard__progress-bar--yellow'
    } else if (vm.currentScore >= 75 && vm.currentScore <= 100) {
      return 'md-accent'
    }
  }
}

export { mcqsDashboardGraph }
