'use strict'
/**
 * builderViewController handles the overall builder view.
 * @param $document
 * @param $state
 * @param $window
 * @param BuilderService
 * @param BuilderHandler
 * @param TourBuilderHandler
 * @param $scope
 * @param $timeout
 */
function builderViewController ($document, $state, $window, BuilderService, BuilderHandler, TourBuilderHandler, $scope, $timeout) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  const mediator = TourBuilderHandler.mediator
  const tabSteps = ['set-first-tab-item', 'set-second-tab-item', 'set-third-tab-item']

  vm.$state = $state

  vm.service = BuilderHandler

  vm.selectedTab = 0

  vm.saveQuestion = saveQuestion

  activate()

  // sets up a listener for when a state change is started. This listener checks if
  // the question is valid and if not it cancels the transition and asks the
  // user to confirm their exit.
  let stateCheck = $scope.$on('$stateChangeStart', (event, toState) => {
    if (toState.name.includes('root.auth.builder')) return
    if (!BuilderHandler.isQuestionValid()) {
      event.preventDefault()
      BuilderHandler.showWarning().then(
        function warningSuccess () {
          stateCheck()
          vm.$state.transitionTo(toState.name)
        }, null
      )
    }
  })

  // listener to destroy listener on cleanup
  $scope.$on('$destroy', () => {
    // calling the listener in this way destroys it.
    stateCheck()
  })

  /**
   * initialisation function. Sets correct state, checks minWidth to decide
   * whether or not to display builder and creates tour steps
   */
  function activate () {
    // When the view loads, make sure the edit state is visible.
    vm.$state.transitionTo('root.auth.builder.edit')
    vm.isMinWidth = isMinWidth()
    // checks if screen is minimum required size for builder
    if (vm.isMinWidth) {
      vm.service.clearAll()
      BuilderService.openQuestionDialog()
    }
    // map through tab steps and create a tour listener for each one.
    tabSteps.map((item, idx, arr) => {
      if (!mediator.bindings[idx]) {
        mediator.on(arr[idx], () => {
          $timeout(() => { changeTab(idx) })
        })
      }
    })
  }

  /**
   * handles switching between Images, Media and Mathjax tabs
   * @param idx int index of clicked tab
   */
  function changeTab (idx) {
    vm.selectedTab = idx
    $timeout(TourBuilderHandler.builderTour.next, 500)
  }

  /**
   * manually triggers a save of the question
   */
  function saveQuestion () {
    BuilderHandler.verifyEntireQuestion((err) => {
      if (err) return failure(err)
      BuilderService.saveQuestion().then(success, failure)
    })
  }

  /**
   * success handler for BuilderService.saveQuestion()
   * @param data object returned from BuilderService.saveQuestion()
   */
  function success (data) { (data && !data.status) ? BuilderHandler.setStatus(data) : failure(data) }

  /**
   * error handler for BuilderService.saveQuestion()
   * @param error object returned from BuilderService.saveQuestion()
   */
  function failure (error) { console.error('Question did not save:', error) }

  /**
   * calculates width of page. Used to decide if question dialog should be opened
   * @returns {boolean}
   */
  function isMinWidth () {
    let body = $document[0].body.offsetWidth
    let window = $window.innerWidth
    return (body > 959 && window > 959)
  }
}

export { builderViewController }
