import { magnifiedImageController } from './image_magnifier_controller'

/**
 * constructor that contains the function for opening image magnifier dialog
 * @param $mdDialog
 * @returns {{openDialog: openDialog}}
 * @constructor
 */
function ImageHandler ($mdDialog) {
  'ngInject'
  return { openDialog: openDialog }

  /**
   * opens image in a dialog
   * @param e
   * @param src
   * @param imageArray
   * @param startIdx
   */
  function openDialog (e, src, imageArray, startIdx) {
    $mdDialog.show({
      targetEvent: e,
      templateUrl: 'partials/templates/image/image_magnifier_dialog.html',
      clickOutsideToClose: true,
      locals: {
        imageSrc: src || '',
        imageArray: imageArray || [],
        startIdx: startIdx || 0
      },
      controller: magnifiedImageController,
      controllerAs: 'vm',
      disableParentScroll: false,
      fullscreen: true
    })
  }
}

export { ImageHandler }
