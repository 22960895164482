module.exports=[
    {
        "commentId":10,
        "core":"DHCP  reservation binds  a particular IP address  in your router   to a MAC address",
        "attemptId":23269,
        "date":"2016-09-28T11:25:39.000Z",
        "comment":"Great question",
        "likes":1,
        "dislikes":0,
        "tourComment": true,
        "username":"aaron123",
        "profilePic":"https://diatldtcixe86.cloudfront.net/Pl0ZFlSkuAjU0EttyA76hXBA.png",
        "userId":111,
        "isHidden":0,
        "likeDislike":1,
        "replies":[
            {
                "commentId":92,
                "attemptId":23217,
                "date":"2016-10-03T14:39:36.000Z",
                "comment":"Yes it is a good question but it skips over some important parts.",
                "likes":1,
                "dislikes":0,
                "tourComment": true,
                "username":"User123",
                "profilePic":"https://diatldtcixe86.cloudfront.net/Pl0ZFlSkuAjU0EttyA76hXBA.png",
                "userId":111,
                "likeDislike":1
            },
            {
                "commentId":91,
                "attemptId":23217,
                "date":"2016-10-03T14:39:34.000Z",
                "comment":"I disagree.",
                "likes":0,
                "dislikes":1,
                "tourComment": true,
                "username":"BobDole",
                "profilePic":"https://diatldtcixe86.cloudfront.net/Pl0ZFlSkuAjU0EttyA76hXBA.png",
                "userId":111,
                "likeDislike":0,
                "owner": true
            }
        ]
    }
]
