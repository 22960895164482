'use strict'

/**
 * controller used to hold the comment/reply binding
 */
function commentListItemController () {
  'ngInject'
  /* validthis:true */
  const vm = this // eslint-disable-line no-unused-vars
}

export { commentListItemController }
