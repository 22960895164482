/**
 * mcqs-builder-mathjax-editor directive
 * @type {{templateUrl: string, controller: builderMathjaxEditorController, controllerAs: string}}
 */
const mcqsBuilderMathjaxEditor = {
  templateUrl: `partials/templates/builder/builder_mathjax_editor.html`,
  controller: builderMathjaxEditorController,
  controllerAs: 'vm'
}

/**
 * builderMathjaxEditorController handles the realtime mathjax editor
 */
function builderMathjaxEditorController () {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.mathjaxString = ''

  vm.store = store

  activate()

  /**
   * initialisation function that pulls the mathjax string from local storage
   * if it exists there or sets the default if it does not
   */
  function activate () {
    const { mathjaxString } = localStorage
    // if mathjax string exists, display it. Else show the default mathjax sample text
    if (localStorage && mathjaxString && mathjaxString.length > 0) vm.mathjaxString = mathjaxString
    else vm.mathjaxString = '\\\\( 5 + 5 = 10 \\\\) \n \n $$ 100/11.59 $$'
  }

  /**
   * stores the mathjax string in localStorage
   */
  function store () { if (localStorage) localStorage.mathjaxString = vm.mathjaxString }
}

export { mcqsBuilderMathjaxEditor }
