'use strict'
/**
 * mcqs-alphanumeric-validation
 * @returns {{restrict: string, require: string, link: link}}
 */
function mcqsAlphanumericValidation () {
  'ngInject'
  return {
    restrict: 'A',
    require: '^ngModel',
    link
  }

  /**
   * this is used for the validating text areas in real time for usernames and passwords
   * @param scope
   * @param elem
   * @param attrs
   * @param ngModel
   */
  function link (scope, elem, attrs, ngModel) {
    ngModel.$validators.validCharacters = (modelValue, viewValue) => {
      let value = modelValue || viewValue
      // regex check that input is only alphanumeric
      let isValid = /^((?!\[)(?!])(?!`)(?!\^)[A-z0-9\u0621-\u064A\-_.]){5,25}$/.test(value)
      ngModel.$setValidity('alphanumeric', isValid)
      return isValid
    }
  }
}
export { mcqsAlphanumericValidation }
