'use strict'
/**
 * mcqs-exam-select directive
 * @type {{templateUrl: string, bindings: {exam: string, action: string}, controller: examSelectController, controllerAs: string}}
 */
const mcqsExamSelect = {
  templateUrl: 'partials/templates/exam/select.html',
  bindings: {
    exam: '=',
    // action === selectExam in exam_select_dialog_controller.js
    action: '&?'
  },
  controller: examSelectController,
  controllerAs: 'vm'
}
/**
 * controller to initialise and set current exam
 * @param ExamService
 * @param $filter
 * @param $timeout
 */
function examSelectController (ExamService, $filter, $timeout) {
  'ngInject'
  /* jshint validthis:true */
  let vm = this

  vm.performAction = performAction

  activate()

  let textarea = angular.element(document.getElementById('examSearch'))
  // delay attaching listeners to ensure DOM is loaded
  $timeout(() => {
    textarea.on('keydown', (e) => {
      e.stopPropagation()
    })
    textarea.on('keyup', (e) => {
      e.stopPropagation()
    })
  })

  /**
   * initialisation function that get all exams and set initial exam
   */
  function activate () {
    // timeout is used to trigger a digest cycle here
    $timeout(() => { ExamService.getExamsByCategory().then(success, failure) })

    /**
     * success handler for ExamService.getExamsByCategory()
     * @param data object returned from ExamService.getExamsByCategory()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      data.forEach((category, i) => {
        data[i].exams = $filter('orderBy')(category.exams, 'examName')
      })
      vm.categories = data
      // if an exam is passed in through bindings set it as the initial exam
      if (vm.exam) setInitialExam()
    }

    /**
     * error handler for ExamService.getExamsByCategory()
     * @param error object returned from ExamService.getExamsByCategory()
     */
    function failure (error) { console.error('Get exams failed:', error) }
  }

  /**
   * sets initial exam in builder to first exam in your current category
   */
  function setInitialExam () {
    for (let i = 0; i < vm.categories.length; i++) {
      let exams = $filter('filter')(vm.categories[i].exams, returnExam)
      if (exams.length) {
        vm.exam = exams[0]
        vm.performAction(vm.exam)
        break
      }
    }

    /**
     * checks the exams in current category for a match stored locally
     * @param value
     * @returns {boolean}
     */
    function returnExam (value) { return value.examId === vm.exam.examId }
  }
  // http://stackoverflow.com/questions/18378520/angularjs-pass-function-to-directive
  /**
   * sets current exam
   * @param exam
   */
  function performAction (exam) { if (vm.action) vm.action({ exam: exam }) }
}

export { mcqsExamSelect }
