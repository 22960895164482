'use strict'
/**
 * mcqs-image-preview directive
 * @type {{template: string, bindings: {url: string, imageArray: string, key: string, idx: string}, controller: imagePreviewController, controllerAs: string}}
 */
const mcqsFooter = {
  templateUrl: 'partials/templates/landing/footer.html',
  controller: 'landingFooterController',
  controllerAs: 'vm'
}

export { mcqsFooter }
