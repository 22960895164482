'use strict'
/**
 * mcqs-builder-title directive
 * @type {{templateUrl: string, bindings: {date: string, title: string}, controller: builderTitleController, controllerAs: string}}
 */
const mcqsBuilderTitle = {
  templateUrl: 'partials/templates/builder/title.html',
  bindings: {
    date: '@',
    title: '='
  },
  controller: builderTitleController,
  controllerAs: 'vm'
}

/**
 * builderTitleController handles the updating of the question title.
 * @param $state
 * @param BuilderService
 * @param BuilderHandler
 */
function builderTitleController ($state, BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.$state = $state

  vm.update = update

  /**
   * fires save when title is updated
   */
  function update () {
    BuilderHandler.question.title = vm.title
    BuilderService.saveQuestion().then(success, failure)

    /**
     * success handler for BuilderService.saveQuestion()
     * @param data object returned from BuilderService.saveQuestion()
     */
    function success (data) { if (!data || data.status) failure(data) }

    /**
     * error handler for BuilderService.saveQuestion()
     * @param error object returned from BuilderService.saveQuestion()
     */
    function failure (error) { console.error('Question failed to save:', error) }
  }
}

export { mcqsBuilderTitle }
