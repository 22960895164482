
import { default as AngularMarked } from 'angular-marked'

import { markdownConfig } from './../question/question_markdown_config'
import { flashcardViewController } from './flashcard_view_controller'
import { mcqsFlashcardNextButton, flashcardNextController } from './flashcard_next_button'
import { routeConfig } from './flashcard_routes'

(() => {
  'use strict'
  angular
    .module('flashcard', [
      AngularMarked,
      'angularResizable'
    ])
    .config(markdownConfig)
    .config(routeConfig)
    .directive('mcqsFlashcardNextButton', mcqsFlashcardNextButton)
    .controller('flashcardViewController', flashcardViewController)
    .controller('flashcardNextController', flashcardNextController)
})()

export default 'flashcard'
