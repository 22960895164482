/* The authentication module handles all of the user session functionality/services.
    Users are authorised by signing in to the application and storing a token.
    The token is used to authenticate a user when they make an API requeset.
    If user authentication fails, the 401 is handled by prompting the user to sign back in. */
// http://blog.thoughtram.io/angularjs/es6/2015/01/23/exploring-angular-1.3-using-es6.html
import { FBService } from './authentication_facebook_service'
import { mcqsCookieError } from './authentication_cookie_error'
import { mcqsFacebookLogin } from './authentication_facebook'
import { GoogleService } from './authentication_google_service'
import { mcqsGoogleLogin } from './authentication_google'
import { mcqsPasswordResetLeave } from './authentication_password_reset_leave'
import { mcqsPasswordResetForm, passwordResetDialogController } from './authentication_password_reset'
import { AuthenticationService } from './authentication_service'
import { signInController } from './authentication_signin_controller'
import { mcqsSignInForm } from './authentication_signin_form'
import { mcqsSignOut } from './authentication_signout'
import { mcqsSignUpForm } from './authentication_signup'
import { authenticationPasswordDialogs } from './authentication_password_dialogs'
// http://www.2ality.com/2014/09/es6-modules-final.html
import { default as ngCookies } from 'angular-cookies'
import { default as ngAnimate } from 'angular-animate'
import 'angular-recaptcha'

(() => {
  'use strict'
  angular
    .module('authentication', [
      ngCookies,
      ngAnimate,
      'vcRecaptcha'
    ])
    .factory('FBService', FBService)
    .component('mcqsCookieError', mcqsCookieError)
    .component('mcqsFacebookLogin', mcqsFacebookLogin)
    .factory('GoogleService', GoogleService)
    .component('mcqsGoogleLogin', mcqsGoogleLogin)
    .component('mcqsPasswordResetLeave', mcqsPasswordResetLeave)
    .component('mcqsPasswordResetForm', mcqsPasswordResetForm)
    .controller('passwordResetDialogController', passwordResetDialogController)
    .factory('AuthenticationService', AuthenticationService)
    .controller('authenticationPasswordDialogs', authenticationPasswordDialogs)
    .controller('signInController', signInController)
    .component('mcqsSignInForm', mcqsSignInForm)
    .component('mcqsSignOut', mcqsSignOut)
    .component('mcqsSignUpForm', mcqsSignUpForm)
})()

export default 'authentication'
