'use strict'

/**
 * Controller for landing footer
 * @param $state
 * @param $rootScope
 * @param $timeout
 * @param $mdSidenav
 */
function landingFooterController ($state, $rootScope, $timeout, $mdSidenav) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const landingFooter = document.getElementsByClassName('landing-footer')

  vm.hideFooter = false

  checkState($state.current.url)

  $rootScope.$on('$stateChangeSuccess', (event, to) => {
    if (to.url === '/' || to.url === '/aboutUs'|| to.url === '/product') {
      $timeout(() => {
        vm.hideFooter = false
      }, 1000)
      removeSlideIn(landingFooter[0])
    } else {
      attachSlideIn(landingFooter[0])
    }
  })

  /**
   * checks the current state to decide style of footer
   * @param toUrl
   */
  function checkState (toUrl) {
    toUrl === '/contact'
      ? vm.footerStyle = {
        'background-color': '#ffffff',
        'position': 'absolute',
        'width': '100%',
        'bottom': '0'
      }
      : vm.footerStyle = { 'background-color': '#ffffff' }
  }

  /**
   * attaches slide in class to element
   * @param element
   */
  function attachSlideIn (element) {
    angular.element(element).addClass('landing__instant-slide-in')
  }

  /**
   * removes slide in class from element
   * @param element
   */
  function removeSlideIn (element) {
    angular.element(element).removeClass('landing__instant-slide-in')
  }
}

export { landingFooterController }
