'use strict'
import { default as Shepherd } from 'tether-shepherd'

/**
 * This service is where the steps and actions for the profile tour are defined
 * @param NavbarService
 * @param $mdSidenav
 * @returns {{mediator, profileTour: Tour, shepherd, profileStartTour: profileStartTour}}
 * @constructor
 */
function TourProfileHandler (NavbarService, $mdSidenav, $document) {
  'ngInject'
  const defaults = {
    classes: 'shepherd-theme-arrows-plain-buttons mcqs-shepherd mcqs-shepherd-head'
  }

  const service = {
    mediator: new Shepherd.Evented(),
    profileTour: new Shepherd.Tour({ defaults: defaults }),
    shepherd: Shepherd,
    profileStartTour: profileStartTour
  }

  const cancelButton = {
    text: 'Cancel',
    classes: ' md-button ',
    action: () => service.profileTour.cancel()
  }

  const buttons = [
    cancelButton,
    {
      text: 'Next',
      classes: ' md-button md-primary md-raised',
      action: () => service.profileTour.next()
    }
  ]
  service.profileTour.addStep('1', {
    text: `<p>Click here to change your profile picture</p>`,
    attachTo: '.profile__profile-photo bottom',
    advanceOn: 'click',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    buttons: buttons
  }).addStep('2', {
    text: `<p>Click here to change your background picture</p>`,
    attachTo: '.fa-camera bottom',
    advanceOn: 'click',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    buttons: buttons
  }).addStep('3', {
    text: `<p>Click here to update your details</p>`,
    attachTo: '.fa-pencil bottom',
    advanceOn: 'click',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    buttons: buttons
  }).addStep('4', {
    text: `<p>This button will allow you to change your exam</p>`,
    attachTo: '#exam-change-button bottom',
    advanceOn: 'click',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          !$document[0].querySelector('.fa-graduation-cap') ? service.profileTour.next() : service.shepherd.activeTour.show('6')
        }
      }
    ]
  }).addStep('5', {
    text: `<p>These buttons will allow you to choose between<br/>questions and flashcards</p></br><i>This will only be available on select exams</i>`,
    attachTo: '.sidenav__button-container bottom',
    advanceOn: 'click',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    buttons: buttons
  }).addStep('6', {
    text: `<p>Click here to update your cookie preferences</p>`,
    attachTo: '.profile__cookie--button bottom',
    advanceOn: 'click',
    tetherOptions: {
      'attachment': 'top left',
      'targetAttachment': 'bottom center'
    },
    buttons: [
      {
        text: 'Finish',
        classes: ' md-button md-primary md-raised',
        action: () => {
          service.profileTour.cancel()
        }
      }
    ]
  })

  // listener to fire off profile tour
  service.mediator.on('profile-start-tour', () => { service.profileTour.start() })

  return service

  function profileStartTour () {
    let currentSidenav = $mdSidenav('profile')
    currentSidenav.onClose(() => {
      if (service.shepherd.activeTour) {
        service.profileTour.cancel()
      }
    })
    NavbarService.setCurrentSidenav(currentSidenav, 'profile').then(() => {
      currentSidenav.open().then(() => {
        service.mediator.trigger('profile-start-tour')
      })
    })
  }
}

export { TourProfileHandler }
