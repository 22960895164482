'use strict'
/**
 * The AccountService is used to get a user's accounts from the back end and to update the profile
 * from the account page
 * @param $http
 * @param $mdDialog
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @returns {{getAccount: (function(): *), showDialog: showDialog, updateProfile: (function(*=, *): angular.IPromise<{currentExam: int, preferences: {builderTour: int, questionTour: int, addDataModel: int}, token: string, username: string}>)}}
 * @constructor
 */
function AccountService ($http, $mdDialog, DOMAIN) {
  'ngInject'
  return {
    getAccount: getAccount,
    showDialog: showDialog,
    updateProfile: updateProfile
  }

  /**
   * GETS an account from the BE
   * @returns {{country: string, dateOfBirth: string, email: string, firstName: string, surname: string, username: string, }}
   */
  function getAccount () { return $http.get(DOMAIN + '/api/account').then(success, failure) }

  /**
   *
   * @param profile string
   * @param password string
   * @returns {angular.IPromise<{currentExam: int, preferences: {builderTour: int, questionTour: int, addDataModel: int}, token: string, username: string}>}
   */
  function updateProfile (profile, password) {
    profile.password = password
    let localProfile = Object.assign({}, profile)
    return $http.put(DOMAIN + '/api/profile', localProfile).then(success, failure)
  }

  /**
   * Success handler
   * @param response object from BE
   * @returns {{data: object}|boolean}
   */
  function success (response) {
    return (response.status !== 200) ? response : response.data || true
  }

  /**
   * Error handler
   * @param error object
   * @returns {{error: object}}
   */
  function failure (error) { return error }

  /**
   * opens an mdDialog in the DOM
   */
  function showDialog () {
    $mdDialog.show(
      $mdDialog.alert()
        .title('Account updated')
        .disableParentScroll(false)
        .textContent('You have successfully updated your account.')
        .ariaLabel('Account updated successfully dialog')
        .ok('Great!')
    )
  }
}

export { AccountService }
