import { navbarController } from './js/navbar/navbar_controller'
import { globalProgressController } from './js/progress/progress_controller'
import { termsController } from './js/terms/terms_controller'

/* global FB, Cookiebot */
function routeConfig ($locationProvider, $stateProvider, $urlRouterProvider, $routeProvider) {
  'ngInject'
  $locationProvider.html5Mode(true)
  // TODO move all landing stuff to landing module and handle routes there
  $stateProvider
    .state('root', {
      url: '',
      abstract: true,
      views: {
        'navbar@': {}
      }
    })
    .state('root.landing', {
      views: {
        'navbar@': {
          templateUrl: 'partials/templates/landing/landing_header.html',
          controller: 'landingHeaderController',
          controllerAs: 'vm'
        }
      }
    })
    .state('root.landing.home', {
      url: '/',
      views: {
        'main@': {
          templateUrl: 'partials/templates/landing/landing.html',
          controller: 'landingController',
          controllerAs: 'vm'
        }
      }
    })
    .state('root.landing.product', {
      url: '/product',
      views: {
        'main@': {
          templateUrl: 'partials/templates/landing/product.html',
          controller: 'productController'
        }
      }
    })
    .state('root.landing.examples', {
      url: '/examples',
      views: {
        'main@': {
          templateUrl: 'partials/templates/landing/examples.html',
          controller: 'examplesController'
        }
      }
    })
    .state('root.landing.aboutUs', {
      url: '/aboutUs',
      views: {
        'main@': {
          templateUrl: 'partials/templates/landing/about-us.html',
          controller: 'aboutUsController'
        }
      }
    })
    .state('root.landing.contact', {
      url: '/contact',
      views: {
        'main@': {
          templateUrl: 'partials/templates/landing/contact.html',
          controller: 'contactController',
          controllerAs: 'vm'
        }
      }
    })
    .state('root.landing.privacy', {
      url: '/privacy',
      views: {
        'main@': {
          templateUrl: '/partials/views/standalone_terms.html',
          controller: termsController,
          controllerAs: 'vm'
        }
      }
    })
    .state('root.signin', {
      url: '/signin',
      views: {
        'main@': {
          templateUrl: 'partials/views/sign_in.html'
        }
      }
    })
    .state('root.signup', {
      url: '/signup',
      views: {
        'main@': {
          templateUrl: 'partials/views/sign_up.html'
        }
      },
      params: {
        name: '',
        firstName: '',
        lastName: '',
        message: ''
      }
    })
    .state('root.password', {
      url: '/password',
      views: {
        'main@': {
          templateUrl: 'partials/views/password_reset.html'
        }
      }
    })
    .state('root.auth', {
      abstract: true,
      views: {
        'navbar@': {
          templateUrl: 'partials/views/navbar.html',
          controller: navbarController,
          controllerAs: 'vm'
        },
        'progress@': {
          template: `
                    <div class="progress">
                        <md-progress-linear
                            md-mode="indeterminate"
                            ng-show="vm.progressService.progress"
                        ></md-progress-linear>
                    </div>
                `,
          controller: globalProgressController,
          controllerAs: 'vm'
        }
      },
      resolve: {
        // Authenticate the first time a view is called
        authorize: function (AuthenticationService) {
          AuthenticationService.authenticateTransition()
        }
      }
    })
    .state('root.auth.admin', {
      abstract: true,
      resolve: {
        authorize: function (AuthenticationService) {
          AuthenticationService.authenticateAdmin()
        }
      }
    })

  $urlRouterProvider.otherwise(($injector, $location) => {
    let path = $location.$$absUrl.split(new RegExp(/\//, 'g'))
    if (path.length !== 4 || path[3] !== 'privacy') {
      $location.replace().path('/')
    }
  })
}

function runOnStateChange ($rootScope, $window, $templateCache, AuthenticationService, $state) {
  'ngInject'
  // http://opensourcesoftwareandme.blogspot.ie/2014/02/safely-prevent-template-caching-in-angularjs.html
  $rootScope.$on('$viewContentLoaded', clearTemplateCache)
  // When a view is called, check that the user is authenticated before navigating
  $rootScope.$on('$stateChangeStart', () => AuthenticationService.authenticateTransition())
  // When a state is changed fire off an event to GA
  $rootScope.$on('$stateChangeSuccess', () => {
    if ((typeof Cookiebot !== 'undefined' && Cookiebot.consent.statistics) && $window.ga) {
      $window.ga('send', 'pageview', {'page': $state.current.name})
    }
  })
  function clearTemplateCache (event, next, current) {
    if (typeof (current) !== 'undefined') { $templateCache.removeAll() }
  }
  // Facebook authentication
  window.fbAsyncInit = () => FB.init({
    appId: '387426594752723',
    status: true,
    cookie: false,
    xfbml: false,
    version: 'v3.0'
  });
  (function (d, s, id) {
    let js
    let fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s); js.id = id
    js.src = '//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.0'
    fjs.parentNode.insertBefore(js, fjs)
  }(document, 'script', 'facebook-jssdk'))
}

export { routeConfig, runOnStateChange }
