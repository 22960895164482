'use strict'

/**
 * Controller for the example page
 * @param $window
 * @param $scope
 */
function examplesController ($window, $scope) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const body = angular.element(document.getElementsByTagName('body'))
  const landingFooter = document.getElementsByClassName('landing-footer')

  angular.element(body[0]).on('scroll', () => {
    if (landingFooter[0].getBoundingClientRect().top <= $window.innerHeight &&
      !landingFooter[0].classList.contains('landing__instant-slide-in')) {
      attachSlideIn(landingFooter[0])
    }
  })

  $scope.$on('$destroy', () => {
    angular.element(body[0]).off('scroll')
  })

  function attachSlideIn (element) {
    angular.element(element).addClass('landing__instant-slide-in')
  }

}

export { examplesController }
