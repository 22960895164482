'use strict'
/**
 * mcqs-terms-toggle directive
 * @type {{template: string, bindings: {logo: string}, controller: mcqsTermsToggleController, controllerAs: string}}
 */
const mcqsTermsToggle = {
  template: `
       <div style="width:100px; height: 100px; cursor: pointer;position: absolute" ui-sref="root.landing"></div>
       <div class="landing__mcqs-logo-container">
            <object type="image/svg+xml"
                    class="landing__mcqs-logo"
                    data="../../../img/MCQS-full.svg"></object>
        </div>
        <a ng-if="!vm.logo" ng-click="vm.check()">Terms of service &amp; privacy policy.</a>
    `,
  bindings: { logo: '<' },
  controller: mcqsTermsToggleController,
  controllerAs: 'vm'
}

/**
 * controller that opens a new dialog to display the terms and conditions
 * @param $mdDialog
 */
function mcqsTermsToggleController ($mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.check = check

  function check () {
    $mdDialog.show({
      template: `
            <md-dialog aria-label="Terms of Service and Privacy Policy">
                <md-toolbar>
                    <div class="md-toolbar-tools">
                        <span flex></span>
                        <md-button class="md-icon-button" ng-click="vm.cancel()" aria-label="Close">
                            <md-icon md-font-set="fa" class="fa fa-close fa-2x"></md-icon>
                        </md-button>
                    </div>
                </md-toolbar>
                <md-dialog-content ng-cloak>
                    <ng-include src="'partials/views/terms.html'"></ng-include>
                </md-dialog-content>
            </md-dialog>
            `,
      controller: 'termsController',
      controllerAs: 'vm',
      clickOutsideToClose: true,
      disableParentScroll: false,
      fullscreen: true
    })
  }
}

export { mcqsTermsToggle }
