'use strict'

/**
 * service for AdminImageManagement module
 * @param $http
 * @param DOMAIN
 * @param $mdToast
 * @returns {{deleteRow: (function(*): *), getAllAdminImages: (function(): *), insertBlankRow: (function(): angular.IPromise<any>), showToast: showToast, updateAllRows: (function(*=): angular.IPromise<any>), updateRow: (function(*=): angular.IPromise<any>)}}
 * @constructor
 */
function AdminManagementService ($http, DOMAIN, $mdToast) {
  'ngInject'
  const service = {
    deleteRow,
    getAllAdminImages,
    getAllAnimations,
    insertBlankRow,
    showToast,
    updateAllImageRows,
    updateAllAnimations,
    updateRow
  }

  return service

  /**
   * passes a delete request to the BE
   * @param relatedImageId int id of image to be deleted
   * @returns {*}
   */
  function deleteRow (relatedImageId) {
    return $http.delete(DOMAIN + `/api/admin-images/delete-row/${relatedImageId}`).then(success, failure)
  }

  /**
   * requests all related images from BE
   * @returns {*}
   */
  function getAllAdminImages () {
    return $http.get(DOMAIN + '/api/admin-images/').then(success, failure)
  }

  /**
   * returns all animations
   * @returns {*}
   */
  function getAllAnimations () {
    return $http.get(DOMAIN + '/api/admin-animations/').then(success, failure)
  }

  /**
   * request BE to put a blank row in DB
   * @returns {angular.IPromise<any>}
   */
  function insertBlankRow () {
    return $http.put(DOMAIN + '/api/admin-images/new-row/').then(success, failure)
  }

  /**
   * displays an md toast on screen
   * @param text string to be displayed in toast
   * @param err boolean whether or not error
   */
  function showToast (text, err) {
    let toastClass = err ? 'md-warn' : ''
    $mdToast.show(
      $mdToast.simple()
        .textContent(text)
        .position('top right')
        .toastClass(toastClass)
        .hideDelay(3000)
        // This was added to stop a pop on Windows when the toast was present
        .parent(document.getElementById('toast-container'))
    )
  }

  /**
   * request BE to update multiple rows in BE
   * @param updatedRows Array containing rows to be updated
   * @returns {angular.IPromise<any>}
   */
  function updateAllImageRows (updatedRows) {
    return $http.put(DOMAIN + '/api/admin-images/update-all-rows/', {
      updatedRows: updatedRows
    }).then(success, failure)
  }

  /**
   * posts updated animations to node
   * @param animations
   * @returns {Promise<T>}
   */
  function updateAllAnimations (animations) {
    return $http.put(DOMAIN + '/api/admin-animations/update-all/', {
      animations: animations
    }).then(success, failure)
  }

  /**
   * requests BE to pdate a single row
   * @param updatedRow object row to be updated
   * @returns {angular.IPromise<any>}
   */
  function updateRow (updatedRow) {
    return $http.put(DOMAIN + '/api/admin-images/update-row/', {
      updatedRow: updatedRow
    }).then(success, failure)
  }

  /**
   * success handler returns data or empty string
   * @param response
   * @returns {*|{}}
   */
  function success (response) { return (response.status !== 200) ? response : response.data || {} }

  /**
   * error handler return error
   * @param error
   * @returns {*}
   */
  function failure (error) { return error }
}

export { AdminManagementService }
