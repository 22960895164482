'use strict'
/**
 * The AuthenticationService handles the overall authentication of the app.
 * It is used to authenticate every transition the user makes around the site.
 * It handles the setting and destroying of cookies/localStorage and it handles
 * all of the authentication requests
 * @param $http
 * @param $cookies
 * @param $location
 * @param $mdDialog
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @returns {{authenticateTransition: (function(): boolean), createSession: createSession, destroySession: destroySession, getPasswordResetCode: (function(*=): *), hasSession: (function(): *), resetPassword: (function(*=, *=, *=): *), signIn: (function(*=, *=): *), signUp: (function(*=, *=): *), signOut: (function(): *), socialSignIn: (function(*=): *), updateToken: updateToken}}
 * @constructor
 */
function AuthenticationService ($http, $cookies, $location, $mdDialog, DOMAIN) {
  'ngInject'
  return {
    authenticateTransition: authenticateTransition,
    authenticateAdmin: authenticateAdmin,
    createSession: createSession,
    checkUserPasswordExists: checkUserPasswordExists,
    destroySession: destroySession,
    getPasswordResetCode: getPasswordResetCode,
    hasSession: hasSession,
    isAdmin: isAdmin,
    passwordCheckDialog: passwordCheckDialog,
    resetPassword: resetPassword,
    signIn: signIn,
    signUp: signUp,
    signOut: signOut,
    socialSignIn: socialSignIn,
    updateToken: updateToken
  }

  /**
   * If a user token exists, send them to the restricted view
   * other redirect to the base view
   * @returns {boolean}
   */
  function authenticateTransition () {
    const unrestricted = [
      '/',
      '/signin',
      '/signup',
      '/terms',
      '/product',
      '/examples',
      '/aboutUs',
      '/contact',
      '/privacy'
    ]
    const token = $cookies.get('token') || ''
    const toPath = $location.url()
    // lock down related images path to martin
    // if (toPath === 'relatedImages' && $cookies.get('userId') !== '195') {
    //   $location.path('/question')
    // }
    // sets whether the destination is restricted
    const toRestricted = unrestricted.indexOf(toPath) === -1
    if (token.length > 0) {
      // if token exists and headed to restricted path allow it
      // else send them to the question page (which will ask them to re auth)
      toRestricted ? $location.path(toPath) : $location.path('/question')
    } else {
      // if token does NOT exist and headed to restricted path, send them to default path
      // else send them where they are requesting
      toRestricted ? $location.path('/') : $location.path(toPath)
    }
    return true
  }

  /**
   * Checks whether a user is an admin to allow access to a location
   * @returns {boolean}
   */
  function authenticateAdmin () {
    const toPath = $location.url()
    isAdmin().then((response) => {
      (!response) ? $location.path('/question') : $location.path(toPath)
    })
    return true
  }

  /**
   *
   * @param loginId int used to track a users specific sessions
   * @param userId int
   * @param token string to authenticate API requests
   * @param displayName identifier to pass to FullStory
   */
  function createSession (loginId, userId, token, displayName) {
    let date = new Date()
    date.setDate(date.getDate() + 30)
    let cookieDate = new Date(date).toISOString()
    $cookies.put('token', token, { 'expires': cookieDate })
    $cookies.put('loginId', loginId, { 'expires': cookieDate })
    $cookies.put('userId', userId, { 'expires': cookieDate })
    window.FullStory.identify(userId, {
      displayName: displayName
    })
  }

  /**
   * checks if a password exists for a user
   * @param userId - Integer
   */
  function checkUserPasswordExists () {
    return $http.get(DOMAIN + `/api/user/password/${$cookies.get('userId')}`)
      .then(success, failure)
  }

  /**
   * The session is destroyed when a user signs out/is kicked out
   */
  function destroySession () {
    $cookies.remove('loginId')
    $cookies.remove('userId')
    $cookies.remove('token')
    $cookies.remove('currentExam')
    $cookies.remove('mcqsQuestionFilter')
    $cookies.remove('mcqsPreferencesQuestionTour')
    $cookies.remove('mcqsPreferencesBuilderTour')
    $cookies.remove('mcqsPreferencesAddDataModal')
    $cookies.remove('mcqsQuestionFlashcard')
    $cookies.remove('mcqsCurrentExam')
    $cookies.remove('mcqsFlashcardHidden')
    // remove LinkedChat details
    window.localStorage.removeItem('linkedchatUserId')
    window.localStorage.removeItem('linkedchatOpened5eYMAe')
    window.localStorage.removeItem('linkedchatUnread5eYMAe')
    window.localStorage.removeItem('linkedchatMessages5eYMAe')
    window.localStorage.removeItem('linkedchatUserInfo')
    window.FullStory.anonymize()
  }

  /**
   * request a password reset code from the BE
   * @param email string
   * @returns {string}
   */
  function getPasswordResetCode (email) {
    return $http.post(DOMAIN + '/password/code', {
      'email': email
    }).then(success, failure)
  }

  /**
   * Check for all session cookies. If all exist, user is authenticated
   * @returns {boolean}
   */
  function hasSession () {
    let loginId = $cookies.get('loginId')
    let userId = $cookies.get('userId')
    let token = $cookies.get('token')
    return (loginId && userId && token)
  }

  /**
   * calls the dialog to prompt a user to set a password
   * @param userId - Integer
   * @param scope - $rootScope
   */
  function passwordCheckDialog (userId, scope) {
    $mdDialog.show({
      templateUrl: 'partials/templates/authentication/set_password_dialog.html',
      controller: 'authenticationPasswordDialogs',
      controllerAs: 'vm',
      locals: {userId: userId},
      disableParentScroll: true,
      escapeToClose: false
    }).then(() => { scope.$emit('tourReady') })
  }

  /**
   * rests a users password
   * @param email string the users email
   * @param code string the code provided in an email to the user
   * @param password string new password to be set
   * @returns {{username: string}}
   */
  function resetPassword (email, code, password) {
    return $http.post(DOMAIN + '/password/reset', {
      'email': email,
      'code': code,
      'password': password
    }).then(success, failure)
  }

  /**
   * POSTS username and password to the BE to sign a user in
   * @param username string
   * @param password string
   * @returns {{currentExam: int, hasExam: int, loginId: int, preferences: {builderTour: int, questionTour: int, addDataModel: int}, token: string, userId: int, userRole: string, username: string}}
   */
  function signIn (username, password) {
    return $http.post(DOMAIN + '/signin', {
      'username': username,
      'password': password
    }).then(success, failure)
  }

  /**
   *
   * @param personalDetails object {{email: string, firstName: string, password: string, surname: string, username: string}}
   * @param recaptchaResponse string
   * @returns {{successMessage: string}}
   */
  function signUp (personalDetails, recaptchaResponse) {
    const browserDetails = getBrowserDetails()
    return $http.post(DOMAIN + '/signup', {
      'personalDetails': personalDetails,
      'browserDetails': browserDetails,
      'recaptchaResponse': recaptchaResponse
    }).then(success, failure)
  }

  /**
   * Signs a user out
   * @returns {string}
   */
  function signOut () {
    let loginId = $cookies.get('loginId')
    return $http.get(DOMAIN + '/api/signout/' + loginId).then(success, failure)
  }

  /**
   * Signs a user in using Facebook or Google
   * @param details object {{browserDetails: {availableHeight: int, availableWidth: int, cookieEnabled: boolean, currentUrl: string, javaEnabled: boolean, setHeight: int, setWidth: int}, email:string, firstName: string, name: string, network, string, profilePic: string, socialId: string, surname: string}}
   * @returns {{currentExam: int, hasExam: int, loginId: int, preferences: {builderTour: int, questionTour: int, addDataModel: int}, token: string, userId: int, userRole: string, username: string}}
   */
  function socialSignIn (details) {
    details.browserDetails = getBrowserDetails()
    return $http.post(DOMAIN + '/signin/social', details).then(success, failure)
  }

  /**
   * success handler
   * @param response object
   * @returns {*|boolean}
   */
  function success (response) {
    return (response.status !== 200) ? response : response.data || true
  }

  /**
   * error handler
   * @param error object
   * @returns {*}
   */
  function failure (error) { return error }

  /**
   * returns the current browser details
   * @returns {{availableWidth: number, availableHeight: number, cookieEnabled: boolean, currentUrl: string, javaEnabled: boolean, setWidth: number, setHeight: number}}
   */
  function getBrowserDetails () {
    return {
      availableWidth: window.screen.width,
      availableHeight: window.screen.height,
      cookieEnabled: navigator.cookieEnabled,
      currentUrl: document.URL,
      javaEnabled: navigator.javaEnabled(),
      setWidth: window.innerWidth,
      setHeight: window.innerHeight
    }
  }

  /**
   * If a new token is ever returned, update the cookie
   * @param token string to authenticate API requests
   */
  function updateToken (token) {
    $cookies.remove('token')
    let date = new Date()
    date.setDate(date.getDate() + 30)
    let tokenDate = new Date(date).toISOString()
    $cookies.put('token', token, { 'expires': tokenDate })
  }

  /**
   * Posts to the BE to check if a user is an admin
   * @returns {*}
   */
  function isAdmin () {
    return $http.get(DOMAIN + '/api/admin-check')
      .then((response) => {
        return (response.data) ? response.data : false
      }, failure)
  }
}
export { AuthenticationService }
