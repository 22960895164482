'use strict'
/* global Cookiebot */
/**
 * profile-sidenav directive
 * @type {{templateUrl: string, controller: profileSidenavController, controllerAs: string}}
 */
const profileSidenav = {
  templateUrl: 'partials/views/profile.html',
  controller: profileSidenavController,
  controllerAs: 'vm'
}
/* @ngInject */
/**
 * controller to initialise the profile sidenav
 * @param $mdSidenav
 * @param $state
 * @param ProfileService
 * @param ExamService
 */
function profileSidenavController ($mdSidenav, $state, ProfileService, ExamService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.profile = {}
  vm.state = $state.current.name
  vm.flashcardHidden = ExamService.flashcardHidden

  vm.goToAccount = goToAccount
  vm.openCookieDialog = () => { Cookiebot.show() }

  activate()

  /**
   * initialisation function that gets the users profile
   */
  function activate () {
    ProfileService.getProfile().then(success, failure)

    /**
     * success handler for ProfileService.getProfile
     * binds returned data to the vm
     * @param data object returned from ProfileService.getProfile
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      // fix for if exam description is not properly formatted
      data.currentExam.examDesc = data.currentExam.examDesc.replace(/,/g, ', ')
      vm.profile = data
    }

    /**
     * error handler for ProfileService.getProfile
     * @param error object returned from ProfileService.getProfile
     */
    function failure (error) { console.error('Failed to get Profile', error) }
  }

  /**
   * go to account state and close profile sidenav
   */
  function goToAccount () {
    $state.go('root.auth.account')
    $mdSidenav('profile').close()
  }
}

export { profileSidenav }
