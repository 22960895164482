import * as components from './components' // eslint-disable-line no-unused-vars

import { default as angular } from 'angular'
import { default as AngularMaterial } from 'angular-material'
import { default as AngularMessages } from 'angular-messages'
import { default as AngularUiRouter } from 'angular-ui-router'
import { default as AngularRoute } from 'angular-route'

import { default as Account } from './js/account/account'
import { default as AdminManagement } from './js/admin_management/admin_management'
import { default as Authentication } from './js/authentication/authentication'
import { default as Builder } from './js/builder/builder'
import { default as Chat } from './js/chat/chat'
import { default as Chess } from './js/chess/chess'
import { default as Comment } from './js/comment/comment'
import { default as Dashboard } from './js/dashboard/dashboard'
import { default as Exam } from './js/exam/exam'
import { default as Flashcard } from './js/flashcard/flashcard'
import { default as Images } from './js/image/image'
import { default as Landing } from './js/landing/landing'
import { default as Lottie } from './js/lottie/lottie'
import { default as Navbar } from './js/navbar/navbar'
import { default as Profile } from './js/profile/profile'
import { default as Progress } from './js/progress/progress'
import { default as Question } from './js/question/question'
import { default as Terms } from './js/terms/terms'
import { default as Tour } from './js/tour/tour'
import { default as Utils } from './js/utils/utils'
import { default as Vendor } from './js/vendor/vendor'
import { default as Webinar } from './js/webinar/webinar'
import { globalStyleController } from './global-style-controller'

import { config, httpRequestInterceptor } from './app_config'
import { routeConfig, runOnStateChange } from './app_routes'
import { themeConfig } from './app_theme';

(() => {
  'use strict'
  angular
    .module('mcqs', [
      AngularMaterial,
      AngularMessages,
      AngularUiRouter,
      AngularRoute,
      Account,
      AdminManagement,
      Authentication,
      Builder,
      Chat,
      Chess,
      Comment,
      Dashboard,
      Exam,
      Flashcard,
      Images,
      Landing,
      Lottie,
      Navbar,
      Profile,
      Progress,
      Question,
      Terms,
      Tour,
      Utils,
      Vendor,
      Webinar
    ])
    .config(routeConfig)
    .run(runOnStateChange)
    .config(config)
    .factory('httpRequestInterceptor', httpRequestInterceptor)
    .config(themeConfig)
    .controller('globalStyleController', globalStyleController)
    .constant('DOMAIN', process.env.APP_ENV.trim() || '')
    .constant('ADMINAPI', process.env.ADMIN_API.trim() || '')
    .constant('DOMAIN_CHAT', process.env.APP_CHAT.trim() || '')
    .constant('APP_VERSION', process.env.APP_VERSION.trim() || '')
    .constant('YOUTUBE_API_KEY', process.env.YOUTUBE_API_KEY.trim() || '')
})()
