'use strict'
/**
 * mcqs-builder-related-data directive
 * @type {{template: string, bindings: {nodeIdx: string}, controller: builderDeleteRelatedDataController, controllerAs: string}}
 */
const mcqsBuilderDeleteRelatedData = {
  template: `
    <md-button
        class="md-icon-button"
        ng-click="vm.deleteData()"
    >
    <md-icon md-font-set="fa" class="fa fa-close fa-2x"></md-icon>
    <md-tooltip md-direction="bottom">Delete related data</md-tooltip>
    </md-button>
    `,
  bindings: { nodeIdx: '@' },
  controller: builderDeleteRelatedDataController,
  controllerAs: 'vm'
}

/**
 *  builderDeleteRelatedDataController is used to confirm the users action before deleting
 * a related data node
 * @param $mdDialog
 * @param $state
 * @param $timeout
 * @param BuilderService
 * @param BuilderHandler
 * @param TourBuilderHandler
 */
function builderDeleteRelatedDataController ($mdDialog, $state, $timeout, BuilderService, BuilderHandler, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.deleteData = deleteData

  /**
   * opens dialog for deleting related data
   */
  function deleteData () {
    $mdDialog.show($mdDialog.confirm()
      .title('Are you sure you want to remove the related data?')
      .disableParentScroll(false)
      .textContent('This action cannot be undone.')
      .ariaLabel('Delete related data')
      .ok('Delete data')
      .cancel('Cancel')
    ).then(deleteRelatedData)
  }

  /**
   * handles logic for deleting related data
   * deletes the node, verifies the question and then saves the question
   */
  function deleteRelatedData () {
    BuilderHandler.deleteRelatedData(vm.nodeIdx)
    BuilderHandler.verifyEntireQuestion((err) => {
      if (err) return failure(err)
      BuilderService.saveQuestion().then(null, failure)
    })
    // moves user from the dynamic builder state
    $state.go('root.auth.builder.edit')
    let { activeTour } = TourBuilderHandler.shepherd
    if (activeTour && activeTour.currentStep.id > 11) $timeout(() => TourBuilderHandler.shepherd.activeTour.show(19))
  }

  /**
   * error handler for BuilderHandler.verifyEntireQuestion() and
   * BuilderService.saveQuestion()
   * @param error object returned from BuilderHandler.verifyEntireQuestion() and
   * BuilderService.saveQuestion()
   */
  function failure (error) { console.error('Failed to save question after deleting data:', error) }
}

export { mcqsBuilderDeleteRelatedData }
