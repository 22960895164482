'use strict'

/**
 * mcqs-comment-rating directive
 * @param CommentService
 * @returns {{templateUrl: string, restrict: string, scope: {commentId: string, commentLikeDislike: string, replyLikeDislike: string, commentIdx: string, replyIdx: string, likes: string, dislikes: string, isHidden: string}, link: link}}
 */
function mcqsCommentRating (CommentService) {
  'ngInject'
  return {
    templateUrl: 'partials/templates/comment/comment_rating.html',
    restrict: 'E',
    scope: {
      commentId: '@',
      commentLikeDislike: '=',
      replyLikeDislike: '=',
      commentIdx: '@',
      replyIdx: '<',
      likes: '@',
      dislikes: '@',
      isHidden: '<'
    },
    link: link
  }

  /**
   * link function that handles rating of a comment
   * @param scope angular scope
   * @param elem current element
   */
  function link (scope, elem) {
    let commentLikeDislike

    scope.rateComment = rateComment

    activate()

    /**
     * initialisation function that applies css styling to already liked/disliked comments
     */
    function activate () {
      commentLikeDislike = scope.replyLikeDislike >= 0 ? scope.replyLikeDislike : scope.commentLikeDislike
      // this applies a css class based on the status of the comments like or dislike status
      if (commentLikeDislike === 0) elem[0].getElementsByClassName('fa-thumbs-down')[0].classList.add('comment__action--dislike')
      if (commentLikeDislike) elem[0].getElementsByClassName('fa-thumbs-up')[0].classList.add('comment__action--like')
    }

    /**
     * rates a comment, sends it to the BE via CommentService and updates the relevant css
     * e is event which we use to update correct element
     * @param rating int 0 for dislike, 1 for like
     * @param e click event
     */
    function rateComment (rating, e) {
      if (commentLikeDislike || commentLikeDislike === 0) return
      const isIcon = (e.target.tagName === 'MD-ICON')
      let target = angular.element(e.target)
      if (!isIcon) target = angular.element(target.find('md-icon')[0])
      CommentService.rateComment(rating, scope.commentId).then(
        () => {
          CommentService.updateComment(rating, scope.commentIdx, scope.replyIdx)
          target.hasClass('fa-thumbs-up') ? target.addClass('comment__action--like') : target.addClass('comment__action--dislike');
          (rating === 1) ? scope.likes++ : scope.dislikes++
        },
        error => console.error('Error rating comment:', error))
    }
  }
}

export { mcqsCommentRating }
