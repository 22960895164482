'use strict'
/**
 * mcqs-chat-sidenav directive
 * @type {{templateUrl: string, controller: chatSidenavController, controllerAs: string}}
 */
const chatSidenav = {
  templateUrl: 'partials/views/chat.html',
  controller: chatSidenavController,
  controllerAs: 'vm'
}

/**
 * chatSidenavController is used to bind values to the html and expose the
 * ChatService.sendMessage function to the html
 * @param ChatService
 */
function chatSidenavController (ChatService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.disabled = false
  vm.message = ''

  vm.messages = ChatService.messages
  vm.settings = ChatService.settings
  vm.status = ChatService.status
  vm.qService = ChatService

  vm.isOpen = ChatService.isOpen

  vm.sendMessage = sendMessage

  activate()

  /**
   * initialisation function. Opens chat socket when sidenav is opened
   */
  function activate () { ChatService.open() }

  /**
   * passees the message to the ChatService, to be emitted to the socket
   */
  function sendMessage () {
    ChatService.sendMessage({
      type: 'chat',
      owner: true,
      message: vm.message.trim()
    })
    vm.message = ''
  }
}

export { chatSidenav }
