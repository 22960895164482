'use strict'
/* global Cookiebot */

/**
 * cookie-bot-render directive
 * @param $timeout
 * @returns {{restrict: string, link(): void}}
 */
function cookieBotRender ($timeout) {
  'ngInject'
  return {
    restrict: 'A',
    link () {
      activate()

      /**
       * This renders the cookiebot script tag inside a template
       */
      function activate () {
        // timeout needed to manually trigger digest cycle
        $timeout(() => {
          Cookiebot.getScript('https://consent.cookiebot.com/9d8dfca1-a168-4815-aaad-3fa775164633/cd.js')
        })
      }
    }
  }
}

export { cookieBotRender }
