'use strict'
/**
 * mcqs-builder-question-list directive
 * @type {{templateUrl: string, bindings: {questions: string, closeable: string, currentQuestionId: string, refresh: string}, controller: builderQuestionListController, controllerAs: string}}
 */
const mcqsBuilderQuestionList = {
  templateUrl: 'partials/templates/builder/question_list.html',
  bindings: {
    questions: '<',
    closeable: '=',
    currentQuestionId: '@',
    refresh: '&'
  },
  controller: builderQuestionListController,
  controllerAs: 'vm'
}

/**
 * builderQuestionListController is used to attach the bindings to the vm, and then question_list.html
 */
function builderQuestionListController () {
  'ngInject'
  /* jshint validthis:true */
  const vm = this // eslint-disable-line no-unused-vars
}

export { mcqsBuilderQuestionList }
