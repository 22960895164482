/*
    The question module handles the primary question functionality:
    loading questions, answering questions, rendering valid markdown,
    displaying questions (styling), storing question/author data in a service.
*/
import { default as AngularMarked } from 'angular-marked'
import * as AngularResizable from 'angular-resizable' // eslint-disable-line no-unused-vars

import { mcqsQuestionLikes } from './question_likes'
import { markdownConfig } from './question_markdown_config'
import { mcqsQuestionNextButton, questionNextController } from './question_next_button'
import { mcqsQuestionOptions, questionOptionsController } from './question_options'
import { mcqsQuestionReport, questionReportDialogController } from './question_report'
import { routeConfig } from './question_routes'
import { QuestionService } from './question_service'
import { mcqsQuestionToolbar } from './question_toolbar'
import { questionViewController } from './question_view_controller'
import { questionAnsweredController } from './question_answered_controller';

(() => {
  'use strict'
  angular
    .module('question', [
      AngularMarked,
      'angularResizable'
    ])
    .component('mcqsQuestionLikes', mcqsQuestionLikes)
    .config(markdownConfig)
    .directive('mcqsQuestionNextButton', mcqsQuestionNextButton)
    .controller('questionNextController', questionNextController)
    .directive('mcqsQuestionOptions', mcqsQuestionOptions)
    .controller('questionOptionsController', questionOptionsController)
    .component('mcqsQuestionReport', mcqsQuestionReport)
    .controller('questionReportDialogController', questionReportDialogController)
    .config(routeConfig)
    .factory('QuestionService', QuestionService)
    .component('mcqsQuestionToolbar', mcqsQuestionToolbar)
    .controller('questionViewController', questionViewController)
    .controller('questionAnsweredController', questionAnsweredController)
})()

export default 'question'
