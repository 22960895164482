'use strict'

import { diff } from 'deep-diff'

/**
 * Controller for animation admin view
 * @param AdminManagementService
 * @param $scope
 * @param $filter
 * @param $timeout
 */
function adminAnimationViewController (AdminManagementService, $scope, $filter, $timeout) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  let limit, originalAnimationsArr, tempStart

  vm.updateList = updateList
  vm.updateAllAnimations = updateAllAnimations

  vm.searchText = ''
  vm.startPos = 0

  limit = 30

  $scope.$watch('vm.searchText', () => {
    $timeout(() => {
      if (vm.searchText !== '' && vm.searchText !== undefined) {
        vm.startPos = 0
      } else if (tempStart !== undefined) {
        vm.startPos = tempStart
      }
    })
  })

  init()

  /**
   * initialisation function
   */
  function init () {
    AdminManagementService.getAllAnimations().then((data) => {
      originalAnimationsArr = angular.copy(data)
      vm.animations = angular.copy(data)
    }, (err) => {
      console.error('err === ', err)
    })
  }

  /**
   * checks rows for changes and passes modified rows to service
   * @returns {Promise}
   */
  function updateAllAnimations () {
    return new Promise((resolve, reject) => {
      let allUpdatedAnimations = []
      // angular.copy used to remove hashes before comparison
      let animations = angular.copy(vm.animations)
      let arrayDiff = diff(animations, originalAnimationsArr)
      if (arrayDiff === undefined) return resolve()
      vm.animations.map((item, idx, arr) => {
        if (diff(arr[idx], originalAnimationsArr[idx])) {
          allUpdatedAnimations.push(arr[idx])
        }
      })
      AdminManagementService.updateAllAnimations(allUpdatedAnimations).then((data) => {
        if (!data || data.status) return reject(failure())
        AdminManagementService.showToast('Rows updated successfully')
        init()
        resolve()
      }, failure)
    })
  }

  /**
   * updates the animations show in the HTML
   * @param newItems - Boolean - handles whether going forwards or backwards
   * @param oldItems - Boolean - handles whether going forwards or backwards
   */
  function updateList (newItems, oldItems) {
    // checks length of displayed items after search
    if ($filter('filter')(originalAnimationsArr, vm.searchText).length <= limit) return
    // ensures that a blank page will not be loaded
    if (newItems && (vm.startPos + limit < vm.animations.length)) {
      vm.startPos += limit
    } else if (oldItems && (vm.startPos !== 0)) {
      vm.startPos -= limit
    }
    if (vm.searchText !== undefined && vm.searchText !== '') {
      tempStart = vm.startPos
    }
  }

  /**
   * handles showing of toast on failure
   */
  function failure () {
    AdminManagementService.showToast('Error updating row data', true)
  }
}

export { adminAnimationViewController }
