/* This component handles the answering of a question.
The options, attemptId, correctAnswer and the answered boolean are passed in. */
'use strict'

/**
 * mcqs-question-options directive
 * @param $document
 * @param $state
 * @param NavbarService
 * @returns {{restrict: string, templateUrl: string, scope: {}, bindToController: {attempt: string, correctAnswer: string, options: string}, controller: string, controllerAs: string, link: link}}
 */
function mcqsQuestionOptions ($document, $state, NavbarService) {
  'ngInject'
  return {
    // A button is rendered for each option.
    restrict: 'AE',
    templateUrl: 'partials/templates/question/question_options.html',
    scope: {},
    bindToController: {
      attempt: '=',
      correctAnswer: '=',
      options: '='
    },
    controller: 'questionOptionsController',
    controllerAs: 'vm',
    link: link
  }

  /**
   * link function that attaches and manages key bindings for question hotkeys
   * @param scope
   * @param elem
   */
  function link (scope, elem) {
    // 49 to 53 are main 1-5 keys
    // 96 to 100 are numberpad 1-5 keys
    const main = [49, 50, 51, 52, 53]
    const numPad = [97, 98, 99, 100, 101]

    $document.on('keyup', getKeyIndex)
    elem.on('$destroy', unbindEvent)

    /**
     * handles hotkeys for answering question with keyboard
     * @param e key event
     */
    function getKeyIndex (e) {
      let target = e.target.tagName
      let keyCode = e.which
      if (keyCode && target !== 'INPUT' && !NavbarService.isOpen()) {
        // sets index to the position of keycode in main or numpad
        let index = main.indexOf(keyCode) >= 0
          ? main.indexOf(keyCode)
          : numPad.indexOf(keyCode)
        if (index >= 0 && !$state.includes('root.auth.question.answered')) {
          scope.vm.selectAnswer(index)
        }
      }
    }

    /**
     * removes trigger and destroys scope
     */
    function unbindEvent () {
      $document.off('keyup', getKeyIndex)
      scope.$destroy()
    }
  }
}

/**
 * controller that handle sthe slecting of buttons and setting of correct/incorrect styles
 * @param $state
 * @param $timeout
 * @param ChessHandler
 * @param QuestionService
 * @param TourQuestionHandler
 * @param $rootScope
 * @param $element
 */
function questionOptionsController ($state, $timeout, ChessHandler, QuestionService, TourQuestionHandler, $rootScope, $element) {
  /* jshint validthis:true */
  const vm = this
  let choice
  const mediator = TourQuestionHandler.mediator

  vm.$state = $state
  vm.selectAnswer = selectAnswer
  vm.setButtons = setButtons
  vm.mouseover = mouseover
  vm.mouseleave = mouseleave

  activate()

  $rootScope.$on('chessMove', (e, option) => {
    vm.selectAnswer(option)
  })

  if ($state.current.name.includes('builder')) {
    let options = $element[0].getElementsByClassName('option-wrapper')
    $timeout(() => {
      ChessHandler.setUpPreviewButtonEvents(options)
    }, 100)
  }

  /**
   * initialisation function that sets up triggers for tour
   */
  function activate () {
    // if binding exists in tour for select-answer
    if (!mediator.bindings['tour-select-answer']) {
      // add listener for tour step
      mediator.on('tour-select-answer', () => {
        // if user is not in answered state, answer question else next question
        !$state.includes('root.auth.question.answered') ? vm.selectAnswer(4) : TourQuestionHandler.nextStep()
      })
    }
    // if binding exists in tour for last step
    if (!mediator.bindings['finish-question-tour']) {
      // add listener for tour step
      mediator.on('finish-question-tour', () => {
        TourQuestionHandler.questionTour.complete()
        TourQuestionHandler.activeTour = false
      })
    }
  }

  /**
   * attaches to html element for ng-mouseover
   * @param option
   */
  function mouseover (option, $index) {
    if (!QuestionService.question.chess) return
    const from = option.substring(0, 3).replace(',', '')
    ChessHandler.mouseOver(from, QuestionService.question.options, $index)
  }

  /**
   * attaches to html element for ng-mouseleave
   */
  function mouseleave () {
    if (!QuestionService.question.chess) return
    ChessHandler.mouseOut()
  }

  /**
   * selects answer, changes stage, and sends the answer to the BE
   * @param idx int index of selected answer
   */
  function selectAnswer (idx) {
    if (!$state.includes('root.auth.question.answered')) {
      if (QuestionService.question.hasOwnProperty('chess')) {
        $rootScope.$emit('chessAnswered',
          vm.options[idx].replace(', ', ''))
        mouseleave()
      }
      choice = Number(idx)
      if (vm.$state.current.name === ('root.auth.question')) $state.go('root.auth.question.answered.explanation')
      // The attemptId is used to POST the user's choice to the API.
      QuestionService.answerQuestion(vm.attempt, idx).then(success, failure)
    }

    /**
     * success handler for QuestionService.answerQuestion()
     * @param data object returned from QuestionService.answerQuestion()
     */
    function success (data) {
      if (!data || data.status) failure(data)
      if (TourQuestionHandler.activeTour) TourQuestionHandler.checkForElement('.question__option--correct')
    }

    /**
     * erro handler for QuestionService.answerQuestion()
     * @param error object returned from QuestionService.answerQuestion()
     */
    function failure (error) { console.error('Selecting an answer failed:', error) }
  }

  /**
   * When a button is clicked, the correctAnswer is used to set the correct button styles
   * @param idx int index of selected answer
   * @returns {string}
   */
  function setButtons (idx) {
    if ($state.includes('root.auth.builder.preview') && idx === vm.correctAnswer) return 'question__option--correct'
    if (!$state.includes('root.auth.question.answered')) return 'md-primary md-no-ink'
    if (idx === vm.correctAnswer) return 'question__option--correct'
    if (idx === choice && choice !== vm.correctAnswer) return 'question__option--incorrect'
    return 'md-primary md-no-ink'
  }
}

export { mcqsQuestionOptions, questionOptionsController }
