'use strict'
import { default as Hammer } from 'hammerjs'
/**
 * hammer-warpper
 * @param $timeout
 * @returns {{scope: {step: string, cancel: string, isZoomed: string}, link: link}}
 */
function hammerWrapper ($timeout) {
  'ngInject'
  return {
    scope: {step: '&', cancel: '&', isZoomed: '=?'},
    link: link
  }

  /**
   * hammerjs is used for handling touch events to give the web app the same multi touch functionality as a native app
   * docs are found here http://hammerjs.github.io/
   * @param scope
   * @param elem
   */
  function link (scope, elem) {
    let posX = 0
    let posY = 0
    let lastPosX = 0
    let lastPosY = 0
    let zoomLevel = 1
    let lastZoomLevel = 1

    scope.isZoomed = false
    // setting up and configuring the touch inputs hammerjs manager
    let hammertime = new Hammer.Manager(elem[0])

    hammertime.add(new Hammer.Tap({
      event: 'doubletap',
      taps: 2,
      posThreshold: 30
    }))
    hammertime.add(new Hammer.Tap({event: 'singletap'}))
    hammertime.add(new Hammer.Pan())
    hammertime.add(new Hammer.Pinch())
    hammertime.add(new Hammer.Press())

    // this allows a single tap event to be fired when a double tap fails the threshold
    hammertime.get('doubletap').recognizeWith('singletap')
    hammertime.get('singletap').requireFailure('doubletap')

    // toggles the zoom level on double tap
    hammertime.on('doubletap', (ev) => {
      if (!scope.isZoomed) {
        zoomLevel = 2
        updateOnZoom(ev)
      } else {
        zoomLevel = 1
      }
      scope.isZoomed = !scope.isZoomed
      updateImageTransition()
      stayOnScreen()
      updateImage()
      updateZoom()
      updateLastPos()
      $timeout(updateImageTransitionReset, 400)
    })

    // centers the image to a center tap location
    hammertime.on('singletap', (ev) => {
      updateOnZoom(ev)
      stayOnScreen()
      updateImage()
      updateLastPos()
    })

    // no action is currently fired on a long press
    hammertime.on('press', (ev) => { })

    hammertime.on('pan', (ev) => {
      posX = lastPosX + ev.deltaX
      posY = lastPosY + ev.deltaY
      stayOnScreen()
      updateImage()
    })

    // this handles the swipe gestures changing images in an album and closing images.
    hammertime.on('panend', (ev) => {
      updateLastPos()
      const {velocity, deltaX, deltaY} = ev
      if (zoomLevel <= 1.1) {
        if (velocity <= -1.5 && deltaY < 100 && deltaY > -100) {
          $timeout(() => scope.step({dir: -1}))
        } else if (velocity >= 1.5 && deltaY < 100 && deltaY > -100) {
          $timeout(() => scope.step({dir: 1}))
        } else if (velocity <= -1.5 && deltaX < 50 && deltaX > -50) {
          scope.cancel()
        } else if (velocity >= 1.5 && deltaX < 50 && deltaX > -50) {
          scope.cancel()
        }
      }
    })

    // no action is currently fired on the start of a pinch gesture
    hammertime.on('pinchstart', (ev) => { })

    // this handles the pinch to zoom gesture
    hammertime.on('pinch', (ev) => {
      scope.isZoomed = true
      zoomLevel = Math.max(1, Math.min(lastZoomLevel * ev.scale, 4))
      stayOnScreen()
      updateImage()
    })

    // this resets the zoom status at the end of a pinch gesture
    hammertime.on('pinchend', (ev) => {
      if (zoomLevel <= 1.1) scope.isZoomed = false
      updateZoom()
      updateLastPos()
    })

    /**
     * this keeps the image with in the bounds of image dialog
     */
    function stayOnScreen () {
      let maxPosX = ((elem[0].offsetWidth * zoomLevel) - elem[0].clientWidth) / 2
      let maxPosY = ((elem[0].offsetHeight * zoomLevel) - elem[0].clientHeight) / 2
      let minPosX = -maxPosX
      let minPosY = -maxPosY
      if (posX > maxPosX) posX = maxPosX
      if (posX < minPosX) posX = minPosX
      if (posY > maxPosY) posY = maxPosY
      if (posY < minPosY) posY = minPosY
    }

    /**
     * updates the last scroll positions
     */
    function updateLastPos () {
      lastPosX = posX
      lastPosY = posY
    }

    /**
     * updates the zoom level
     */
    function updateZoom () { lastZoomLevel = zoomLevel }

    /**
     * this handles the updating of the image and translating its position
     */
    function updateImage () {
      elem[0].style.transform = 'scale(' + zoomLevel + ',' + zoomLevel + ') ' +
        'translate(' + (posX / zoomLevel) + 'px,' + (posY / zoomLevel) + 'px) '
    }

    /**
     * this smooths any image translations and removes any stuttering while zooming in
     */
    function updateImageTransition () {
      elem[0].style.transition = 'all .4s'
    }

    /**
     * this resets the translation delay after zooming in
     */
    function updateImageTransitionReset () {
      elem[0].style.transition = 'all .1s'
    }

    /**
     * updates the image position on zoom
     * @param ev event
     */
    function updateOnZoom (ev) {
      // event position on element
      let eleX = ev.center.x - elem[0].x
      let eleY = ev.center.y - elem[0].y

      // calculate event position relative to viewport center
      let elementOffsetX = (elem[0].clientWidth / 2) - eleX
      let elementOffsetY = (elem[0].clientHeight / 2) - eleY

      // calculate event on scaled image
      let imageXX = (posX + elementOffsetX)
      let imageYY = (posY + elementOffsetY)

      // translate delta
      let dx = (imageXX - lastPosX) / lastZoomLevel
      let dy = (imageYY - lastPosY) / lastZoomLevel

      // update the image position
      posX = lastPosX + (dx * zoomLevel)
      posY = lastPosY + (dy * zoomLevel)
    }
  }
}

export { hammerWrapper }
