'use strict'
/* global FB */
// http://blog.brunoscopelliti.com/facebook-authentication-in-your-angularjs-web-app/
/**
 * The FBService handles the functionality that makes requests to the facebook api.
 * First it authenticates a user with facebook and then, if successful, signs the user in
 * @returns {{login: (function(): Promise<any>), logout: logout, loginStatus: (function(): Promise<any>)}}
 * @constructor
 */
function FBService () {
  'ngInject'
  return {
    login: login,
    logout: logout,
    loginStatus: loginStatus
  }

  /**
   * returns a promise from the global FB object, or rejects the promise if the call throws an error
   * @returns {Promise<any>}
   */
  function login () {
    return new Promise((resolve, reject) => {
      if (noFB()) return reject(new Error('No Facebook API instance found. Could not sign in with Facebook API.'))
      FB.login(response => {
        // if user has authenticated the application then authResponse will be present
        if (response.authResponse) {
          // returns the below fields for the logged in user from facebook.
          return FB.api('/me', {
            fields: 'email, id, name, first_name, last_name, picture.type(large), cover'
          }, response => resolve(response))
        }
        reject(new Error('User cancelled FB login.'))
      }, { scope: 'email, public_profile' })
    })
  }

  /**
   * return a promise from the global FB object if a user is currently
   * connected or rejects they promise if they are not
   * @returns {Promise<any>}
   */
  function loginStatus () {
    return new Promise((resolve, reject) => {
      if (noFB()) return reject(new Error('No Facebook API instance found. Could not check login status.'))
      FB.getLoginStatus(response => {
        response.status === 'connected' ? resolve(true) : reject(new Error(false))
      })
    })
  }

  /**
   * logs a user out using the global FB object
   */
  function logout () {
    if (noFB()) return console.error('No Facebook API instance found. Could not sign out with Facebook API.')
    FB.logout(() => { console.log('Facebook sign out called successfully.') })
  }

  /**
   * checks for the existence of a Facebook Object on the window
   * @returns {boolean}
   */
  function noFB () { return (!window.FB || !FB || typeof FB === 'undefined') }
}
export { FBService }
