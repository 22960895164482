'use strict'
/**
 * mcqs0builder-question-list-item-actions directive
 * @type {{templateUrl: string, bindings: {question: string, refresh: string, currentQuestionId: string}, controller: builderQuestionListItemActionsController, controllerAs: string}}
 */
const mcqsBuilderQuestionListItemActions = {
  templateUrl: 'partials/templates/builder/question_list_item_actions.html',
  bindings: {
    question: '=',
    refresh: '&',
    currentQuestionId: '@'
  },
  controller: builderQuestionListItemActionsController,
  controllerAs: 'vm'
}

/**
 * builderQuestionListItemActionsController handles deleting a question from within the question list
 * @param $state
 * @param BuilderHandler
 * @param BuilderService
 */
function builderQuestionListItemActionsController ($state, BuilderHandler, BuilderService) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.questionToDelete = null

  vm.toggleDelete = toggleDelete
  vm.deleteQuestion = deleteQuestion

  /**
   * switches the trash icon to display two different icons, one for delete, one for cancel
   * @param e event the event that fires on click
   * @param questionId int the question id
   */
  function toggleDelete (e, questionId) {
    e.stopPropagation()
    vm.questionToDelete = (vm.questionToDelete === questionId) ? null : questionId
  }

  /**
   * calls BuilderService.deleteQuestion to delete the selected question
   * @param questionId int question to be deleted
   */
  function deleteQuestion (questionId) {
    BuilderService.deleteQuestion(questionId).then(deleteSuccess, deleteFailure)

    /**
     * success handler for BuilderService.deleteQuestion
     * reloads the builder if deleting question currently working on
     * or refresh the question list if deleting from question list
     * @param data
     */
    function deleteSuccess (data) {
      if (!data || data.status) return deleteFailure(data)
      // If the user deletes the question they're currently working on
      if (vm.currentQuestionId && vm.currentQuestionId === String(questionId)) {
        BuilderHandler.clearAll()
        $state.go('root.auth.builder.edit')
        // prevents the dialogue from being manually closed by the user
        vm.closeable = false
      }
      // if vm.refresh exists update the exam list
      if (vm.refresh) vm.refresh()
    }

    /**
     * error handler for BuilderService.deleteQuestion()
     * @param error object returned from BuilderService.deleteQuestion()
     */
    function deleteFailure (error) { console.error('Failed to delete question ' + questionId, error) }
  }
}
export { mcqsBuilderQuestionListItemActions }
