'use strict'
/**
 * mcqs-builder-delete directive
 * @type {{template: string, bindings: {questionId: string, examId: string}, controller: builderDeleteController, controllerAs: string}}
 */
const mcqsBuilderDelete = {
  template: `
        <md-button
            class="md-fab md-raised md-mini md-warn md-hue-3"
            ng-click="vm.openDialog()"
        >
            <md-tooltip md-direction="right">Delete question</md-tooltip>
            <md-icon md-font-set="fa" class="fa fa-trash fa-2x"></md-icon>
        </md-button>
    `,
  bindings: {
    questionId: '@',
    examId: '@'
  },
  controller: builderDeleteController,
  controllerAs: 'vm'
}

/**
 * builderDeleteController is attached to the above template and conatains a single
 * function which opens a dialog, prompting additional input from the user
 * @param $mdDialog
 * @param TourBuilderHandler
 */
function builderDeleteController ($mdDialog, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.openDialog = openDialog

  /**
   * opens a dialog to ask a user to confirm deletion of a question
   */
  function openDialog () {
    TourBuilderHandler.builderTour.cancel()
    $mdDialog.show({
      templateUrl: 'partials/templates/builder/delete_dialog.html',
      controller: builderDeleteDialogController,
      controllerAs: 'vm',
      locals: {
        questionId: vm.questionId,
        examId: vm.examId
      },
      disableParentScroll: false
    })
  }
}

/**
 * builderDeleteDialogController is attached to the above dialog and contains two
 * functions. One to close the dialog and the other to call the service to delete the question
 * @param questionId int current question id
 * @param examId int current exam id
 * @param $mdDialog
 * @param $state
 * @param BuilderService
 * @param BuilderHandler
 */
function builderDeleteDialogController (questionId, examId, $mdDialog, $state, BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.cancel = $mdDialog.cancel
  vm.deleteQuestion = deleteQuestion

  /**
   * calls BuilderService to delete the current question
   */
  function deleteQuestion () {
    BuilderService.deleteQuestion(questionId).then(success, failure)

    /**
     * success handler for BuilderService.deleteQuestion()
     * clears the question in the handler, reloads the state and
     * opens the question dialog
     * @param data object returned from BuilderService.deleteQuestion()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      BuilderHandler.clearAll()
      $state.go('root.auth.builder.edit')
      BuilderService.openQuestionDialog({ currentExam: examId })
    }

    /**
     * error handler for BuilderService.deleteQuestion()
     * @param error object returned from BuilderService.deleteQuestion()
     */
    function failure (error) { console.error('Failed to delete question ' + questionId, error) }
  }
}

export { mcqsBuilderDelete, builderDeleteDialogController }
