'use strict'

/**
 * Controller for chess board in the question
 * @param $scope
 * @param $timeout
 * @param QuestionService
 * @param $rootScope
 * @param ChessHandler
 */
function questionChessController ($scope, $timeout, QuestionService, $rootScope, ChessHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.service = QuestionService

  let board
  let game = ChessHandler.newChessGame(QuestionService.question.chess.fen)

  $rootScope.$on('$stateChangeStart', (e, toState) => {
    if (toState.name === 'root.auth.question') {
      game = ChessHandler.newChessGame(QuestionService.question.chess.fen)
      board.setPosition(QuestionService.question.chess.fen)
    }
  })

  $timeout(() => {
    board = document.getElementsByTagName('chess-board')[0]

    $scope.$watch('vm.service.question.attemptId', () => {
      game = ChessHandler.newChessGame(QuestionService.question.chess.fen)
      board.setPosition(QuestionService.question.chess.fen)
      board.draggablePieces = true
    })

    $rootScope.$on('chessAnswered', (e, move) => {
      const moveSplit = move.split(', ')
      if (moveSplit[1]) {
        move = moveSplit[0] + '=' + moveSplit[1]
        game.move(move, {sloppy: true})
      } else {
        game.move(move, {sloppy: true})
      }
      board.setPosition(game.fen())
      board.draggablePieces = false
    })

    ChessHandler.createStyles()

    board.addEventListener('drag-start', (e) => {
      ChessHandler.addDragStartEvent(e, game)
    })

    board.addEventListener('drop', (e) => {
      ChessHandler.addDropEvent(e, game, board, true, QuestionService.question.options, $rootScope)
    })

    board.addEventListener('mouseover-square', (e) => {
      ChessHandler.mouseOver(e, QuestionService.question.options)
    })

    board.addEventListener('mouseout-square', (e) => {
      ChessHandler.mouseOut()
    })

    $scope.$on('$destroy', () => {
      ChessHandler.removeStyles()
    })
  })
}

export { questionChessController }
