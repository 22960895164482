'use strict'
// It seems like this file is currently not being used
/**
 * mcqs-profile-user-picture directive
 * @param ProfileService
 * @returns {{restrict: string, link: link}}
 */
function mcqsProfileUserPicture (ProfileService) {
  'ngInject'
  return {
    restrict: 'A',
    link
  }
  function link (scope, elem, attrs) {
    activate()

    function activate () {
      if (!elem || !elem[0] || elem[0].tagName !== 'IMG') return
      let userId = attrs.mcqsProfileUserPicture
      ProfileService.getProfilePicture(userId).then(success, failure)
    }

    function success (data) {
      let { url } = data
      if (!data || !url) return failure('No image data in response.')
      elem[0].src = url || elem[0].src || ''
    }

    function failure (error) { console.error('Could not get profile picture for user:', error) }
  }
}

export { mcqsProfileUserPicture }
