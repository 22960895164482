'use strict'
/**
 * mcqs-question-toolbar directive
 * @type {{templateUrl: string, transclude: boolean, bindings: {author: string, answered: string, disableNext: string}, controller: questionToolbarController, controllerAs: string}}
 */
const mcqsQuestionToolbar = {
  templateUrl: 'partials/templates/question/question_toolbar.html',
  transclude: true,
  bindings: {
    author: '=',
    answered: '=',
    disableNext: '='
  },
  controller: questionToolbarController,
  controllerAs: 'vm'
}

/**
 * controller used to attach bindings to the DOM
 * @param QuestionService
 */
function questionToolbarController (QuestionService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this // eslint-disable-line no-unused-vars
}

export { mcqsQuestionToolbar }
