'use strict'

/**
 * Controller for the landing header
 * @param $mdSidenav
 * @param $rootScope
 * @param $timeout
 * @param $scope
 * @param $mdComponentRegistry
 */
function landingHeaderController ($mdSidenav, $rootScope, $timeout, $scope, $mdComponentRegistry) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.openNavSidebar = openNavSidebar

  let listener

  $mdComponentRegistry.when('navSidenav').then(() => {
    listener = $rootScope.$on('$stateChangeStart', () => {
      $mdComponentRegistry.when('navSidenav').then(() => {
        $mdSidenav('navSidenav').close()
      })
    })
  })

  $scope.$on('$destroy', () => {
    listener()
  })

  /**
   * function that triggers opening of the sidebar
   */
  function openNavSidebar () {
    $mdSidenav('navSidenav').open()
  }
}

export { landingHeaderController }
