'use strict'
/**
 * mcqs-profile-current-exam directive
 * @type {{templateUrl: string, bindings: {currentExam: string}, controller: profileCurrentExamController, controllerAs: string, transclude: boolean}}
 */
const mcqsProfileCurrentExam = {
  templateUrl: 'partials/templates/profile/profile_current_exam.html',
  bindings: { currentExam: '=' },
  controller: profileCurrentExamController,
  controllerAs: 'vm',
  transclude: true
}

/**
 * controller that binds ExamService.showExamSelectDialog to the DOM
 * @param ExamService
 */
function profileCurrentExamController (ExamService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.openExamDialog = ExamService.showExamSelectDialog
}

export { mcqsProfileCurrentExam }
