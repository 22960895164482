'use strict'
import { builderPreviewController } from './builder_preview_view_controller'
import { builderViewController } from './builder_view_controller'

/**
 * route provider for builder
 * @param $stateProvider
 */
function builderRouteConfig ($stateProvider) {
  $stateProvider
    .state('root.auth.builder', {
      url: '/builder',
      views: {
        'main@': {
          templateUrl: 'partials/views/builder.html',
          controller: builderViewController,
          controllerAs: 'vm'
        }
      }
    })
    .state('root.auth.builder.edit', {
      views: {
        'builder@root.auth.builder': {
          templateUrl: 'partials/views/builder_edit.html'
        }
      }
    })
    .state('root.auth.builder.preview', {
      views: {
        'builder@root.auth.builder': {
          templateUrl: 'partials/views/builder_preview.html',
          controller: builderPreviewController,
          controllerAs: 'vm'
        }
      }
    })
    .state('root.auth.builder.dynamic', {
      views: {
        'builder@root.auth.builder': {
          templateUrl: 'partials/views/builder_dynamic.html'
        }
      }
    })
}

export { builderRouteConfig }
