'use strict'
/**
 * mcqs-account-profile-form directive
 * @type {{templateUrl: string, bindings: {profile: string, account: string}, controller: accountProfileFormController, controllerAs: string}}
 */
const mcqsAccountProfileForm = {
  templateUrl: 'partials/templates/account/profile_form.html',
  bindings: {profile: '=?', account: '=?'},
  controller: accountProfileFormController,
  controllerAs: 'vm'
}
/**
 * The accountProfileFormController is used to manage the form in the above template.
 * This controller controls the showing/hiding of the ng-messages and allowing/disabling
 * form submission
 * @param AccountService
 * @param AuthenticationService
 * @param $timeout
 */
function accountProfileFormController (AccountService, AuthenticationService, $timeout) {
  'ngInject'
  /* jshint validthis:true */
  let vm = this

  vm.reset = reset
  vm.update = update
  vm.checkForPristine = checkForPristine
  vm.checkPassword = checkPassword
  vm.checkUsername = checkUsername
  vm.checkFieldsForErrors = checkFieldsForErrors

  vm.profileForm = {
    username: '',
    password: ''
  }

  activate()
  /**
   * Initial function that runs when accountProfileFormController is loaded
   */
  function activate () {
    // timeout is to manually trigger a digest cycle so changes are reflected on web page
    $timeout(() => { vm.profileForm.password.$touched = true })
    vm.profile.country = vm.profile.country == null ? '' : vm.profile.country
    vm.profile.dateOfBirth = new Date(vm.profile.dateOfBirth)
    vm.password = ''
    // creates a copy of the profile before changes are made. This is to handle a user resetting all changes.
    vm.profileCopy = angular.copy(vm.profile)
  }
  /**
   * Updates the users account
   */
  function update () {
    AccountService.updateProfile(vm.profile, vm.password).then(success, failure)
    function success (data) {
      if (!data || data.status) return failure(data)
      activate()
      setPristine()
      // Set the inputs to valid to hide ng-messages
      vm.profileForm.password.$setValidity('correctPassword', true)
      vm.profileForm.username.$setValidity('usernameTaken', true)
      // timeout is called to trigger a digest
      $timeout(() => { vm.profileForm.password.$setValidity('required', true) })
      // set the password to an empty string BEFORE setting the form input to prstine.
      vm.password = ''
      vm.profileForm.password.$setPristine()
      AuthenticationService.updateToken(data.token)
      AccountService.showDialog()
    }
    /**
     * Error handler
     * @param error object The error returned from the BE
     */
    function failure (error) {
      if (error.data.status === 403) {
        // will show ngMessage username already taken
        vm.profileForm.username.$setValidity('usernameTaken', false)
      } else {
        // will show ngMessage for incorrect password
        vm.profileForm.password.$setValidity('correctPassword', false)
      }
      console.error('Failed to update account profile:', error)
    }
  }
  /**
   * Resets all changes made in the profile form
   */
  function reset () {
    // Set the inputs to valid to hide ng-messages
    vm.profileForm.password.$setValidity('correctPassword', true)
    vm.profileForm.username.$setValidity('usernameTaken', true)
    vm.password = ''
    // reverts all changes made to profile by setting it as the copy created in activate()
    Object.assign(vm.profile, vm.profileCopy)
    setPristine()
  }

  /**
   * Sets the form to its initial state
   */
  function setPristine () { vm.profileForm.$setPristine() }

  /**
   * Checks if both objects match if. If they do set the form to its initial state
   */
  function checkForPristine () {
    if (angular.equals(vm.profile, vm.profileCopy)) {
      vm.profileForm.password.$setValidity('formChanged', true)
      return true
    } else {
      if (typeof vm.password === 'undefined' || vm.password === '') {
        vm.profileForm.password.$setValidity('formChanged', false)
      }
      return false
    }
  }

  /**
   * Checks the passwords and disables form submission if no match
   * */
  function checkPassword () {
    if (typeof vm.password === 'undefined' || vm.password === '') {
      vm.profileForm.password.$setValidity('correctPassword', true)
    }
    vm.profileForm.password.$setValidity('formChanged', true)
  }

  /**
   * If username is equal to initial value then disable reset button
   */
  function checkUsername () {
    if (vm.profile.username === vm.profileCopy.username) {
      vm.profileForm.username.$setPristine()
    }
  }

  /**
   * checks all fields for errors and disables submission if errors exist
   * @returns {boolean}
   */
  function checkFieldsForErrors () {
    return (Object.keys(vm.profileForm.country.$error).length > 0 ||
      Object.keys(vm.profileForm.firstName.$error).length > 0 ||
      Object.keys(vm.profileForm.surname.$error).length > 0 ||
      Object.keys(vm.profileForm.username.$error).length > 0 ||
      Object.keys(vm.profileForm.password.$error).length > 0 ||
      Object.keys(vm.profileForm.dateOfBirth.$error).length > 0)
  }
}

export { mcqsAccountProfileForm }
