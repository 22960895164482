'use strict'

/**
 * Navbar service that handles setting the correct sidenav to be opened
 * @param $mdSidenav
 * @param $q
 * @returns {{currentSidenav: {}, currentSidenavName: string, isOpen: (function(): *), setCurrentSidenav: (function(*, *): *)}}
 * @constructor
 */
function NavbarService ($mdSidenav, $q) {
  'ngInject'
  const service = {
    currentSidenav: {},
    currentSidenavName: '',
    isOpen: isOpen,
    setCurrentSidenav: setCurrentSidenav
  }

  return service

  /**
   * sets the service values that are used when calling a new sidenav
   * @param sideNav $mdSideNav object
   * @param navID string selected sideNav name
   * @returns {*}
   */
  function setCurrentSidenav (sideNav, navID) {
    service.currentSidenav = {}
    service.currentSidenavName = ''
    // creates a promise, sets the new current sidenav then resolves the promise
    let defer = $q.defer()
    service.currentSidenav = sideNav
    service.currentSidenavName = navID
    defer.resolve()
    return defer.promise
  }

  /**
   * returns true if either sidenav is open
   * @returns {*}
   */
  function isOpen () { return $mdSidenav('profile').isOpen() || $mdSidenav('chat').isOpen() }
}

export { NavbarService }
