'use strict'

/**
 * Service to make requests on behalf of lottie player
 * @param $http
 * @param DOMAIN
 * @returns {function(*=): *}
 * @constructor
 */
function LottieService ($http, DOMAIN) {
  'ngInject'

  return {
    getLottieFile,
    uploadJSON
  }

  /**
   * returns a lottie file
   * @param filename - name of json file on S3
   * @param questionId
   * @returns {*}
   */
  function getLottieFile (filename, questionId) {
    return $http.post(`${DOMAIN}/api/question/lottie`, {
      lottieUrl: filename,
      questionId
    })
  }

  /**
   * Sends the path to the json to node so it can be uploaded
   * to S3
   * @param url - path to JSON so it can be downloaded to S3 on the BE
   * @param filename - name of file being uploaded
   */
  function uploadJSON (filename, url) {
    return $http.put(DOMAIN + '/api/builder/lottie/upload', {
      filename: filename,
      url: url
    })
  }
}

export { LottieService }
