'use strict'
/**
 * mcqs-chat-report directive
 * @type {{template: string, bindings: {message: string}, controller: chatReportController, controllerAs: string}}
 */
const mcqsChatReport = {
  template: `
        <span class="chat__report-button" ng-click="vm.report()">Report</span>
    `,
  bindings: {message: '='},
  controller: chatReportController,
  controllerAs: 'vm'
}

/**
 * chatReportController handles reporting a message
 * @param $mdDialog
 */
function chatReportController ($mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.report = report

  /**
   * opens a dialog window to handle reporting of a message
   */
  function report () {
    $mdDialog.show({
      templateUrl: 'partials/templates/chat/report_dialog.html',
      controller: chatReportDialogController,
      controllerAs: 'vm',
      disableParentScroll: false,
      locals: {message: Object.assign({}, vm.message)}
    })
  }
}

/**
 * chatReportDialogController calls the service to send the report to the back end
 * @param message string the message to be reported
 * @param $mdDialog
 * @param $timeout
 * @param ChatService
 */
function chatReportDialogController (message, $mdDialog, $timeout, ChatService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.disabled = false
  vm.messageStatus = ''
  vm.reportMessage = ''

  vm.message = message

  vm.cancel = $mdDialog.cancel
  vm.send = send

  /**
   * calls ChatService.reportMessage and handles the responses
   */
  function send () {
    vm.disabled = true
    vm.messageStatus = ''
    let {messageId} = vm.message
    ChatService.report({messageId, reason: vm.reportMessage}).then(data => {
      vm.reportMessage = ''
      vm.messageStatus = 'Thank you for filing this report. We take violations of our Terms of Service very seriously and will investigate this matter.'
      // wait 5 seconds after repsonse to close the report window
      $timeout(vm.cancel, 5000)
    }, error => {
      vm.disabled = false
      vm.messageStatus = 'There was a problem submitting your report at this time. Please try again.'
      console.error('Reporting chat message failed:', error)
    })
  }
}

export { mcqsChatReport, chatReportDialogController }
