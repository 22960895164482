'use strict'

/**
 * Service the handling updating of profile information via the BE
 * @param $http
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @param $cookies
 * @returns {{getProfile: (function(*=): *), getProfilePicture: (function(*=): *), setPreferences: setPreferences, getPreferences: (function(): {questionTour: *, builderTour: *, addDataModal: *}), updatePreferences: (function(): angular.IPromise<any>), setDataModalPreference: (function(*=): *), setQuestionTourPreference: (function(*=): *), setBuilderTourPreference: (function(*=): *)}}
 * @constructor
 */
function ProfileService ($http, DOMAIN, $cookies) {
  'ngInject'
  let prefObj = {}
  const service = {
    getProfile,
    getProfilePicture,
    setPreferences,
    getPreferences,
    updatePreferences,
    setDataModalPreference,
    setQuestionTourPreference,
    setBuilderTourPreference
  }

  const success = response => response.status !== 200 ? response : response.data || {}
  const failure = error => error

  return service

  /**
   * gets the profile of the given userId
   * @param userId int user id
   * @returns {*}
   */
  function getProfile (userId = '') {
    return $http.get(DOMAIN + `/api/profile/${userId}`).then(success, failure)
  }

  /**
   * gets the profile picture for the given userId
   * @param userId int user id
   * @returns {*}
   */
  function getProfilePicture (userId = '') {
    return $http.get(DOMAIN + `/api/profile/image/${userId}`, { cache: true }).then(success, failure)
  }

  /**
   * Update the users preferences
   * @returns {angular.IPromise<any>}
   */
  function updatePreferences () {
    prefObj = service.getPreferences()
    return $http.put(DOMAIN + '/api/user/tour', prefObj).then(success, failure)
  }

  /**
   * sets the users preferences in the cookies
   * @param preferences object containg a users preferences
   */
  function setPreferences (preferences) {
    $cookies.put('mcqsPreferencesQuestionTour', preferences.questionTour)
    $cookies.put('mcqsPreferencesBuilderTour', preferences.builderTour)
    // sets a users preference for dynamic builder
    $cookies.put('mcqsPreferencesAddDataModal', preferences.addDataModal)
  }

  /**
   * gets a users preferences from the cookies
   * @returns {{questionTour: *, builderTour: *, addDataModal: *}}
   */
  function getPreferences () {
    return {
      questionTour: $cookies.get('mcqsPreferencesQuestionTour'),
      builderTour: $cookies.get('mcqsPreferencesBuilderTour'),
      // gets a users preference for dynamic builder
      addDataModal: $cookies.get('mcqsPreferencesAddDataModal')
    }
  }

  /**
   * sets the dataModal preference explicitly
   * @param preference boolean
   * @returns {angular.IPromise<any>}
   */
  function setDataModalPreference (preference) {
    $cookies.put('mcqsPreferencesAddDataModal', preference)
    return service.updatePreferences()
  }

  /**
   * sets the questionTour preference explicitly
   * @param preference boolean
   * @returns {angular.IPromise<any>}
   */
  function setQuestionTourPreference (preference) {
    $cookies.put('mcqsPreferencesQuestionTour', preference)
    return service.updatePreferences()
  }

  /**
   * sets the dataModal preference explicitly
   * @param preference boolean
   * @returns {angular.IPromise<any>}
   */
  function setBuilderTourPreference (preference) {
    $cookies.put('mcqsPreferencesBuilderTour', preference)
    return service.updatePreferences()
  }
}

export { ProfileService }
