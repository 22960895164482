'use strict'
/**
 * mcqs-error-source directive
 * @returns {{link: link}}
 */
function mcqsErrorSource () {
  'ngInject'
  return {
    /**
     * contains default image when an image fails to load
     * @param scope
     * @param elem
     * @param attrs
     */
    link: function (scope, elem, attrs) {
      elem.bind('error', () => {
        attrs.$set('src', 'http://diatldtcixe86.cloudfront.net/Pl0ZFlSkuAjU0EttyA76hXBA.png')
      })
    }
  }
}

export { mcqsErrorSource }
