'use strict'
/**
 * mcqs-tour-toggle directive
 * @type {{template: string, controller: mcqsTourToggleController, controllerAs: string}}
 */
const mcqsTourToggle = {
  template: `
    <md-menu md-position-mode="target-right bottom">
        <md-button
        ng-click="vm.openMenu($mdMenu.open, $event)"
        class="md-icon-button"
        ng-disabled="
            !vm.$state.includes('root.auth.question') &&
            vm.$state.includes('root.auth.builder.preview') || 
            vm.qService.question.core.length == 0 && 
            vm.$state.includes('root.auth.question')
        ">
            <md-tooltip>Tour</md-tooltip>
            <md-icon md-font-set="fa" class="fa fa-info fa-2x"></md-icon>
        </md-button>
        <md-menu-content width="3">
        <md-menu-item ng-if="vm.$state.includes('root.auth.question')">
                <md-button ng-click="vm.startQuestionTour()">
                    Question Tour
                </md-button>
            </md-menu-item>
            <md-menu-item ng-if="vm.$state.includes('root.auth.builder')">
                <md-button ng-click="vm.startBuilderTour()">
                    Builder Tour
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button ng-click="vm.startNavbarTour()">
                    Navbar Tour
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button ng-click="vm.startProfileTour()">
                    Profile Tour
                </md-button>
            </md-menu-item>
        </md-menu-content>
    </md-menu>
    `,
  controller: mcqsTourToggleController,
  controllerAs: 'vm'
}

/**
 * controller that handles starting/stopping of tours
 * @param $state
 * @param TourBuilderHandler
 * @param TourQuestionHandler
 * @param TourNavbarHandler
 * @param $scope
 * @param QuestionService
 * @param TourProfileHandler
 */
function mcqsTourToggleController ($state, TourBuilderHandler, TourQuestionHandler, TourNavbarHandler, TourProfileHandler, $scope, QuestionService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.qService = QuestionService
  vm.$state = $state

  vm.openMenu = openMenu
  vm.startQuestionTour = startQuestionTour
  vm.startNavbarTour = startNavbarTour
  vm.startBuilderTour = startBuilderTour
  vm.startProfileTour = startProfileTour

  function openMenu ($mdOpenMenu, ev) {
    $mdOpenMenu(ev)
  }

  function startQuestionTour () {
    if (!TourQuestionHandler.shepherd.activeTour) TourQuestionHandler.questionStartTour()
  }
  function startNavbarTour () {
    if (!TourNavbarHandler.shepherd.activeTour) TourNavbarHandler.navbarStartTour()
  }
  function startBuilderTour () {
    if (!TourBuilderHandler.shepherd.activeTour) TourBuilderHandler.builderStartTour()
  }
  function startProfileTour () {
    if (!TourProfileHandler.shepherd.activeTour) TourProfileHandler.profileStartTour()
  }
}

export { mcqsTourToggle }
