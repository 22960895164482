'use strict'
/**
 * mcqs-builder-add-related-data directive
 * @type {{template: string, controller: addRelatedDataController, controllerAs: string}}
 */
const mcqsBuilderAddRelatedData = {
  template: `
        <md-button
            class="md-icon-button"
            ng-click="vm.openDialog()"
            ng-disabled="!vm.$state.includes('root.auth.builder.edit')"
        >
            <md-tooltip>Add question data</md-tooltip>
            <div class="fa-stack">
                <md-icon md-font-set="fa" class="fa fa-sticky-note-o fa-stack-2x"></md-icon>
                <md-icon md-font-set="fa" class="fa-stack-1x fa fa-plus"></md-icon>
            </div>
        </md-button>
    `,
  controller: addRelatedDataController,
  controllerAs: 'vm'
}

/**
 * addRelatedDataController is used to show the user a quick explanation of related data
 * and to move to the dynamic state
 * @param $mdDialog
 * @param $state
 * @param BuilderHandler
 * @param ProfileService
 * @param TourBuilderHandler
 */
function addRelatedDataController ($mdDialog, $state, BuilderHandler, ProfileService, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.$state = $state

  vm.openDialog = openDialog

  /**
   * calls BuilderHandler.addRelatedData to add a relatedData node to the question
   * @returns {*} used to finish execution of function
   */
  function openDialog () {
    TourBuilderHandler.builderTour.cancel()
    let preference = ProfileService.getPreferences().addDataModal
    // if user has not seen the related data dialog before the show them.
    if (preference === false || preference === 0) {
      return $mdDialog.show({
        templateUrl: 'partials/templates/builder/dynamic/related_data_dialog.html',
        controller: addRelatedDataDialogController,
        controllerAs: 'vm',
        disableParentScroll: false
      })
    }
    BuilderHandler.addRelatedData()
    vm.$state.go('root.auth.builder.dynamic')
  }
}

/**
 * the addRelatedDataDialogController is used to handle the setting of related data
 * when a user has not been shown the related data explanation
 * @param $mdDialog
 * @param $state
 * @param BuilderHandler
 * @param ProfileService
 */
function addRelatedDataDialogController ($mdDialog, $state, BuilderHandler, ProfileService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.preference = ProfileService.getPreferences().addDataModal

  vm.close = $mdDialog.cancel

  vm.addData = addData

  /**
   * calls BuilderHandler.addRelatedData to add a relatedData node to the question
   * only executed this way if it is a users first time adding a relatedData node
   */
  function addData () {
    ProfileService.setDataModalPreference(vm.preference)
    BuilderHandler.addRelatedData()
    $state.go('root.auth.builder.dynamic')
    vm.close()
  }
}

export { mcqsBuilderAddRelatedData, addRelatedDataDialogController }
