'use strict'
/**
 * mcqs-mathjax drective
 * @param $timeout
 * @returns {{link: link}}
 */
function mcqsMathjax ($timeout) {
  'ngInject'
  return {
    /**
     * This is used to add MathJax rendering to questions
     * MathJax is loaded via a CDN and configured in index.html and preinjection.html
     * @param scope
     * @param elem
     * @param attrs
     */
    link: (scope, elem, attrs) => {
      activate()

      /**
       * initialisation function that sets up an observation of the mcqs-mathjax attribute
       * and fires updateMathjax on changes
       */
      function activate () {
        let hasArgs = (attrs.mcqsMathjax.length > 0)
        // observe the expression attached to the mcqs-mathjax attribute for changes
        hasArgs ? attrs.$observe('mcqsMathjax', updateMathjax) : updateMathjax()
      }

      /**
       * applies mathjax to render the output
       */
      function updateMathjax () {
        // timeout is needed here to fire off a digest cycle
        $timeout(() => {
          // http://docs.mathjax.org/en/latest/api/hub.html
          MathJax.Hub.Config({showMathMenu: false})
          MathJax.Hub.Queue(['Typeset', MathJax.Hub])
        })
      }
    }
  }
}
export { mcqsMathjax }
