/**
 * mcqs-comment-edit directive
 * @type {{template: string, bindings: {comment: string, isHidden: string, tourComment: string}, controller: mcqsCommentEditController, controllerAs: string}}
 */
const mcqsCommentEdit = {
  template: `
    <md-button
        class="md-icon-button comment__action"
        ng-click="vm.openEditDialog()"
        ng-disabled="(vm.isHidden != 0 && vm.isHidden != false) || vm.tourComment"
    >
        <md-tooltip md-direction="bottom">Edit comment</md-tooltip>
        <md-icon md-font-set="fa" class="fa fa-pencil"></md-icon>
    </md-button>
    `,
  bindings: {
    comment: '=',
    isHidden: '@',
    tourComment: '<'
  },
  controller: mcqsCommentEditController,
  controllerAs: 'vm'
}

/**
 * controller to handle the functionality for editing a comment
 * @param $mdDialog
 * @param CommentService
 */
function mcqsCommentEditController ($mdDialog, CommentService) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.openEditDialog = openEditDialog

  /**
   * opens the dialog that allows a user to edit a comment
   */
  function openEditDialog () {
    $mdDialog.show({
      templateUrl: 'partials/templates/comment/comment_edit_dialog.html',
      controller: 'mcqsCommentEditDialogController',
      controllerAs: 'vm',
      disableParentScroll: false,
      fullscreen: true,
      locals: { commentText: vm.comment.comment }
    }).then(editedCommentText => {
      // if no changes return
      if (!editedCommentText || vm.comment.comment === editedCommentText) return
      CommentService.editComment(vm.comment.commentId, editedCommentText).then(
        () => {
          // update comment text on FE
          vm.comment.comment = editedCommentText
        },
        error => console.error('Failed to edit comment:', error)
      )
    })
  }
}

/**
 * checks that the comment edit is valid
 * @param $mdDialog
 * @param commentText
 */
function mcqsCommentEditDialogController ($mdDialog, commentText) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.commentText = commentText || ''
  vm.errorMessage = ''

  vm.cancel = $mdDialog.cancel
  vm.edit = $mdDialog.hide
  vm.checkComment = checkComment
  vm.disableEdit = false
  // This regex is used to prevent images from being added to comments
  const regExMarkdownImg = new RegExp(/!\[.*]\(.*\)/)
  function checkComment () {
    if (regExMarkdownImg.test(vm.commentText)) {
      vm.errorMessage = 'You can’t post a comment that contains links or images, sorry!'
      vm.disableEdit = true
    } else {
      vm.errorMessage = ''
      vm.disableEdit = false
    }
  }
}

export { mcqsCommentEdit, mcqsCommentEditDialogController }
