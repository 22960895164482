'use strict'
function webinarDialogController ($scope, $timeout, $mdDialog, WebinarService, BuilderHandler, userId, examId) {
  const vm = this

  vm.webinarService = WebinarService
  vm.service = BuilderHandler
  vm.startWebinarError = false
  vm.cancelWebinarError = false

  vm.isLive = vm.webinarService.isLive
  let examName = vm.webinarService.liveExamName

  vm.goLive = goLive
  vm.cancelLive = cancelLive
  vm.closeDialog = closeDialog
  vm.toolbarClass = toolbarClass

  setButtonText()

  function setButtonText () {
    if (vm.isLive) {
      vm.buttonText = 'Cancel Live'
    } else {
      vm.buttonText = 'Go Live'
    }
  }

  function goLive () {
    let selectedTagsNames = []
    if (BuilderHandler.selectedTags.length > 0) {
      BuilderHandler.selectedTags.forEach((a) => {
        selectedTagsNames.push(a.tag)
      })
    }
    let topic = BuilderHandler.selectedTags.length === 0
      ? examName
      : selectedTagsNames.join(', ')
    vm.webinar = {
      topic: topic,
      approval_type: 0,
      duration: 10,
      start_time: new Date().toISOString(),
      type: 5,
      webinar_tutor: userId
    }
    WebinarService.createNewWebinar(vm.webinar, BuilderHandler.selectedTags, examId, vm.title).then(() => {
      // vm.isLive = true
      closeDialog()
      vm.buttonText = 'Cancel Live'
      vm.startWebinarError = false
      // a lot of this functionality should probably be
      // replaced with sockets at some stage
      // to handle a user refreshing and clearing this timeout
      $timeout(() => {
        WebinarService.isTutorLive(userId, examId)
      }, process.env.LIVE_WEBINAR_TIMEOUT)
    }, (err) => {
      // vm.isLive = false
      vm.startWebinarError = true
      console.error('Error creating new Webinar: ', err)
    })
  }

  function cancelLive () {
    WebinarService.deleteLiveWebinar(userId).then(() => {
      closeDialog()
      // vm.isLive = false
      vm.cancelWebinarError = false
      vm.buttonText = 'Go Live'
    }, (err) => {
      vm.cancelWebinarError = true
      // vm.isLive = true
      console.error('Error deleting live webinar: ', err)
    })
  }

  function toolbarClass () {
    if (vm.isLive) return 'md-warn'
  }

  function closeDialog () {
    $mdDialog.hide()
  }
}

export { webinarDialogController }
