'use strict'

/**
 * manages instances of the lottie players states
 * @returns {function(*=): *}
 * @constructor
 */
function LottieHandler () {
  'ngInject'

  const service = {
    addAnimation,
    resetPlayerStates,
    defaultPlayerState: {
      tempIsPaused: true,
      waitingForAudio: false,
      polyPlaying: false,
      isSeeking: false,
      speedAltered: false,
      directionAltered: false,
      playerId: 0
    },
    playerStates: []
  }

  return service

  function addAnimation () {
    if (service.playerStates.length === 0) {
      service.playerStates.push(angular.copy(service.defaultPlayerState))
    } else {
      service.defaultPlayerState.playerId++
      service.playerStates.push(angular.copy(service.defaultPlayerState))
    }
    return service.defaultPlayerState.playerId
  }

  function resetPlayerStates () {
    service.playerStates = []
    service.defaultPlayerState.playerId = 0
  }
}

export { LottieHandler }
