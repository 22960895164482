'use strict'
import { default as placeholderComments } from './default_comments.json'

/**
 * Comment Service that handles interaction between comments and the BE
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @param $http
 * @param TourQuestionHandler
 * @returns {{comments: Array, getComments: (function(*, *=, *=, *): *), sendComment: (function(*=): *), reportComment: (function(*=): *), addComment: addComment, clearComments: clearComments, deleteComment: (function(*): *), editComment: (function(*, *): angular.IPromise<any>), rateComment: (function(*, *): angular.IPromise<any>), updateComment: updateComment, updateCommentDelete: updateCommentDelete}}
 * @constructor
 */
function CommentService (DOMAIN, $http, TourQuestionHandler, $timeout) {
  'ngInject'
  const service = {
    comments: [],
    getComments,
    sendComment,
    reportComment,
    addComment,
    clearComments,
    deleteComment,
    editComment,
    rateComment,
    updateComment,
    updateCommentDelete
  }

  return service

  /**
   * gets the comments for the current question from the BE
   * @param reqObj object contains attemptId
   * @param filter object filter object
   * @param order string whether order should ascending or descending
   * @param last int id of last comment
   * @returns {{data: {attemptId: int, comment: string, commentId: int, core: string, date: string, dislikes: int, isHidden: int, likes: int, owner: boolean, profilePic: string, replies:[{attemptId: int, comment: string, commentId: int, date: string, dislikes: int, likes: int, owner: boolean, profilePic: string, userId: int, username: string}], userId: int, username: string}}}
   */
  function getComments (reqObj, filter, order, last) {
    let lastComment = service.comments[service.comments.length - 1]
    last = (last !== 0 && lastComment) ? lastComment.commentId : 0
    // default query for BE to sort comments
    const query = `?filter=${filter || 'latest'}&last=${last}&order=${order || 'desc'}`
    return $http.get(`${DOMAIN}/api/question/comments/${reqObj.attemptId}${query}`)
      .then(response => {
        let { comments } = response.data
        let { activeTour } = TourQuestionHandler.shepherd
        if (!activeTour) {
          service.clearComments()
          angular.extend(service.comments, comments)
        } else { angular.extend(service.comments, placeholderComments) }
        return success(response)
      }, failure)
  }

  /**
   * posts comment object to BE
   * @param commentObj object
   * @returns {*}
   */
  function sendComment (commentObj) {
    // Post attemptId OR commentId to determine comment or reply.
    return $http.post(`${DOMAIN}/api/question/comment`, commentObj).then(success, failure)
  }

  /**
   * requests comment to be deleted by BE
   * @param commentId int commentId to be deleted
   * @returns {*}
   */
  function deleteComment (commentId) {
    return $http.delete(`${DOMAIN}/api/question/comment/${commentId}`).then(success, failure)
  }

  /**
   * calls the BE to edit a comment
   * @param commentId int comment to be edited
   * @param text string edit to be made
   * @returns {angular.IPromise<any>}
   */
  function editComment (commentId, text) {
    return $http.put(`${DOMAIN}/api/question/comment`, { commentId, text }).then(success, failure)
  }

  /**
   * posts a question report tp the BE
   * @param reportObj object
   * @returns {*}
   */
  function reportComment (reportObj) {
    return $http.post(`${DOMAIN}/api/question/comment/report`, reportObj).then(success, failure)
  }

  /**
   * posts a comments rating to the BE
   * @param rating int 0 for dislike, 1 for like
   * @param commentId int id of comment
   * @returns {angular.IPromise<any>}
   */
  function rateComment (rating, commentId) {
    return $http.put(`${DOMAIN}/api/question/comment/rate`, {
      rating,
      commentId
    }).then(response => {
      return success(response)
    }, failure)
  }

  /**
   * Updates the comment when actions such as rate comment are successful
   * @param rating int 0 for dislike, 1 for like
   * @param commentIdx int index of comment in comments array
   * @param replyIdx int index or reply in replies index
   * @returns {*}
   */
  function updateComment (rating, commentIdx, replyIdx) {
    const comment = service.comments[commentIdx]
    if (replyIdx || replyIdx === 0) return (comment.replies[replyIdx].likeDislike = rating)
    comment.likeDislike = rating
  }

  /**
   * this updates comments on the frontend when they're deleted
   * if a comment has no replies it will be removed from the list
   * if a comment has replies it will be replaced with the '[DELETED]' text
   * @param commentIdx int index of comment in comments array
   * @param replyIdx int index of reply in replies array
   * @returns {*} updated array
   */
  function updateCommentDelete (commentIdx, replyIdx) {
    const comment = service.comments[commentIdx]
    if (replyIdx || replyIdx === 0) return comment.replies.splice(replyIdx, 1)
    if (comment.replies.length <= 0) return service.comments.splice(commentIdx, 1)
    comment.comment = '[DELETED]'
    comment.isHidden = true
  }

  /**
   * success handler for BE responses
   * @param response object returned from BE
   * @returns {*|{}}
   */
  function success (response) { return (response.status !== 200) ? response : response.data || {} }

  /**
   * error handler for BE responses
   * @param error returned from BE
   * @returns {*}
   */
  function failure (error) { return error }

  /**
   * function that updates the FE comment array for dislpaying in the DOM
   * @param commentObj object comment object returned from BE
   * @param commentIdx int index of comment in comments array
   * @returns {number} used to control execution flow
   */
  function addComment (commentObj, commentIdx) {
    commentObj.date = new Date()
    commentObj.replies = []
    // Push comment
    if (!commentIdx) return service.comments.unshift(commentObj)
    // Push reply
    let comment = service.comments[commentIdx]
    if (comment) comment.replies.unshift(commentObj)
  }

  /**
   * clears all comments
   */
  function clearComments () { service.comments.splice(0, service.comments.length) }
}

export { CommentService }
