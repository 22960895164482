'use strict'

/**
 * controller to handle the opening/closing of sidenavs
 * @param $mdSidenav
 * @param $mdDialog
 * @param $timeout
 * @param $compile
 * @param $scope
 * @param $cookies
 * @param $state
 * @param ChatService
 * @param NavbarService
 * @param ExamService
 * @param WebinarService
 */
function navbarController ($mdSidenav, $mdDialog, $timeout, $compile, $scope,
                           $cookies, $state, ChatService, NavbarService,
                           ExamService, WebinarService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.admin = false

  vm.examId = $cookies.get('currentExam')

  vm.webinarService = WebinarService

  vm.$state = $state
  vm.chatStatus = ChatService.status
  vm.navService = NavbarService

  vm.openChat = openChat
  vm.toggleSidenav = toggleSidenav
  vm.openMenu = openMenu
  vm.liveButtonClass = liveButtonClass

  let userId

  activate()

  function activate () {
    userId = $cookies.get('userId')

    WebinarService.checkAdmin(userId).then((adminId) => {
      if (adminId.data !== null) vm.admin = true
    }, (err) => {
      console.error('Error checking admin: ', err)
    })
    if (vm.examId && vm.examId !== '0') {
      WebinarService.isTutorLive(userId, vm.examId)
    }

    ExamService.getFlashCardHidden(ExamService.getCurrentExam()).then((data) => {
      ExamService.updateFlashcardHidden(data.flashcardHidden)
      vm.flashcardHidden = ExamService.flashcardHidden
      if (vm.flashcardHidden === 1 && $state.includes('root.auth.flashcard')) {
        $state.go('root.auth.question')
      }
    }, (error) => {
      console.error(`Flashcard status failed: ${error}`)
    })
  }

  /**
   * sets selected sidenav in service and then opens current sidenav
   * @param navID
   */
  function toggleSidenav (navID) {
    let currentSidenav = $mdSidenav(navID)
    vm.navService.setCurrentSidenav(currentSidenav, navID).then(() => currentSidenav.open())
  }

  /**
   * opens chat sidenav and sets all message to read
   */
  function openChat () {
    toggleSidenav('chat')
    ChatService.clearUnread()
  }

  function openMenu () {
    if (vm.examId !== '0') {
      WebinarService.isTutorLive(userId, vm.examId)
    }
    $mdDialog.show({
      templateUrl: '/partials/templates/webinar/webinar_dialog.html',
      controller: 'webinarDialogController',
      controllerAs: 'vm',
      parent: angular.element(document.body),
      locals: {
        userId: userId,
        examId: vm.examId
      },
      escapeToClose: true,
      disableParentScroll: true,
      fullscreen: true,
      openFrom: '#live-button',
      closeTo: '#live-button'
    })
  }

  function liveButtonClass () {
    if (!vm.webinarService.isLive) return 'fa fa-video-camera fa-2x go-live'
    return 'fa fa-circle fa-2x button-live pulse'
  }
}

export { navbarController }
