/**
 * mcqs-media-validator directive
 * @returns {{require: string, link(*, *, *, *=): (undefined)}}
 */
function mcqsMediaValidator () {
  return {
    require: 'ngModel',
    /**
     * this link function validates that media links are in the correct format
     * @param scope angular scope
     * @param elem HTML element
     * @param attrs attribute attached to the HTML element
     * @param ctrl this functions controller
     */
    link (scope, elem, attrs, ctrl) {
      // ctrl is this directives inherent controller.
      // we dont need to explicitly declare it as we only need access to its
      // validation and $parsers
      if (!ctrl) return
      // puts validator at the beginning of the $parsers Array so it will be executed first
      ctrl.$parsers.unshift(validator)

      /**
       * this checks if the submitted url is of a valid source and structure before sending requesting
       * to the APIs to see if the links content exists
       * @param value string value to be validated
       * @returns {*|string}
       */
      function validator (value) {
        const YOUTUBE = /^(?:https?:\/\/)(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(v\/|watch\?v=|watch\?.+&v=))((\w|-){11})($|(&))/g
        const VIMEO = /^(?:https?:\/\/)(?:vimeo\.com\/)((\w|-){9})$/g
        const SOUNDCLOUD = /^(?:https?:\/\/)(soundcloud.com\/)(.*\/)(.*)$/g

        if (value) value = value.trim()

        const isYouTube = YOUTUBE.test(value)
        const isVimeo = VIMEO.test(value)
        const isSoundcloud = SOUNDCLOUD.test(value)

        const validity = isYouTube || isVimeo || isSoundcloud
        ctrl.$setValidity('isMedia', validity)
        return value || ''
      }
    }
  }
}

export { mcqsMediaValidator }
