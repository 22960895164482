'use strict'
/**
 * mcqs-character-replace directive
 * @param $timeout
 * @returns {{restrict: string, require: string, link: link}}
 */
function mcqsCharacterReplace ($timeout) {
  'ngInject'
  return {
    restrict: 'A',
    require: '^ngModel',
    link: link
  }

  /**
   * this is used stop any special characters from being added to the question related title by replacing them with _
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attributes attached to the HTML
   * @param ngModel ng-model attached to the HTML
   */
  function link (scope, elem, attrs, ngModel) {
    let replaceWith

    activate()

    /**
     * replace special characters with _ by default
     */
    function activate () {
      replaceWith = attrs.replaceWith || '_'
      elem.on('drop keydown', replaceCharacters)
      elem.bind('$destroy', destroy)
    }

    /**
     * wait 25 milliseconds before triggering parsing
     */
    function replaceCharacters () { $timeout(parseString, 25) }

    /**
     * removes special characters and updates the ng-model to match
     */
    function parseString () {
      // http://stackoverflow.com/questions/4374822/javascript-regexp-remove-all-special-characters
      let parsedString = ngModel.$viewValue.replace(/[^\w]/gi, replaceWith)
      ngModel.$setViewValue(parsedString)
      ngModel.$render()
    }

    function destroy () { elem.off('drop keyup', replaceCharacters) }
  }
}

export { mcqsCharacterReplace }
