'use strict'

function WebinarService ($http, $mdDialog, $q, DOMAIN) {
  'ngInject'
  const service = {
    // attachWebinar,
    createNewWebinar,
    deleteLiveWebinar,
    // updateWebinar,
    checkAdmin,
    // getAllWebinars,
    // getAllWebinarTutors,
    isTutorLive,
    isLive: false,
    liveExamName: ''
  }

  return service

  function isTutorLive (userId, examId) {
    $http.get(`${DOMAIN}/api/webinar/live/${userId}/${examId}`)
      .then(success, failure)
  }

  // function getAllWebinarTutors () {
  //   return $http.get(DOMAIN + '/api/builder/webinar/tutors')
  //     .then(success, failure)
  // }

  function deleteLiveWebinar (userId) {
    return $http.delete(`${DOMAIN}/api/webinar/delete/${userId}`)
      .then(success, failure)
  }

  function createNewWebinar (webinar, tags, examId, title) {
    const newWebinar = angular.copy(webinar)
    const formattedTime = new Date(webinar.start_time).toISOString()
    newWebinar.start_time = formattedTime
    return $http.post(DOMAIN + '/api/webinar/new', {
      webinar: newWebinar,
      tags: tags,
      examId: examId,
      title: title})
      .then(success, (err) => {
        return Promise.reject(err)
      })
  }

  function checkAdmin (userId) {
    return $http.get(`${DOMAIN}/api/webinar/admin/${userId}`)
      .then(null, failure)
  }

  /**
   * success handler for Webinar Service
   * @param response object returned from BE
   * @returns {*|boolean} either th response.data object or the full response if error
   */
  function success (response) {
    service.isLive = response.data.live
    service.liveExamName = response.data.examName || ''
    return (response.status !== 200) ? response : response.data || true
  }

  /**
   * Error handler for Webinar Service
   * @param error object returned from server
   * @returns {*} object error object
   */
  function failure (error) { return error }
}

export { WebinarService }
