import { mcqsCommentActions } from './comment_actions'
import { mcqsCommentDelete, mcqsCommentDeleteDialogController } from './comment_delete'
import { mcqsCommentEdit, mcqsCommentEditDialogController } from './comment_edit'
import { commentListItemController } from './comment_list_item_controller'
import { mcqsCommentListItemReply } from './comment_list_item_reply'
import { mcqsCommentListItem } from './comment_list_item'
import { mcqsCommentPost } from './comment_post'
import { mcqsCommentReport, commentReportDialogController } from './comment_report'
import { mcqsCommentFilter } from './comment_filter'
import { mcqsCommentRating } from './comment_rating'
import { CommentService } from './comment_service'
import { commentViewController } from './comment_view_controller';

(() => {
  'use strict'
  angular
    .module('comment', [])
    .component('mcqsCommentActions', mcqsCommentActions)
    .component('mcqsCommentDelete', mcqsCommentDelete)
    .controller('mcqsCommentDeleteDialogController', mcqsCommentDeleteDialogController)
    .component('mcqsCommentEdit', mcqsCommentEdit)
    .controller('mcqsCommentEditDialogController', mcqsCommentEditDialogController)
    .controller('commentListItemController', commentListItemController)
    .component('mcqsCommentPost', mcqsCommentPost)
    .component('mcqsCommentReport', mcqsCommentReport)
    .controller('commentReportDialogController', commentReportDialogController)
    .factory('CommentService', CommentService)
    .component('mcqsCommentFilter', mcqsCommentFilter)
    .component('mcqsCommentListItemReply', mcqsCommentListItemReply)
    .component('mcqsCommentListItem', mcqsCommentListItem)
    .directive('mcqsCommentRating', mcqsCommentRating)
    .controller('commentViewController', commentViewController)
})()

export default 'comment'
