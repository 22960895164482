'use strict'
/**
 * mcqs-builder-question-list-item directive
 * @type {{templateUrl: string, bindings: {question: string}, controller: builderQuestionListItemController, controllerAs: string, transclude: boolean}}
 */
const mcqsBuilderQuestionListItem = {
  templateUrl: 'partials/templates/builder/question_list_item.html',
  bindings: {question: '='},
  controller: builderQuestionListItemController,
  controllerAs: 'vm',
  transclude: true
}

/**
 * builderQuestionListItemController handles the loading of a specific question from the question list.
 * @param $mdDialog
 * @param $state
 * @param BuilderHandler
 * @param BuilderService
 * @param TourBuilderHandler
 */
function builderQuestionListItemController ($mdDialog, $state, BuilderHandler, BuilderService, TourBuilderHandler) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.loading = false

  vm.open = open

  /**
   * opens the selected question in the question dialog
   * @param questionId int question to be opened
   */
  function open (questionId) {
    vm.loading = true
    BuilderService.getQuestion(questionId).then(success, failure)

    /**
     * success handler for BuilderService.getQuestion()
     * if corect data is returned clears current question and sets it to the correct question
     * else calls failure
     * @param data {{data: {items: {0: string, 1: string, 2: string, 3: string, 4: string}}, images: [{imageId: int, shortUrl: string, thumbnail: string}], links: [], question:{background: string, core: string, exam: {examId: int, examName: string}, explanation: string, options: {correct: [], incorrect: []}, optionsArray: [], question: string, questionId: int, relatedQuestions: int, title: int}, status: {dynamic: boolean, isDynamic: boolean, isPublished: boolean, isValid, boolean, messages: []}}}
     */
    function success (data) {
      if (!data || !data.hasOwnProperty('question')) return failure(data)
      vm.loading = false
      BuilderHandler.clearAll()
      BuilderHandler.setAll(data.question, data.data, data.images || [], data.links || [], data.chess || undefined, data.status)
      BuilderHandler.setLastSave()
      // if related data load dynamic builder, else normal builder
      Object.keys(data.data).length > 1 ? $state.go('root.auth.builder.dynamic') : $state.go('root.auth.builder.edit')
      $mdDialog.cancel()
      if (TourBuilderHandler.showBuilderTour()) TourBuilderHandler.builderStartTour()
    }

    /**
     * error handler for BuilderService.getQuestion()
     * @param error object returned from BuilderService.getQuestion()
     */
    function failure (error) {
      vm.loading = false
      console.error('Failed to open question ' + questionId, error)
    }
  }
}
export { mcqsBuilderQuestionListItem }
