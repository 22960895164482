'use strict'
/**
 * mcqs-chat-update directive
 * @type {{template: string, bindings: {update: string}, controller: chatUpdateController, controllerAs: string}}
 */
const mcqsChatUpdate = {
  template: `
        <div class="chat__update">
            <p class="md-caption" ng-bind="'– ' + vm.update.message + ' –'"></p>
            <hr class="chat__update-rule"/>
        </div>
    `,
  bindings: {update: '='},
  controller: chatUpdateController,
  controllerAs: 'vm'
}

/**
 * chatUpdateController is used to bind update to the above html.
 * It is used to inform the user when someone joins/leaves the chat
 */
function chatUpdateController () {
  'ngInject'
  /* jshint validthis:true */
  const vm = this // eslint-disable-line no-unused-vars
}

export { mcqsChatUpdate }
