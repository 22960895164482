'use strict'
/**
 * mcqs-builder-explanation directive
 * @type {{templateUrl: string, bindings: {core: string, explanation: string}, controller: builderExplanationController, controllerAs: string}}
 */
const mcqsBuilderExplanation = {
  templateUrl: 'partials/templates/builder/explanation.html',
  bindings: {
    core: '=',
    explanation: '='
  },
  controller: builderExplanationController,
  controllerAs: 'vm'
}

/**
 * builderExplanationController is a controller that handles the saving of text in
 * the core and explanation fields
 * @param BuilderService
 * @param BuilderHandler
 */
function builderExplanationController (BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.relatedData = []

  vm.getRelatedData = getRelatedData
  vm.update = update

  /**
   * gets the related data from BiulderHandler and sets it in the DOM
   */
  function getRelatedData () { vm.relatedData = BuilderHandler.getRelatedData() }

  /**
   * saves and verifies the question when an explanation input area losses focus
   */
  function update () {
    BuilderHandler.setExplanation(vm.core, vm.explanation).then(() => {
      BuilderHandler.verifyEntireQuestion((err) => {
        if (err) return failure(err)
        BuilderService.saveQuestion().then(success, failure)
      })
    })

    /**
     * success handler for BuilderHandler.verifyEntireQuestion() and
     * BuilderService.saveQuestion()
     * @param data object returned from either BuilderHandler.verifyEntireQuestion()
     * and BuilderService.saveQuestion()
     */
    function success (data) { if (!data || data.status) failure(data) }

    /**
     * error handler for BuilderHandler.verifyEntireQuestion() and
     * BuilderService.saveQuestion()
     * @param error object returned from either BuilderHandler.verifyEntireQuestion()
     * and BuilderService.saveQuestion()
     */
    function failure (error) { console.error('Question did not save:', error) }
  }
}

export { mcqsBuilderExplanation }
