'use strict'

/**
 * controller used to bind the question to the DOM and initialise tour elements
 * @param QuestionService
 * @param TourQuestionHandler
 */
function questionAnsweredController (QuestionService, TourQuestionHandler) {
  'ngInject'
  const vm = this
  const mediator = TourQuestionHandler.mediator

  // set default selection to explanation after question is answered
  vm.selectedItem = 'Explanation'

  vm.qService = QuestionService

  activate()

  /**
   * initialisation function that checks for an active tour and creates
   * triggers if present
   */
  function activate () {
    // if tour is active
    if (!mediator.bindings['change-nav-item']) {
      // attach event listener to change nav item to comments
      mediator.on('change-nav-item', () => {
        vm.selectedItem = 'Comments'
        TourQuestionHandler.checkForElement('.comment__head')
      })
    }
  }
}

export { questionAnsweredController }
