'use strict'
/* global Hammer */
/**
 * mcqs-image-magnifier directive
 * @param ImageHandler
 * @returns {{restrict: string, link: link}}
 */
function mcqsImageMagnifier (ImageHandler) {
  'ngInject'
  return {
    restrict: 'A',
    link: link
  }

  /**
   * link function that handles the zooming of images in the question
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attributes attached to the HTML element
   */
  function link (scope, elem, attrs) {
    // A small hammerjs manager is used to get around the problem of perfect scroll bar
    // blocking touch input from propagating down to the image element
    const touchManager = new Hammer.Manager(elem[0])

    touchManager.add(new Hammer.Tap({ event: 'singletap' }))

    // if the element that is touched or clicked is an image, open the images in the question in a modal
    touchManager.on('singletap', (ev) => {
      if (ev.target.tagName === 'IMG') {
        let startIdx = null
        const images = Array.from(elem.find('img'))
        const target = ev.target
        const src = target.src ? target.src : undefined
        if (images.length < 2 && src) return ImageHandler.openDialog(target[0], src)
        // The follow logic only gets executed if there's more than one image.
        let imagesArray = []
        images.map((img, i) => {
          if (src === img.src) startIdx = i
          imagesArray.push(img.src)
        })
        ImageHandler.openDialog(target[0], null, imagesArray, startIdx)
      }
    })
  }
}

export { mcqsImageMagnifier }
