'use strict'
/**
 * mcqs-builder-delete-node-item directive
 * @type {{template: string, bindings: {nodeIdx: string, itemIdx: string}, controller: deleteNodeItemController, controllerAs: string}}
 */
const mcqsBuilderDeleteNodeItem = {
  template: `
    <span
        class="builder__delete-item"
        ng-click="vm.deleteNodeItem()"
    >
        <i class="fa fa-times-circle-o"></i>
        <md-tooltip md-direction="bottom">Delete item</md-tooltip>
    </span>
    `,
  bindings: {
    nodeIdx: '@',
    itemIdx: '@'
  },
  controller: deleteNodeItemController,
  controllerAs: 'vm'
}

/**
 * deleteNodeItemController is used to expose  BuilderHandler.deleteNodeItem to the html
 * and to handle the outcome of this call
 * @param $timeout
 * @param BuilderHandler
 * @param BuilderService
 * @param TourBuilderHandler
 */
function deleteNodeItemController ($timeout, BuilderHandler, BuilderService, TourBuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.deleteNodeItem = deleteNodeItem

  /**
   * deletes a node item from the question
   */
  function deleteNodeItem () {
    BuilderHandler.deleteNodeItem(vm.nodeIdx, vm.itemIdx)
    // verifies that the question is still valid after deleting node item
    BuilderHandler.verifyEntireQuestion(() => {
      BuilderService.saveQuestion().then(success, failure)
      let { activeTour } = TourBuilderHandler.shepherd
      // if tour is active when this is manually fired, skip to appropriate step
      if (activeTour && activeTour.currentStep.id === 17) $timeout(() => TourBuilderHandler.builderTour.next())
    })

    /**
     * success handler for BuilderService.saveQuestion()
     * @param data object returned from BuilderService.saveQuestion()
     */
    function success (data) { if (!data || data.status) failure(data) }

    /**
     * error handler for BuilderService.saveQuestion()
     * @param error object returned from BuilderService.saveQuestion()
     */
    function failure (error) { console.error('Question did not save:', error) }
  }
}

export { mcqsBuilderDeleteNodeItem }
