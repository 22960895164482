import { navbarController } from './navbar_controller'
import { NavbarService } from './navbar_service'

(() => {
  'use strict'
  angular
    .module('navbar', [])
    .controller('navbarController', navbarController)
    .factory('NavbarService', NavbarService)
})()

export default 'navbar'
