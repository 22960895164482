'use strict'

/**
 * The accountViewController is used to gran the current users account when
 * opening the account page
 * @param AccountService
 */
function accountViewController (AccountService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.profile = {}
  vm.loaded = false

  activate()

  /**
   * Initial function that runs when accountViewController is loaded.
   * sets the profile to the values returned from AccountService.getAccount on success
   * and throws an error to the console on failure
   */
  function activate () {
    AccountService.getAccount().then(
      data => {
        if (!data || data.status) return failure(data)
        let { firstName, surname, country, dateOfBirth, username, email } = data
        vm.profile = { firstName, surname, country, dateOfBirth, username, email }
        vm.loaded = true
      }, failure)
    function failure (error) {
      console.error('Failed to load account:', error)
      vm.loaded = true
    }
  }
}

export { accountViewController }
