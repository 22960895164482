'use strict'
import { flashcardViewController } from './flashcard_view_controller'

function routeConfig ($stateProvider) {
  'ngInject'
  $stateProvider
    .state('root.auth.flashcard', {
      url: '/flashcard',
      views: {
        'main@': {
          templateUrl: 'partials/views/flashcard.html',
          controller: flashcardViewController,
          controllerAs: 'vm'
        }
      }
    })
}

export { routeConfig }
