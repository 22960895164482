'use strict'
/* global Cookiebot */
/**
 * Stores cookiebot consent and updates these values on accept
 * @param $rootScope
 * @returns {{cookieConsent: boolean, hasResponse: boolean}}
 * @constructor
 */
function CookieConsentService ($rootScope) {
  'ngInject'
  const service = {
    cookieConsent: Cookiebot.consent.statistics && Cookiebot.consent.marketing,
    hasResponse: Cookiebot.hasResponse
  }

  Cookiebot.onaccept = () => {
    service.cookieConsent = Cookiebot.consent.statistics && Cookiebot.consent.marketing
    service.hasResponse = Cookiebot.hasResponse
    // This logic happens outside angular so $apply is needed to make angular aware of the changes
    $rootScope.$apply()
    $rootScope.$emit('cookiesAccepted')
  }

  Cookiebot.ondecline = (e) => {
    service.cookieConsent = Cookiebot.consent.statistics && Cookiebot.consent.marketing
    // This logic happens outside angular so $apply is needed to make angular aware of the changes
    $rootScope.$apply()
  }

  return service
}

export { CookieConsentService }
