'use strict'

/**
 * service that handles question requests to the BE
 * @param $http
 * @param DOMAIN the value of process.env.APP_ENV.trim()
 * @returns {{answerQuestion: (function(*=, *=): *), getQuestion: (function(): *), question: {}, rateQuestion: (function(*=, *=, *=): *), reportQuestion: (function(*=, *=): *), setBlankQuestion: setBlankQuestion, setFailedQuestion: setFailedQuestion, updateQuestion: updateQuestion}}
 * @constructor
 */
function QuestionService ($http, DOMAIN) {
  'ngInject'
  const service = {
    answerQuestion: answerQuestion,
    getQuestion: getQuestion,
    question: {},
    rateQuestion: rateQuestion,
    reportQuestion: reportQuestion,
    setBlankQuestion: setBlankQuestion,
    setFailedQuestion: setFailedQuestion,
    updateQuestion: updateQuestion
  }

  return service

  /**
   * posts the selected answer to the BE
   * @param attemptId int attempt id
   * @param selectedAnswer int index of selected answer
   * @returns {*}
   */
  function answerQuestion (attemptId, selectedAnswer) {
    return $http.post(DOMAIN + '/api/question/answer', {
      'attemptId': attemptId,
      'selectedAnswer': selectedAnswer
    }).then(success, failure)
  }

  /**
   * gets the next question from the BE
   * @returns {*}
   */
  function getQuestion () {
    return $http.get(DOMAIN + '/api/question/enhanced').then(success, failure)
  }

  /**
   * updates the current question in the service to the data passed in
   * @param data object question data
   */
  function updateQuestion (data) { service.question = data }

  /**
   * posts the users question rating to the bE
   * @param upOrDown int 1 for like 2 for dislike
   * @param likes int total likes
   * @param dislikes int total dislikes
   * @returns {*}
   */
  function rateQuestion (upOrDown, likes, dislikes) {
    return $http.post(DOMAIN + '/api/question/rate', {
      'attemptId': service.question.attemptId,
      'upOrDown': upOrDown,
      'likesDislikes': {
        'likes': likes,
        'dislikes': dislikes
      }
    }).then(success, failure)
  }

  /**
   * post a question report to the BE
   * @param type int type of report from array
   * @param message string detailed report. Only available for type 6
   * @returns {*}
   */
  function reportQuestion (type, message) {
    let attemptId = service.question.attemptId
    return $http.post(DOMAIN + '/api/question/report', {
      'attemptId': attemptId,
      'reportType': type,
      'reportMessage': message
    }).then(success, failure)
  }

  /**
   * success handler for BE requests
   * @param response object returned from BE
   * @returns {*|{}}
   */
  function success (response) { return (response.status !== 200) ? response : response.data || {} }

  /**
   * error handler for BE requests
   * @param error object returned from BE
   * @returns {*}
   */
  function failure (error) { return error }

  /**
   * default message that's displayed when no suitable question is returned from the server
   */
  function setFailedQuestion () {
    service.question = {
      background: 'No question was returned from the server. There might not be any questions available at your level in this exam.',
      question: 'Please change your exam to get questions at your level or click the magic wand above to write your own questions (only available on desktop).',
      options: [],
      correctAnswer: null,
      core: '',
      explanation: '',
      author: {},
      questionId: null,
      attemptId: null
    }
  }

  /**
   * sets the current question to a blank question
   */
  function setBlankQuestion () {
    service.setFailedQuestion()
    service.background = ''
    service.question.background = ''
  }
}

export { QuestionService }
