'use strict'
/**
 * mcqs-comment-filter directive
 * @type {{templateUrl: string, bindings: {attemptId: string}, controller: mcqsCommentFilterController, controllerAs: string}}
 */
const mcqsCommentFilter = {
  templateUrl: 'partials/templates/comment/comment_filter.html',
  bindings: { attemptId: '<' },
  controller: mcqsCommentFilterController,
  controllerAs: 'vm'
}

/**
 * controller to mange the comment filters and apply them
 * @param CommentService
 * @param TourQuestionHandler
 */
function mcqsCommentFilterController (CommentService, TourQuestionHandler) {
  'ngInject'
  /* validthis:true */
  const vm = this
  const mediator = TourQuestionHandler.mediator

  vm.updateComments = updateComments

  vm.filters = [
    { name: 'Newest', value: 'latest', order: 'desc' },
    { name: 'Oldest', value: 'latest', order: 'asc' },
    { name: 'Top Rated', value: 'rated', order: 'desc' },
    { name: 'Lowest Rated', value: 'rated', order: 'asc' }
  ]

  vm.filter = vm.filters[0]
  // if tour is active attach listeners four tour steps
  if (!mediator.bindings['filter-questions']) mediator.on('filter-questions', updateComments)
  if (!mediator.bindings['remove-default-comments']) mediator.on('remove-default-comments', updateComments)

  /**
   * requests the comments filtered by the selected filters
   */
  function updateComments () {
    CommentService.getComments({attemptId: vm.attemptId}, vm.filter.value, vm.filter.order, 0).then(
      data => {},
      error => console.error('Error filtering comments:', error)
    )
  }
}

export { mcqsCommentFilter }
