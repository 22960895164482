'use strict'

/**
 * builderWarningSaveDialogController handles the error dialog shown when a user tries to leave the
 * builder. It binds the errors to the html
 * @param BuilderHandler
 * @param $mdDialog
 */
function builderWarningSaveDialogController (BuilderHandler, $mdDialog) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  vm.valadationErrs = BuilderHandler.status.urgentErrorMessages
  vm.cancel = $mdDialog.cancel
  vm.ok = $mdDialog.hide
}

export { builderWarningSaveDialogController }
