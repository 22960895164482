'use strict'
/**
 * mcqs-lazy-load directive
 * @param $timeout
 * @param $document
 * @param $window
 * @returns {{link: link, scope: {limit: string, collectionLength: string, isLoading: string}}}
 */
function mcqsLazyLoad ($timeout, $document, $window) {
  'ngInject'
  return {
    link,
    scope: {
      limit: '=',
      collectionLength: '=',
      isLoading: '='
    }
  }

  /**
   * this allows for the lazy rendering of list items.
   * By deferring the the rendering of list items the user will experience
   * little to no lag in displaying large list items
   * @param scope
   * @param elem
   * @param attrs
   */
  function link (scope, elem, attrs) {
    let target, increment

    activate()

    /**
     * initialisation function that sets the increment that should be used,
     * the target of the lazy load and binds the bindToScroll function to the bind event
     */
    function activate () {
      increment = Number(attrs.increment)
      // decides whether to bind to body or bind to specific element
      target = attrs.bindToApp === 'true' ? angular.element($document[0].body) : elem

      scope.isLoading = false
      // sets the limit of items to load at a time in the lazy list
      scope.limit = Number(attrs.setLimit)

      target.bind('scroll', bindToScroll)

      scope.$on('$destroy', () => target.unbind('scroll', bindToScroll))
    }

    /**
     * this function fires on each scroll event
     * it checks how much of the list is loaded and increases the increment triggering more loads
     */
    function bindToScroll () {
      // if total length is less than limit, no lazy loading needed
      if (scope.collectionLength <= scope.limit) {
        if (scope.isLoading === false) return
        scope.isLoading = false
        scope.$apply()
      }
      // This line checks if the current position of the scrollbar is >= to
      // the totalHeight of the element - visible height of the element * 0.7 (70% of the height)
      const { scrollTop, scrollHeight, offsetHeight } = target[0]
      if ((scrollTop >= (scrollHeight - offsetHeight) * 0.7) && scope.isLoading === false) {
        scope.isLoading = true
        scope.$apply()
        $timeout(() => {
          scope.limit += increment
          scope.isLoading = false
        }, 500)
      }
    }
  }
}

export { mcqsLazyLoad }
