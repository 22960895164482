'use strict'
/**
 * builderPreviewController handles displaying the question preview
 * @param BuilderService
 * @param BuilderHandler
 * @param $rootScope
 */
function builderPreviewController (BuilderService, BuilderHandler, $rootScope) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  // displays placeholder text until preview is ready.
  vm.isQuestionLoaded = false
  vm.isQuestionLoadError = false

  vm.handler = BuilderHandler

  vm.getPreview = getPreview

  vm.getPreview()

  /**
   * loads the preview question
   */
  function getPreview () {
    BuilderService.getPreviewQuestion().then(success, failure)
    $rootScope.$emit('chessRefreshed')
  }

  /**
   * success handler for BuilderService.getPreviewQuestion()
   * sets the preview in the handler
   * @param data {{background: string, core: string, correctAnswer: int, explanation: string, optionsArray: [], question: string}}
   */
  function success (data) {
    // Clears placeholder text on success
    vm.isQuestionLoaded = true
    vm.isQuestionLoadError = false
    if (!data || data.status) return failure(data)
    BuilderHandler.setPreview(data)
  }

  /**
   * error handler for BuilderService.getPreviewQuestion()
   * @param error object returned from BuilderService.getPreviewQuestion()
   */
  function failure (error) {
    // sets error text on failure
    vm.isQuestionLoadError = true
    vm.isQuestionLoaded = false
    console.error('Failed to load question preview: ', error)
  }
}

export { builderPreviewController }
