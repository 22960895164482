'use strict'
/**
 * Service to handle the progress bar which is displayed below the navbar
 * during certain actions, most commonly while a question is loading or
 * waiting for a response
 * @param $timeout
 * @returns {{progress: boolean, setProgress: setProgress}}
 * @constructor
 */
function GlobalProgressService ($timeout) {
  'ngInject'
  let inProgress = false

  const service = {
    progress: false,
    setProgress: setProgress
  }

  return service

  /**
   * sets the progress of the loading bar
   * @param bool boolean true whle active false while inactive
   */
  function setProgress (bool) {
    if (bool === true && !inProgress) {
      setBool(true, true)
    } else if (bool === false && inProgress) {
      // timeout is used to allow progress bar to reach 100%
      $timeout(setBool, 1000)
    }
  }

  /**
   * used to set the progress boolean in this service to stop multiple
   * bars being activated
   * @param inProg
   * @param prog
   */
  function setBool (inProg, prog) {
    inProgress = inProg || false
    service.progress = prog || false
  }
}

export { GlobalProgressService }
