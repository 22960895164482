'use strict'
/**
 * mcqs-overflow-scroll directive
 * @returns {{restrict: string, link: link}}
 */
function mcqsOverflowScroll () {
  'ngInject'
  return {
    restrict: 'A',
    link: link
  }

  /**
   * this is used to scroll long question titles sideways in the builders question
   * list when the user mouses over the question list item
   * @param scope angular scope
   * @param elem HTML element
   */
  function link (scope, elem) {
    let scrollElement

    activate()

    /**
     * initialisation function that attaches triggers
     */
    function activate () {
      elem.on('mouseenter', getScrollElement)
      elem.on('$destroy', destroy)
    }

    /**
     * grabs the scroll element and adds a width scroller if required
     */
    function getScrollElement () {
      scrollElement = angular.element(elem[0].querySelector('.mcqs-scroll'))
      let containerWidth = elem[0].offsetWidth
      let scrollWidth = scrollElement[0].offsetWidth
      if (scrollWidth > containerWidth) {
        elem.on('mouseleave', scrollBack)
        // There is a cushion of 125px
        let scrollDistance = (scrollWidth - containerWidth) + 125
        scrollElement[0].style.left = `-${scrollDistance}px`
      }
    }

    /**
     * scrolls back to starting position
     */
    function scrollBack () { scrollElement[0].style.left = '0' }

    /**
     * destroys bindings
     */
    function destroy () {
      elem.off('mouseenter', getScrollElement)
      elem.off('mouseleave', scrollBack)
    }
  }
}

export { mcqsOverflowScroll }
