import { ImageHandler } from './image_handler'
import { magnifiedImageController } from './image_magnifier_controller'
import { mcqsImageMagnifier } from './image_magnifier'
import { mcqsImagePreview } from './image_preview';

(() => {
  'use strict'
  angular
    .module('image', [])
    .factory('ImageHandler', ImageHandler)
    .controller('magnifiedImageController', magnifiedImageController)
    .directive('mcqsImageMagnifier', mcqsImageMagnifier)
    .component('mcqsImagePreview', mcqsImagePreview)
})()

export default 'image'
