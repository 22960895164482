'use strict'
/**
 * mcqs-builder-options directive
 * @type {{templateUrl: string, bindings: {options: string}, controller: builderOptionsController, controllerAs: string}}
 */
const mcqsBuilderOptions = {
  templateUrl: 'partials/templates/builder/options.html',
  bindings: { options: '=' },
  controller: builderOptionsController,
  controllerAs: 'vm'
}

/**
 * builderOptionsController handles updating the question options in the question object
 * @param BuilderService
 * @param BuilderHandler
 */
function builderOptionsController (BuilderService, BuilderHandler) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  // used to set css in the html
  vm.isCorrect = BuilderHandler.isOptionCorrect
  // binds Object.keys method to the DOM for validation
  vm.keys = Object.keys

  vm.toggle = toggle
  vm.update = update
  vm.getRelatedData = getRelatedData

  /**
   * verifies and saves question on option change
   */
  function update () {
    BuilderHandler.data[0] = vm.options
    BuilderHandler.verifyEntireQuestion((err) => {
      if (err) return failure(err)
      BuilderService.saveQuestion().then(success, failure)
    })

    /**
     * success handler for BuilderService.saveQuestion()
     * calls failure if a false positive success
     * @param data
     */
    function success (data) { if (!data || data.status) failure(data) }

    /**
     * error handler fro BuilderService.saveQuestion()
     * writes the error to the console
     * @param error
     */
    function failure (error) { console.error('Question did not save:', error) }
  }

  /**
   * toggles between correct and incorrect option
   * @param parent int index of options node. Always 0
   * @param child int index of child in parent node
   */
  function toggle (parent, child) {
    BuilderHandler.toggleOption(parent, child)
    vm.update()
  }

  /**
   * sets the related data to the values in the handler
   */
  function getRelatedData () { vm.relatedData = BuilderHandler.getRelatedData() }
}

export { mcqsBuilderOptions }
