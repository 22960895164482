import { default as ngFileUpload } from 'ng-file-upload'

import { mcqsBuilderBackground } from './builder_background'
import { mcqsBuilderChangeExam, builderExamChangeDialogController } from './builder_change_exam'
import { mcqsBuilderDelete, builderDeleteDialogController } from './builder_delete'
import { mcqsBuilderExplanation } from './builder_explanation'
import { BuilderHandler } from './builder_handler'
import { mcqsHeaderActions } from './builder_header_actions'
import { mcqsBuilderImages } from './builder_images'
import { mcqsBuilderMediaLinks } from './builder_media_links'
import { mcqsBuilderMathjaxEditor } from './builder_mathjax_editor'
import { mcqsBuilderMarkdownHelp } from './builder_markdown_help'
import { mcqsBuilderOptions } from './builder_options'
import { builderPreviewController } from './builder_preview_view_controller'
import { mcqsBuilderPublishButton } from './builder_publish_button'
import { builderQuestionDialogController } from './builder_question_dialog_controller'
import { mcqsBuilderQuestionListItemActions } from './builder_question_list_item_actions'
import { mcqsBuilderQuestionListItem } from './builder_question_list_item'
import { mcqsBuilderQuestionList } from './builder_question_list'
import { mcqsBuilderQuestionSpeedDial } from './builder_question_speed_dial'
import { builderRouteConfig } from './builder_routes'
import { mcqsBuilderSaveButton } from './builder_save_button'
import { mcqsBuilderScreenMessage } from './builder_screen_message'
import { BuilderService } from './builder_service'
import { mcqsBuilderStatus } from './builder_status'
import { mcqsBuilderTags } from './builder_tags'
import { builderTagsController } from './builder_tags_controller'
import { mcqsBuilderTitle } from './builder_title'
import { builderViewController } from './builder_view_controller'
import { builderWarningSaveDialogController } from './builder_warning_save_dialog_controller'
// import { mcqsBuilderWebinar } from './builder_webinar'
// import { builderWebinarController } from './builder_webinar_controller'
// import { builderWebinarDialogController } from './builder-webinar-dialog-controller'

import { mcqsBuilderAddNodeItem } from './dynamic/builder_add_node_item'
import { mcqsBuilderCloneQuestion } from './dynamic/builder_clone'
import { mcqsBuilderDeleteNodeItem } from './dynamic/builder_delete_node_item'
import { mcqsBuilderAddRelatedData, addRelatedDataDialogController } from './dynamic/builder_related_data_add'
import { mcqsBuilderDeleteRelatedData } from './dynamic/builder_related_data_delete'
import { mcqsBuilderRelatedData } from './dynamic/builder_related_data'
import { mcqsBuilderRelatedQuestions, builderRelatedQuestionsDialogController } from './dynamic/builder_related_questions'
import {mcqsBuilderChessOptions} from './builder-chess-options'

(() => {
  'use strict'
  angular
    .module('builder', [
      ngFileUpload
    ])
    .component('mcqsBuilderBackground', mcqsBuilderBackground)
    .component('mcqsBuilderChangeExam', mcqsBuilderChangeExam)
    .controller('builderExamChangeDialogController', builderExamChangeDialogController)
    .component('mcqsBuilderDelete', mcqsBuilderDelete)
    .controller('builderDeleteDialogController', builderDeleteDialogController)
    .component('mcqsBuilderChessOptions', mcqsBuilderChessOptions)
    .component('mcqsBuilderExplanation', mcqsBuilderExplanation)
    .factory('BuilderHandler', BuilderHandler)
    .component('mcqsHeaderActions', mcqsHeaderActions)
    .component('mcqsBuilderImages', mcqsBuilderImages)
    .component('mcqsBuilderMarkdownHelp', mcqsBuilderMarkdownHelp)
    .component('mcqsBuilderMathjaxEditor', mcqsBuilderMathjaxEditor)
    .component('mcqsBuilderMediaLinks', mcqsBuilderMediaLinks)
    .component('mcqsBuilderOptions', mcqsBuilderOptions)
    .controller('builderPreviewController', builderPreviewController)
    .component('mcqsBuilderPublishButton', mcqsBuilderPublishButton)
    .controller('builderQuestionDialogController', builderQuestionDialogController)
    .component('mcqsBuilderQuestionListItemActions', mcqsBuilderQuestionListItemActions)
    .component('mcqsBuilderQuestionListItem', mcqsBuilderQuestionListItem)
    .component('mcqsBuilderQuestionList', mcqsBuilderQuestionList)
    .component('mcqsBuilderQuestionSpeedDial', mcqsBuilderQuestionSpeedDial)
    .config(builderRouteConfig)
    .component('mcqsBuilderSaveButton', mcqsBuilderSaveButton)
    .directive('mcqsBuilderScreenMessage', mcqsBuilderScreenMessage)
    .factory('BuilderService', BuilderService)
    .component('mcqsBuilderStatus', mcqsBuilderStatus)
    .directive('mcqsBuilderTags', mcqsBuilderTags)
    .controller('builderTagsController', builderTagsController)
    .component('mcqsBuilderTitle', mcqsBuilderTitle)
    // dynamic components, directives, controllers
    .component('mcqsBuilderAddNodeItem', mcqsBuilderAddNodeItem)
    .component('mcqsBuilderCloneQuestion', mcqsBuilderCloneQuestion)
    .component('mcqsBuilderDeleteNodeItem', mcqsBuilderDeleteNodeItem)
    .component('mcqsBuilderAddRelatedData', mcqsBuilderAddRelatedData)
    .controller('addRelatedDataDialogController', addRelatedDataDialogController)
    .component('mcqsBuilderDeleteRelatedData', mcqsBuilderDeleteRelatedData)
    .component('mcqsBuilderRelatedData', mcqsBuilderRelatedData)
    .component('mcqsBuilderRelatedQuestions', mcqsBuilderRelatedQuestions)
    .controller('builderRelatedQuestionsDialogController', builderRelatedQuestionsDialogController)
    .controller('builderViewController', builderViewController)
    .controller('builderWarningSaveDialogController', builderWarningSaveDialogController)
    // .component('mcqsBuilderWebinar', mcqsBuilderWebinar)
    // .controller('builderWebinarController', builderWebinarController)
    // .controller('builderWebinarDialogController', builderWebinarDialogController)
})()

export default 'builder'
