'use strict'

/**
 * chatConfig sets up a listener that will close the chat when heading away from the question.
 * @param $rootScope
 * @param ChatService
 */
function chatConfig ($rootScope, ChatService) {
  'ngInject'
  // listener to close chat service when leaving question state
  $rootScope.$on('$stateChangeStart', (e, toState, toParams, fromState) => {
    let fromQuestion = fromState.name.indexOf('root.auth.question') >= 0
    let notToQuestion = toState.name.indexOf('root.auth.question') === -1
    if (fromQuestion && notToQuestion) ChatService.close()
  })
}

export { chatConfig }
