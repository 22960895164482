'use strict'
import { accountViewController } from './account_view_controller'

/**
 * This file controls the routes for the account module.
 * @param $stateProvider
 */
function routeConfig ($stateProvider) {
  'ngInject'
  $stateProvider
    .state('root.auth.account', {
      url: '/account',
      views: {
        'main@': {
          templateUrl: 'partials/views/account.html',
          controller: accountViewController,
          controllerAs: 'vm'
        }
      }
    })
}

export { routeConfig }
