'use strict'

/**
 * overall controller for the question view
 * @param $timeout
 * @param $rootScope
 * @param ExamService
 * @param QuestionService
 * @param TourQuestionHandler
 * @param AuthenticationService
 */
function questionViewController ($timeout, $rootScope, ExamService, QuestionService,
                                 TourQuestionHandler, AuthenticationService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  vm.disableNext = false
  /* Bind the controller to the service so that when the question updates
   in the service, the bound object updates also, and it's then passed
   down the chain to the view and to the components in that view. */
  vm.qService = QuestionService

  if (AuthenticationService.hasSession() !== undefined) { activate() }

  /**
   * initialisation function that gets a question when the view loads
   */
  function activate () {
    // Check for current exam
    let exam = ExamService.getCurrentExam()
    if (!exam || (typeof exam) === 'undefined' || exam === 0) {
      ExamService.showExamSelectDialog(true, null, $rootScope)
    } else {
      // Reset the question whenever the view loads to stop old questions showing
      vm.qService.setBlankQuestion()
      vm.qService.getQuestion().then(data => {
        if (!data || data.status) { return failure(data) }
        QuestionService.updateQuestion(data)
        if (TourQuestionHandler.showQuestionTour()) {
          AuthenticationService.checkUserPasswordExists().then((res) => {
            if (!res.password) {
              let tourReady = $rootScope.$on('tourReady', () => {
                $timeout(() => { TourQuestionHandler.questionStartTour() })
                tourReady()
              })
            } else {
              $timeout(() => { TourQuestionHandler.questionStartTour() })
            }
          })
        }
      }, failure)
    }

    /**
     * error handler for QuestionService.getQuestion()
     * @param error object returned from QuestionService.getQuestion()
     */
    function failure (error) {
      console.error('Loading question failed:', error)
      vm.qService.setFailedQuestion()
    }
  }
}

export { questionViewController }
