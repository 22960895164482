'use strict'
import { diff } from 'deep-diff'
import { default as async } from 'async'

/**
 * BuilderHandler is a crucial component of the builder. It is responsible for
 * nearly every modification that will happen to the question. It sets each part of the question,
 * can clear the question, sets the preview, verifies the entire question and fires off the builder
 * toast errors, among other functions
 * @param $timeout
 * @param $mdToast
 * @param $mdDialog
 * @returns {{data: {}, emptyQuestion: {background: string, question: string, core: string, exam: {}, explanation: string, options: {correct: {}, incorrect: {}}, optionsArray: Array, questionId: undefined, relatedData: {}}, question: {}, status: {}, images: Array, preview: {}, addNodeItem: addNodeItem, addImage: addImage, addRelatedData: addRelatedData, clearAll: clearAll, clearLastSave: clearLastSave, deleteNodeItem: deleteNodeItem, deleteRelatedData: deleteRelatedData, getRelatedData: (function(): Array), isDifferent: (function(): boolean), isQuestionValid: isQuestionValid, isOptionCorrect: (function(*, *=): boolean), isRelatedData: (function(*, *=): boolean), removeImage: removeImage, setAll: setAll, setBackground: (function(*=, *=): Promise<any>), setExplanation: (function(*=, *=): Promise<any>), setExam: setExam, setLastSave: setLastSave, setPreview: setPreview, setStatus: setStatus, showWarning: (function(): *), toggleOption: toggleOption, toggleRelatedData: toggleRelatedData, verifyEntireQuestion: verifyEntireQuestion, showToast: showToast}}
 * @constructor
 */
function BuilderHandler ($timeout, $mdToast, $mdDialog) {
  'ngInject'
  // used to check that media tags are in the correct format
  const regExMarkdownImg = /(?!!\[SOUNDCLOUD])(!\[.*](\(https:\/\/|\(http:\/\/|\(www\.))(.*\))/
  const partsToCheck = ['core', 'explanation', 'question', 'background', 'title']
  let lastQuestion, lastData, optionsValid, questionsValid, dataValid

  const service = {
    data: {},
    emptyQuestion: {
      background: '',
      question: '',
      core: '',
      exam: {},
      explanation: '',
      options: {'correct': {}, 'incorrect': {}},
      optionsArray: [],
      questionId: undefined,
      relatedData: {}
    },
    // webinars: [],
    question: {},
    selectedTags: [],
    status: {},
    images: [],
    preview: {},
    chess: {
      fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR',
      orientation: 'white'
    },
    disableAudioUpload: false,
    deleteInProgress: false,
    addNodeItem,
    addImage,
    addRelatedData,
    clearAll,
    clearLastSave,
    deleteNodeItem,
    deleteRelatedData,
    getRelatedData,
    isDifferent,
    isQuestionValid,
    isOptionCorrect,
    isRelatedData,
    removeImage,
    setAll,
    setBackground,
    setExplanation,
    setExam,
    setLastSave,
    setPreview,
    setSelectedTags,
    setStatus,
    showWarning,
    toggleOption,
    toggleRelatedData,
    verifyEntireQuestion,
    showToast
  }

  return service

  /**
   * adds an image to images array in the service
   * @param image string path to image
   */
  function addImage (image) { service.images.push(image) }

  /**
   * removes an image from the images array in the service
   * @param idx int location of image in array
   */
  function removeImage (idx) { service.images.splice(idx, 1) }

  /**
   * clears all data and resets the service
   */
  function clearAll () {
    service.question = angular.copy(service.emptyQuestion)
    service.data = {
      '0': {
        title: '',
        items: {'0': '', '1': '', '2': '', '3': '', '4': ''}
      }
    }
    service.chess = {}
    service.images = []
    service.status = {}
    service.links = []
  }

  function setSelectedTags (tags) { service.selectedTags = tags }

  /**
   * This function checks whether a question should be saved. no difference === no save
   * @returns {boolean}
   */
  function isDifferent () {
    // uses deep-diff to deeply compare the two objects
    let qDiff = diff(service.question, lastQuestion)
    let dDiff = diff(service.data, lastData)
    return ((qDiff !== undefined || dDiff !== undefined) || (!lastQuestion && !lastData))
  }

  /**
   * checks whether a question is valid by checking the length of urgent error messages
   * @returns {boolean}
   */
  function isQuestionValid () {
    if (service.status.urgentErrorMessages) return (!service.status.urgentErrorMessages.length > 0)
    return true
  }

  /**
   * sets the values of the service to the provided values
   * @param question {{background: string, core: string, exam: {examId: int, examName: string}, options: {correct: [], incorrect: []}, optionsArray: [], question: string, questionId: int, relatedQuestions: int, title: string}}
   * @param data {{0: items: {0: string, 1: string, 2: string, 3: string, 4: string}, title: string}}
   * @param images [{imageId: int, shortUrl: string, thumbnail: string}]
   * @param links [{$$hashKey: string, id: int, img: string, url: string}]
   * @param chess {fen: string, orientation: string}
   * @param status {{isDynamic: boolean, isPublished: boolean, isValid: boolean, messages: []}}
   */
  function setAll (question, data, images, links, chess, status) {
    angular.merge(service.question, question)
    angular.merge(service.data, data)
    service.images = images || []
    service.status = status || {}
    service.links = links || []
    service.chess = chess || {
      fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR',
      orientation: 'white'
    }
  }

  /**
   * sets only the background and question fields in the service
   * @param background string
   * @param question string
   * @returns {Promise<any>} resolves once fields have been set and digest cycle triggered
   */
  function setBackground (background = '', question = '') {
    return new Promise(resolve => {
      service.question.background = background
      service.question.question = question
      // timeout is used to here to trigger digest cycle in Angular
      $timeout(() => resolve(true))
    })
  }

  /**
   * sets only the explanation and core fields in the service
   * @param core string
   * @param explanation string
   * @returns {Promise<any>} resolves once fields have been set and digest cycle triggered
   */
  function setExplanation (core = '', explanation = '') {
    return new Promise(resolve => {
      service.question.core = core
      service.question.explanation = explanation
      // timeout is used to here to trigger digest cycle in Angular
      $timeout(() => resolve(true))
    })
  }

  /**
   * sets the current exam in the service
   * @param exam int
   */
  function setExam (exam) { service.question.exam = exam }

  /**
   * this function sets the values that isDifferent() uses for comparision
   */
  function setLastSave () {
    lastQuestion = angular.copy(service.question)
    lastData = angular.copy(service.data)
  }

  /**
   * clears the last save when there is a failure on save
   */
  function clearLastSave () {
    lastQuestion = angular.copy(service.emptyQuestion)
    lastData = {
      '0': {
        title: '',
        items: {'0': '', '1': '', '2': '', '3': '', '4': ''}
      }
    }
  }

  /**
   * sets the preview question using the question data
   * @param previewQuestionData {{background: string, core: string, correctAnswer: int, explanation: string, optionsArray: [], question: string}}
   */
  function setPreview (previewQuestionData) { service.preview = angular.merge({}, service.emptyQuestion, previewQuestionData) }

  /**
   * merges the given status message into the status messages array
   * @param status {{isDynamic: boolean, isPublished: boolean, isValid: boolean, messages: []}}
   */
  function setStatus (status) {
    // timeout is used here to trigger a digest cycle in Angular
    $timeout(() => {
      service.status.messages.splice(0)
      angular.merge(service.status, status)
    })
  }

  /**
   * notifies the user when a question has issues that prevent it from being saved
   * such as invalid images/media links
   * @returns {*}
   */
  function showWarning () {
    return $mdDialog.show({
      templateUrl: 'partials/templates/builder/warning_save_dialog.html',
      controller: 'builderWarningSaveDialogController',
      controllerAs: 'vm',
      disableParentScroll: false
    })
  }

  /**
   * asynchronously verifies that the question is valid
   * @param callback function to be called when verification has finished
   */
  function verifyEntireQuestion (callback) {
    // service.status.urgentErrorMessages is undefined on first save
    // so on first save we create it.
    if (!service.status.urgentErrorMessages) {
      service.status.urgentErrorMessages = []
    }// TODO validate question options here if chess
    async.parallel({
      'Options': async.apply(verifyQuestionOptions),
      'Question': async.apply(verifyQuestion),
      'Data': async.apply(verifyQuestionRelatedData),
      'Data Title': async.apply(verifyRelatedDataTitle)
    }, populateErrors)
    // if errors are found pushes them to the urgentErrorMessages
    function populateErrors (err, result) {
      if (err) return callback(err)
      // forEach of the results
      async.forEachOf(result, (data, key, callback) => {
        // sets string based off of the current result being checked.
        let errorMessage = (key.indexOf('Title') !== -1) ? `${key} cannot be empty.` : `${key} contains an invalid image link.`
        // if data === false then current question part/result is valid
        if (data === false) {
          // if errorMessage is in urgentErrorMessages
          if (service.status.urgentErrorMessages.indexOf(errorMessage) !== -1) {
            $timeout(() => {
              // remove errorMessage from urgentErrorMessages because it is now valid
              service.status.urgentErrorMessages.splice(service.status.urgentErrorMessages.indexOf(errorMessage), 1)
            })
          }
          return callback()
        }
        // if errorMessage is NOT in urgentErrorMessages then push it in.
        if (service.status.urgentErrorMessages.indexOf(errorMessage) === -1) {
          $timeout(() => { service.status.urgentErrorMessages.push(errorMessage) })
        }
        // eslint-disable-next-line
        callback('invalid')
      }, displayToast)
    }

    /**
     * this is the callback called when populateErrors is finished
     * @param isValid boolean
     */
    function displayToast (isValid) {
      // if populateErrors was called back with 'invalid', then entire question becomes invalid
      if (isValid === 'invalid') {
        service.status.isValid = false
      } else {
        // if no error messages AND callback was NOT returned with 'invalid' set question to valid
        if (service.status.messages.length === 0) service.status.isValid = true
      }
      if (isValid === 'invalid') {
        $timeout(() => {
          // if more than one error tell user about multiple errors, else show them specific error
          (service.status.urgentErrorMessages.length > 1)
            ? showToast('Question not saved due to multiple issues.', true)
            : showToast(service.status.urgentErrorMessages[0], true)
        })
      }
      callback(isValid)
    }
  }

  /**
   * verifies that the options are valid
   * @param callback function to be called when verification has finished
   */
  function verifyQuestionOptions (callback) {
    // loop through each question option and return true as soon as the regex
    // matches a question option.
    optionsValid = Object.keys(service.data[0].items).some((outerKey) => {
      return regExMarkdownImg.test(service.data[0].items[outerKey].toLowerCase())
    })
    callback(null, optionsValid)
  }

  /**
   * verifies that the question fields are valid
   * @param callback function to be called when verification has finished
   */
  function verifyQuestion (callback) {
    // loop through each question field and return true as soon as the regex
    // matches a question field.
    questionsValid = Object.keys(service.question).some((outerKey) => {
      if (partsToCheck.indexOf(outerKey) === -1) return false
      return regExMarkdownImg.test(service.question[outerKey].toLowerCase())
    })
    callback(null, questionsValid)
  }

  /**
   * verifies that the related data fields are valid
   * @param callback function to be called when verification has finished
   * @returns {*} used to cancel execution when image found
   */
  function verifyQuestionRelatedData (callback) {
    // if no related data return valid.
    if (!service.data[1]) return callback(null, false)
    // loop through each question related data item and return true as soon as the regex
    // matches a question related data item.
    dataValid = Object.keys(service.data[1].items).some((outerKey) => {
      return regExMarkdownImg.test(service.data[1].items[outerKey].toLowerCase())
    })
    callback(null, dataValid)
  }

  /**
   * verfies that the realted data title field is valid
   * @param callback function to be called when verification has finished
   * @returns {*} used to cancel execution if no title found
   */
  function verifyRelatedDataTitle (callback) {
    // if related data does not exist OR its title is undefined return invalid
    if (service.data[1] && service.data[1].title === undefined) return callback(null, true)
    // else return true
    callback(null, false)
  }

  /**
   * adds related data to the service
   */
  function addRelatedData () {
    let length = Object.keys(service.data).length
    service.data[length.toString()] = {
      title: 'placeholder_data_title',
      items: {'0': '', '1': '', '2': '', '3': '', '4': ''}
    }
  }

  /**
   * This function allows a user to toggle options between correct and
   * incorrect. Parent is the data node. 0 is options 1 or > 1 is related data
   * This function seems confusing because of the reference to parent but that
   * is just future proofing. Atm parent will always be 0 because we only allow one
   * options node. However this function is written in a way that would allow multiple
   * option nodes
   * @param parent int is always 0 to reference options node
   * @param child int reference to specific option
   */
  function toggleOption (parent, child) {
    // correct object
    let correct = service.question.options.correct
    // incorrect object
    let incorrect = service.question.options.incorrect
    // this line checks that the parent(data node) exist in the correct object
    // and that the child(data item) exists in that array.
    if (correct.hasOwnProperty(parent.toString()) && correct[parent].indexOf(child) >= 0) {
      // remove option from correct
      let idx = correct[parent].indexOf(child)
      correct[parent].splice(idx, 1)
      // if no incorrect options create an array for them
      if (!incorrect[parent]) { incorrect[parent] = [] }
      incorrect[parent].push(child)
      // this line checks that the parent(data node) exist in the incorrect object
      // and that the child(data item) exists in that array.
    } else if (incorrect.hasOwnProperty(parent.toString()) && incorrect[parent].indexOf(child) >= 0) {
      let idx = incorrect[parent].indexOf(child)
      incorrect[parent].splice(idx, 1)
      // if no correct options create an array for them
      if (!correct[parent]) { correct[parent] = [] }
      correct[parent].push(child)
    }
    service.question.options.correct = correct
    service.question.options.incorrect = incorrect
  }

  /**
   * used to set css class for correct/incorrect option
   * @param parent is always 0 to reference options node
   * @param child reference to specific option
   * @returns {boolean}
   */
  function isOptionCorrect (parent, child) {
    let correct = service.question.options.correct
    return (correct.hasOwnProperty(parent.toString()) && correct[parent].indexOf(child) >= 0)
  }

  /**
   * This function allows a user to toggle related data on/off. Similar to the above
   * function it seems more complicated than it is because of the parent reference.
   * However this is just future proofing to allow for multiple related data nodes.
   * Atm parent will always be 1 because we only allow one related data node.
   * @param parent is always 1 to reference related data node
   * @param child reference to specific option
   */
  function toggleRelatedData (parent, child) {
    if (!service.question.relatedData) { service.question.relatedData = {} }
    let relatedData = service.question.relatedData
    // sets array for the parent nodes linked items.
    relatedData[parent] = relatedData.hasOwnProperty(parent.toString()) ? relatedData[parent] : []
    let childIdx = relatedData[parent].indexOf(child)
    // checks the relatedData[parent] array for the current item. If not present push it in
    // if present pull it out.
    childIdx >= 0 ? relatedData[parent].splice(childIdx, 1) : relatedData[parent].push(child)
    if (!relatedData[parent].length) { delete relatedData[parent] }
    service.question.relatedData = relatedData
  }

  /**
   * used to set css class for related data
   * @param parent is always 1 to reference related data node
   * @param child reference to specific option
   * @returns {boolean} true if related data has parent and parent has child
   */
  function isRelatedData (parent, child) {
    let relatedData = service.question.relatedData || {}
    return (relatedData.hasOwnProperty(parent.toString()) && relatedData[parent].indexOf(child) >= 0)
  }

  /**
   * returns an array of relatedData with the label of the data node (for ment.io)
   * Used to search the fields in the question for references to relatedData
   * @returns {Array} [{$$hashKey: string, label: string}]
   */
  function getRelatedData () {
    let newArray = []
    let options = [...service.question.optionsArray]
    let data = angular.copy(service.data)
    let relatedData = Object.keys(data).filter(e => options.indexOf(parseInt(e)) === -1)
    angular.forEach(data, (val, key) => {
      if (relatedData.indexOf(key.toString()) >= 0) newArray.push({label: val.title})
    })
    return newArray
  }

  /**
   * deletes the related node
   * @param nodeIdx int index of related data node
   */
  function deleteRelatedData (nodeIdx) {
    delete service.question.relatedData[nodeIdx.toString()]
    delete service.data[nodeIdx.toString()]
  }

  /**
   * adds a node item to either the options or related data
   * @param nodeIdx int index of node to add the item to
   */
  function addNodeItem (nodeIdx) {
    let data = service.data[nodeIdx.toString()]
    let length = Object.keys(data.items).length
    if (!data || length > 9) return
    // sets newest item's value to an empty string
    data.items[length.toString()] = ''
    let optionsArray = service.question.optionsArray
    // sets the newest option to be incorrect
    if (optionsArray.indexOf(parseInt(nodeIdx)) >= 0 || optionsArray.indexOf(nodeIdx.toString()) >= 0) {
      if (service.question.options.incorrect[nodeIdx].indexOf(length) === -1) {
        service.question.options.incorrect[nodeIdx].push(length)
      }
    }
  }

  /**
   * deletes a node item from either options or related data
   * and ensures that that the rest of the node items retain their values
   * @param nodeIdx int index of node to delete item from. 0 for options, 1 for related data
   * @param itemIdx int index of item in the node to delete
   */
  function deleteNodeItem (nodeIdx, itemIdx) {
    if (Object.keys(service.data[nodeIdx].items).length <= 5) return
    let correct = service.question.options.correct
    correct[nodeIdx] = spliceArr(correct[nodeIdx], parseInt(itemIdx))
    let incorrect = service.question.options.incorrect
    incorrect[nodeIdx] = spliceArr(incorrect[nodeIdx], parseInt(itemIdx))
    let relData = service.question.relatedData
    relData[nodeIdx] = spliceArr(relData[nodeIdx], parseInt(itemIdx))
    let dataItems = service.data[nodeIdx].items
    delete dataItems[itemIdx]
    for (let key in dataItems) {
      if (parseInt(key) > parseInt(itemIdx)) {
        let newKey = parseInt(key) - 1
        let tempVal = dataItems[key]
        dataItems[newKey] = tempVal
        delete dataItems[key]
      }
    }
  }

  /**
   * Returns the updated arrays after the deleted node has been spliced out
   * @param arr array to be spliced
   * @param itemIdx int idx of item in array to be spliced
   * @returns {any[]} updated array with appropriate item removed
   */
  function spliceArr (arr = [], itemIdx) {
    let idx = arr.indexOf(parseInt(itemIdx)) >= 0 ? arr.indexOf(parseInt(itemIdx)) : arr.indexOf(itemIdx.toString())
    if (arr.length && idx >= 0) arr.splice(idx, 1)
    let x = arr.map(i => {
      if (i > itemIdx) {
        i -= 1
        return i
      } else {
        return i
      }
    })
    return x
  }

  /**
   * This function is used to display the current issue with the question
   * @param text string toast text e.g. your question has been saved
   * @param err string error message to be displayed on toast
   */
  function showToast (text, err) {
    let toastClass = err ? 'md-warn' : 'md-success'
    $mdToast.show(
      $mdToast.simple()
        .textContent(text)
        .position('top right')
        .toastClass(toastClass)
        .hideDelay(3000)
        // HACKY! REMOVE WHEN THIS IS CLOSED https://github.com/angular/material/issues/9295
        // This was added to stop a pop on Windows when the toast was present
        .parent(document.getElementById('toast-container'))
    )
  }
}

export { BuilderHandler }
