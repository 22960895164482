'use strict'
/* global Ps */
/**
 * mcqs-perfect-scrollbar directive
 * @param $timeout
 * @param $window
 * @returns {{restrict: string, link: link}}
 */
function mcqsPerfectScrollbar ($timeout, $window) {
  'ngInject'
  return {
    restrict: 'A',
    link: link
  }

  /**
   * This is an extension of perfect-scrollbar and based off angular-perfect-scrollbar.
   * https://github.com/noraesae/perfect-scrollbar
   * https://github.com/itsdrewmiller/angular-perfect-scrollbar
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attribute attached to HTML element
   */
  function link (scope, elem, attrs) {
    let jqWindow = angular.element($window)
    let lastScroll = 0
    // isHovering stops scrolling to bottom if user's mouse is in chat window
    let isHovering = false
    // Delay activation to make sure content is loaded.
    $timeout(activate, 150)

    /**
     * initialisation function that sets up perfect scroll, sets up event triggers and
     * observes the question content to trigger reloads
     */
    function activate () {
      Ps.initialize(elem[0], {stopPropagationOnClick: false})
      // used in chat window to scroll a user to the bottom automatically
      if (attrs.scrollDown && attrs.scrollDown === 'true') elem[0].scrollTop = elem[0].scrollHeight
      update('mouseenter')
      elem.on('mouseenter', handleMouseenter)
      elem.on('mouseleave', handleMouseleave)
      elem.bind('$destroy', destroy)
      // fires off update when content changes
      if (attrs.refreshOnChange || typeof attrs.refreshOnChange === 'string') attrs.$observe('refreshOnChange', update)
      if (attrs.refreshOnResize) jqWindow.on('resize', update)
      // when y axis is scrolled update last scroll to the correct scrollTop
      elem[0].addEventListener('ps-scroll-y', e => { lastScroll = e.target.scrollTop })
    }

    /**
     * calls checkReverseScroll on update
     * @param e
     */
    function update (e) { scope.$evalAsync(() => checkReverseScroll(e)) }

    /**
     * updates the scroll position when the page is resized
     * @param e
     * @returns {*}
     */
    function checkReverseScroll (e) {
      // 300ms to allow the md-card to update.
      $timeout(() => Ps.update(elem[0]), 300)
      let entered = (e === 'mouseenter')
      if (elem[0].nodeName === 'TEXTAREA') return
      if (!entered && (!attrs.scrollDown || attrs.scrollDown !== 'true')) {
        // if on change element has fixedScroll maintain scroll position, else reset it
        elem[0].scrollTop = attrs.hasOwnProperty('fixedScroll') ? lastScroll : 0
        return elem[0].scrollTop
      }
      // if not entered or hovering. Keep scroll at top
      if (!entered && isHovering === false) $timeout(() => { elem[0].scrollTop = elem[0].scrollHeight })
    }

    /**
     * sets boolean if mouse enters element
     */
    function handleMouseenter () {
      isHovering = true
      update('mouseenter')
    }

    /**
     * stes boolean when mouse leaves element
     */
    function handleMouseleave () { isHovering = false }

    /**
     * destroys all bindings
     */
    function destroy () {
      jqWindow.off('resize', update)
      elem.off('mouseenter', handleMouseenter)
      Ps.destroy(elem[0])
    }
  }
};

export { mcqsPerfectScrollbar }
