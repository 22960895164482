'use strict'
import { adminImageViewController } from './admin_image_management_view_controller'
import { adminAnimationViewController } from './admin_animation_management_view_controller'

/**
 * route configuration for AdminImageManagementModule
 * @param $stateProvider
 */
function routeConfig ($stateProvider) {
  'ngInject'
  $stateProvider
    .state('root.auth.admin.image_management', {
      url: '/relatedImages',
      views: {
        'main@': {
          templateUrl: 'partials/views/admin_image_management.html',
          controller: adminImageViewController,
          controllerAs: 'vm'
        }
      }
    })
      .state('root.auth.admin.animation_management', {
        url: '/animations',
        views: {
          'main@': {
            templateUrl: 'partials/views/admin_animation_management.html',
            controller: adminAnimationViewController,
            controllerAs: 'vm'
          }
        }
      })
}

export { routeConfig }
