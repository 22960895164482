'use strict'
import { default as Shepherd } from 'tether-shepherd'

/**
 * This service is where the steps and actions for the question builder tour are defined
 * @param $document
 * @param $timeout
 * @param $state
 * @param ProfileService
 * @param BuilderHandler
 * @returns {{mediator, builderTour: Tour, shepherd, builderStartTour: builderStartTour, builderFinishTour: builderFinishTour, builderRestartTour: builderRestartTour, builderNewQuestion: builderNewQuestion, showBuilderTour: (function(): boolean)}}
 * @constructor
 */
function TourBuilderHandler ($document, $timeout, $state, ProfileService, BuilderHandler) {
  'ngInject'

  // !$document[0].querySelector('.some-element') is used repeatedly in this
  // file to check for existing elements and then deciding the next step.
  // This is because a user has some freedom during the tour and they can
  // affect the flow of the tour.

  const defaults = {
    classes: 'shepherd-theme-arrows-plain-buttons mcqs-shepherd-head mcqs-shepherd'
  }

  const service = {
    mediator: new Shepherd.Evented(),
    builderTour: new Shepherd.Tour({defaults: defaults}),
    shepherd: Shepherd,
    builderStartTour: builderStartTour,
    builderFinishTour: builderFinishTour,
    builderRestartTour: builderRestartTour,
    builderNewQuestion: builderNewQuestion,
    showBuilderTour: showBuilderTour
  }

  const defaultTether = {
    // attachment properties for tour
    // quite a few steps manually set this again. Should be refactored to
    // instead use defaultTether.constraints
    constraints: [
      {
        // attaches the element to the same element as the scroll bar
        to: 'scrollParent',
        // sets the tour element to attach to its relevant element. Flipping
        // above or below based on spacing unless explicitly stated
        attachment: 'together',
        // stops the tour element from leaving the screen
        pin: true
      }
    ]
  }

  const cancelButton = {
    text: 'Cancel',
    classes: ' md-button ',
    action: () => {
      ProfileService.setBuilderTourPreference(1)
      service.builderTour.cancel()
    }
  }

  const buttons = [cancelButton, {
    text: 'Next',
    classes: ' md-button md-primary md-raised',
    action: () => service.builderTour.next()
  }
  ]

  let counter = 0

  service.mediator.on('start-builder-tour', () => $timeout(() => { service.builderTour.start() }))

  service.mediator.on('check-save-step', () => $timeout(() => { checkSaveStep(true) }))

  service.builderTour.addStep({
    id: '1',
    title: 'Background',
    text: 'Set the scene for your question.<br/>The background should only<br/>contain relevant information.',
    attachTo: '#builder_background top',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '2',
    title: 'Question',
    text: "Make sure it's clear what you're asking.<br>The best questions are short and concise.",
    attachTo: '#builder_question top',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '3',
    title: 'Teaching Point',
    text: "This gets at the heart of your question.<br>What's the one thing you want somebody<br>to take away from your question?",
    attachTo: '#builder_teaching_point top',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '4',
    title: 'Explanation',
    text: 'Elaborate: why is the correct option right,<br/>and why are the others wrong.',
    attachTo: '#builder_explanation top',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '5',
    title: 'Correct/Incorrect',
    text: 'Click this icon to toggle correct/incorrect options.',
    attachTo: '#option_input_0 left',
    tetherOptions: {
      offset: '-5 35',
      defaultTether
    },
    advanceOn: 'clicks',
    buttons: buttons
  }).addStep({
    id: '6',
    text: 'Every question needs at least 5 options:<br/>At least one of which should be correct<br/> and at least four of which should be incorrect.',
    attachTo: '#option_input_0 top',
    advanceOn: 'clicks',
    tetherOptions: {
      target: '#option_input_0',
      attachment: 'top right',
      targetAttachment: 'bottom center',
      constraints: [
        {
          to: '#option_input_0',
          pin: true
        }
      ]
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          $timeout(service.mediator.trigger('set-first-tab-item'))
        }
      }
    ]
  }).addStep({
    id: '7',
    text: 'Want to show something? Upload an image<br/>from your computer and drag it to your question.',
    attachTo: '#builder_upload_image top',
    advanceOn: 'clicks',
    tetherOptions: {
      target: '#builder_upload_image',
      attachment: 'bottom center',
      targetAttachment: 'top center',
      constraints: [
        {
          to: '#builder_upload_image',
          pin: true
        }
      ]
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          $timeout(service.mediator.trigger('set-second-tab-item'))
        }
      }
    ]
  }).addStep({
    id: '8',
    text: 'You can upload up to five valid Soundcloud,</br> YouTube, and Vimeo links and drag them into your question.',
    attachTo: '#link-input top',
    advanceOn: 'clicks',
    tetherOptions: {
      target: '#link-input',
      attachment: 'top center',
      targetAttachment: 'bottom center',
      constraints: [
        {
          to: '#link-input',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          $timeout(service.mediator.trigger('set-third-tab-item'))
        }
      }
    ]
  }).addStep({
    id: '9',
    attachTo: '#mathjax-input top',
    text: 'Here you will be able to write Mathjax equations in real time.',
    advanceOn: 'clicks',
    tetherOptions: {
      target: '#mathjax-input',
      attachment: 'bottom center',
      targetAttachment: 'top center',
      constraints: [
        {
          to: '#mathjax-input',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '10',
    attachTo: '#mathjax-output top',
    text: 'Here you will see the output of the above Mathjax equation.',
    advanceOn: 'clicks',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: {
      target: '#mathjax-output',
      attachment: 'bottom center',
      targetAttachment: 'top center',
      constraints: [
        {
          to: '#mathjax-output',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '11',
    text: 'Your question is saved whenever you make a change but<br/>you can save it manually at any time by clicking here.',
    attachTo: '#builder_save_button top',
    advanceOn: 'clicks',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: {
      attachment: 'bottom right',
      targetAttachment: 'top right',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          checkSaveStep()
        }
      }
    ]
  }).addStep({
    id: '12',
    title: 'Question status',
    text: 'This will show you what parts</br>of your question are invalid.</br>Try fixing these and the publish</br>button will become enabled.',
    attachTo: '.fa-angle-double-down right',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom center',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    advanceOn: 'clicks',
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          service.builderTour.show('15')
        }
      }
    ]
  }).addStep({
    id: '13',
    title: 'Question status',
    text: 'This will show you what parts</br>of your question are invalid.</br>Try fixing these and the publish</br>button will become enabled.',
    attachTo: '.fa-angle-double-up right',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom center',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    advanceOn: 'clicks',
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          service.builderTour.show('15')
        }
      }
    ]
  }).addStep({
    id: '14',
    title: 'Question status',
    text: 'This will show you when your question is valid an ready to be published.',
    attachTo: '.builder__status right',
    scrollTo: true,
    scrollToHandler: customScroller,
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom center',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    advanceOn: 'clicks',
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          service.builderTour.show('15')
        }
      }
    ]
  }).addStep({
    id: '15',
    title: 'Need formatting?',
    text: 'You can use Markdown to improve <br/>the look of your question.<br>Click here to learn about Markdown.',
    attachTo: '.fa-lightbulb-o top',
    advanceOn: 'clicks',
    tetherOptions: {
      attachment: 'bottom right',
      targetAttachment: 'top middle',
      offset: '0 -33px',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '16',
    text: 'When your question is valid <br/>you can make it public.<br/>Clicking here publishes or <br/>unpublishes your question.',
    attachTo: '#builder_publish_button top',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '17',
    title: 'Dynamic Questions',
    scrollTo: true,
    scrollToHandler: customScroller,
    text: 'You can create more complex questions here,<br> using dynamic data allows you to create several questions quickly.',
    attachTo: '.fa-plus bottom',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          if (!$state.includes('root.auth.builder.dynamic')) {
            BuilderHandler.addRelatedData()
            $state.go('root.auth.builder.dynamic')
            checkForElement('.fa-link')
          } else {
            service.builderTour.next()
          }
        }
      }
    ]
  }).addStep({
    id: '18',
    title: 'Data Title',
    text: 'Data is used to insert dynamic content into your question.<br>Typing <i>@placeholder_data_title</i> in your question<br>will create a placeholder for a data item below.<br><b>Pro tip:</b> Give your data a catchy group title, e.g. colour, animal, medication.',
    attachTo: 'mcqs-builder-related-data input bottom',
    advanceOn: 'clicks',
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom center',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '19',
    text: 'Click this icon to toggle your data on and off.',
    attachTo: 'mcqs-builder-related-data md-icon bottom',
    advanceOn: 'clicks',
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom left',
      offset: '0 15px',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '20',
    text: 'You can add dynamic data to your questions here.<br><b>Pro tip:</b> make each item similar so they fit nicely into your question, e.g.<br>The sky was <i>@colour</i> (blue, navy, grey).',
    attachTo: 'mcqs-builder-related-data textarea bottom',
    advanceOn: 'clicks',
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom middle',
      offset: '0 30px',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '21',
    title: 'Adding More Data',
    text: 'You can always add more data here if you need it.',
    attachTo: 'mcqs-builder-related-data .fa-plus bottom',
    advanceOn: 'clicks',
    tetherOptions: {
      attachment: 'top left',
      targetAttachment: 'bottom middle',
      offset: '0 32px',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          if ($document[0].querySelectorAll('mcqs-builder-related-data textarea').length === 5) $timeout(() => { BuilderHandler.addNodeItem(1) })
          $timeout(() => service.builderTour.next(), 100)
        }
      }
    ]
  }).addStep({
    id: '22',
    title: 'Deleting Data',
    text: 'Click here to delete a data item.',
    attachTo: 'mcqs-builder-related-data .fa-times-circle-o bottom',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          if ($document[0].querySelectorAll('mcqs-builder-related-data textarea').length > 5) $timeout(() => BuilderHandler.deleteNodeItem(1, 0))
          $timeout(() => service.builderTour.next(), 1000)
        }
      }
    ]
  }).addStep({
    id: '23',
    title: 'Want to remove dynamic questions?',
    text: 'Click this icon to delete the dynamic data and<br> revert back to a normal question.',
    attachTo: 'mcqs-builder-related-data .fa-close bottom',
    advanceOn: 'clicks',
    tetherOptions: {
      attachment: 'bottom left',
      targetAttachment: 'top center',
      constraints: [
        {
          to: 'mcqs-builder-related-data .fa-close',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: [
      cancelButton,
      {
        text: 'Next',
        classes: ' md-button md-primary md-raised',
        action: () => {
          BuilderHandler.deleteRelatedData(1)
          $state.go('root.auth.builder.edit')
          $timeout(() => service.builderTour.next(), 100)
        }
      }
    ]
  }).addStep({
    id: '24',
    title: 'Preview',
    text: 'Want to see what your question will look like?<br>You can preview it at any time.<br> This is currently disabled for the tour.',
    attachTo: '.fa-eye bottom',
    advanceOn: 'clicks',
    tetherOptions: defaultTether,
    buttons: buttons
  }).addStep({
    id: '25',
    title: 'New/Clone',
    text: 'Click here to open an existing question or hover to reveal<br>shortcuts. You can create a new blank question or clone<br>your current question to make variations of it quickly.',
    attachTo: '.fa-bars left',
    advanceOn: 'clicks',
    tetherOptions: {
      attachment: 'top right',
      targetAttachment: 'bottom center',
      constraints: [
        {
          to: '.fa-bars',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '26',
    title: 'Change exam',
    text: 'Use this button to switch the exam that<br/>your current question is associated with.',
    attachTo: '.fa-exchange bottom',
    advanceOn: 'clicks',
    tetherOptions: {
      target: '.fa-exchange',
      attachment: 'top left',
      targetAttachment: 'bottom left',
      offset: '0 16px',
      constraints: [
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true
        }
      ]
    },
    buttons: buttons
  }).addStep({
    id: '27',
    title: 'Keep practicing',
    text: 'Click here to go back to the Question page.',
    attachTo: '.md-fab bottom',
    advanceOn: 'clicks',
    tetherOptions: {
      'attachment': 'top right',
      'targetAttachment': 'bottom center'
    },
    buttons: [
      {
        text: 'Finish',
        classes: ' md-button md-primary md-raised',
        action: () => {
          ProfileService.setBuilderTourPreference(1)
          service.builderTour.cancel()
        }
      }
    ]
  })

  return service

  /**
   * this checks the page for an element exists for a step to attach itself to
   * if the element is not found it will skip onto the next step
   * @param element
   */
  function checkForElement (element) {
    let correctOption = $document[0].querySelector(element)
    if (!correctOption) {
      counter++
      // look for element repeatedly, if never found end tour
      if (counter >= 1000) service.mediator.trigger('finish-builder-tour')
      // trigger digest cycle, then check for element again
      $timeout(() => { checkForElement(element) })
    } else {
      counter = 0
      // trigger digest cycle before moving to next step
      $timeout(() => { service.builderTour.next() })
    }
  }

  /**
   * allows the page to scroll smoothly to the next step
   * @param a
   */
  function customScroller (a) {
    a.scrollIntoView({behavior: 'smooth'})
  }

  function builderStartTour () { service.mediator.trigger('start-builder-tour') }

  function builderRestartTour () { service.mediator.trigger('restart-builder-tour') }

  function builderFinishTour () { service.mediator.trigger('finish-builder-tour') }

  function builderNewQuestion () { service.mediator.trigger('builder-new-question') }

  function showBuilderTour () { return ProfileService.getPreferences().builderTour === '0' }

  /**
   * logic for triggering save in builder tour to showcase question errors
   * @param override boolean used to force a save during tour
   */
  function checkSaveStep (override) {
    if ($document[0].querySelector('.fa-angle-double-down') && !override) {
      service.mediator.trigger('save-question')
    } else if ($document[0].querySelector('.fa-angle-double-down')) {
      service.builderTour.show('12')
    } else if ($document[0].querySelector('.fa-angle-double-up')) {
      service.builderTour.show('13')
    } else {
      service.builderTour.show('14')
    }
  }
}

export { TourBuilderHandler }
