'use strict'
/**
 * mcqs-cmd-save directive
 * @param $parse
 * @param $document
 * @returns {{restrict: string, link: link}}
 */
function mcqsCmdSave ($parse, $document) {
  'ngInject'
  return {
    restrict: 'A',
    link
  }

  /**
   * This allows the user to save a question while in the builder by pressing Ctrl + s
   * @param scope angular scope
   * @param elem HTML element
   * @param attrs attributes attached to HTML element
   */
  function link (scope, elem, attrs) {
    activate()

    /**
     * initialisation function that attaches keydown trigger
     */
    function activate () {
      $document.on('keydown', performSave)
      elem.bind('$destroy', () => $document.off('keydown', performSave))
    }

    /**
     * performs save if user presses the correct key combination
     * @param e key event
     * @returns {boolean}
     */
    function performSave (e) {
      // meta key is command key on Mac, keyCode 83 is 's'
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault()
        // $parse allows us to convert an angular expression into a javascript function
        if (mcqsCmdSave) $parse(attrs.mcqsCmdSave)(scope)
        return false
      }
    }
  }
}

export { mcqsCmdSave }
