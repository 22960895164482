'use strict'
//  This controller only applies to the question/profile exam select
/**
 * controller to handle exam select dialog in relation to the question/profile exam select
 * @param $mdDialog
 * @param $state
 * @param $mdSidenav
 * @param noCancel
 * @param currentExam
 * @param ExamService
 * @param ChatService
 * @param TourQuestionHandler
 */
function examSelectDialogController ($mdDialog, $state, $mdSidenav, noCancel, currentExam, ExamService, ChatService, TourQuestionHandler) {
  'ngInject'
  /* jshint validthis:true */
  let vm = this

  vm.errorMessage = 'Could not change exam. Please try again.'
  vm.selectedCategory = undefined
  vm.categories = []
  vm.exams = []
  vm.isLoaded = false

  vm.close = $mdDialog.cancel

  vm.selectExam = selectExam
  vm.getExams = getExams

  activate()

  /**
   * initialisation function that checks if user is new, forces an category selection
   * or sets exam to the users selected exam
   */
  function activate () {
    // if user has just signed up dont allow them to cancel dialog
    vm.hideCancel = noCancel || false
    vm.currentCategory = currentExam ? currentExam.categoryId : undefined
    ExamService.getExamsByCategory().then(success, failure)

    /**
     * success handler for ExamService.getExamsByCategory()
     * @param data object returned from ExamService.getExamsByCategory()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      vm.isLoaded = true
      vm.categories = data
      if (vm.currentCategory >= 0) {
        setDefaultCategory()
        vm.getExams()
      }
    }

    /**
     * error handler for ExamService.getExamsByCategory()
     * @param error object returned from ExamService.getExamsByCategory()
     */
    function failure (error) { console.error('Get exams failed:', error) }
  }

  /**
   * changes the selected exam
   * @param examId int selected exam id
   */
  function selectExam (examId) {
    ChatService.close()
    vm.showError = false
    ExamService.changeExam(examId).then(success, failure)

    /**
     * success handler for ExamService.changeExam()
     * updates the current exam and closes the profile sidenav
     * @param data object returned from ExamService.changeExam()
     */
    function success (data) {
      if (!data || data.status) return failure(data)
      ExamService.updateCurrentExam(examId)
      let { activeTour } = TourQuestionHandler.shepherd
      if (activeTour) TourQuestionHandler.questionTour.cancel()
      vm.close().then(() => {
        $mdSidenav('profile').isOpen()
          ? $mdSidenav('profile').close().then(reloadState)
          : reloadState()
        ChatService.open()
      })
    }

    /**
     * error handler for ExamService.changeExam()
     * @param error object returned from ExamService.changeExam()
     */
    function failure (error) {
      console.error('Exam change failed:', error)
      vm.showError = true
    }
  }

  /**
   * gets all exams from current category
   */
  function getExams () {
    vm.exams = vm.categories[vm.selectedCategory].exams
  }

  /**
   * reloads the question state
   */
  function reloadState () {
    $state.includes('root.auth.question') || $state.includes('root.auth.builder')
      ? $state.go('root.auth.question', {}, { reload: true })
      : $state.go('root.auth.flashcard', {}, { reload: true })
  }

  /**
   * sets the default exam to the first exam in the selected category
   */
  function setDefaultCategory () {
    for (let i = 0; i < vm.categories.length; i++) {
      if (vm.categories[i].categoryId === vm.currentCategory) {
        vm.selectedCategory = i
        break
      }
    }
  }
}

export { examSelectDialogController }
