'use strict'

/**
 * controller that manages to overall comment view
 * @param reqObj
 * @param CommentService
 */
function commentViewController (reqObj, CommentService) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.questionObject = reqObj
  vm.comments = CommentService.comments

  activate()

  /**
   * clears previous comments and fetches new comments on comment view load
   */
  function activate () {
    CommentService.clearComments()
    CommentService.getComments(reqObj).then(
      data => {},
      error => console.error('Failed to get comments:', error)
    )
  }
}

export { commentViewController }
