import { mcqsLottiePlayer } from './mcqs_lottie_player'
import { mcqsLottieMediaPlayer } from './mcqs_lottie_media_player'
import { LottieService } from './lottie_service'
import { LottieHandler } from './lottie_handler'

(() => {
  'use strict'
  angular
    .module('lottie', [

    ])
    .directive('mcqsLottiePlayer', mcqsLottiePlayer)
    .directive('mcqsLottieMediaPlayer', mcqsLottieMediaPlayer)
    .factory('LottieService', LottieService)
    .factory('LottieHandler', LottieHandler)
})()

export default 'lottie'
