'use strict'
function globalStyleController ($rootScope, $state) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this

  const landingStates = [
    '/',
    '/product',
    '/examples',
    '/aboutUs',
    '/contact'
  ]

  checkState($state.current.url)

  $rootScope.$on('$stateChangeStart', (event, to) => {
    checkState(to.url)
    removeVideoAskWidget(to.url)
  })

  function checkState (toUrl) {
    if (landingStates.includes(toUrl)) {
      vm.bodyClass = 'landing__body'
    } else {
      vm.bodyClass = ''
    }
  }

  function removeVideoAskWidget (toUrl) {
    const widget = document.getElementsByClassName('videoask-embed__button--nnvG1')
    if (widget[0] && !landingStates.includes(toUrl)) {
      widget[0].style.display = 'none'
    } else if (widget[0]) {
      widget[0].style.display = 'block'
    }
  }
}

export { globalStyleController }
