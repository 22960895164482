const mcqsBuilderChessOptions = {
  templateUrl: 'partials/templates/builder/builder_chess_options.html',
  controller: mcqsBuilderChessOptionsController,
  controllerAs: 'vm'
}

function mcqsBuilderChessOptionsController (BuilderHandler, BuilderService, $timeout) {
  'ngInject'
  /* validthis:true */
  const vm = this

  vm.setIsFlashcard = setIsFlashcard

  vm.setDifficulty = setDifficulty

  vm.setStarStyle = setStarStyle

  function setIsFlashcard () { $timeout(() => { saveQuestion() }) }

  function setDifficulty (diff) {
    BuilderService.setDifficulty(diff)
      .then((data) => {
        if (data && !data.status) {
          BuilderHandler.question.difficulty = diff
        } else {
          failure(data)
        }
      })
  }

  function setStarStyle (idx) {
    if (idx === 1) {
      return {color: '#ECC94B'}
    } else if (idx === 2 && BuilderHandler.question.difficulty > 0.3) {
      return {color: '#ECC94B'}
    } else if (idx === 3 && BuilderHandler.question.difficulty >= 0.8) {
      return {color: '#ECC94B'}
    }
  }

  function saveQuestion () {
    BuilderHandler.verifyEntireQuestion((err) => {
      if (err) return failure(err)
      BuilderService.saveQuestion().then(success, failure)
    })
  }

  function success (data) { (data && !data.status) ? BuilderHandler.setStatus(data) : failure(data) }

  function failure (error) { console.error('Question did not save:', error) }
}

export { mcqsBuilderChessOptions }
