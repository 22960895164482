'use strict'
/**
 * mcqs-facebook-login directive
 * @type {{template: string, controller: facebookLoginController, controllerAs: string}}
 */
const mcqsFacebookLogin = {
  template: `
        <md-button
            class="bttn bttn__auth bttn--facebook"
            ng-click="vm.signIn()"
            ng-disabled="!vm.cookieService.cookieConsent"
        >
        Sign in with Facebook
        </md-button>
    `,
  controller: facebookLoginController,
  controllerAs: 'vm'
}

/**
 * The facebookLoginController exposes the FBService to the html
 * @param $mdDialog
 * @param $state
 * @param $rootScope
 * @param $timeout
 * @param AuthenticationService
 * @param ExamService
 * @param FBService
 * @param ProfileService
 * @param CookieConsentService
 */
function facebookLoginController ($mdDialog, $state, $rootScope, $timeout, AuthenticationService, ExamService, FBService, ProfileService, CookieConsentService) {
  'ngInject'
  /* jshint validthis:true */
  const vm = this
  // binds to the DOM to disable facebook login button until cookies are accepted.
  vm.cookieService = CookieConsentService

  vm.signIn = signIn

  /**
   * Calls the Facebook service to log a user in. If successful passes the response to socialSignin.
   * If unsuccessful displays a dialog to the user showcasing the error
   */
  function signIn () {
    /* Unfortunately this timeout is needed because FF has a problem handling asynchronous tasks.
            https://github.com/angular/angular.js/issues/10083 */
    $timeout(() => {
      FBService.login().then(
        response => {
          // check if facebook responded with a full name or a first name
          // if full name then split it.
          if (response.name && !response.first_name) { response = parseName(response) }
          if (response.email) { return socialSignIn(response) }
          response.message = 'No email address was returned from Facebook. You need a valid email address to enter MCQS.'
          $state.go('root.signup', response)
        },
        error => {
          console.error('Facebook login failed:', error)
          $mdDialog.show($mdDialog
            .alert()
            .title('Facebook sign in failed')
            .disableParentScroll(false)
            .textContent(`
                            You could not be signed in with Facebook at this time.
                            A browser extension may be causing this problem.
                            Please check your settings and refresh before trying again.
                        `)
            .ok('Okay')
          )
        }
      )
    })
  }

  /**
   * Passes the users details to the AuthenticationService to update their sign in details on the BE
   * then creates a session, updates their current exam and transitions them to the question page
   * @param name users full name
   * @param first_name string
   * @param last_name string
   * @param email string
   * @param id int users id
   * @param picture string users picture to use fro their profile picture
   * @param cover string picture to use as cover picture in account sidenav
   */
  // eslint-disable-next-line
  function socialSignIn ({ name, first_name, last_name, email, id, picture, cover }) {
    AuthenticationService.socialSignIn({
      name,
      // eslint-disable-next-line
      firstName: first_name,
      // eslint-disable-next-line
      surname: last_name,
      email,
      network: 'Facebook',
      socialId: id,
      profilePic: picture && picture.data ? picture.data.url : null,
      coverPic: cover && cover.source ? cover.source : null
    }).then(
      data => {
        AuthenticationService.createSession(data.loginId, data.userId, data.token, email)
        ProfileService.setPreferences(data.preferences)
        ExamService.updateCurrentExam(data.currentExam)
        $state.go('root.auth.question')
        if (data.currentExam === null || data.currentExam === 0) {
          let examCheck = $rootScope.$on('examSelected', () => {
            checkPassword(data)
            examCheck()
          })
        } else { checkPassword(data) }
      },
      error => console.error('Social sign in with Facebook failed:', error)
    )
  }

  function checkPassword (data) {
    AuthenticationService.checkUserPasswordExists().then((res) => {
      if (!res.password) {
        AuthenticationService.passwordCheckDialog(data.userId, $rootScope)
      }
    }, (err) => { console.error('Error checking user password: ', err) })
  }

  /**
   * splits the full name string into a first and last name
   * @param obj object that contains a users details returned from Facebook
   * @returns {{last_name: string, first_name: string}}
   */
  function parseName (obj) {
    let nameArray = obj.name.split(' ')
    obj.last_name = nameArray.pop()
    obj.first_name = nameArray.join(' ')
    return obj
  }
}

export { mcqsFacebookLogin }
