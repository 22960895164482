import { mcqsExamSelect } from './exam_select'
import { ExamService } from './exam_service'
import { examSelectDialogController } from './exam_select_dialog_controller';

(() => {
  'use strict'
  angular
    .module('exam', [])
    .component('mcqsExamSelect', mcqsExamSelect)
    .controller('examSelectDialogController', examSelectDialogController)
    .factory('ExamService', ExamService)
})()

export default 'exam'
